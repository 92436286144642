/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as Common from "../actions/Common.js";
import styles from "./BottomSheet.module.scss";

import checkIcon from "../assets/icons/round-check-gr-2.svg";
import adImage from "../assets/images/86580.png";

const BootomSheet = ({ visible }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      Common.getCookie("chada_bottom_popup") !== null &&
      Common.getCookie("chada_bottom_popup") === "close"
    ) {
      setSheetStatus(false);
    } else {
      setSheetStatus(visible);
    }
  }, [visible]);

  const [sheetStatus, setSheetStatus] = useState(false);

  // 팝업 닫기 (24시간)
  const setPopupNotExposed = () => {
    Common.setCookie("chada_bottom_popup", "close", 1);
    setSheetStatus(false);
  };
  // 팝업 닫기
  const handleSheetStatus = () => {
    setSheetStatus(false);
  };

  return (
    <>
      {sheetStatus && (
        <div className={styles.bottomCard}>
          <div className={styles.cardHeader}>
            <div className={`${styles.inner}` + " global-width"}>
              <button type={styles.button} onClick={setPopupNotExposed}>
                오늘 하루 열지 않음
                <img src={checkIcon} alt="" />{" "}
              </button>
              <button type={styles.button} onClick={handleSheetStatus}>
                닫기
              </button>
            </div>
          </div>
          <div className={styles.cardBody}>
            <div className={`${styles.inner}` + " global-width"}>
              <div className={styles.contents}>
                <div className={styles.img}>
                  <img src={adImage} alt="" />
                </div>
                <div className={styles.desc}>
                  <p className={styles.mainTxt}>
                    원하는 매물을 기다리고 있나요?
                  </p>
                  <p className={styles.subTxt}>
                    알림 설정을 통해 매물이 입고되는대로 빠르게 확인하실 수
                    있습니다.
                  </p>
                </div>
              </div>

              <div className="button-form">
                <button
                  type="button"
                  className={`${styles.btnAlert}` + " btn btn-xl btn--primary"}
                  onClick={() => {
                    navigate("/mypage", {
                      state: {
                        tabIndex: 5,
                      },
                    });
                  }}
                >
                  알림 설정 하러가기
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BootomSheet;
