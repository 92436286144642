/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import ApiController from "../../api/ApiController";
import Toast from "../../components/Toast.js";
import ModalConfirm from "../../components/ModalConfirm.js";
import Dashboard from "./Dashboard.js";
import styles from "./ManagerPhone.module.scss";

// API URL
import { baseUrl } from "../../api";
const BASE_URL = baseUrl();
const MYDATA_URL = "/api/user/member/v1/me";
const MANAGER_URL = "/api/user/member/v1/manager";
const MANAGER_CHECK_URL = "/api/user/join/v1/exist_manager_phone";
const AUTH_SEND_URL = "/api/user/auth/v1/phone/send";
const AUTH_VALIDATION_URL = "/api/user/auth/v1/phone/validation";

const ManagerPhone = () => {
  const navigate = useNavigate();

  /****************************************
   * TODO 초기 세팅
   ****************************************/
  //* 로그인 상태
  const [loginStatus, setLoginStatus] = useState(false);
  const [isManagerCheck, setIsManagerCheck] = useState(false);
  const [isManagerAuth, setIsManagerAuth] = useState(false);
  const [findDataId, setFindDataId] = useState();
  const [authNum, setAuthNum] = useState();

  // 버튼 상태
  let [btnState, setBtnState] = useState(false);

  useEffect(() => {
    fetchData();
  }, [loginStatus]);

  const fetchData = async () => {
    try {
      //* 토큰 상태 확인
      const result = await fetchMyData();

      if (!result) {
        setLoginStatus(false);

        setModal({
          open: true,
          dim: true,
          title: "로그인이 필요합니다.",
          message: "지금 로그인 하시겠습니까?",
          notxt: "취소",
          oktxt: "로그인 하기",
          callback2: () => {
            navigate("/login");
          },
        });
      } else {
        setLoginStatus(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /****************************************
   * TODO 항목별 API 목록
   ****************************************/
  // 토큰 상태 확인
  const fetchMyData = async () => {
    try {
      const response = await axios.get(BASE_URL + MYDATA_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setInputs({
        managerName: response.data.data.rentCarCorperation.managerName,
        managerDivision: response.data.data.rentCarCorperation.managerDivision,
        managerPosition: response.data.data.rentCarCorperation.managerPosition,
        managerPhone: response.data.data.rentCarCorperation.managerPhone,
        managerEmail: response.data.data.rentCarCorperation.managerEmail,
      });
      return response;
    } catch (err) {
      handleToast(err.response?.data.message);
      return false;
    }
  };

  // 담당자 연락처 중복확인
  const fetchCheckManager = async () => {
    try {
      const response = await axios.get(
        BASE_URL + MANAGER_CHECK_URL + `?manager_phone=${managerPhone}`
      );

      return true;
    } catch (err) {
      handleToast(err.response?.data.message);
      return false;
    }
  };

  // 담당자 연락처 인증하기
  const handleCheckManagerPhone = async () => {
    if (managerPhone.length === 0) {
      handleToast("연락처를 입력해주세요.");
      return;
    }
    if (managerPhone.length <= 10) {
      handleToast("담당자 연락처를 확인해주세요.");
      return;
    }
    if ((await fetchCheckManager()) === false) {
      setIsManagerCheck(false);
      return;
    }
    //TODO phone에다가 인증번호 날리기
    fetchSend();
    setIsManagerCheck(true);
  };

  // 담당자 연락처 인증번호 확인
  const handleCheckManagerAuth = async () => {
    //TODO 인증확인시에 setIsManagerAuth(true) 처리
    if ((await fetchValidation()) === false) {
      setIsManagerAuth(false);
      return;
    }
    setIsManagerAuth(true);
    handleToast("인증되었습니다.");
  };

  // 인증번호 발송
  const fetchSend = async () => {
    try {
      const response = await axios.post(BASE_URL + AUTH_SEND_URL, {
        phone: managerPhone,
      });

      setFindDataId(response.data.data.id);
      setAuthNum(response.data.data.authNum);
    } catch (err) {
      handleToast(err.response?.data.message);
      return false;
    }
  };

  // 인증번호 확인
  const fetchValidation = async (authKeys) => {
    try {
      const response = await axios.post(BASE_URL + AUTH_VALIDATION_URL, {
        id: findDataId,
        phone: managerPhone,
        authNum: managerPhoneAuth,
      });

      return true;
    } catch (err) {
      handleToast(err.response?.data.message);
      return false;
    }
  };

  /****************************************
   * TODO 이벤트 핸들러
   ****************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(
        BASE_URL + MANAGER_URL,
        {
          managerName: managerName,
          managerDivision: managerDivision,
          managerPosition: managerPosition,
          managerPhone: managerPhone,
          managerEmail: managerEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      setModal({
        open: true,
        dim: true,
        title: "완료 되었습니다.",
        message: "",
        notxt: "",
        oktxt: "확인",
        callback2: () => {
          navigate("/mypage", {
            state: {
              tabIndex: 10,
            },
          });
        },
      });
    } catch (err) {
      handleToast(err.response?.data.message);
    }
  };

  /****************************************
   * TODO 유효성 검사
   ****************************************/
  // 입력 데이터 세팅
  const [inputs, setInputs] = useState({
    managerName: "", // 담당자 성함
    managerDivision: "", // 담당 부서
    managerPosition: "", // 직급
    managerPhone: "", // 담당자 연락처
    managerPhoneAuth: "", // 인증번호
    managerEmail: "", // 담당자 이메일
  });
  const {
    managerName,
    managerDivision,
    managerPosition,
    managerPhone,
    managerPhoneAuth,
    managerEmail,
  } = inputs;

  const updateInputData = (e) => {
    const { name, value } = e.target;

    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  // 폼 유효성 검사
  useEffect(() => {
    if (
      managerPhone != "" &&
      managerPhoneAuth != "" &&
      isManagerAuth === true
    ) {
      setBtnState(true);
    } else {
      setBtnState(false);
    }
    // input 별로 유효성 검사 처리

    ///////////////////////////////////
  }, [
    managerPhone,
    managerPhoneAuth,
    isManagerAuth,
  ]);

  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  /****************************************
   * TODO 모달 & 토스트 팝업 상태
   ****************************************/
  //* 모달
  const [modal, setModal] = useState({
    open: false,
    dim: true,
    title: "",
    message: "",
    notxt: "",
    oktxt: "확인",
    callback1: false,
    callback2: false,
  });

  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = (txt) => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);

  return (
    <>
      {/* 대시보드 */}
      <div className={`${styles.dashboard}` + " global-width"}>
        <Dashboard />
      </div>
      {/* 담당자 정보 변경 */}
      <form
        className={`${styles.managerPhone}` + " form-width"}
        action=""
        onSubmit={handleSubmit}
      >
        <div className={styles.content}>
          <div className={`${styles.mainTxt}` + " heading-1"}>
            담당자 정보 변경
          </div>
          <div className={styles.formContainer}>
            {/* 담당자 연락처 */}
            <div className="input-form required">
              <p className="form-title">
                <span>담당자 연락처</span>
                <em className="title-sub">(-없이 입력하세요)</em>
              </p>
              <div className="form-field disabled">
                <div className="input-element">
                  <input
                    type="number"
                    name="managerPhone"
                    className=""
                    placeholder="- 없이 입력"
                    onChange={updateInputData}
                    value={managerPhone}
                    onInput={maxLengthCheck}
                    maxLength="11"
                  />
                </div>
                <button
                  type="button"
                  className="btn-auth"
                  onClick={handleCheckManagerPhone}
                >
                  {isManagerCheck === false ? "인증하기" : "인증번호 재전송"}
                </button>
              </div>
            </div>
            {isManagerCheck === true && (
              <div className="input-form required">
                <div className="form-field">
                  <div className="input-element">
                    <input
                      type="text"
                      name="managerPhoneAuth"
                      className=""
                      placeholder="인증번호 입력"
                      onChange={updateInputData}
                      value={managerPhoneAuth}
                    />
                  </div>
                  <button
                    type="button"
                    className="btn-auth"
                    onClick={handleCheckManagerAuth}
                  >
                    인증확인
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={`${styles.buttons}` + " button-form"}>
          <button
            type="submit"
            className={"btn btn-xl btn--outline_gray"}
            onClick={() => {
              navigate("/mypage", {
                state: {
                  tabIndex: 10,
                },
              });
            }}
          >
            돌아가기
          </button>
          <button
            type="submit"
            className={
              btnState ? "btn btn-xl btn--primary" : "btn btn-xl btn--disabled"
            }
            disabled={!btnState}
          >
            완료
          </button>
        </div>
      </form>

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />

      {/* 모달 */}
      {modal.open && (
        <ModalConfirm
          dim={modal.dim}
          setModal={setModal}
          title={modal.title}
          message={modal.message}
          notxt={modal.notxt}
          oktxt={modal.oktxt}
          callback1={modal.callback1}
          callback2={modal.callback2}
        />
      )}
    </>
  );
};

export default ManagerPhone;
