/* eslint-disable */
import React, { useEffect } from "react";

import styles from "./Toast.module.scss";

const Toast = ({ visible, msg = "메세지 없음" }) => {
  return (
    <>
      <div
        className={
          visible ? `${styles.toast} ${styles.active}` : `${styles.toast}`
        }
      >
        <span>{msg}</span>
      </div>
    </>
  );
};

export default Toast;
