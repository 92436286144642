import axios from "axios";

const token = localStorage.getItem("access_token");

const instance = axios.create({
  baseUrl: "http://chada-dev.kr",
  timeout: 1000,
});

instance.interceptors.request.use(
  (config) => {
    config.headers["Content-Type"] = "application/json; charset=utf-8";
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    // console.log(response);

    return response.data;
  },
  (error) => {
    console.log(error);
    return error;
  }
);

export default instance;
