/* eslint-disable */
import React, { useEffect, useState } from "react";

import styles from "./Modal_region.module.scss";

const regionList = [
  { id: 1, value: "서울", isToggle: true },
  { id: 2, value: "경기", isToggle: false },
  { id: 3, value: "인천", isToggle: false },
  { id: 4, value: "강원", isToggle: false },
  { id: 5, value: "대전", isToggle: false },
  { id: 6, value: "경북", isToggle: false },
  { id: 7, value: "경남", isToggle: false },
  { id: 8, value: "대구", isToggle: false },
  { id: 9, value: "울산", isToggle: false },
  { id: 10, value: "전북", isToggle: false },
  { id: 11, value: "전남", isToggle: false },
  { id: 12, value: "광주", isToggle: false },
  { id: 13, value: "제주", isToggle: false },
];

const Modal_region = ({ deleter }) => {
  // 보유 쿠폰 목록 Checkbox
  const [btnStatus, setBtnStatus] = useState(false);

  const [regionStatus, setRegionStatus] = useState(regionList);

  const toggleRegionStatus = (id) => {
    setRegionStatus(
      regionStatus.map((item) =>
        item.id === id ? { ...item, isToggle: !item.isToggle } : item
      )
    );
  };

  useEffect(() => {
    // object/array 내 특정 값 확인
    if (regionStatus.some((item) => item.isToggle == true)) {
      setBtnStatus(true);
    } else {
      setBtnStatus(false);
    }
  }, [regionStatus]);

  return (
    <>
      <div className={`${styles.modal} ${styles.modalRegion}`}>
        <div className={`${styles.wrapper} ${styles.dim}`}>
          <div className={styles.container}>
            <button
              className={styles.btn_close}
              onClick={() => deleter(false)}
            ></button>
            <div className={styles.title}>
              <span>지역 선택</span>
            </div>
            <div className={styles.body}>
              <div className={styles.itemList}>
                {regionStatus.map((item) => {
                  return (
                    <button
                      type="button"
                      className={
                        item.isToggle
                          ? `${styles.itemBtn} ${styles.active}`
                          : `${styles.itemBtn}`
                      }
                      key={item.id}
                      onClick={() => {
                        toggleRegionStatus(item.id);
                      }}
                    >
                      {item.value}
                    </button>
                  );
                })}
              </div>
            </div>
            <div className={styles.footer}>
              <div className="button-form">
                <button
                  type="button"
                  className={
                    btnStatus
                      ? "btn btn-xl btn--primary"
                      : "btn btn-xl btn--disabled"
                  }
                >
                  선택 완료
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal_region;
