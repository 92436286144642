/* eslint-disable */
/****************************************
 * TODO 공용 함수
 ****************************************/
// DATE 타입 변환
export const dateToString = (date, type) => {
  let timeString = "";

  if (type === "start") {
    timeString = "T00:00:00";
  } else if (type === "end") {
    timeString = "T23:59:59";
  }
  return (
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date.getDate().toString().padStart(2, "0") +
    timeString
  );
};

// 문자 입력 방지 (input)
export const isString = (e) => {
  if (e.key === "." || e.key === "-" || (e.key >= 0 && e.key <= 9)) {
    return true;
  }
  return false;
};

//* 현재 연도 반환
export const getYear = () => {
  const now = new Date(); // 현재 날짜 및 시간
  const currentYear = now.getFullYear();

  const newArr = [];

  for (let i = currentYear - 45; i <= currentYear; i++) {
    newArr.push(i);
  }

  return newArr;
};
//* 천단위 콤마 삽입
export const commaFormat = (str) => {
  const comma = (str) => {
    str = String(str);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
  };
  const uncomma = (str) => {
    str = String(str);
    return str.replace(/[^\d]+/g, "");
  };
  return comma(uncomma(str));
};

//* 차량 등록일로부터 경과개월수 반환
export const getMonths = (value) => {
  const today = new Date();
  const timeValue = new Date(value);

  const betweenTime = Math.floor(
    (today.getTime() - timeValue.getTime()) / 1000 / 60
  );

  const betweenTimeHour = Math.floor(betweenTime / 60);
  // console.log(betweenTimeHour / 24);
};

// 경과 개월수 반환
export const getElapsedMonths = (value) => {
  // value가 ex)20200401 형태로 들어와야 함.
  const nowTime = getCurrentTime();
  const startDate = nowTime.split("T")[0];
  const endDate = value.replace(/(\d{4})(\d{2})(\d{2})/, "$1-$2-$3"); // value에 하이픈 기호 삽입 ex)2020-04-01

  const start_date_arr = startDate.split("-");
  const end_date_arr = endDate.split("-");

  // 개월수 비교
  const numberOfMonths =
    (end_date_arr[0] - start_date_arr[0]) * 12 +
    (end_date_arr[1] - start_date_arr[1]);
  if (numberOfMonths > 12) {
    console.log("달별 range 최대 12개월" + numberOfMonths);
    return;
  }

  return Math.abs(numberOfMonths); // 음수를 정수로 변환 후 반환
};

//* 현재 일자/시각 구하기
export const getCurrentTime = () => {
  let today = new Date();

  let year = today.getFullYear();
  let month = ("0" + (today.getMonth() + 1)).slice(-2);
  let day = ("0" + today.getDate()).slice(-2);
  let hours = ("0" + today.getHours()).slice(-2);
  let minutes = ("0" + today.getMinutes()).slice(-2);
  let seconds = ("0" + today.getSeconds()).slice(-2);
  let milliseconds = today.getMilliseconds();

  let dateString = year + "-" + month + "-" + day;
  let timeString = hours + ":" + minutes + ":" + seconds + "." + milliseconds;

  return dateString + "T" + timeString;
};
//* 상품화 뱃지 (등록된 시간으로부터 48시간 이내)
export const isNewData = (value) => {
  const today = new Date();
  const timeValue = new Date(value);

  const betweenTime = Math.floor(
    (today.getTime() - timeValue.getTime()) / 1000 / 60
  );

  const betweenTimeHour = Math.floor(betweenTime / 60);
  if (betweenTimeHour <= 48) {
    return true;
  }
  return false;
};
//* 쿠키
export const setCookie = (cname, cvalue, exdays) => {
  let d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + expires;
};
export const getCookie = (cname) => {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1);
    if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
  }
  return "";
};
export const delCookie = (cname) => {
  document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};
export const getCookieJson = (cname) => {
  let res = getCookie(cname);

  if (res === "") {
    return null;
  } else {
    return JSON.parse(res);
  }
};
