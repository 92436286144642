/* eslint-disable */
const loginUser = () => {
  return {
    type: "LOGIN",
  };
};

const logoutUser = () => {
  return {
    type: "LOGOUT",
  };
};

export default {
  loginUser,
  logoutUser,
};
