/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import ApiController from "../../api/ApiController";
import * as Common from "../../actions/Common.js";
import ModalConfirm from "../../components/ModalConfirm.js";
import Toast from "../../components/Toast.js";
import styles from "./Profile.module.scss";

import moreIcon from "../../assets/icons/depth-sm-gr.svg";

// API URL
import { baseUrl } from "../../api";
const BASE_URL = baseUrl();
const CHECKPW_URL = "/api/user/member/v1/password/validation";
const MYDATA_URL = "/api/user/member/v1/me";
const DELIVERY_URL = "/api/user/member/v1/delivery_location";

const Profile = () => {
  const navigate = useNavigate();

  //TODO GET & SET 유저아이디
  useEffect(() => {
    getUserId();
  }, []);

  const getUserId = async () => {
    try {
      const response = await axios.get(BASE_URL + MYDATA_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setUserId(response.data.data.loginId);
    } catch (err) {
      console.log(err);
    }
  };
  //TODO

  //TODO 비밀번호 및 본인 확인 API 전송
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        BASE_URL + CHECKPW_URL,
        { loginId: userId, password: userPw },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      if (response.status === 200) {
        setCheckPwStatus(true);
      }
    } catch (err) {
      if (err.response?.status === 401) {
        handleToast("세션이 만료되었습니다.");
        return;
      } else {
        handleToast(err.response?.data.message);
      }
    }
  };
  //TODO

  // 유저 아이디
  const [userId, setUserId] = useState("");
  const [userPw, setUserPw] = useState("");

  // 비밀번호 체크 여부
  const [checkPwStatus, setCheckPwStatus] = useState(false);

  /****************************************
   * TODO 초기 세팅
   ****************************************/
  const [loading, setLoading] = useState(true);
  const [myData, setMyData] = useState([]);
  const [deliveryLocation, setDeliveryLocation] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);

    try {
      fetchMyData();
      fetchDelivery();

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  /****************************************
   * TODO 항목별 API 목록
   ****************************************/
  // 내 정보 가져오기
  const fetchMyData = async () => {
    try {
      const response = await axios.get(BASE_URL + MYDATA_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setMyData(response.data.data);
    } catch (err) {
      if (err.response?.status === 401) {
        setModal({
          open: true,
          dim: true,
          title: "세션이 만료되었습니다.",
          message: "로그인 화면으로 이동합니다.",
          notxt: "",
          oktxt: "확인",
          callback2: () => {
            navigate("/logout");
          },
        });
        return;
      } else {
        handleToast(err.response?.data.message);
        return false;
      }
    }
  };

  // 배송지 목록 가져오기
  const fetchDelivery = async () => {
    try {
      const response = await axios.get(BASE_URL + DELIVERY_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setDeliveryLocation(response.data.data);
    } catch (err) {
      handleToast(err.response?.data.message);
    }
  };

  /****************************************
   * TODO 모달 & 토스트 팝업 상태
   ****************************************/
  //* 모달
  const [modal, setModal] = useState({
    open: false,
    dim: true,
    title: "",
    message: "",
    notxt: "",
    oktxt: "확인",
    callback1: false,
    callback2: false,
  });

  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = (txt) => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);

  return (
    <>
      <div className={`${styles.profile}` + " global-width"}>
        {!checkPwStatus ? (
          <div className={styles.checkingPw}>
            <p className={styles.desc}>
              개인정보를 안전하게 보호하기 위하여 <br />
              비밀번호를 한번 더 입력해주세요.
            </p>

            <form className={styles.frmCont} action="" onSubmit={handleSubmit}>
              {/* default */}
              <div className="input-form disabled">
                <div className="form-field">
                  <div className="input-element">
                    <input
                      type="text"
                      className=""
                      placeholder="아이디 (이메일)"
                      value={userId}
                      readOnly
                    />
                  </div>
                </div>
                <p className="alert-msg error">에러 메시지입니다.</p>
                <p className="alert-msg success">인증을 완료했습니다 :)</p>
              </div>
              {/* default */}
              <div className="input-form mt-15">
                <div className="form-field">
                  <div className="input-element">
                    <input
                      type="password"
                      className=""
                      onChange={(e) => setUserPw(e.target.value)}
                      placeholder="비밀번호"
                      value={userPw}
                      required
                    />
                  </div>
                </div>
                <p className="alert-msg error">에러 메시지입니다.</p>
                <p className="alert-msg success">인증을 완료했습니다 :)</p>
              </div>
              {/* button-default */}
              <div className="button-form mt-40">
                <button type="submit" className="btn btn-xl btn--primary">
                  확인
                </button>
              </div>
            </form>
          </div>
        ) : (
          <>
            <div className={styles.header}>
              <h1>회원 정보</h1>
            </div>
            <div className={styles.contents}>
              <div className={styles.boxList}>
                {/* 아이디 정보 */}
                <div className={styles.box}>
                  <div className={styles.boxHeader}>
                    <div className={styles.item}>
                      <div className={styles.itemTit}>아이디 정보</div>
                      <Link to={"/resetPw"} className={styles.btnDepth}>
                        비밀번호 변경하기 <img src={moreIcon} alt="" />
                      </Link>
                    </div>
                  </div>
                  <div className={styles.boxContent}>
                    <div className={styles.itemList}>
                      <div className={styles.item}>
                        <div className={styles.itemTit}>아이디</div>
                        <div className={styles.itemDesc}>{userId}</div>
                      </div>
                      <div className={styles.item}>
                        <div className={styles.itemTit}>비밀번호</div>
                        <div className={styles.itemDesc}>
                          {[...Array(userPw.length)].map((value, index) => (
                            <span key={index}>*</span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 배송지 정보 */}
                <div className={styles.box}>
                  <div className={styles.boxHeader}>
                    <div className={styles.item}>
                      <div className={styles.itemTit}>배송지 정보</div>
                      <Link to={"/addressBook"} className={styles.btnDepth}>
                        배송지 관리 <img src={moreIcon} alt="" />
                      </Link>
                    </div>
                  </div>
                  <div className={styles.boxContent}>
                    <div className={styles.itemList}>
                      {deliveryLocation.map((item, index) => {
                        return (
                          <div className={styles.item} key={index}>
                            <div className={styles.itemTit}>
                              배송지{index + 1}
                            </div>
                            <div className={styles.itemDesc}>
                              {item.address} {item.addressDetail}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                {/* 법인 정보 */}
                <div className={styles.box}>
                  <div className={styles.boxHeader}>
                    <div className={styles.item}>
                      <div className={styles.itemTit}>법인 정보</div>
                      <Link to={"/corpModify"} className={styles.btnDepth}>
                        변경하기 <img src={moreIcon} alt="" />
                      </Link>
                    </div>
                  </div>
                  <div className={styles.boxContent}>
                    <div className={styles.itemList}>
                      <div className={styles.item}>
                        <div className={styles.itemTit}>사업자등록증</div>
                        <div className={styles.itemDesc}>
                          <a
                            target="_blank"
                            href={myData.rentCarCorperation.licenseImgUrl}
                            className={styles.btnFileDownload}
                          >
                            {myData.rentCarCorperation.licenseImgOriginFileName}
                          </a>
                        </div>
                      </div>
                      <div className={styles.item}>
                        <div className={styles.itemTit}>법인명</div>
                        <div className={styles.itemDesc}>
                          {myData.rentCarCorperation.name}
                        </div>
                      </div>
                      <div className={styles.item}>
                        <div className={styles.itemTit}>사업자등록번호</div>
                        <div className={styles.itemDesc}>
                          {myData.rentCarCorperation.licenseNumber}
                        </div>
                      </div>
                      <div className={styles.item}>
                        <div className={styles.itemTit}>법인등록번호</div>
                        <div className={styles.itemDesc}>
                          {myData.rentCarCorperation.corpRegNumber}
                        </div>
                      </div>
                      <div className={styles.item}>
                        <div className={styles.itemTit}>사업자 주소</div>
                        <div className={styles.itemDesc}>
                          {myData.rentCarCorperation.address}{" "}
                          {myData.rentCarCorperation.addressDetail}
                        </div>
                      </div>
                      <div className={styles.item}>
                        <div className={styles.itemTit}>대표자 연락처</div>
                        <div className={styles.itemDesc}>
                          {myData.rentCarCorperation.repPhone}
                        </div>
                      </div>
                      <div className={styles.item}>
                        <div className={styles.itemTit}>대표 번호</div>
                        <div className={styles.itemDesc}>
                          {myData.rentCarCorperation.corpPhone}
                        </div>
                      </div>
                      <div className={styles.item}>
                        <div className={styles.itemTit}>환불 계좌 정보</div>
                        <div className={styles.itemDesc}>
                          {myData.rentCarCorperation.refundBankName}{" "}
                          {myData.rentCarCorperation.refundBankAccount}{" "}
                          {myData.rentCarCorperation.refundBankOwner}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 담당자 정보 */}
                <div className={styles.box}>
                  <div className={styles.boxHeader}>
                    <div className={styles.item}>
                      <div className={styles.itemTit}>담당자 정보</div>
                      <Link to={"/managerModify"} className={styles.btnDepth}>
                        변경하기 <img src={moreIcon} alt="" />
                      </Link>
                    </div>
                  </div>
                  <div className={styles.boxContent}>
                    <div className={styles.itemList}>
                      <div className={styles.item}>
                        <div className={styles.itemTit}>담당자 성함</div>
                        <div className={styles.itemDesc}>
                          {myData.rentCarCorperation.managerName}
                        </div>
                      </div>
                      <div className={styles.item}>
                        <div className={styles.itemTit}>담당 부서</div>
                        <div className={styles.itemDesc}>
                          {myData.rentCarCorperation.managerDivision}
                        </div>
                      </div>
                      <div className={styles.item}>
                        <div className={styles.itemTit}>직급</div>
                        <div className={styles.itemDesc}>
                          {myData.rentCarCorperation.managerPosition}
                        </div>
                      </div>
                      {/* <div className={styles.item}>
                        <div className={styles.itemTit}>담당자 연락처</div>
                        <div className={styles.itemDesc}>
                          {myData.rentCarCorperation.managerPhone}
                        </div>
                      </div> */}
                      <div className={styles.item}>
                        <div className={styles.itemTit}>담당자 이메일</div>
                        <div className={styles.itemDesc}>
                          {myData.rentCarCorperation.managerEmail}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 담당자 연락처 */}
                <div className={styles.box}>
                  <div className={styles.boxHeader}>
                    <div className={styles.item}>
                      <div className={styles.itemTit}>담당자 연락처</div>
                      <Link to={"/managerPhone"} className={styles.btnDepth}>
                        변경하기 <img src={moreIcon} alt="" />
                      </Link>
                    </div>
                  </div>
                  <div className={styles.boxContent}>
                    <div className={styles.itemList}>
                      <div className={styles.item}>
                        <div className={styles.itemTit}>담당자 연락처</div>
                        <div className={styles.itemDesc}>
                          {myData.rentCarCorperation.managerPhone}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />

      {/* 로그인 모달 */}
      {modal.open && (
        <ModalConfirm
          dim={modal.dim}
          setModal={setModal}
          title={modal.title}
          message={modal.message}
          notxt={modal.notxt}
          oktxt={modal.oktxt}
          callback1={modal.callback1}
          callback2={modal.callback2}
        />
      )}
    </>
  );
};

export default Profile;
