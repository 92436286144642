/* eslint-disable */
import React, { useMemo, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Select from "react-select";
import ModalConfirm from "../components/ModalConfirm.js";
import QuickMenu from "../components/QuickMenu.js";
import axios from "axios";

import * as Common from "../actions/Common.js";
import Toast from "../components/Toast.js";
import Share from "../components/Share.js";
import styles from "./Buy.module.scss";

import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";
import emptyIcon from "../assets/icons/empty.svg";

import { baseUrl } from "../api";
const BASE_URL = baseUrl();
const MYDATA_URL = "/api/user/member/v1/me";
const PRODUCT_URL = "/api/user/product/v1";
const SELLER_URL = "/api/user/data/v1/seller";
const CARMANUFACTURER_URL = "/api/user/data/v1/car_manufacturer";
const CARCOLOR_URL = "/api/user/data/v1/car_color";
const BANK_URL = "/api/user/data/v1/bank";
const CART_URL = `/api/user/product/v1/cart`;
const FAVORITE_URL = `/api/user/product/v1/favorite`;

const Buy = () => {
  const navigate = useNavigate();

  //* 로그인 상태
  const [loginStatus, setLoginStatus] = useState(false);

  /****************************************
   * TODO 초기 세팅
   ****************************************/
  //* 헤더 검색 키워드
  const location = useLocation();

  //* 데이터 세팅 및 화면 그리기
  const [loading, setLoading] = useState(true);
  const [loadItemStatus, setLoadItemStatus] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      //* 토큰 상태 확인
      const result = await fetchMyData();

      if (!result) {
        setLoginStatus(false);
      } else {
        setLoginStatus(true);

        // 장바구니 차량 세팅
        fetchCartData();
        //* 찜한 차량 세팅
        fetchFavoriteData();
      }

      fetchSeller();
      fetchManufacturer();
      fetchColor();
    } catch (error) {
      console.log(error);
    }
  };

  const [seller, setSeller] = useState([]); // 소유업체
  const [manufacturer, setManufacturer] = useState([]); // 제조사
  const [color, setColor] = useState([]); // 차 색상
  const [bank, setBank] = useState([]); // 차다 은행 계좌
  const [items, setItems] = useState([]); // 아이템 목록
  const [favoriteItems, setFavoriteItems] = useState([]); // 찜한 차량
  const [cartItems, setCartItems] = useState([]); // 장바구니 차량

  /****************************************
   * TODO Pagination Init
   ****************************************/
  const [pagination, setPagination] = useState([]);
  const [page, setPage] = useState(0); // 현재 페이지 번호
  const rangeSize = 10;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  /****************************************
   * TODO 항목별 API 목록
   ****************************************/
  // 토큰 상태 확인
  const fetchMyData = async () => {
    try {
      const response = await axios.get(BASE_URL + MYDATA_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      return response;
    } catch (err) {
      console.error(err.response?.data.message);
      return false;
    }
  };
  // 소유업체
  const fetchSeller = async () => {
    try {
      const response = await axios.get(BASE_URL + SELLER_URL);

      setSeller(response.data.data);
    } catch (err) {
      console.error(err.response?.data.message);
      return false;
    }
  };
  // 제조사
  const fetchManufacturer = async () => {
    try {
      const response = await axios.get(BASE_URL + CARMANUFACTURER_URL);

      setManufacturer(response.data.data);
    } catch (err) {
      console.error(err.response?.data.message);
      return false;
    }
  };
  // 차 색상
  const fetchColor = async () => {
    try {
      const response = await axios.get(BASE_URL + CARCOLOR_URL);

      setColor(response.data.data);

      // parameter state 색상 초기화 (모든 색상 string 집어넣기)
      // const newArr = [];
      // response.data.data.map((item) => {
      //   newArr.push(item.nameKr);
      // });
      // setParameter((prev) => ({
      //   ...prev,
      //   carColors: newArr,
      // }));
    } catch (err) {
      console.error(err.response?.data.message);
      return false;
    }
  };
  // 상품 목록
  const fetchItems = async loading => {
    if (loading === true) {
      setLoading(true);
    }

    try {
      let urlParams;
      parameter.product_yn === "Y"
        ? (urlParams = `&searchKeyWord=${parameter.searchKeyWord}&countryType=${parameter.countryType}&carTypes=${parameter.carTypes}&product_yn=${parameter.product_yn}&carManufacturerNames=${parameter.carManufacturerNames}&minCarPrice=${parameter.minCarPrice}&maxCarPrice=${parameter.maxCarPrice}&minMileage=${parameter.minMileage}&maxMileage=${parameter.maxMileage}&carColors=${parameter.carColors}&fuelTypes=${parameter.fuelTypes}&capitalCorpNames=${parameter.capitalCorpNames}&sort=${parameter.sort}`)
        : (urlParams = `&searchKeyWord=${parameter.searchKeyWord}&countryType=${parameter.countryType}&carTypes=${parameter.carTypes}&carManufacturerNames=${parameter.carManufacturerNames}&minCarPrice=${parameter.minCarPrice}&maxCarPrice=${parameter.maxCarPrice}&minMileage=${parameter.minMileage}&maxMileage=${parameter.maxMileage}&carColors=${parameter.carColors}&fuelTypes=${parameter.fuelTypes}&capitalCorpNames=${parameter.capitalCorpNames}&sort=${parameter.sort}`);

      const response = await axios.get(BASE_URL + PRODUCT_URL + `?page=${page}&size=${rangeSize}&ids=${parameter.ids}&sort=productStatus,asc` + urlParams, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setPagination(response.data.pagination);
      setItems(response.data.data);
      setLoadItemStatus(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return false;
    }
  };
  // 장바구니 차량
  const fetchCartData = async () => {
    try {
      const response = await axios.get(BASE_URL + CART_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      setCartItems(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  // 장바구니 담기
  const fetchCartAdd = async (target, id) => {
    try {
      if (target === "off") {
        //? cart 추가
        const response = await axios.post(BASE_URL + CART_URL, id, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });

        handleToast("장바구니에 등록 되었습니다.");
      } else if (target === "on") {
        //? cart 해제
        const response = await axios.delete(BASE_URL + CART_URL, {
          data: id,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });

        handleToast("장바구니에서 등록 해제 되었습니다.");
      }
      // const response = await axios.post(BASE_URL + CART_URL, id, {
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      //   },
      // });

      // handleToast(response.data.message);
      fetchData();
    } catch (err) {
      handleToast(err.response?.data.message);
    }
  };
  // 찜한 차량
  const fetchFavoriteData = async () => {
    try {
      const response = await axios.get(BASE_URL + FAVORITE_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      setFavoriteItems(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  // 찜하기 설정 / 찜하기 해제
  const fetchFavoriteSet = async (target, id) => {
    try {
      if (target === "off") {
        //? favorite 추가
        const response = await axios.post(BASE_URL + FAVORITE_URL, id, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });

        handleToast("찜한차량으로 등록 되었습니다.");
      } else if (target === "on") {
        //? favorite 해제
        const response = await axios.delete(BASE_URL + FAVORITE_URL, {
          data: id,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });

        handleToast("찜한차량이 등록 해제 되었습니다.");
      }

      fetchData();
    } catch (err) {
      console.log(err);
    }
  };

  /****************************************
   * TODO 이벤트 핸들러
   ****************************************/
  // 공유하기 버튼 클릭
  const handleShare = async (carId, carData) => {
    setShareModal({
      open: true,
      dim: true,
      carId: carId,
      carData: carData,
    });
  };

  //* 장바구니 버튼 클릭
  const handleCart = async (e, id) => {
    e.preventDefault();

    if (!loginStatus) {
      setModal({
        open: true,
        dim: true,
        title: "로그인이 필요합니다.",
        message: "지금 로그인 하시겠습니까?",
        notxt: "취소",
        oktxt: "로그인 하기",
        callback2: () => {
          navigate("/login");
        },
      });
      return;
    }

    const isCart = e.currentTarget.dataset.cart;
    fetchCartAdd(isCart, [id]);
  };
  //* 찜하기 버튼 클릭
  const handleFavorite = async (e, id) => {
    e.preventDefault();

    if (!loginStatus) {
      setModal({
        open: true,
        dim: true,
        title: "로그인이 필요합니다.",
        message: "지금 로그인 하시겠습니까?",
        notxt: "취소",
        oktxt: "로그인 하기",
        callback2: () => {
          navigate("/login");
        },
      });
      return;
    }

    const isFavorite = e.currentTarget.dataset.favorite;
    fetchFavoriteSet(isFavorite, [id]);
  };

  /****************************************
   * TODO 필터, 카테고리
   ****************************************/
  // 필터 세팅
  const [filters, setFilters] = useState({
    filter1: true, // 카테고리
    filter2: true, // 차종
    filter3: true, // 제조사
    filter4: true, // 가격
    filter5: true, // 주행거리
    filter6: false, // 색상
    filter7: true, // 연료
    filter8: true, // 소유업체
  });
  const { filter1, filter2, filter3, filter4, filter5, filter6, filter7, filter8 } = filters;

  const onChangeFilters = e => {
    const { id } = e.target;

    setFilters({
      ...filters,
      [id]: !filters[id],
    });
  };

  // 필터 - 카테고리
  const [btnCategories, setBtnCategories] = useState({
    btnCtgr1: true, // 전체
    btnCtgr2: false, // 수입
    btnCtgr3: false, // 국산차
  });
  const { btnCtgr1, btnCtgr2, btnCtgr3 } = btnCategories;

  const onChangeCategoriesButtons = e => {
    const { id } = e.target;

    setBtnCategories(prev => ({
      ...prev,
      btnCtgr1: false,
      btnCtgr2: false,
      btnCtgr3: false,
      [id]: true,
    }));
  };

  // 필터 - 차종
  const [btnCarType, setBtnCarType] = useState({
    COMPACT: false, // 경차
    SMALL: false, // 소형
    SMALL_MID: false, // 준중형
    MID: false, // 중형
    BIG: false, // 대형
    SUV: false, // SUV
    RV: false, // RV
    VAN: false, // 기타
    ETC: false, // VAN
  });
  const { COMPACT, SMALL, SMALL_MID, MID, BIG, SUV, RV, VAN, ETC } = btnCarType;

  const resetCarType = () => {
    setBtnCarType({
      COMPACT: false,
      SMALL: false,
      SMALL_MID: false,
      MID: false,
      BIG: false,
      SUV: false,
      RV: false,
      VAN: false,
      ETC: false,
    });
  };
  const onChangeCarTypeButtons = e => {
    const { id } = e.target;

    setBtnCarType({
      ...btnCarType,
      [id]: !btnCarType[id],
    });
  };

  // 필터 - 제조사
  const [brandChkedItems, setBrandChkedItems] = useState([]);

  const brandChkHandler = e => {
    let checked = e.currentTarget.checked;
    let value = e.target.value;

    if (checked) {
      setBrandChkedItems([...brandChkedItems, value]);
    } else if (!checked && brandChkedItems.includes(value)) {
      setBrandChkedItems(brandChkedItems.filter(el => el !== value));
    }
  };

  // 필터 - 가격
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000000000);

  const minPriceOptions = [
    { value: "min", label: "최소" },
    { value: "1000000", label: "100만원" },
    { value: "2000000", label: "200만원" },
    { value: "3000000", label: "300만원" },
    { value: "4000000", label: "400만원" },
    { value: "5000000", label: "500만원" },
    { value: "6000000", label: "600만원" },
    { value: "7000000", label: "700만원" },
    { value: "8000000", label: "800만원" },
    { value: "9000000", label: "900만원" },
    { value: "10000000", label: "1,000만원" },
    { value: "20000000", label: "2,000만원" },
    { value: "30000000", label: "3,000만원" },
    { value: "40000000", label: "4,000만원" },
    { value: "50000000", label: "5,000만원" },
    { value: "60000000", label: "6,000만원" },
    { value: "70000000", label: "7,000만원" },
    { value: "80000000", label: "8,000만원" },
    { value: "90000000", label: "9,000만원" },
    { value: "100000000", label: "10,000만원" },
    { value: "over", label: "10,000만원 초과" },
  ];
  const maxPriceOptions = [
    { value: "max", label: "최대" },
    { value: "1000000", label: "100만원" },
    { value: "2000000", label: "200만원" },
    { value: "3000000", label: "300만원" },
    { value: "4000000", label: "400만원" },
    { value: "5000000", label: "500만원" },
    { value: "6000000", label: "600만원" },
    { value: "7000000", label: "700만원" },
    { value: "8000000", label: "800만원" },
    { value: "9000000", label: "900만원" },
    { value: "10000000", label: "1,000만원" },
    { value: "20000000", label: "2,000만원" },
    { value: "30000000", label: "3,000만원" },
    { value: "40000000", label: "4,000만원" },
    { value: "50000000", label: "5,000만원" },
    { value: "60000000", label: "6,000만원" },
    { value: "70000000", label: "7,000만원" },
    { value: "80000000", label: "8,000만원" },
    { value: "90000000", label: "9,000만원" },
    { value: "100000000", label: "10,000만원" },
    { value: "over", label: "10,000만원 초과" },
  ];

  // 필터 - 주행거리
  const [minMileage, setMinMileage] = useState(0);
  const [maxMileage, setMaxMileage] = useState(1000000);

  const minDistanceOptions = [
    { value: "min", label: "최소" },
    { value: "10000", label: "10,000km" },
    { value: "20000", label: "20,000km" },
    { value: "30000", label: "30,000km" },
    { value: "40000", label: "40,000km" },
    { value: "50000", label: "50,000km" },
    { value: "60000", label: "60,000km" },
    { value: "70000", label: "70,000km" },
    { value: "80000", label: "80,000km" },
    { value: "90000", label: "90,000km" },
    { value: "100000", label: "100,000km" },
    { value: "110000", label: "110,000km" },
    { value: "120000", label: "120,000km" },
    { value: "130000", label: "130,000km" },
    { value: "140000", label: "140,000km" },
    { value: "150000", label: "150,000km" },
    { value: "160000", label: "160,000km" },
    { value: "170000", label: "170,000km" },
    { value: "180000", label: "180,000km" },
    { value: "190000", label: "190,000km" },
    { value: "200000", label: "200,000km" },
  ];
  const maxDistanceOptions = [
    { value: "min", label: "최대" },
    { value: "10000", label: "10,000km" },
    { value: "20000", label: "20,000km" },
    { value: "30000", label: "30,000km" },
    { value: "40000", label: "40,000km" },
    { value: "50000", label: "50,000km" },
    { value: "60000", label: "60,000km" },
    { value: "70000", label: "70,000km" },
    { value: "80000", label: "80,000km" },
    { value: "90000", label: "90,000km" },
    { value: "100000", label: "100,000km" },
    { value: "110000", label: "110,000km" },
    { value: "120000", label: "120,000km" },
    { value: "130000", label: "130,000km" },
    { value: "140000", label: "140,000km" },
    { value: "150000", label: "150,000km" },
    { value: "160000", label: "160,000km" },
    { value: "170000", label: "170,000km" },
    { value: "180000", label: "180,000km" },
    { value: "190000", label: "190,000km" },
    { value: "200000", label: "200,000km" },
  ];

  // 필터 - 색상
  const [colorChkedItems, setColorChkedItems] = useState([]);

  const colorChkHandler = e => {
    let checked = e.currentTarget.checked;
    let value = e.target.value;

    if (checked) {
      setColorChkedItems([...colorChkedItems, value]);
    } else if (!checked && colorChkedItems.includes(value)) {
      setColorChkedItems(colorChkedItems.filter(el => el !== value));
    }
  };

  // 필터 - 연료
  const [btnFuels, setBtnFuels] = useState({
    DIESEL: false, // 디젤
    GAS: false, // 가솔린
    LPG: false, // LPG
    ELECTRIC: false, // 전기
    GAS_HYBRID: false, // 하이브리드
    HYDROGEN: false, // 수소
  });
  const { DIESEL, GAS, LPG, ELECTRIC, GAS_HYBRID, HYDROGEN } = btnFuels;

  const resetFuels = () => {
    setBtnFuels({
      DIESEL: false,
      GAS: false,
      LPG: false,
      ELECTRIC: false,
      GAS_HYBRID: false,
      HYDROGEN: false,
    });
  };
  const onChangeFuelButtons = e => {
    const { id } = e.target;

    resetFuels();
    setBtnFuels({
      ...btnFuels,
      [id]: !btnFuels[id],
    });
  };

  // 필터 - 소유업체
  const [ownerChkedItems, setOwnerChkedItems] = useState([]);

  const ownerChkHandler = e => {
    let checked = e.currentTarget.checked;
    let value = e.target.value;

    if (checked) {
      setOwnerChkedItems([...ownerChkedItems, value]);
    } else if (!checked && ownerChkedItems.includes(value)) {
      setOwnerChkedItems(ownerChkedItems.filter(el => el !== value));
    }
  };

  // 정렬 종류
  const [sortValue, setSortValue] = useState("최신순");
  const sortOptions = [
    { value: "최신순", label: "최신순" },
    { value: "가격 낮은순", label: "가격 낮은순" },
    { value: "가격 높은순", label: "가격 높은순" },
    { value: "주행거리 짧은순", label: "주행거리 짧은순" },
    { value: "주행거리 긴순", label: "주행거리 긴순" },
    { value: "연식 높은순", label: "연식 높은순" },
    { value: "연식 낮은순", label: "연식 낮은순" },
  ];

  // 셀렉트박스 스타일링
  const customStyles = useMemo(
    () => ({
      option: (provided, { isDisabled, isFocused, isSelected }) => ({
        ...provided,
        color: "black",
        opacity: 0.8,
        padding: 12,
        cursor: "default",
        backgroundColor: isDisabled ? undefined : isSelected ? "#eaeaea" : isFocused ? "#eaeaea" : undefined,
        ":active": {
          ...provided[":active"],
          backgroundColor: "#eaeaea",
        },
      }),
      control: (provided, state) => ({
        ...provided,
        width: "140px",
        height: "20px",
        background: "white",
        boxShadow: "transparent",
        cursor: "pointer",
        borderColor: state.isFocused ? "transparent" : "transparent",
        "&:hover": {
          borderColor: state.isFocused ? "transparent" : "transparent",
        },
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: "#000",
      }),
    }),
    []
  );
  const customStyles2 = useMemo(
    () => ({
      option: (provided, { isDisabled, isFocused, isSelected }) => ({
        ...provided,
        color: "black",
        opacity: 0.8,
        padding: 12,
        cursor: "default",
        backgroundColor: isDisabled ? undefined : isSelected ? "#eaeaea" : isFocused ? "#eaeaea" : undefined,
        ":active": {
          ...provided[":active"],
          backgroundColor: "#eaeaea",
        },
      }),
      control: (provided, state) => ({
        ...provided,
        width: "120px",
        height: "20px",
        background: "white",
        boxShadow: "transparent",
        cursor: "pointer",
        borderColor: state.isFocused ? "hsl(0, 0%, 80%)" : "hsl(0, 0%, 80%)",
        "&:hover": {
          borderColor: state.isFocused ? "hsl(0, 0%, 80%)" : "hsl(0, 0%, 80%)",
        },
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: "#000",
      }),
    }),
    []
  );

  /****************************************
   * TODO 정렬
   ****************************************/
  // 상품화 완료 토글
  const [commercialState, setCommercialState] = useState(false);

  /****************************************
   * TODO 모달 & 토스트 팝업 상태
   ****************************************/
  //* 모달
  const [modal, setModal] = useState({
    open: false,
    dim: true,
    title: "",
    message: "",
    notxt: "",
    oktxt: "확인",
    callback1: false,
    callback2: false,
  });

  //* 공유 모달
  const [shareModal, setShareModal] = useState({
    open: false,
    dim: true,
    carId: null,
    carData: null,
  });

  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = txt => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);

  /****************************************
   * TODO 검색 parameter keyword 세팅
   ****************************************/
  const [parameter, setParameter] = useState({
    init: false,
    searchKeyWord: "",
    countryType: "",
    carTypes: [],
    product_yn: "",
    carManufacturerNames: "",
    minCarPrice: 0,
    maxCarPrice: 1000000000,
    minMileage: 0,
    maxMileage: 1000000,
    carColors: [],
    fuelTypes: [],
    capitalCorpNames: "",
    sort: "carPrice.price,asc",
    ids: "",
  }); // 세팅된 파라미터

  useEffect(() => {
    let countryType = "";
    const newCarTypes = [];
    const newFuelTypes = [];
    let sortParams = "";
    let keyword1 = "";
    // console.log(location.state.keyword);
    // 키워드
    if (location.state != null) {
      keyword1 = location.state.keyword;

      // 모델 키워드명 검색이 존재할 경우 && 페이지가 0이 아닌 경우 0으로 초기화
      if (page !== 0) {
        console.log(page);
        // setPage(0);
      }
    }

    // 수입 여부
    for (var key in btnCategories) {
      if (btnCategories[key]) {
        btnCategories === true;
        switch (key) {
          case "btnCtgr1":
            countryType = "";
            break;
          case "btnCtgr2":
            countryType = "INTERNATIONAL";
            break;
          case "btnCtgr3":
            countryType = "DOMESTIC";
            break;
        }
      }
    }

    //차종
    for (var key in btnCarType) {
      if (btnCarType[key]) {
        btnCarType === true;
        newCarTypes.push(key);
      }
    }

    //연료
    for (var key in btnFuels) {
      if (btnFuels[key]) {
        btnFuels === true;
        newFuelTypes.push(key);
      }
    }

    // 상품화 여부
    const productYn = commercialState === true ? "Y" : "N";

    // 정렬 여부
    switch (sortValue) {
      case "최신순":
        sortParams = "exposedAt,desc";
        break;
      case "가격 낮은순":
        sortParams = "carPrice.price,asc";
        break;
      case "가격 높은순":
        sortParams = "carPrice.price,desc";
        break;
      case "주행거리 짧은순":
        sortParams = "mileage,asc";
        break;
      case "주행거리 긴순":
        sortParams = "mileage,desc";
        break;
      case "연식 높은순":
        sortParams = "carMadedYear,desc&sort=carMadedMonth,desc";
        break;
      case "연식 낮은순":
        sortParams = "carMadedYear,asc&sort=carMadedMonth,asc";
        break;
    }

    setParameter(prev => ({
      ...prev,
      init: true,
      searchKeyWord: keyword1,
      countryType: countryType,
      carTypes: newCarTypes,
      carManufacturerNames: brandChkedItems,
      minCarPrice: minPrice,
      maxCarPrice: maxPrice,
      minMileage: minMileage,
      maxMileage: maxMileage,
      carColors: colorChkedItems,
      capitalCorpNames: ownerChkedItems,
      fuelTypes: newFuelTypes,
      product_yn: productYn,
      sort: sortParams,
    }));
  }, [location.state, btnCategories, btnCarType, brandChkedItems, minPrice, maxPrice, minMileage, maxMileage, colorChkedItems, ownerChkedItems, btnFuels, commercialState, sortValue, page]);

  useEffect(() => {
    setPage(0);
  }, [location.state, btnCategories, btnCarType, brandChkedItems, minPrice, maxPrice, minMileage, maxMileage, colorChkedItems, ownerChkedItems, btnFuels, commercialState, sortValue]);

  // useEffect(() => {
  //   // API 로딩 O
  //   if(loading === true) {
  //     fetchItems(true);
  //   }
  // }, [loading]);
  useEffect(() => {
    // API 로딩 X
    if (parameter.init === true) {
      fetchItems(loading);
    }
  }, [parameter]);

  return (
    <>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <div className={`${styles.buy}` + " global-width"}>
          {/* 화면 좌측 필터 */}
          <div className={styles.filter}>
            {/* 카테고리 */}
            <div className={filter1 ? styles.filterItem__active : styles.filterItem}>
              <div className={styles.header}>
                <span id="filter1" className={styles.tit} onClick={onChangeFilters}>
                  카테고리
                </span>
              </div>
              <div className={styles.body}>
                <div className={styles.btnList}>
                  <button type="button" id="btnCtgr1" className={btnCtgr1 ? styles.btnToggle__active : styles.btnToggle} onClick={onChangeCategoriesButtons}>
                    전체
                  </button>
                  <button type="button" id="btnCtgr2" className={btnCtgr2 ? styles.btnToggle__active : styles.btnToggle} onClick={onChangeCategoriesButtons}>
                    수입
                  </button>
                  <button type="button" id="btnCtgr3" className={btnCtgr3 ? styles.btnToggle__active : styles.btnToggle} onClick={onChangeCategoriesButtons}>
                    국산차
                  </button>
                </div>
              </div>
            </div>

            {/* 차종 */}
            <div className={filter2 ? styles.filterItem__active : styles.filterItem}>
              <div className={styles.header}>
                <span id="filter2" className={styles.tit} onClick={onChangeFilters}>
                  차종
                </span>
              </div>
              <div className={styles.body}>
                <div className={styles.btnList}>
                  <button type="button" id="COMPACT" className={COMPACT ? styles.btnToggle__active : styles.btnToggle} onClick={onChangeCarTypeButtons}>
                    경차
                  </button>
                  <button type="button" id="SMALL" className={SMALL ? styles.btnToggle__active : styles.btnToggle} onClick={onChangeCarTypeButtons}>
                    소형
                  </button>
                  <button type="button" id="SMALL_MID" className={SMALL_MID ? styles.btnToggle__active : styles.btnToggle} onClick={onChangeCarTypeButtons}>
                    준중형
                  </button>
                  <button type="button" id="MID" className={MID ? styles.btnToggle__active : styles.btnToggle} onClick={onChangeCarTypeButtons}>
                    중형
                  </button>
                  <button type="button" id="BIG" className={BIG ? styles.btnToggle__active : styles.btnToggle} onClick={onChangeCarTypeButtons}>
                    대형
                  </button>
                  <button type="button" id="SUV" className={SUV ? styles.btnToggle__active : styles.btnToggle} onClick={onChangeCarTypeButtons}>
                    SUV
                  </button>
                  <button type="button" id="RV" className={RV ? styles.btnToggle__active : styles.btnToggle} onClick={onChangeCarTypeButtons}>
                    RV
                  </button>
                  <button type="button" id="VAN" className={VAN ? styles.btnToggle__active : styles.btnToggle} onClick={onChangeCarTypeButtons}>
                    VAN
                  </button>
                  <button type="button" id="ETC" className={ETC ? styles.btnToggle__active : styles.btnToggle} onClick={onChangeCarTypeButtons}>
                    기타
                  </button>
                </div>
              </div>
            </div>

            {/* 제조사 */}
            <div className={filter3 ? styles.filterItem__active : styles.filterItem}>
              <div className={styles.header}>
                <span id="filter3" className={styles.tit} onClick={onChangeFilters}>
                  제조사
                </span>
              </div>
              <div className={styles.body}>
                <div className={styles.chkList}>
                  {manufacturer.map(opt => {
                    return (
                      <div className={`${styles.chkItem}` + " checkbox"} key={opt.id}>
                        <label>
                          <input type="checkbox" value={opt.nameKr} onChange={brandChkHandler} checked={brandChkedItems.includes(`${opt.nameKr}`) ? true : false} />
                          <div></div>
                          <span>{opt.nameKr}</span>
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {/* 가격 */}
            <div className={filter4 ? styles.filterItem__active : styles.filterItem}>
              <div className={styles.header}>
                <span id="filter4" className={styles.tit} onClick={onChangeFilters}>
                  가격
                </span>
              </div>
              <div className={styles.body}>
                <div className={styles.range}>
                  <Select
                    options={minPriceOptions}
                    defaultValue={minPriceOptions[0]}
                    isClearable={false}
                    isSearchable={false}
                    closeMenuOnSelect={true}
                    styles={customStyles2}
                    onChange={e => {
                      setMinPrice(e.value);
                    }}
                  />
                  <Select
                    options={maxPriceOptions}
                    defaultValue={maxPriceOptions[0]}
                    isClearable={false}
                    isSearchable={false}
                    closeMenuOnSelect={true}
                    styles={customStyles2}
                    onChange={e => {
                      setMaxPrice(e.value);
                    }}
                  />
                </div>
              </div>
            </div>

            {/* 주행거리 */}
            <div className={filter5 ? styles.filterItem__active : styles.filterItem}>
              <div className={styles.header}>
                <span id="filter5" className={styles.tit} onClick={onChangeFilters}>
                  주행거리
                </span>
              </div>
              <div className={styles.body}>
                <div className={styles.range}>
                  <Select
                    options={minDistanceOptions}
                    defaultValue={minDistanceOptions[0]}
                    isClearable={false}
                    isSearchable={false}
                    closeMenuOnSelect={true}
                    styles={customStyles2}
                    onChange={e => {
                      setMinMileage(e.value);
                    }}
                  />
                  <Select
                    options={maxDistanceOptions}
                    defaultValue={maxDistanceOptions[0]}
                    isClearable={false}
                    isSearchable={false}
                    closeMenuOnSelect={true}
                    styles={customStyles2}
                    onChange={e => {
                      setMaxMileage(e.value);
                    }}
                  />
                </div>
              </div>
            </div>

            {/* 색상 */}
            <div className={filter6 ? styles.filterItem__active : styles.filterItem}>
              <div className={styles.header}>
                <span id="filter6" className={styles.tit} onClick={onChangeFilters}>
                  색상
                </span>
              </div>
              <div className={styles.body}>
                <div className={styles.chkList}>
                  {color.map(opt => {
                    return (
                      <div className={`${styles.chkItem}` + " checkbox"} key={opt.id}>
                        <label>
                          <input type="checkbox" value={opt.nameKr} onChange={colorChkHandler} checked={colorChkedItems.includes(`${opt.nameKr}`) ? true : false} />
                          <div></div>
                          <span>{opt.nameKr}</span>
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {/* 연료 */}
            <div className={filter7 ? styles.filterItem__active : styles.filterItem}>
              <div className={styles.header}>
                <span id="filter7" className={styles.tit} onClick={onChangeFilters}>
                  연료
                </span>
              </div>
              <div className={styles.body}>
                <div className={styles.btnList}>
                  <button type="button" id="DIESEL" className={DIESEL ? styles.btnToggle__active : styles.btnToggle} onClick={onChangeFuelButtons}>
                    디젤
                  </button>
                  <button type="button" id="GAS" className={GAS ? styles.btnToggle__active : styles.btnToggle} onClick={onChangeFuelButtons}>
                    가솔린
                  </button>
                  <button type="button" id="LPG" className={LPG ? styles.btnToggle__active : styles.btnToggle} onClick={onChangeFuelButtons}>
                    LPG
                  </button>
                  <button type="button" id="ELECTRIC" className={ELECTRIC ? styles.btnToggle__active : styles.btnToggle} onClick={onChangeFuelButtons}>
                    전기
                  </button>
                  <button type="button" id="GAS_HYBRID" className={GAS_HYBRID ? styles.btnToggle__active : styles.btnToggle} onClick={onChangeFuelButtons}>
                    하이브리드
                  </button>
                  <button type="button" id="HYDROGEN" className={HYDROGEN ? styles.btnToggle__active : styles.btnToggle} onClick={onChangeFuelButtons}>
                    수소
                  </button>
                </div>
              </div>
            </div>

            {/* 소유업체 */}
            <div className={filter8 ? styles.filterItem__active : styles.filterItem}>
              <div className={styles.header}>
                <span id="filter8" className={styles.tit} onClick={onChangeFilters}>
                  소유업체
                </span>
              </div>
              <div className={styles.body}>
                <div className={styles.chkList}>
                  {seller.map(opt => {
                    return (
                      <div className={`${styles.chkItem}` + " checkbox"} key={opt.id}>
                        <label>
                          <input type="checkbox" value={opt.name} onChange={ownerChkHandler} checked={ownerChkedItems.includes(`${opt.name}`) ? true : false} />
                          <div></div>
                          <span>{opt.name}</span>
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          {/* 화면 우측 차량 목록 */}
          {loading === false && loadItemStatus === true && (
            <div className={styles.carResult}>
              <div className={styles.header}>
                <div className={styles.total}>
                  총 <em>{Common.commaFormat(pagination.totalElements)}</em>대
                </div>
                <div className={styles.sortArea}>
                  <div
                    className={commercialState ? styles.cmzChk__active : styles.cmzChk}
                    onClick={() => {
                      setCommercialState(!commercialState);
                    }}
                  >
                    상품화 완료
                    <span className={styles.qDesc}>상품화 완료 차량은 이미 사전에 정비 및 수리가 완료된 차량입니다.</span>
                  </div>
                  <div className={styles.sort}>
                    <div className="select-form">
                      <div className="select-element">
                        <Select
                          value={sortOptions.filter(opt => opt.label === sortValue)}
                          onChange={item => {
                            setSortValue(item.value);
                          }}
                          options={sortOptions.map((opt, index) => {
                            return {
                              label: opt.value,
                              value: opt.value,
                              key: index,
                            };
                          })}
                          styles={customStyles}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* 제품 목록 */}
              {items.length == 0 ? (
                <div className={styles.nonItems}>
                  <div className={styles.icon}>
                    <img src={emptyIcon} alt="" />
                  </div>
                  <p>검색결과가 없습니다.</p>
                </div>
              ) : (
                <div className={styles.content}>
                  <div className={styles.carList}>
                    {items.map(item => {
                      return (
                        <div className={item.productStatus === "EXPOSED" ? `${styles.carItem}` : `${styles.carItem} ${styles.disabled}`} key={item.id}>
                          {/*! disabled 구현 */}
                          <div className={styles.img}>
                            <Link to={`/product/${item.id}`} className={styles.img}>
                              <img src={item.mainImgUrl} alt="" />
                            </Link>
                          </div>
                          <div className={styles.desc}>
                            <div className={styles.dealProcessCnt}>
                              {item.dealOnProcessCount !== 0 && (
                                <div>
                                  <em>{item.dealOnProcessCount}</em>
                                  <span className={styles.qDesc}>해당 차량이 진행중인 거래 건수 입니다.</span>
                                </div>
                              )}
                            </div>
                            <div className={styles.tags}>
                              {item.newYn === "Y" && <span className={`${styles.tag} ${styles.new}`}>New</span>}
                              {item.productYn === "Y" && <span className={`${styles.tag} ${styles.commercialized}`}>상품화</span>}
                              {item.productStatus === "BUY_ING" && <span className={`${styles.tag} ${styles.purchasing}`}>구매 진행 중</span>}
                            </div>
                            <div className={styles.title}>
                              <span>
                                <Link to={`/product/${item.id}`}>
                                  {item.manufacturerName + " " + item.modelName} ({item.carNumber})
                                </Link>
                              </span>
                            </div>
                            <div className={styles.detail}>
                              <span>{String(item.carMadedYear).slice(2) + "년" + String(item.carMadedMonth) + "월 (" + String(item.carMadedYear).slice(2) + "년형)"}</span>
                              <span>{Common.commaFormat(item.mileage)}km</span>
                              <span>
                                {item.fuelType === "GAS"
                                  ? "가솔린"
                                  : item.fuelType === "DIESEL"
                                  ? "디젤"
                                  : item.fuelType === "LPG"
                                  ? "LPG"
                                  : item.fuelType === "GAS_LPG"
                                  ? "가솔린(LPG겸용)"
                                  : item.fuelType === "GAS_CNG"
                                  ? "가솔린(CNG겸용)"
                                  : item.fuelType === "GAS_HYBRID"
                                  ? "HEV(하이브리드)"
                                  : item.fuelType === "ELECTRIC"
                                  ? "전기"
                                  : item.fuelType === "HYDROGEN"
                                  ? "수소"
                                  : null}
                              </span>
                              <span>{item.accident === "NONE" ? "무사고" : item.accident === "SIMPLE_CHANGE" ? "단순교환" : item.accident === "SIMPLE_TOUCH" ? "단순사고(접촉)" : item.accident === "NORMAL" ? "사고" : item.accident === "TOTAL_LOSS" ? "전손" : item.accident === "FLOODING" ? "침수" : null}</span>
                            </div>
                            <div className={styles.options}>
                              {item.carOptions &&
                                item.carOptions.map((opt, idx) => {
                                  return idx < 5 && <span key={idx}>{opt.nameKr}</span>;
                                })}
                            </div>
                            <div className={styles.itemOption}>
                              {cartItems.some(el => el.id === item.id) ? (
                                <button
                                  type="button"
                                  className={`${styles.optionItem} ${styles.cart}`}
                                  onClick={e => {
                                    handleCart(e, item.id);
                                  }}
                                  data-cart="on"
                                ></button>
                              ) : (
                                <button
                                  type="button"
                                  className={`${styles.optionItem} ${styles.cart}`}
                                  onClick={e => {
                                    handleCart(e, item.id);
                                  }}
                                  data-cart="off"
                                ></button>
                              )}
                              {favoriteItems.some(el => el.id === item.id) ? (
                                <button
                                  type="button"
                                  className={`${styles.optionItem} ${styles.favorite}`}
                                  onClick={e => {
                                    handleFavorite(e, item.id);
                                  }}
                                  data-favorite="on"
                                ></button>
                              ) : (
                                <button
                                  type="button"
                                  className={`${styles.optionItem} ${styles.favorite}`}
                                  onClick={e => {
                                    handleFavorite(e, item.id);
                                  }}
                                  data-favorite="off"
                                ></button>
                              )}
                              <button
                                type="button"
                                onClick={() => {
                                  handleShare(item.id, item);
                                }}
                                className={`${styles.optionItem} ${styles.share}`}
                              ></button>
                            </div>
                            <div className={styles.price}>
                              {loginStatus === true ? (
                                <span>
                                  <em>{Common.commaFormat(String(item.carPrice.price).slice(0, -4))}</em> 만원
                                </span>
                              ) : (
                                <div className="hidePrice">
                                  <p className="hp1">금액 비공개</p>
                                  <p className="hp2">로그인 후 금액이 노출됩니다.</p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  {/* pagination */}
                  <div className={styles.pagination}>
                    <div className={styles.numList}>
                      <div className={styles.btnPrev} onClick={() => setPage(pagination.prevPage)} aria-current={pagination.isFirst ? false : true}>
                        <AiOutlineLeft />
                      </div>
                      <div className={styles.btnNext} onClick={() => setPage(pagination.nextPage)} aria-current={pagination.isLast ? false : true}>
                        <AiOutlineRight />
                      </div>
                      <ul className={styles.currentPage}>
                        {Array(pagination.endPage)
                          .fill()
                          .map(
                            (_, i) =>
                              i >= pagination.startPage - 1 && (
                                <li key={i} onClick={() => setPage(i)} className={pagination.page === i ? `${styles.active}` : null}>
                                  {i + 1}
                                </li>
                              )
                          )}
                      </ul>
                      {pagination.endPage !== pagination.totalPages && (
                        <div className={styles.lastPage}>
                          <li onClick={() => setPage(pagination.totalPages - 1)}>{pagination.totalPages}</li>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />

      {/* 공유하기 */}
      {shareModal.open && <Share dim={shareModal.dim} setModal={setShareModal} carId={shareModal.carId} carData={shareModal.carData} />}

      {/* 퀵메뉴 */}
      <QuickMenu />

      {/* 로그인 모달 */}
      {modal.open && <ModalConfirm dim={modal.dim} setModal={setModal} title={modal.title} message={modal.message} notxt={modal.notxt} oktxt={modal.oktxt} callback1={modal.callback1} callback2={modal.callback2} />}
    </>
  );
};

export default Buy;
