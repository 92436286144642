/* eslint-disable */
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import allActions from "../actions";
import * as Common from "../actions/Common.js";

const Logout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    handleLogout();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    Common.delCookie("chada_login_status");
    dispatch(allActions.userActions.logoutUser());
    // window.location.reload();
    navigate("/login");
  };

  return null;
};

export default Logout;
