/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ApiController from "../../api/ApiController";

import Toast from "../../components/Toast.js";
import ModalConfirm from "../../components/ModalConfirm.js";
import Dashboard from "./Dashboard.js";
import styles from "./ResetPw.module.scss";

// API URL
import { baseUrl } from "../../api";
const BASE_URL = baseUrl();
const PASSWORD_URL = "/api/user/member/v1/password";

const ResetPw = () => {
  const navigate = useNavigate();
  let [opWarnStatus, setOpWarnStatus] = useState(true);
  let [opWarnTxt, setOpWarnTxt] = useState("");
  let [pwWarnStatus, setPwWarnStatus] = useState(true);
  let [pwWarnTxt, setPwWarnTxt] = useState("");

  //TODO 비밀번호 변경 API 전송
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (pw !== pwChk) {
      setPwWarnStatus(false);
      setPwWarnTxt("비밀번호가 일치하지 않습니다.");
      return;
    }
    if (pw.length < 8) {
      setPwWarnStatus(false);
      setPwWarnTxt("최소 8글자 이상 입력해주세요.");
      return;
    }
    setPwWarnStatus(true);
    setOpWarnStatus(true);

    //* API 전송
    try {
      const response = await axios.put(
        BASE_URL + PASSWORD_URL,
        { oldPassword: oldPw, newPassword: pw },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      setModal({
        open: true,
        dim: true,
        title: "완료 되었습니다.",
        message: "",
        notxt: "",
        oktxt: "확인",
        callback2: () => {
          navigate("/mypage", {
            state: {
              tabIndex: 10,
            },
          });
        },
      });
    } catch (err) {
      setOpWarnStatus(false);
      setOpWarnTxt("비밀번호가 일치하지 않습니다.");
      handleToast(err.response?.data.message);
    }
  };
  //TODO

  // 버튼 상태
  let [btnState, setBtnState] = useState(false);

  // 입력 데이터 세팅
  const [inputs, setInputs] = useState({
    oldPw: "", // 기존 비밀번호
    pw: "", // 비밀번호
    pwChk: "", // 비밀번호 재입력
  });
  const { oldPw, pw, pwChk } = inputs;

  const updateInputData = (e) => {
    const { name, value } = e.target;

    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const resetInputData = () => {
    setInputs({
      oldPw: "",
      pw: "",
      pwChk: "",
    });
  };

  // 폼 유효성 검사
  useEffect(() => {
    if (pw !== "" && pwChk !== "") {
      setBtnState(true);
    } else {
      setBtnState(false);
    }
    // input 별로 유효성 검사 처리

    ///////////////////////////////////
  }, [pw, pwChk]);

  /****************************************
   * TODO 모달 & 토스트 팝업 상태
   ****************************************/
  //* 모달
  const [modal, setModal] = useState({
    open: false,
    dim: true,
    title: "",
    message: "",
    notxt: "",
    oktxt: "확인",
    callback1: false,
    callback2: false,
  });

  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = (txt) => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);

  return (
    <>
      {/* 대시보드 */}
      <div className={`${styles.dashboard}` + " global-width"}>
        <Dashboard />
      </div>
      {/* 비밀번호 변경하기 */}
      <form
        className={`${styles.resetPw}` + " form-width"}
        action=""
        onSubmit={handleSubmit}
      >
        <div className={styles.content}>
          <div className={`${styles.mainTxt}` + " heading-1"}>
            비밀번호 변경하기
          </div>
          <div className={styles.formContainer}>
            <div
              className={`${styles.findPw__content__mainTxt}` + " heading-3"}
            >
              비밀번호 재설정
            </div>
            <p className={`${styles.findPw__content__subTxt}` + " pm"}>
              새롭게 사용할 비밀번호를 입력해주세요.
            </p>
            <div
              className={`${styles.findPw__content__formContainer}` + " mt-35"}
            >
              {/* 기존 비밀번호 */}
              <div
                className={
                  opWarnStatus === true ? "input-form" : "input-form error"
                }
              >
                <p className="form-title">
                  <span>기존 비밀번호</span>
                </p>
                <div className="form-field">
                  <div className="input-element">
                    <input
                      type="password"
                      name="oldPw"
                      className=""
                      placeholder="기존 비밀번호"
                      onKeyDown={(e) => {
                        if (e.keyCode === 32) {
                          // spacebar 막기
                          e.preventDefault();
                        }
                      }}
                      onChange={updateInputData}
                      value={oldPw}
                    />
                  </div>
                </div>
                <p className="alert-msg error">{opWarnTxt}</p>
              </div>
              {/* 새로운 비밀번호 */}
              <div
                className={
                  pwWarnStatus === true ? "input-form" : "input-form error"
                }
              >
                <p className="form-title">
                  <span>새로운 비밀번호</span>
                </p>
                <div className="form-field">
                  <div className="input-element">
                    <input
                      type="password"
                      name="pw"
                      className=""
                      placeholder="비밀번호"
                      onKeyDown={(e) => {
                        if (e.keyCode === 32) {
                          // spacebar 막기
                          e.preventDefault();
                        }
                      }}
                      onChange={updateInputData}
                      value={pw}
                    />
                  </div>
                </div>
              </div>
              {/* 비밀번호 재입력 */}
              <div
                className={
                  pwWarnStatus === true ? "input-form" : "input-form error"
                }
              >
                <div className="form-field">
                  <div className="input-element">
                    <input
                      type="password"
                      name="pwChk"
                      className=""
                      placeholder="비밀번호 재입력"
                      onKeyDown={(e) => {
                        if (e.keyCode === 32) {
                          // spacebar 막기
                          e.preventDefault();
                        }
                      }}
                      onChange={updateInputData}
                      value={pwChk}
                    />
                  </div>
                </div>
                <p className="alert-msg error">{pwWarnTxt}</p>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.buttons}` + " button-form"}>
          {" "}
          <button
            type="submit"
            className={"btn btn-xl btn--outline_gray"}
            onClick={() => {
              navigate("/mypage", {
                state: {
                  tabIndex: 10,
                },
              });
            }}
          >
            돌아가기
          </button>
          <button
            type="submit"
            className={
              btnState ? "btn btn-xl btn--primary" : "btn btn-xl btn--disabled"
            }
            disabled={!btnState}
          >
            완료
          </button>
        </div>
      </form>

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />

      {/* 모달 */}
      {modal.open && (
        <ModalConfirm
          dim={modal.dim}
          setModal={setModal}
          title={modal.title}
          message={modal.message}
          notxt={modal.notxt}
          oktxt={modal.oktxt}
          callback1={modal.callback1}
          callback2={modal.callback2}
        />
      )}
    </>
  );
};

export default ResetPw;
