/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AsyncSelect from "react-select/async";
import ApiController from "../../api/ApiController";

import Toast from "../../components/Toast.js";
import ModalConfirm from "../../components/ModalConfirm.js";
import Dashboard from "./Dashboard.js";
import DaumPost from "../../components/DaumPost.js";
import styles from "./CorpModify.module.scss";

import fileUploadIcon from "../../assets/icons/PluscirclePr.svg";
import fileDeleteIcon from "../../assets/icons/ClosecircleGr.svg";

// API URL
import { baseUrl } from "../../api";
const BASE_URL = baseUrl();
const MYDATA_URL = "/api/user/member/v1/me";
const BANKLIST_URL = "/api/user/bank/v1";
const CORPINFO_URL = "/api/user/member/v1/corp_infomation";
const REFUND_BANK_URL = "/api/user/member/v1/refund_bank";
const UPLOAD_URL = "/api/user/upload/v1/upload/image";

const CorpModify = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  /****************************************
   * TODO 초기 세팅
   ****************************************/
  //* 로그인 상태
  const [loginStatus, setLoginStatus] = useState(false);

  useEffect(() => {
    fetchData();
  }, [loginStatus]);

  const fetchData = async () => {
    try {
      //* 토큰 상태 확인
      const result = await fetchMyData();

      if (!result) {
        setLoginStatus(false);

        setModal({
          open: true,
          dim: true,
          title: "로그인이 필요합니다.",
          message: "지금 로그인 하시겠습니까?",
          notxt: "취소",
          oktxt: "로그인 하기",
          callback2: () => {
            navigate("/login");
          },
        });
      } else {
        setLoginStatus(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /****************************************
   * TODO 항목별 API 목록
   ****************************************/
  // 토큰 상태 확인
  const fetchMyData = async () => {
    try {
      const response = await axios.get(BASE_URL + MYDATA_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      // initAsyncSelect(response.data.data.rentCarCorperation.refundBankName);
      setImgSrc(response.data.data.rentCarCorperation.licenseImgUrl);
      // setFilename("파일 업로드");
      setFilename(
        response.data.data.rentCarCorperation.licenseImgOriginFileName
      );
      setInputs({
        corpName: response.data.data.rentCarCorperation.name,
        licenseNumber: response.data.data.rentCarCorperation.licenseNumber,
        corpRegNumber: response.data.data.rentCarCorperation.corpRegNumber,
        address: response.data.data.rentCarCorperation.address,
        addressDetail: response.data.data.rentCarCorperation.addressDetail,
        sido: response.data.data.rentCarCorperation.sido,
        sigungu: response.data.data.rentCarCorperation.sigungu,
        sigunguCode: response.data.data.rentCarCorperation.sigunguCode,
        repName: response.data.data.rentCarCorperation.repName,
        repPhone: response.data.data.rentCarCorperation.repPhone,
        corpPhone: response.data.data.rentCarCorperation.corpPhone,
        refundBankName: response.data.data.rentCarCorperation.refundBankName,
        refundBankOwner: response.data.data.rentCarCorperation.refundBankOwner,
        refundBankAccount:
          response.data.data.rentCarCorperation.refundBankAccount,
      });
      return response;
    } catch (err) {
      handleToast(err.response?.data.message);
      return false;
    }
  };

  // 이미지 객체 API 전송 / 이미지 ID값 받아오기
  const apiSendImage = async () => {
    if (imgFile === null) {
      return false;
    } else {
      const fd = new FormData();
      Object.values(imgFile).forEach((file) => fd.append("file", file));
      fd.append("comment", imgFile);

      const token = localStorage.getItem("access_token");

      const response = await axios.post(BASE_URL + UPLOAD_URL, fd, {
        headers: {
          "Content-Type": `multipart/form-data; `,
          Authorization: `Bearer ${token}`,
        },
      });

      return response;
    }
  };
  const apiSendFormData = async (imageId, imageUrl) => {
    let data;

    data = {
      // id: 0,
      // bankId: refundBankId,
      bankName: refundBankName,
      bankOwner: refundBankOwner,
      bankAccount: refundBankAccount,
    };

    // if (imageId === null && imageUrl === null) {
    //   data = {
    //     corpName: corpName,
    //     licenseNumber: licenseNumber,
    //     corpRegNumber: corpRegNumber,
    //     address: address,
    //     addressDetail: addressDetail,
    //     sido: sido,
    //     sigungu: sigungu,
    //     sigunguCode: sigunguCode,
    //     repName: repName,
    //     repPhone: repPhone,
    //     corpPhone: corpPhone,
    //     refundBankName: refundBankName,
    //     refundBankOwner: refundBankOwner,
    //     refundBankAccount: refundBankAccount,
    //   };
    // } else {
    //   data = {
    //     licenseImgId: imageId,
    //     licenseImgUrl: imageUrl,
    //     corpName: corpName,
    //     licenseNumber: licenseNumber,
    //     corpRegNumber: corpRegNumber,
    //     address: address,
    //     addressDetail: addressDetail,
    //     sido: sido,
    //     sigungu: sigungu,
    //     sigunguCode: sigunguCode,
    //     repName: repName,
    //     repPhone: repPhone,
    //     corpPhone: corpPhone,
    //     refundBankName: refundBankName,
    //     refundBankOwner: refundBankOwner,
    //     refundBankAccount: refundBankAccount,
    //   };
    // }

    const response = await axios.put(BASE_URL + REFUND_BANK_URL, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    handleToast(response.data.message);

    navigate("/mypage", {
      state: {
        tabIndex: 10,
      },
    });
    return response;
  };

  const [myBank, setMyBank] = useState({ id: null, name: null });
  // const initAsyncSelect = (bankname) => {
  //   bankList.filter((item) => {
  //     if (item.name === bankname) {
  //       setMyBank({ id: item.id, name: item.name });
  //     }
  //   });
  // };
  const [bankList, setBankList] = useState();
  // 은행 목록 가져오기
  const getBankList = async () => {
    try {
      const response = await axios.get(BASE_URL + BANKLIST_URL);

      setBankList(response.data.data);
      return response.data.data;
    } catch (err) {
      handleToast(err.response?.data.message);
      return false;
    }
  };

  // const getBankList = async () => {
  //   const response = await ApiController({
  //     url: BASE_URL + BANKLIST_URL,
  //     method: "get",
  //   });

  //   setBankList(response.data);
  //   return response.data;
  // };

  const loadOptions = async () => {
    return await getBankList();
  };

  //* 은행 목록 API 데이터 추출 및 Selectbox 연동
  const [selectedValue, setSelectedValue] = useState("");

  //* Selectbox 커스텀
  const customSelectboxStyles = useMemo(
    () => ({
      option: (provided, { isDisabled, isFocused, isSelected }) => ({
        ...provided,
        color: "black",
        opacity: 0.8,
        padding: 12,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#eaeaea"
          : isFocused
          ? "#eaeaea"
          : undefined,
        ":active": {
          ...provided[":active"],
          backgroundColor: "eaeaea",
        },
      }),
      control: (provided, state) => ({
        ...provided,
        width: "360px",
        height: "48px",
        background: "white",
        boxShadow: "transparent",
        borderColor: state.isFocused ? "#eaeaea" : "#eaeaea",
        "&:hover": {
          borderColor: state.isFocused ? "#eaeaea" : "#eaeaea",
        },
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: "#000",
      }),
    }),
    []
  );

  /****************************************
   * TODO 이벤트 핸들러
   ****************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();
    // 1. POST Image Instance & GET Image ID, URL

    // GET Image ID, URL & Form Data API 전송
    try {
      const result1 = await apiSendImage();

      if (result1 === false) {
        // 이미지 갱신 X
        await apiSendFormData(null, null);
      } else {
        // 이미지 갱신 O
        await apiSendFormData(result1.data.data.id, result1.data.data.url);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //* 다음 API 주소 검색 팝업 세팅
  const [addressPopup, setAddressPopup] = useState(false);
  const [daumAddress, setDaumAddress] = useState("");
  const [addressPost, setAddressPost] = useState("");
  const [daumData, setDaumData] = useState();

  useEffect(() => {
    if (daumData !== undefined && daumData !== null) {
      setInputs({
        sido: daumData.sido,
        sigungu: daumData.sigungu,
        sigunguCode: daumData.sigunguCode,
      });
    }
  }, [daumData]);

  // 팝업창 열기
  const openPostCode = () => {
    setAddressPopup(true);
  };
  // 팝업창 닫기
  const closePostCode = () => {
    setAddressPopup(false);
  };

  useEffect(() => {
    setInputs({
      ...inputs,
      ["address"]: daumAddress,
    });
  }, [daumAddress, addressPost]);

  // 버튼 상태
  let [btnState, setBtnState] = useState(false);

  // 입력 데이터 세팅
  const [inputs, setInputs] = useState({
    corpName: "", // 법인명
    licenseNumber: "", // 사업자 등록번호
    corpRegNumber: "", // 법인 등록번호
    address: "", // 사업자 주소
    addressDetail: "", // 사업자 주소(상세)
    repName: "", // 대표자 성함
    repPhone: "", // 대표자 연락처
    corpPhone: "", // 대표 번호
    refundBankId: 0, // 환불 계좌 은행 아이디
    refundBankName: "", // 환불 계좌 정보(은행)
    refundBankOwner: "", // 환불 계좌 정보(예금주)
    refundBankAccount: "", // 환불 계좌 정보(계좌번호)
    sido: "",
    sigungu: "",
    sigunguCode: "",
  });
  const {
    corpName,
    licenseNumber,
    corpRegNumber,
    address,
    addressDetail,
    repName,
    repPhone,
    corpPhone,
    refundBankId,
    refundBankName,
    refundBankOwner,
    refundBankAccount,
    sido,
    sigungu,
    sigunguCode,
  } = inputs;

  const updateInputData = (e) => {
    const { name, value } = e.target;

    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  useEffect(() => {
    if (bankList != undefined) {
      bankList.filter((item) => {
        if (item.name === inputs.refundBankName) {
          setMyBank({ id: item.id, name: item.name });
        }
      });
    }
  }, [bankList, inputs.refundBankName]);

  const resetInputData = () => {
    setInputs({
      corpName: "",
      licenseNumber: "",
      corpRegNumber: "",
      address: "",
      addressDetail: "",
      repName: "",
      repPhone: "",
      corpPhone: "",
      refundBankId: "",
      refundBankName: "",
      refundBankOwner: "",
      refundBankAccount: "",
    });
  };

  //* 탭 3 input[type=file] 세팅
  const [filename, setFilename] = useState("파일 업로드");
  const [imgSrc, setImgSrc] = useState("");

  const resetFileData = (e) => {
    if (filename == "파일 업로드") {
      return;
    }
    setFilename("파일 업로드");
    e.preventDefault();
  };

  const [imgBase64, setImgBase64] = useState([]); // 파일 base64
  const [imgFile, setImgFile] = useState(null); //파일

  const handleChangeFile = (event) => {
    setImgFile(event.target.files);
    setImgBase64([]);

    for (var i = 0; i < event.target.files.length; i++) {
      if (event.target.files[i]) {
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[i]);
        reader.onloadend = () => {
          const base64 = reader.result;
          if (base64) {
            var base64Sub = base64.toString();

            setImgBase64((imgBase64) => [...imgBase64, base64Sub]);
          }
        };
      }
    }

    let filename = event.target.files[0].name;

    setFilename(filename);

    if (event.target.files.length) {
      var imgTarget = event.target.files[0];
      var fileReader = new FileReader();
      fileReader.readAsDataURL(imgTarget);
      fileReader.onload = function (e) {
        setImgSrc(e.target.result);
      };
    } else {
      setImgSrc("");
    }
  };

  //* 폼 유효성 검사
  useEffect(() => {
    if (
      filename != "파일 업로드" &&
      corpName != "" &&
      licenseNumber != "" &&
      corpRegNumber != "" &&
      address != "" &&
      addressDetail != "" &&
      repName != "" &&
      repPhone != "" &&
      corpPhone != "" &&
      refundBankOwner != "" &&
      refundBankAccount != ""
    ) {
      setBtnState(true);
    } else {
      setBtnState(false);
    }
    // input 별로 유효성 검사 처리

    ///////////////////////////////////
  }, [
    filename,
    corpName,
    licenseNumber,
    corpRegNumber,
    address,
    addressDetail,
    repName,
    repPhone,
    corpPhone,
    refundBankOwner,
    refundBankAccount,
  ]);

  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  /****************************************
   * TODO 모달 & 토스트 팝업 상태
   ****************************************/
  //* 모달
  const [modal, setModal] = useState({
    open: false,
    dim: true,
    title: "",
    message: "",
    notxt: "",
    oktxt: "확인",
    callback1: false,
    callback2: false,
  });

  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = (txt) => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);

  return (
    <>
      {/* 대시보드 */}
      <div className={`${styles.dashboard}` + " global-width"}>
        <Dashboard />
      </div>
      {/* 법인 정보 변경 */}
      <form
        className={`${styles.corpModify}` + " form-width"}
        action=""
        onSubmit={handleSubmit}
      >
        <div className={styles.content}>
          <div className={`${styles.mainTxt}` + " heading-1"}>
            법인 정보 변경
          </div>
          <p className={styles.subTxt}>
            법인정보 변경을 원할 시 고객센터로 문의해 주세요.
          </p>
          <div className={styles.formContainer}>
            {/* 사업자 등록증 */}
            <div className="input-form disabled">
              <p className="form-title">
                <span>사업자 등록증</span>
              </p>
              <div className="form-field">
                <div className="file-element">
                  <span className="upload_name">{filename}</span>
                  <label htmlFor="file">
                    <img
                      src={
                        filename == "파일 업로드"
                          ? fileUploadIcon
                          : fileDeleteIcon
                      }
                      alt=""
                      onClick={resetFileData}
                    />
                  </label>
                  <input type="file" id="file" onChange={handleChangeFile} />
                </div>
              </div>
              <div className="form-image">
                {filename != null && filename != "파일 업로드" ? (
                  <div className="image-item">
                    <img src={imgSrc} />
                  </div>
                ) : (
                  <div className="image-empty"></div>
                )}
              </div>
            </div>
            {/* 법인명 */}
            <div className="input-form disabled">
              <p className="form-title">
                <span>법인명</span>
              </p>
              <div className="form-field">
                <div className="input-element">
                  <input
                    type="text"
                    name="corpName"
                    className=""
                    placeholder="법인명 입력"
                    onChange={updateInputData}
                    value={corpName}
                    readOnly
                  />
                </div>
              </div>
            </div>
            {/* 사업자 등록번호 */}
            <div className="input-form disabled">
              <p className="form-title">
                <span>사업자 등록번호</span>
              </p>
              <div className="form-field">
                <div className="input-element">
                  <input
                    type="text"
                    name="licenseNumber"
                    className=""
                    placeholder="- 없이 입력"
                    onChange={updateInputData}
                    value={licenseNumber}
                    readOnly
                  />
                </div>
              </div>
            </div>
            {/* 법인 등록번호 */}
            <div className="input-form disabled">
              <p className="form-title">
                <span>법인 등록번호</span>
              </p>
              <div className="form-field">
                <div className="input-element">
                  <input
                    type="text"
                    name="corpRegNumber"
                    className=""
                    placeholder="- 없이 입력"
                    onChange={updateInputData}
                    value={corpRegNumber}
                    readOnly
                  />
                </div>
              </div>
            </div>
            {/* 사업자 주소 */}
            <div className="input-form disabled">
              <p className="form-title">
                <span>사업자 주소</span>
              </p>
              <div className="form-field">
                <div className="input-element">
                  <input
                    type="text"
                    name="address"
                    className=""
                    placeholder="주소"
                    onChange={updateInputData}
                    value={address}
                    readOnly
                  />
                </div>
                <button
                  type="button"
                  className="btn-address"
                  onClick={openPostCode}
                >
                  주소 검색
                </button>
              </div>
            </div>

            {/* 팝업 */}
            {addressPopup && (
              <DaumPost
                onClose={closePostCode}
                setAddress={setDaumAddress}
                setAddressPost={setAddressPost}
                setDaumData={setDaumData}
              />
            )}

            <div className="input-form disabled">
              <div className="form-field">
                <div className="input-element">
                  <input
                    type="text"
                    name="addressDetail"
                    className=""
                    placeholder="상세 주소 입력"
                    onChange={updateInputData}
                    value={addressDetail}
                    readOnly
                  />
                </div>
              </div>
            </div>
            {/* 대표자 성함 */}
            <div className="input-form disabled">
              <p className="form-title">
                <span>대표자 성함</span>
              </p>
              <div className="form-field">
                <div className="input-element">
                  <input
                    type="text"
                    name="repName"
                    className=""
                    placeholder="대표자 성함 입력 (한글 입력)"
                    onKeyPress={(e) => {
                      if (e.keyCode < 12592 || e.keyCode > 12687) {
                        e.returnValue = false;
                        e.preventDefault();
                      }
                    }}
                    onChange={updateInputData}
                    value={repName}
                    readOnly
                  />
                </div>
              </div>
            </div>
            {/* 대표자 연락처 */}
            <div className="input-form disabled">
              <p className="form-title">
                <span>대표자 연락처</span>
              </p>
              <div className="form-field">
                <div className="input-element">
                  <input
                    type="number"
                    name="repPhone"
                    className=""
                    placeholder="- 없이 입력"
                    onChange={updateInputData}
                    value={repPhone}
                    onInput={maxLengthCheck}
                    maxLength="11"
                    readOnly
                  />
                </div>
              </div>
            </div>
            {/* 대표 번호 */}
            <div className="input-form disabled">
              <p className="form-title">
                <span>대표 번호</span>
              </p>
              <div className="form-field">
                <div className="input-element">
                  <input
                    type="text"
                    name="corpPhone"
                    className=""
                    placeholder="- 없이 입력"
                    onChange={updateInputData}
                    value={corpPhone}
                    readOnly
                  />
                </div>
              </div>
            </div>
            {/* 환불 계좌 정보 */}
            <div className="input-form required">
              <p className="form-title">
                <span>환불 계좌 정보</span>
              </p>
              <div className="form-field">
                <div className="select-element">
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={loadOptions}
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.id}
                    // value={selectedValue}
                    value={{ id: myBank.id, name: myBank.name }}
                    onChange={(e) => {
                      setInputs({
                        ...inputs,
                        refundBankId: e.id,
                        refundBankName: e.name,
                      });
                      setSelectedValue(e);
                    }}
                    isClearable={false}
                    isSearchable={false}
                    closeMenuOnSelect={true}
                    styles={customSelectboxStyles}
                    placeholder="은행 선택"
                  />
                </div>
              </div>
            </div>
            <div className="input-form required">
              <div className="form-field">
                <div className="input-element">
                  <input
                    type="text"
                    name="refundBankAccount"
                    className=""
                    placeholder="계좌번호 - 없이 입력"
                    onChange={updateInputData}
                    value={refundBankAccount}
                  />
                </div>
              </div>
            </div>
            <div className="input-form required">
              <div className="form-field">
                <div className="input-element">
                  <input
                    type="text"
                    name="refundBankOwner"
                    className=""
                    placeholder="예금주"
                    onChange={updateInputData}
                    value={refundBankOwner}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.buttons}` + " button-form"}>
          <button
            type="submit"
            className={"btn btn-xl btn--outline_gray"}
            onClick={() => {
              navigate("/mypage", {
                state: {
                  tabIndex: 10,
                },
              });
            }}
          >
            돌아가기
          </button>
          <button
            type="submit"
            className={
              btnState ? "btn btn-xl btn--primary" : "btn btn-xl btn--disabled"
            }
            disabled={!btnState}
            onClick={handleSubmit}
          >
            완료
          </button>
        </div>
      </form>

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />

      {/* 모달 */}
      {modal.open && (
        <ModalConfirm
          dim={modal.dim}
          setModal={setModal}
          title={modal.title}
          message={modal.message}
          notxt={modal.notxt}
          oktxt={modal.oktxt}
          callback1={modal.callback1}
          callback2={modal.callback2}
        />
      )}
    </>
  );
};

export default CorpModify;
