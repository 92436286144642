/* eslint-disable */
import React from "react";
import { useEffect } from "react";

import styles from "./ModalConfirm.module.scss";
import snsIcon from "../assets/icons/button-channel.svg";

const ModalConfirm = ({
  dim,
  setModal,
  title,
  message,
  notxt,
  oktxt,
  service,
  callback1,
  callback2,
}) => {
  const handleCancel = () => {
    setModal(false);
    if (callback1) {
      callback1();
    }
  };
  const handleAccept = () => {
    setModal(false);
    if (callback2) {
      callback2();
    }
  };

  return (
    <div className={dim ? styles.wrapper + " " + styles.dim : styles.wrapper}>
      <div className={styles.container}>
        {title != "" && (
          <div className={styles.title}>
            <span>{title}</span>
          </div>
        )}
        {message != "" && (
          <div className={styles.body}>
            <p>{message}</p>
          </div>
        )}
        {service !== undefined && service === true && (
          <div className={`${styles.body}` + ` ${styles.sns}`}>
            <p className={styles.tel}>1660-6987</p>
            <div className={styles.quickSNS}>
              <img src={snsIcon} alt="" />
            </div>
          </div>
        )}
        <div className={styles.footer}>
          {notxt != "" && (
            <button className={styles.btnCancel} onClick={handleCancel}>
              {notxt}
            </button>
          )}

          <button className={styles.btnOk} onClick={handleAccept}>
            {oktxt}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirm;
