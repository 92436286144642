/* eslint-disable */
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { ko } from "date-fns/esm/locale";

import { GrFormPrevious } from "react-icons/gr";
import { GrFormNext } from "react-icons/gr";

import "react-datepicker/dist/react-datepicker.css";
import styles from "./Modal_datepicker.module.scss";
import "../styles/datepicker.scss";

const Modal_datepicker = ({ modalState, dim, title, btnCancel, btnOk }) => {
  const [startDate, setStartDate] = useState(new Date());
  const months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
  return (
    <div className={dim ? styles.wrapper + " " + styles.dim : styles.wrapper}>
      <div className={styles.container}>
        {/* <button
          className={styles.btn_close}
          onClick={() => modalState(false)}
        ></button> */}
        <div className={styles.title}>
          <span>{title}</span>
        </div>
        <div className={styles.body}>
          <DatePicker
            inline
            locale={ko}
            dateFormat="yyyy-MM-dd"
            className={styles.datepicker}
            placeholderText="Weeks start on Monday"
            selected={startDate}
            // minDate={new Date()} // 최소 선택 날짜값 지정
            maxDate={new Date()} // 최대 선택 날짜값 지정
            onChange={date => setStartDate(date)} // 날짜 선택시 실행될 함수
            shouldCloseOnSelect={false} // 선택시 사라지지 않게
            renderCustomHeader={({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
              <div className="cd-header">
                <button className="cd-arrow cd-prev" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                  <GrFormPrevious className="icon" />
                </button>

                <div className="cd-nav">
                  {date.getFullYear()}. {months[date.getMonth()]}
                </div>

                <button className="cd-arrow cd-next" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                  <GrFormNext className="icon" />
                </button>
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default Modal_datepicker;
