/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import * as Common from "../../actions/Common.js";
import Toast from "../../components/Toast.js";
import ModalConfirm from "../../components/ModalConfirm.js";
import styles from "./OrderRequest.module.scss";

import corpLogoImage from "../../assets/images/autoc_logo.png";
import emptyIcon from "../../assets/icons/empty.svg";

import { baseUrl } from "../../api";
const BASE_URL = baseUrl();
const CANCEL_URL = "/api/user/deal/v1/cancel";
const REQUEST_URL = "/api/user/deal/v1/buy_request";

const OrderRequest = () => {
  const navigate = useNavigate();

  //* API loading state
  const [loading, setLoading] = useState(true);

  /****************************************
   * TODO 초기 세팅
   ****************************************/
  //* Init API Data
  const [items, setItems] = useState([]); // 찜한 차량

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    //* 연관 상품 세팅
    fetchMyProduct();
  };

  /****************************************
   * TODO 항목별 API 목록
   ****************************************/
  // 내 계정 연관 상품 가져오기
  const fetchMyProduct = async () => {
    setLoading(true);
    try {
      const response = await axios.get(BASE_URL + REQUEST_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setItems(response.data.data);
      setLoading(false);
    } catch (err) {
      if (err.response?.status === 401) {
        setModal({
          open: true,
          dim: true,
          title: "세션이 만료되었습니다.",
          message: "로그인 화면으로 이동합니다.",
          notxt: "",
          oktxt: "확인",
          callback2: () => {
            navigate("/logout");
          },
        });
        return;
      } else {
        handleToast(err.response?.data.message);
        setLoading(false);
      }
    }
  };

  // 찜하기 설정 / 찜하기 해제
  const fetchRemove = async (id) => {
    try {
      //? favorite 해제
      const response = await axios.delete(BASE_URL + FAVORITE_URL, {
        data: id,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      fetchFavoriteData();
    } catch (err) {
      handleToast(err.response?.data.message);
    }
  };

  // 구매 요청 취소
  const fetchCancelRequest = async (ids) => {
    try {
      const response = await axios.put(
        BASE_URL + CANCEL_URL,
        { carIds: ids },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      handleToast(response.data.message);
      fetchMyProduct();
    } catch (err) {
      handleToast(err.response?.data.message);
    }
  };

  /****************************************
   * TODO 이벤트 핸들러
   ****************************************/
  //* 찜한차량 item remove 클릭
  const handleRemoveItem = async (id) => {
    setModal({
      open: true,
      dim: false,
      title: "선택한 상품을 삭제하시겠습니까?",
      message: "",
      notxt: "취소",
      oktxt: "확인",
      callback2: () => {
        fetchRemove([id]);
      },
    });
  };

  const handleRemoveGroup = async (id) => {
    if (checkItems.length === 0) {
      setModal({
        open: true,
        dim: false,
        title: "선택하신 제품이 없습니다.",
        message: "",
        notxt: "",
        oktxt: "확인",
        callback2: () => {
          null;
        },
      });
      return;
    }

    const ids = checkItems.join(",");
    setModal({
      open: true,
      dim: false,
      title: "선택한 상품들을 삭제하시겠습니까?",
      message: "",
      notxt: "취소",
      oktxt: "확인",
      callback2: () => {
        fetchRemove(checkItems);
      },
    });
  };

  // 구매요청 취소
  const onCancelRequest = (ids) => {
    if (checkItems.length === 0) {
      setModal({
        open: true,
        dim: false,
        title: "선택하신 제품이 없습니다.",
        message: "",
        notxt: "",
        oktxt: "확인",
        callback2: () => {
          null;
        },
      });
      return;
    }

    setModal({
      open: true,
      dim: true,
      title: "구매 요청을 취소하시겠습니까?",
      message: "",
      notxt: "취소",
      oktxt: "확인",
      callback2: async () => {
        await fetchCancelRequest(ids);
        fetchMyProduct();
      },
    });
  };

  /****************************************
   * TODO 공용 함수
   ****************************************/
  //* 선택된 체크박스 state
  const [checkItems, setCheckItems] = useState([]);

  // 개별 선택
  function checkHandler(checked, id) {
    //* 개별 Checkbox state 처리
    if (checked) {
      setCheckItems([...checkItems, id]);
    } else {
      setCheckItems(checkItems.filter((o) => o !== id));
    }
  }
  // 전체 선택
  function checkAllHandler(checked) {
    if (checked) {
      const ids = [];
      items.forEach((v) => ids.push(v.car.id));
      setCheckItems(ids); // state 채우기
    } else {
      setCheckItems([]); // state 비우기
    }
  }

  /****************************************
   * TODO 모달 & 토스트 팝업 상태
   ****************************************/
  //* 모달
  const [modal, setModal] = useState({
    open: false,
    dim: true,
    title: "",
    message: "",
    notxt: "",
    oktxt: "확인",
    callback1: false,
    callback2: false,
  });

  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = (txt) => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);

  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    // 선택된 제품 합산 금액 계산
    let total = 0;

    items.map((item) => {
      checkItems.filter(
        (el) => el === item.car.id && (total += item.car.carPrice.price)
      );
    });

    setTotalPrice(total);
  }, [checkItems]);

  //!//////////////////////////////////////////////////////////////
  // 구매 요청 아이템 수
  const [vehicleItem, setVehicleItem] = useState(3);

  // 구매 요청 선택 체크박스
  const [chkedItems, setChkedItems] = useState([]);

  const chkHandler = (e) => {
    let checked = e.currentTarget.checked;
    let value = e.target.value;

    if (checked) {
      setChkedItems([...chkedItems, value]);
    } else if (!checked && chkedItems.includes(value)) {
      setChkedItems(chkedItems.filter((el) => el !== value));
    }
  };

  return (
    <>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <div className={`${styles.orderRequest}` + " global-width"}>
          <div className={styles.header}>
            <h1>구매 요청</h1>
          </div>
          <div className={styles.contents}>
            {items.length == 0 ? (
              <div className={styles.nonItems}>
                <div className={styles.icon}>
                  <img src={emptyIcon} alt="" />
                </div>
                <p>구매 요청 차량이 없습니다.</p>
              </div>
            ) : (
              <div className={styles.itemWrapper}>
                <div className={styles.contHeader}>
                  <div className={styles.item}>
                    <div className={styles.tdChk}>
                      <div className="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            onChange={(e) => checkAllHandler(e.target.checked)}
                            checked={checkItems.length === items.length}
                          />
                          <div></div>
                          <span>전체선택</span>
                        </label>
                      </div>
                    </div>
                    <div className={styles.tdContent}>
                      <span className={styles.tdTit}>차량 정보</span>
                    </div>
                    <div className={styles.tdPrice}>
                      <span className={styles.tdTit}>구매 가격</span>
                    </div>
                    <div className={styles.tdStatus}>
                      <span className={styles.tdTit}>구매 상태</span>
                    </div>
                  </div>
                </div>
                <div className={styles.contList}>
                  {items.map((item) => {
                    return (
                      <div className={styles.itemCont} key={item.id}>
                        <div className={styles.itemList}>
                          <div
                            className={
                              item.car.productStatus === "EXPOSED"
                                ? `${styles.item}`
                                : `${styles.item}`
                            }
                            key={item.id}
                          >
                            <div className={styles.chk}>
                              <div className="checkbox">
                                <label>
                                  <input
                                    type="checkbox"
                                    onChange={(e) =>
                                      checkHandler(
                                        e.target.checked,
                                        item.car.id
                                      )
                                    }
                                    checked={
                                      checkItems.indexOf(item.car.id) >= 0
                                        ? true
                                        : false
                                    }
                                    data-id={item.car.id}
                                  />
                                  <div></div>
                                  <span></span>
                                </label>
                              </div>
                            </div>
                            <div className={styles.tdContent}>
                              <div className={styles.img}>
                                <Link
                                  to={`/product/${item.car.id}`}
                                  className={styles.img}
                                >
                                  <img src={item.car.mainImgUrl} alt="" />
                                </Link>
                              </div>
                              <div className={styles.desc}>
                                <div className={styles.tags}>
                                  {item.car.newYn === "Y" ? (
                                    <span
                                      className={`${styles.tag} ${styles.new}`}
                                    >
                                      New
                                    </span>
                                  ) : null}
                                  {item.car.productYn === "Y" ? (
                                    <span
                                      className={`${styles.tag} ${styles.commercialized}`}
                                    >
                                      상품화
                                    </span>
                                  ) : null}
                                  {item.car.productStatus === "BUY_ING" ? (
                                    <span
                                      className={`${styles.tag} ${styles.purchasing}`}
                                    >
                                      구매 진행 중
                                    </span>
                                  ) : null}
                                </div>
                                <div className={styles.title}>
                                  <span>
                                    <Link to={`/product/${item.car.id}`}>
                                      {item.car.manufacturerName +
                                        " " +
                                        item.car.modelName +
                                        " "}
                                      ({item.car.carNumber})
                                    </Link>
                                  </span>
                                </div>
                                <div className={styles.detail}>
                                  <span>
                                    {String(item.car.carMadedYear).slice(2) +
                                      "년" +
                                      String(item.car.carMadedMonth) +
                                      "월 (" +
                                      String(item.car.carMadedYear).slice(2) +
                                      "년형)"}
                                  </span>
                                  <span>
                                    {Common.commaFormat(item.car.mileage)}km
                                  </span>
                                  <span>
                                    {item.car.fuelType === "GAS"
                                      ? "가솔린"
                                      : item.car.fuelType === "DIESEL"
                                      ? "디젤"
                                      : item.car.fuelType === "LPG"
                                      ? "LPG"
                                      : item.car.fuelType === "GAS_LPG"
                                      ? "가솔린(LPG겸용)"
                                      : item.car.fuelType === "GAS_CNG"
                                      ? "가솔린(CNG겸용)"
                                      : item.car.fuelType === "GAS_HYBRID"
                                      ? "HEV(하이브리드)"
                                      : item.car.fuelType === "ELECTRIC"
                                      ? "전기"
                                      : item.car.fuelType === "HYDROGEN"
                                      ? "수소"
                                      : null}
                                  </span>
                                  <span>
                                    {item.car.accident === "NONE"
                                      ? "무사고"
                                      : item.car.accident === "SIMPLE_CHANGE"
                                      ? "단순교환"
                                      : item.car.accident === "SIMPLE_TOUCH"
                                      ? "단순사고(접촉)"
                                      : item.car.accident === "NORMAL"
                                      ? "사고"
                                      : item.car.accident === "TOTAL_LOSS"
                                      ? "전손"
                                      : item.car.accident === "FLOODING"
                                      ? "침수"
                                      : null}
                                  </span>
                                </div>
                                <div className={styles.options}>
                                  {item.car.carOptions?.map((opt, idx) => {
                                    return (
                                      idx < 5 && (
                                        <span key={idx}>{opt.nameKr}</span>
                                      )
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                            <div className={styles.tdPrice}>
                              <div className={styles.price}>
                                <span>
                                  <em>
                                    {Common.commaFormat(
                                      item.car.carPrice.price
                                    )}
                                  </em>
                                  원
                                </span>
                              </div>
                              <p className={styles.fees}>
                                *수수료 :{" "}
                                {Common.commaFormat(
                                  item.car.carPrice.comission
                                )}
                                원
                              </p>
                            </div>
                            <div className={styles.tdStatus}>
                              <span className={styles.rqStatus}>
                                구매 요청중
                              </span>
                              <div className={styles.rqDate}>
                                <p>
                                  {item.requestedAt
                                    ?.replace("T", " ")
                                    ?.split(".")[0]
                                    ?.slice(0, -3)
                                    ?.replaceAll("-", ".")}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={`${styles.contOption}` + " button-form"}>
                  <button
                    type="button"
                    className="btn btn-lg btn--outline"
                    onClick={() => {
                      onCancelRequest(checkItems);
                    }}
                  >
                    선택 요청 취소
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />

      {/* 로그인 모달 */}
      {modal.open && (
        <ModalConfirm
          dim={modal.dim}
          setModal={setModal}
          title={modal.title}
          message={modal.message}
          notxt={modal.notxt}
          oktxt={modal.oktxt}
          callback1={modal.callback1}
          callback2={modal.callback2}
        />
      )}
    </>
  );
};

export default OrderRequest;
