/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ModalConfirm from "../components/ModalConfirm.js";
import ProgressBar from "../components/ProgressBar.js";
import Modal_info from "../components/Modal_info.js";
import styles from "./FindId.module.scss";

import Toast from "../components/Toast.js";
import axios from "axios";
import * as Common from "../actions/Common.js";

// API URL
import { baseUrl } from "../api";
const BASE_URL = baseUrl();
const AUTH_SEND_URL = "/api/user/auth/v1/phone/send";
const AUTH_VALIDATION_URL = "/api/user/auth/v1/phone/validation";
const PHONE_CHECK_URL = "/api/user/join/v1/search/login_id";

const FindId = () => {
  // 아이디 찾기 탭 화면
  let navigate = useNavigate();
  const tabCount = 2;
  const [tabIndex, setTabIndex] = useState(0);
  const [btnState, setBtnState] = useState(false);
  const [findDataId, setFindDataId] = useState();
  const [authNum, setAuthNum] = useState();

  // 인증번호 타이머 변수
  const [times, setTimes] = useState(180 - 1);
  let interval;
  let timer, minutes, seconds;

  useEffect(() => {
    if (tabIndex == 1) {
      initTimer(times);
    }
  }, [tabIndex]);

  // 아이디 찾기 프로그레스 바
  let [progressValue, setProgressValue] = useState(100 / tabCount);

  const progressUpdateHandler = async () => {
    if (btnState != false) {
      switch (tabIndex) {
        case 0: // 핸드폰번호 입력
          if ((await fetchCheckManager()) === true) {
            fetchSend();
          } else {
            return;
          }

          break;
        case 1: // 인증번호 입력
          const result = await fetchValidation(
            authKey_1 +
              authKey_2 +
              authKey_3 +
              authKey_4 +
              authKey_5 +
              authKey_6
          );
          if (result === false) {
            return;
          }
          if (result === null) {
            setModal({
              open: true,
              dim: true,
              title: "해당 번호로 가입된 아이디가 없습니다.",
              message: "",
              notxt: "",
              oktxt: "확인",
              callback2: () => {
                window.location.reload();
                navigate("/findId");
              },
            });
            return;
          }
          if (result !== null && result !== false) {
            navigate("/yourId", {
              state: {
                loginId: result,
              },
            });
          }
          break;
      }

      setTabIndex((prev) => prev + 1);
      setProgressValue((prev) => prev + 100 / tabCount);
      setBtnState(false);

      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  /****************************************
   * TODO 항목별 API 목록
   ****************************************/
  // 인증번호 발송
  const fetchSend = async () => {
    try {
      const response = await axios.post(
        BASE_URL + AUTH_SEND_URL,
        { phone: prsnCntct },
        // {
        //   headers: {
        //     Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        //   },
        // }
      );

      setFindDataId(response.data.data.id);
      setAuthNum(response.data.data.authNum);
    } catch (err) {
      handleToast(err.response?.data.message);
      return false;
    }
  };

  // 인증번호 확인
  const fetchValidation = async (authKeys) => {
    try {
      const response = await axios.post(
        BASE_URL + AUTH_VALIDATION_URL,
        { id: findDataId, phone: prsnCntct, authNum: authKeys },
        // {
        //   headers: {
        //     Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        //   },
        // }
      );

      return response.data.data.loginId;
    } catch (err) {
      handleToast(err.response?.data.message);
      return false;
    }
  };

  // 담당자 연락처 존재확인
  const fetchCheckManager = async () => {
    try {
      const response = await axios.get(
        BASE_URL + PHONE_CHECK_URL + `?manager_phone=${prsnCntct}`
      );

      return true;
    } catch (err) {
      handleToast(err.response?.data.message);
      return false;
    }
  };

  // 이용약관 모달 세팅
  const [modalState, setModalState] = useState(false);
  const modalContent = `
    제 1장 총 칙
    제 1조 (목적)
    본 이용약관은 모비먼트 주식회사(이하 “회사”)에서 제공하는 모든 서비스(이하 “서비스”)를 이용함에 있어 회사와 이용자의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.
    PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.
    
    제 2조 (정의)
    “사이버몰”이란 회사가 재화 또는 용역(이하 “재화 등”)을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신 설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이버 몰을 운영하는 사업자의 의미로도 사용합니다.
    제 1조 (목적)
    본 이용약관은 모비먼트 주식회사(이하 “회사”)에서 제공하는 모든 서비스(이하 “서비스”)를 이용함에 있어 회사와 이용자의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.
    PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.
    
    제 2조 (정의)
    “사이버몰”이란 회사가 재화 또는 용역(이하 “재화 등”)을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신 설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이버 몰을 운영하는 사업자의 의미로도 사용합니다.
    제 1조 (목적)
    본 이용약관은 모비먼트 주식회사(이하 “회사”)에서 제공하는 모든 서비스(이하 “서비스”)를 이용함에 있어 회사와 이용자의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.
    PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.
    
    제 2조 (정의)
    “사이버몰”이란 회사가 재화 또는 용역(이하 “재화 등”)을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신 설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이버 몰을 운영하는 사업자의 의미로도 사용합니다.
  `;

  // 입력 데이터 세팅
  const [inputs, setInputs] = useState({
    prsnCntct: "", // 담당자 연락처
  });
  const { prsnCntct } = inputs;

  const updateInputData = (e) => {
    const { name, value } = e.target;

    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const resetInputData = () => {
    setInputs({
      prsnCntct: "",
    });
  };

  // 탭 1 유효성 검사
  useEffect(() => {
    // 현재 탭 인덱스 값 확인 후 return;
    if (tabIndex != 0) {
      return;
    }
    //////////////////////////////////
    if (prsnCntct != "") {
      if (prsnCntct.length >= 10) {
        setBtnState(true);
      } else {
        setBtnState(false);
      }
    } else {
      setBtnState(false);
    }
    // ID, PW input 별로 유효성 검사 처리

    ///////////////////////////////////
  }, [prsnCntct]);

  // 인증요청 데이터 세팅
  const [authKeys, setAuthKeys] = useState({
    authKey_1: "",
    authKey_2: "",
    authKey_3: "",
    authKey_4: "",
    authKey_5: "",
    authKey_6: "",
  });
  const { authKey_1, authKey_2, authKey_3, authKey_4, authKey_5, authKey_6 } =
    authKeys;
  const [authTimer, setAuthTimer] = useState("03:00");
  const [authInput, setAuthInput] = useState("");

  const updateAuthKey = (e) => {
    const value = e.target.value;

    setAuthInput(e.target.value);
    setAuthKeys({
      ...authKeys,
      ["authKey_1"]: value[0],
      ["authKey_2"]: value[1],
      ["authKey_3"]: value[2],
      ["authKey_4"]: value[3],
      ["authKey_5"]: value[4],
      ["authKey_6"]: value[5],
    });
  };

  const resetAuthKey = (e) => {
    const value = e.target.value;

    setAuthInput("");
    setAuthKeys({
      ...authKeys,
      ["authKey_1"]: null,
      ["authKey_2"]: null,
      ["authKey_3"]: null,
      ["authKey_4"]: null,
      ["authKey_5"]: null,
      ["authKey_6"]: null,
    });
  };

  const initTimer = (times) => {
    timer = times;
    interval = setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setAuthTimer(minutes + ":" + seconds);

      if (--timer < 0) {
        timer = times;
      }
      if (timer === 0) {
        clearInterval(interval);
        setAuthTimer("만료");
      }
    }, 1000);
  };

  // 인증번호 요청
  const requestAuth = () => {
    // 1. authKeys 유효성 검사
    // 텍스트 변경
    // 타이머 가동
    // setTimes(180);
    // clearInterval(interval);
    // initTimer(times);
  };

  // 탭 2 유효성 검사
  useEffect(() => {
    // 현재 탭 인덱스 값 확인 후 return;
    if (tabIndex != 1) {
      return;
    }
    //////////////////////////////////
    if (
      authKey_1 != null &&
      authKey_2 != null &&
      authKey_3 != null &&
      authKey_4 != null &&
      authKey_5 != null &&
      authKey_6 != null
    ) {
      setBtnState(true);
    } else {
      setBtnState(false);
    }
    // ID, PW input 별로 유효성 검사 처리

    ///////////////////////////////////
  }, [authKey_1, authKey_2, authKey_3, authKey_4, authKey_5, authKey_6]);

  // input[type="number"] maxLength check
  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  /****************************************
   * TODO 모달 & 토스트 팝업 상태
   ****************************************/
  //* 모달
  const [modal, setModal] = useState({
    open: false,
    dim: true,
    title: "",
    message: "",
    notxt: "",
    oktxt: "확인",
    callback1: false,
    callback2: false,
  });

  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = (txt) => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);

  return (
    <>
      <div className={`${styles.findId}` + " form-width"}>
        <div className={styles.findId__header}>
          <h1 className="heading-1">아이디 찾기</h1>
          <ProgressBar percent={progressValue} />
        </div>
        {/********** TAB 1 : 이용약관 동의 **********/}
        <div
          className={
            tabIndex == 0
              ? `${styles.findId__content} ${styles.findId__content__visible}`
              : `${styles.findId__content}`
          }
        >
          <div className={`${styles.findId__content__mainTxt}` + " heading-3"}>
            휴대폰 번호 입력
          </div>
          <p className={`${styles.findId__content__subTxt}` + " pm"}>
            담당자의 휴대폰 번호를 입력해주세요.
          </p>
          <div className={styles.findId__content__formContainer}>
            {/* 담당자 연락처 */}
            <div className="input-form">
              <p className="form-title">
                <span>담당자 연락처</span>
              </p>
              <div className="form-field">
                <div className="input-element">
                  <input
                    type="number"
                    name="prsnCntct"
                    className=""
                    placeholder="- 없이 입력"
                    onChange={(e) => {
                      const { value } = e.target;
                      const onlyNumber = value.replace(/[^0-9]/g, ""); // value의 값이 숫자가 아닐경우 빈문자열로 replace

                      if (onlyNumber === "" && prsnCntct.length === 1) {
                        updateInputData(e);
                      } else if (onlyNumber !== "") {
                        updateInputData(e);
                      }
                    }}
                    value={prsnCntct}
                    onInput={maxLengthCheck}
                    maxLength="11"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* 모달 */}
        {modalState ? (
          <Modal_info
            modalState={setModalState}
            dim={false}
            title="이용약관"
            content={modalContent}
          />
        ) : null}

        {/********** TAB 2 : 아이디&비밀번호 입력 **********/}
        <div
          className={
            tabIndex == 1
              ? `${styles.findId__content} ${styles.findId__content__visible}`
              : `${styles.findId__content}`
          }
        >
          <div className={`${styles.findId__content__mainTxt}` + " heading-3"}>
            인증번호 입력
          </div>
          <p className={`${styles.findId__content__subTxt}` + " pm"}>
            휴대폰 번호로 전송된 인증 번호를 입력해주세요.
          </p>
          <div className={styles.findId__content__formContainer}>
            {/* 인증번호 */}
            <div className="input-form">
              <div className="form-field">
                <div className="number-element">
                  <input
                    type="number"
                    name="prsnCntctAuth"
                    className=""
                    onChange={updateAuthKey}
                    onInput={maxLengthCheck}
                    onClick={resetAuthKey}
                    value={authInput}
                    maxLength="6"
                  />
                  <span id="authKey_1" className="authKeyItems">
                    {authKey_1}
                  </span>
                  <span id="authKey_2" className="authKeyItems">
                    {authKey_2}
                  </span>
                  <span id="authKey_3" className="authKeyItems">
                    {authKey_3}
                  </span>
                  <span id="authKey_4" className="authKeyItems">
                    {authKey_4}
                  </span>
                  <span id="authKey_5" className="authKeyItems">
                    {authKey_5}
                  </span>
                  <span id="authKey_6" className="authKeyItems">
                    {authKey_6}
                  </span>
                </div>
              </div>
              <div className="auth-row">
                <span className="remain_time">남은 시간 {authTimer}</span>
                <button type="button" onClick={requestAuth}>
                  인증번호 재요청
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className={`${styles.findId__buttons}` + " button-form"}>
          <button
            type="button"
            className={
              btnState ? "btn btn-xl btn--primary" : "btn btn-xl btn--disabled"
            }
            onClick={() => progressUpdateHandler()}
            disabled={!btnState}
          >
            다음
          </button>
        </div>
      </div>

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />

      {/* 로그인 모달 */}
      {modal.open && (
        <ModalConfirm
          dim={modal.dim}
          setModal={setModal}
          title={modal.title}
          message={modal.message}
          notxt={modal.notxt}
          oktxt={modal.oktxt}
          callback1={modal.callback1}
          callback2={modal.callback2}
        />
      )}
    </>
  );
};

export default FindId;
