/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

import ModalConfirm from "../components/ModalConfirm.js";
import QuickMenu from "../components/QuickMenu.js";
import QuickSNS from "../components/QuickSNS.js";
import Dashboard from "./mypages/Dashboard.js";
import OrderRequest from "./mypages/OrderRequest.js";
import OrderPurchasing from "./mypages/OrderPurchasing.js";
import OrderWaiting from "./mypages/OrderWaiting.js";
import OrderComplete from "./mypages/OrderComplete.js";
import Wish from "./mypages/Wish.js";
import Mileage from "./mypages/Mileage.js";
import Point from "./mypages/Point.js";
import Coupon from "./mypages/Coupon.js";
import Notification from "./mypages/Notification.js";
import Profile from "./mypages/Profile.js";

import styles from "./Mypage.module.scss";

import { baseUrl } from "../api";
const BASE_URL = baseUrl();
const MYDATA_URL = "/api/user/member/v1/me";

const Mypage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state != null) {
      setTabIndex(location.state.tabIndex);
      if (location.state.subTabIndex !== undefined) {
        setSubTabIndex(location.state.subTabIndex);
      }
    }
  }, [location.state]);

  //* 로그인 상태
  const [loginStatus, setLoginStatus] = useState(false);
  const [loading, setLoading] = useState(true);

  /****************************************
   * TODO 초기 세팅
   ****************************************/
  //* 탭 인덱스
  const [tabIndex, setTabIndex] = useState(1);
  const [subTabIndex, setSubTabIndex] = useState(1);

  const toggleTab = (e) => {
    setTabIndex(e.target.dataset.tabLink);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      //* 토큰 상태 확인
      const result = await fetchMyData();

      if (!result) {
        setLoginStatus(false);

        setModal({
          open: true,
          dim: true,
          title: "로그인이 필요합니다.",
          message: "지금 로그인 하시겠습니까?",
          notxt: "취소",
          oktxt: "로그인 하기",
          callback1: () => {
            navigate(-1);
          },
          callback2: () => {
            navigate("/login");
          },
        });
      } else {
        setLoginStatus(true);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /****************************************
   * TODO 항목별 API 목록
   ****************************************/
  // 토큰 상태 확인
  const fetchMyData = async () => {
    setLoading(true);

    try {
      const response = await axios.get(BASE_URL + MYDATA_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      return response;
    } catch (err) {
      console.error(err.response?.data.message);
      return false;
    }
  };

  /****************************************
   * TODO 모달 상태
   ****************************************/
  const [modal, setModal] = useState({
    open: false,
    dim: true,
    title: "",
    message: "",
    notxt: "취소",
    oktxt: "확인",
    callback1: false,
    callback2: false,
  });

  return (
    <>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <div className={`${styles.mypage}` + " global-width"}>
          {/* 대시보드 */}
          <Dashboard />

          {/* 탭 */}
          <div className={styles.tabWrapper}>
            <div className={styles.tabHeader}>
              <button
                className={tabIndex == 1 ? `${styles.active}` : null}
                data-tab-link={1}
                onClick={toggleTab}
              >
                구매 요청
              </button>
              <button
                className={tabIndex == 2 ? `${styles.active}` : null}
                data-tab-link={2}
                onClick={toggleTab}
              >
                구매 진행
              </button>
              <button
                className={tabIndex == 3 ? `${styles.active}` : null}
                data-tab-link={3}
                onClick={toggleTab}
              >
                입금 대기
              </button>
              <button
                className={tabIndex == 4 ? `${styles.active}` : null}
                data-tab-link={4}
                onClick={toggleTab}
              >
                구매 완료
              </button>
              <button
                className={tabIndex == 5 ? `${styles.active}` : null}
                data-tab-link={5}
                onClick={toggleTab}
              >
                관심 차량
              </button>
              <button
                className={tabIndex == 6 ? `${styles.active}` : null}
                data-tab-link={6}
                onClick={toggleTab}
              >
                마일리지내역
              </button>
              <button
                className={tabIndex == 7 ? `${styles.active}` : null}
                data-tab-link={7}
                onClick={toggleTab}
              >
                포인트내역
              </button>
              <button
                className={tabIndex == 8 ? `${styles.active}` : null}
                data-tab-link={8}
                onClick={toggleTab}
              >
                쿠폰
              </button>
              <button
                className={tabIndex == 9 ? `${styles.active}` : null}
                data-tab-link={9}
                onClick={toggleTab}
              >
                알림내역
              </button>
              <button
                className={tabIndex == 10 ? `${styles.active}` : null}
                data-tab-link={10}
                onClick={toggleTab}
              >
                회원정보
              </button>
            </div>
            <div className={styles.tabContainer}>
              {/* 구매 요청 */}
              {tabIndex == 1 && <OrderRequest />}
              {/* 구매 진행 */}
              {tabIndex == 2 && <OrderPurchasing />}
              {/* 입금 대기 */}
              {tabIndex == 3 && <OrderWaiting />}
              {/* 구매 완료 */}
              {tabIndex == 4 && <OrderComplete />}
              {/* 관심 차량 */}
              {tabIndex == 5 && (
                <Wish
                  subTabIndex={subTabIndex}
                  setSubTabIndex={setSubTabIndex}
                />
              )}
              {/* 마일리지내역 */}
              {tabIndex == 6 && <Mileage />}
              {/* 포인트내역 */}
              {tabIndex == 7 && <Point />}
              {/* 쿠폰 */}
              {tabIndex == 8 && <Coupon />}
              {/* 알림내역 */}
              {tabIndex == 9 && <Notification />}
              {/* 회원정보 */}
              {tabIndex == 10 && <Profile />}
            </div>
          </div>
        </div>
      )}

      {/* 퀵메뉴 */}
      <QuickMenu />

      {/* SNS - 카카오 */}
      <QuickSNS />

      {/* 로그인 모달 */}
      {modal.open && (
        <ModalConfirm
          dim={modal.dim}
          setModal={setModal}
          title={modal.title}
          message={modal.message}
          notxt={modal.notxt}
          oktxt={modal.oktxt}
          callback1={modal.callback1}
          callback2={modal.callback2}
        />
      )}
    </>
  );
};

export default Mypage;
