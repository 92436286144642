/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import axios from "axios";
import * as Common from "../../actions/Common.js";

import Toast from "../../components/Toast.js";
import ModalConfirm from "../../components/ModalConfirm.js";
import styles from "./OrderComplete.module.scss";

import emptyIcon from "../../assets/icons/empty.svg";
import { GrFormPrevious } from "react-icons/gr";
import { GrFormNext } from "react-icons/gr";
import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";

import { baseUrl } from "../../api";
const BASE_URL = baseUrl();
const FINISH_URL = "/api/user/deal/v1/finish";

const OrderComplete = () => {
  const navigate = useNavigate();

  /****************************************
   * TODO Pagination Init
   ****************************************/
  const [pagination, setPagination] = useState([]);
  const [page, setPage] = useState(0); // 현재 페이지 번호
  const rangeSize = 10;

  //* API loading state
  const [loading, setLoading] = useState(true);

  /****************************************
   * TODO 초기 세팅
   ****************************************/
  // 데이트피커
  const [startDate, setStartDate] = useState(); // 시작 날짜
  const [endDate, setEndDate] = useState(new Date()); // 종료 날짜

  //* Init API Data
  const [items, setItems] = useState([]); // 찜한 차량

  useEffect(() => {
    const today = new Date();
    const lastMonth = new Date(today.setMonth(today.getMonth() - 1));

    setStartDate(lastMonth);
  }, []);

  useEffect(() => {
    if (startDate === undefined || endDate === undefined) {
      return;
    }

    let monthGap = endDate.getMonth() - startDate.getMonth();

    if (startDate > endDate) {
      handleToast("시작 날짜가 종료 날짜보다 가깝습니다.");
      return;
    }
    if (monthGap > 6) {
      setEndDate(startDate);

      // const pastDay = new Date(startDate);
      // const newEndDay = new Date(pastDay.setMonth(pastDay.getMonth() + 6));
      // setEndDate(newEndDay);

      handleToast("이용 내역은 6개월 단위로 조회 가능합니다.");
      return;
    }

    window.scrollTo(0, 0);
    fetchData();
  }, [page, startDate, endDate]);

  const fetchData = async () => {
    //* 연관 상품 세팅
    fetchMyProduct();
  };

  /****************************************
   * TODO 항목별 API 목록
   ****************************************/
  // 내 계정 연관 상품 가져오기
  const fetchMyProduct = async () => {
    setLoading(true);
    // started_at=2020-10-10T00:00:00&ended_at=2020-10-10T23:59:59
    try {
      const parameter = `?page=${page}&size=${rangeSize}&started_at=${Common.dateToString(
        startDate,
        "start"
      )}&ended_at=${Common.dateToString(endDate, "end")}`;
      const response = await axios.get(BASE_URL + FINISH_URL + parameter, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setPagination(response.data.pagination);
      setItems(response.data.data);
      setLoading(false);
    } catch (err) {
      if (err.response?.status === 401) {
        setModal({
          open: true,
          dim: true,
          title: "세션이 만료되었습니다.",
          message: "로그인 화면으로 이동합니다.",
          notxt: "",
          oktxt: "확인",
          callback2: () => {
            navigate("/logout");
          },
        });
        return;
      } else {
        handleToast(err.response?.data.message);
        setLoading(false);
      }
    }
  };

  // 찜하기 설정 / 찜하기 해제
  const fetchRemove = async (id) => {
    try {
      //? favorite 해제
      const response = await axios.delete(BASE_URL + FAVORITE_URL, {
        data: id,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      fetchFavoriteData();
    } catch (err) {
      console.log(err);
    }
  };

  /****************************************
   * TODO 이벤트 핸들러
   ****************************************/
  //* 찜한차량 item remove 클릭
  const handleRemoveItem = async (id) => {
    setModal({
      open: true,
      dim: false,
      title: "선택한 상품을 삭제하시겠습니까?",
      message: "",
      notxt: "취소",
      oktxt: "확인",
      callback2: () => {
        fetchRemove([id]);
      },
    });
  };

  const handleRemoveGroup = async (id) => {
    if (checkItems.length === 0) {
      setModal({
        open: true,
        dim: false,
        title: "선택하신 제품이 없습니다.",
        message: "",
        notxt: "",
        oktxt: "확인",
        callback2: () => {
          null;
        },
      });
      return;
    }

    const ids = checkItems.join(",");
    setModal({
      open: true,
      dim: false,
      title: "선택한 상품들을 삭제하시겠습니까?",
      message: "",
      notxt: "취소",
      oktxt: "확인",
      callback2: () => {
        fetchRemove(checkItems);
      },
    });
  };

  // 부가서비스 선택
  const handleAddService = (ids) => {
    if (ids.length === 0) {
      // 선택된 제품이 없을 경우
      setModal({
        open: true,
        dim: false,
        title: "선택하신 제품이 없습니다.",
        message: "",
        notxt: "",
        oktxt: "확인",
        callback2: () => {
          null;
        },
      });
      return;
    }

    navigate("/service", { state: { carIds: ids } });
  };

  /****************************************
   * TODO 공용 함수
   ****************************************/
  //* 선택된 체크박스 state
  const [checkItems, setCheckItems] = useState([]);

  // 개별 선택
  function checkHandler(checked, item) {
    //* 개별 Checkbox state 처리
    if (checked) {
      setCheckItems([...checkItems, item.id]);
    } else {
      setCheckItems(checkItems.filter((o) => o !== item.id));
    }
  }
  // 전체 선택
  function checkAllHandler(checked) {
    if (checked) {
      const ids = [];
      items.forEach((v) => ids.push(v.id));
      setCheckItems(ids); // state 채우기
    } else {
      setCheckItems([]); // state 비우기
    }
  }

  /****************************************
   * TODO 모달 & 토스트 팝업 상태
   ****************************************/
  //* 모달
  const [modal, setModal] = useState({
    open: false,
    dim: true,
    title: "",
    message: "",
    notxt: "",
    oktxt: "확인",
    callback1: false,
    callback2: false,
  });

  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = (txt) => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);

  return (
    <>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <div className={`${styles.orderComplete}` + " global-width"}>
          <div className={styles.header}>
            <h1>구매 완료</h1>
            <div className={styles.dateRange}>
              <DatePicker
                locale={ko}
                dateFormat="yyyy-MM-dd"
                className={styles.datepicker}
                placeholderText="Weeks start on Monday"
                selected={startDate}
                // minDate={new Date()}
                maxDate={new Date()}
                onChange={(date) => setStartDate(date)}
                renderCustomHeader={({
                  date,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div className="cd-header">
                    <p className="ch-tit">날짜 선택</p>
                    <button
                      className="cd-arrow cd-prev"
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      <GrFormPrevious className="icon" />
                    </button>

                    <div className="cd-nav">
                      {date.getFullYear()}. {date.getMonth() + 1}
                    </div>

                    <button
                      className="cd-arrow cd-next"
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      <GrFormNext className="icon" />
                    </button>
                  </div>
                )}
              />
              <DatePicker
                locale={ko}
                dateFormat="yyyy-MM-dd"
                className={styles.datepicker}
                placeholderText="Weeks start on Monday"
                selected={endDate}
                // minDate={new Date()}
                maxDate={new Date()}
                onChange={(date) => setEndDate(date)}
                renderCustomHeader={({
                  date,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                  nextMonthButtonDisabled,
                }) => (
                  <div className="cd-header">
                    <p className="ch-tit">날짜 선택</p>
                    <button
                      className="cd-arrow cd-prev"
                      onClick={decreaseMonth}
                      disabled={prevMonthButtonDisabled}
                    >
                      <GrFormPrevious className="icon" />
                    </button>

                    <div className="cd-nav">
                      {date.getFullYear()}. {date.getMonth() + 1}
                    </div>

                    <button
                      className="cd-arrow cd-next"
                      onClick={increaseMonth}
                      disabled={nextMonthButtonDisabled}
                    >
                      <GrFormNext className="icon" />
                    </button>
                  </div>
                )}
              />
            </div>
          </div>
          <div className={styles.contents}>
            {items.length == 0 ? (
              <div className={styles.nonItems}>
                <div className={styles.icon}>
                  <img src={emptyIcon} alt="" />
                </div>
                <p>구매 완료 차량이 없습니다.</p>
              </div>
            ) : (
              <div className={styles.itemWrapper}>
                <div className={styles.contHeader}>
                  <div className={styles.item}>
                    {/* <div className={styles.tdChk}>
                    <div className="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          onChange={(e) => checkAllHandler(e.target.checked)}
                          checked={checkItems.length === items.length}
                        />
                        <div></div>
                        <span>전체선택</span>
                      </label>
                    </div>
                  </div> */}
                    <div className={styles.tdContent}>
                      <span className={styles.tdTit}>차량 정보</span>
                    </div>
                    <div className={styles.tdService}>
                      <span className={styles.tdTit}>구매 관리</span>
                    </div>
                    <div className={styles.tdPrice}>
                      <span className={styles.tdTit}>구매 가격</span>
                    </div>
                    <div className={styles.tdStatus}>
                      <span className={styles.tdTit}>구매 상태</span>
                    </div>
                  </div>
                </div>
                <div className={styles.contList}>
                  {items.map((item) => {
                    return (
                      <div
                        key={item.id}
                        className={`${styles.itemCont} ${styles.active}`}
                      >
                        <div className={styles.itemStatus}>
                          <span className={styles.date}>
                            {item.createdAt
                              .split("T")[0]
                              .split("-")[0]
                              .slice(2) +
                              "." +
                              item.createdAt.split("T")[0].split("-")[1] +
                              "." +
                              item.createdAt.split("T")[0].split("-")[2]}
                          </span>
                          <span className={styles.corpTit}>
                            &#40;{item.identityNumber}&#41;
                          </span>
                        </div>
                        <div className={styles.itemList}>
                          <div
                            className={
                              item.productStatus === "EXPOSED"
                                ? `${styles.item}`
                                : `${styles.item}`
                            }
                            key={item.id}
                          >
                            {/* <div className={styles.chk}>
                            <div className="checkbox">
                              <label>
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    checkHandler(e.target.checked, item)
                                  }
                                  checked={
                                    checkItems.indexOf(item.id) >= 0
                                      ? true
                                      : false
                                  }
                                  data-id={item.id}
                                />
                                <div></div>
                                <span></span>
                              </label>
                            </div>
                          </div> */}
                            <div className={styles.tdContent}>
                              <div className={styles.img}>
                                {item.dealItems.length <= 1 ? (
                                  <Link
                                    to={`/product/${item.dealItems[0].car.id}`}
                                    className={styles.img}
                                  >
                                    <img
                                      src={item.dealItems[0].car.mainImgUrl}
                                      alt=""
                                    />
                                  </Link>
                                ) : (
                                  <span className={styles.img}>
                                    <img
                                      src={item.dealItems[0].car.mainImgUrl}
                                      alt=""
                                    />
                                  </span>
                                )}

                                {/* <Link
                                to={`/product/${item.id}`}
                                className={styles.img}
                              >
                                <img
                                  src={item.dealItems[0].car.mainImgUrl}
                                  alt=""
                                />
                              </Link> */}
                              </div>
                              <div className={styles.desc}>
                                {item.dealItems.length <= 1 && (
                                  <div className={styles.tags}>
                                    {item.dealItems[0].car.newYn === "Y" ? (
                                      <span
                                        className={`${styles.tag} ${styles.new}`}
                                      >
                                        New
                                      </span>
                                    ) : null}
                                    {item.dealItems[0].car.productYn === "Y" ? (
                                      <span
                                        className={`${styles.tag} ${styles.commercialized}`}
                                      >
                                        상품화
                                      </span>
                                    ) : null}
                                    {item.dealItems[0].car.productStatus ===
                                    "BUY_ING" ? (
                                      <span
                                        className={`${styles.tag} ${styles.purchasing}`}
                                      >
                                        입금 대기 중
                                      </span>
                                    ) : null}
                                  </div>
                                )}
                                <div className={styles.title}>
                                  <span>
                                    <span>
                                      {item.dealItems.length <= 1 ? (
                                        <Link
                                          to={`/product/${item.dealItems[0].car.id}`}
                                        >
                                          {item.dealItems[0].car
                                            .manufacturerName +
                                            " " +
                                            item.dealItems[0].car.modelName +
                                            " " +
                                            "(" +
                                            item.dealItems[0].car.carNumber +
                                            ")"}
                                        </Link>
                                      ) : (
                                        item.dealItems[0].car.manufacturerName +
                                        " " +
                                        item.dealItems[0].car.modelName +
                                        ` 외 ${item.dealItems.length - 1}대`
                                      )}
                                    </span>
                                    {item.dealItems.length > 1 && (
                                      <div className={styles.multipleCarNames}>
                                        {item.dealItems.map((dealItem) => {
                                          return (
                                            <Link
                                              to={`/product/${dealItem.car.id}`}
                                              key={dealItem.car.id}
                                            >
                                              {dealItem.car.manufacturerName +
                                                " " +
                                                dealItem.car.modelName +
                                                " " +
                                                "(" +
                                                dealItem.car.carNumber +
                                                ")"}
                                            </Link>
                                          );
                                        })}
                                      </div>
                                    )}
                                  </span>
                                </div>
                                {item.dealItems.length <= 1 && (
                                  <div className={styles.detail}>
                                    <span>
                                      {String(
                                        item.dealItems[0].car.carMadedYear
                                      ).slice(2) +
                                        "년" +
                                        String(
                                          item.dealItems[0].car.carMadedMonth
                                        ) +
                                        "월 (" +
                                        String(
                                          item.dealItems[0].car.carMadedYear
                                        ).slice(2) +
                                        "년형)"}
                                    </span>
                                    <span>
                                      {Common.commaFormat(
                                        item.dealItems[0].car.mileage
                                      )}
                                      km
                                    </span>
                                    <span>
                                      {item.dealItems[0].car.fuelType === "GAS"
                                        ? "가솔린"
                                        : item.dealItems[0].car.fuelType ===
                                          "DIESEL"
                                        ? "디젤"
                                        : item.dealItems[0].car.fuelType ===
                                          "LPG"
                                        ? "LPG"
                                        : item.dealItems[0].car.fuelType ===
                                          "GAS_LPG"
                                        ? "가솔린(LPG겸용)"
                                        : item.dealItems[0].car.fuelType ===
                                          "GAS_CNG"
                                        ? "가솔린(CNG겸용)"
                                        : item.dealItems[0].car.fuelType ===
                                          "GAS_HYBRID"
                                        ? "HEV(하이브리드)"
                                        : item.dealItems[0].car.fuelType ===
                                          "ELECTRIC"
                                        ? "전기"
                                        : item.dealItems[0].car.fuelType ===
                                          "HYDROGEN"
                                        ? "수소"
                                        : null}
                                    </span>
                                    <span>
                                      {item.dealItems[0].car.accident === "NONE"
                                        ? "무사고"
                                        : item.dealItems[0].car.accident ===
                                          "SIMPLE_CHANGE"
                                        ? "단순교환"
                                        : item.dealItems[0].car.accident ===
                                          "SIMPLE_TOUCH"
                                        ? "단순사고(접촉)"
                                        : item.dealItems[0].car.accident ===
                                          "NORMAL"
                                        ? "사고"
                                        : item.dealItems[0].car.accident ===
                                          "TOTAL_LOSS"
                                        ? "전손"
                                        : item.dealItems[0].car.accident ===
                                          "FLOODING"
                                        ? "침수"
                                        : null}
                                    </span>
                                  </div>
                                )}
                                {/* {item.dealItems.length <= 1 && (
                                <div className={styles.options}>
                                  {item.dealItems[0].car.carOptions?.map(
                                    (opt, idx) => {
                                      return (
                                        idx < 5 && (
                                          <span key={idx}>{opt.nameKr}</span>
                                        )
                                      );
                                    }
                                  )}
                                </div>
                              )} */}
                              </div>
                            </div>
                            <div
                              className={`${styles.tdService}` + " button-form"}
                            >
                              <button
                                type="button"
                                className="btn btn-md btn--outline_black"
                                onClick={() => {
                                  navigate("/orderDetail", {
                                    state: {
                                      receiptId: item.id,
                                    },
                                  });
                                }}
                              >
                                구매 내역
                              </button>
                              {/* <button
                              type="button"
                              className="btn btn-md btn--primary"
                            >
                              판매 신청
                            </button>
                            <button
                              type="button"
                              className="btn btn-md btn--disabled"
                            >
                              판매 신청 완료
                            </button> */}
                            </div>
                            <div className={styles.tdPrice}>
                              <div className={styles.price}>
                                <span>
                                  <em>
                                    {Common.commaFormat(item.totalCarPrice)}
                                  </em>
                                  원
                                </span>
                              </div>
                              <p className={styles.fees}>
                                *수수료 :
                                {Common.commaFormat(item.totalComissionPrice)}원
                              </p>
                            </div>
                            <div className={styles.tdStatus}>
                              <span className={styles.rqStatus}>
                                {item.dealStatus === "BUY_ING"
                                  ? "입금대기"
                                  : item.dealStatus === "DEPOSIT_FINISH"
                                  ? "입금완료"
                                  : item.dealStatus === "PRODUCT_MAKING"
                                  ? "상품화중"
                                  : item.dealStatus === "PRODUCT_FINISH"
                                  ? "상품화완료"
                                  : item.dealStatus === "DELIVERY_WAITING"
                                  ? "탁송대기"
                                  : item.dealStatus === "DELIVERY_ING"
                                  ? "차량탁송"
                                  : item.dealStatus === "TRANSFERED"
                                  ? "차량인계"
                                  : item.dealStatus === "FINISH"
                                  ? "구매완료"
                                  : item.dealStatus === "CANCEL"
                                  ? "구매취소"
                                  : null}
                              </span>
                              <div className={styles.rqDate}>
                                {/* <p>입금완료</p> */}
                                <p>
                                  {item.dealFinishedAt
                                    ?.replace("T", " ")
                                    ?.split(".")[0]
                                    ?.slice(0, -3)
                                    ?.replaceAll("-", ".")}
                                </p>
                                {/* <p>
                                {item.depositByDate
                                  .replace("T", " ")
                                  .slice(0, -3)}
                                </p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                {/* pagination */}
                {pagination.totalPages > 1 && (
                  <div className={styles.pagination}>
                    <div className={styles.numList}>
                      <div
                        className={styles.btnPrev}
                        onClick={() => setPage(pagination.prevPage)}
                        aria-current={pagination.isFirst ? false : true}
                      >
                        <AiOutlineLeft />
                      </div>
                      <div
                        className={styles.btnNext}
                        onClick={() => setPage(pagination.nextPage)}
                        aria-current={pagination.isLast ? false : true}
                      >
                        <AiOutlineRight />
                      </div>
                      <ul className={styles.currentPage}>
                        {Array(pagination.endPage)
                          .fill()
                          .map(
                            (_, i) =>
                              i >= pagination.startPage - 1 && (
                                <li
                                  key={i}
                                  onClick={() => setPage(i)}
                                  className={
                                    pagination.page === i
                                      ? `${styles.active}`
                                      : null
                                  }
                                >
                                  {i + 1}
                                </li>
                              )
                          )}
                      </ul>
                      {pagination.endPage !== pagination.totalPages && (
                        <div className={styles.lastPage}>
                          <li
                            onClick={() => setPage(pagination.totalPages - 1)}
                          >
                            {pagination.totalPages}
                          </li>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {/* <div className={`${styles.contOption}` + " button-form"}>
                <button type="button" className="btn btn-lg btn--outline">
                  선택 내역 삭제
                </button>
              </div> */}
              </div>
            )}
          </div>
        </div>
      )}

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />

      {/* 로그인 모달 */}
      {modal.open && (
        <ModalConfirm
          dim={modal.dim}
          setModal={setModal}
          title={modal.title}
          message={modal.message}
          notxt={modal.notxt}
          oktxt={modal.oktxt}
          callback1={modal.callback1}
          callback2={modal.callback2}
        />
      )}
    </>
  );
};

export default OrderComplete;
