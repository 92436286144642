/* eslint-disable */
import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import animation from "../styles/animation.scss";

import Modal_normal from "../components/Modal_normal.js";
import Modal_info from "../components/Modal_info.js";
import Modal_datepicker from "../components/Modal_datepicker.js";

const PageModal = () => {
  const [modal1State, setModal1State] = useState(false);
  const [modal2State, setModal2State] = useState(false);
  const [modal3State, setModal3State] = useState(false);
  const [modal4State, setModal4State] = useState(false);
  const modal4_content = `
    - 마일리지에 대한 설명입니다.
    - 마일리지에 대한 설명입니다.
    - 마일리지에 대한 설명입니다.
    - 마일리지에 대한 설명입니다.
    - 마일리지에 대한 설명입니다.
    - 마일리지에 대한 설명입니다.
    - 마일리지에 대한 설명입니다.
    - 마일리지에 대한 설명입니다.
    - 마일리지에 대한 설명입니다.
    - 마일리지에 대한 설명입니다.
  `;
  const [modal5State, setModal5State] = useState(false);
  const modal5_content = `
    제 1장 총 칙
    제 1조 (목적)
    본 이용약관은 모비먼트 주식회사(이하 “회사”)에서 제공하는 모든 서비스(이하 “서비스”)를 이용함에 있어 회사와 이용자의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.
    PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.
    
    제 2조 (정의)
    “사이버몰”이란 회사가 재화 또는 용역(이하 “재화 등”)을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신 설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이버 몰을 운영하는 사업자의 의미로도 사용합니다.
    제 1조 (목적)
    본 이용약관은 모비먼트 주식회사(이하 “회사”)에서 제공하는 모든 서비스(이하 “서비스”)를 이용함에 있어 회사와 이용자의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.
    PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.
    
    제 2조 (정의)
    “사이버몰”이란 회사가 재화 또는 용역(이하 “재화 등”)을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신 설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이버 몰을 운영하는 사업자의 의미로도 사용합니다.
    제 1조 (목적)
    본 이용약관은 모비먼트 주식회사(이하 “회사”)에서 제공하는 모든 서비스(이하 “서비스”)를 이용함에 있어 회사와 이용자의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.
    PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.
    
    제 2조 (정의)
    “사이버몰”이란 회사가 재화 또는 용역(이하 “재화 등”)을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신 설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이버 몰을 운영하는 사업자의 의미로도 사용합니다.
  `;
  const [modal6State, setModal6State] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  return (
    <>
      <button
        className="openModalBtn"
        onClick={() => {
          setModal1State(true);
        }}
      >
        Modal_Center_1tab
      </button>
      {modal1State ? (
        <Modal_normal
          modalState={setModal1State}
          dim={true}
          title="제목을 입력하세요."
          content=""
          btnCancel=""
          btnOk="확인"
        />
      ) : null}

      <button
        className="openModalBtn"
        onClick={() => {
          setModal2State(true);
        }}
      >
        Modal_Center_2tab
      </button>
      {modal2State ? (
        <Modal_normal
          modalState={setModal2State}
          dim={true}
          title="제목을 입력하세요."
          content=""
          btnCancel="취소"
          btnOk="확인"
        />
      ) : null}

      <button
        className="openModalBtn"
        onClick={() => {
          setModal3State(true);
        }}
      >
        Modal_Center_Title_2tab
      </button>
      {modal3State ? (
        <Modal_normal
          modalState={setModal3State}
          dim={true}
          title="로그인이 필요합니다."
          content="지금 로그인 하시겠습니까?"
          btnCancel="취소"
          btnOk="로그인 하기"
        />
      ) : null}

      <button
        className="openModalBtn"
        onClick={() => {
          setModal4State(true);
        }}
      >
        Modal_info
      </button>
      {modal4State ? (
        <Modal_info
          modalState={setModal4State}
          dim={false}
          title="마일리지 설명"
          content={modal4_content}
        />
      ) : null}

      <button
        className="openModalBtn"
        onClick={() => {
          setModal5State(true);
        }}
      >
        Modal_info
      </button>
      {modal5State ? (
        <Modal_info
          modalState={setModal5State}
          dim={false}
          title="이용약관"
          content={modal5_content}
        />
      ) : null}

      <button
        className="openModalBtn"
        onClick={() => {
          setModal6State(true);
        }}
      >
        Modal_datepicker
      </button>
      {modal6State ? (
        <Modal_datepicker
          modalState={setModal6State}
          dim={false}
          title="날짜 선택"
        />
      ) : null}
    </>
  );
};

export default PageModal;
