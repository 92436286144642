/* eslint-disable */
import React, { useEffect, useState } from "react";

import Toast from "../components/Toast.js";
import DaumPost from "../components/DaumPost.js";
import axios from "axios";
import ApiController from "../api/ApiController";

import styles from "./Modal_address.module.scss";

// API URL
import { baseUrl } from "../api";
const BASE_URL = baseUrl();
const DELIVERY_URL = "/api/user/member/v1/delivery_location";

const Modal_address = ({ method, data, deleter, fetchDelivery }) => {
  const dataType = method;
  const [addressData, setAddressData] = useState(data);

  // 모달 버튼 상태
  const [btnStatus, setBtnStatus] = useState(false);

  // 입력 데이터 세팅
  const [inputs, setInputs] = useState({
    address: "", // 주소
    addressDetail: "", // 상세 주소
    addressName: "", // 배송지 이름
  });
  const { address, addressDetail, addressName, sido, sigungu, sigunguCode } =
    inputs;

  const updateInputData = (e) => {
    const { name, value } = e.target;

    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const resetInputData = () => {
    setInputs({
      address: "",
      addressDetail: "",
      sido: "",
      sigungu: "",
      sigunguCode: "",
    });
  };

  //TODO 다음 API 주소 검색 팝업 세팅
  //* 주소 검색 팝업 데이터
  const [addressPopup, setAddressPopup] = useState(false);
  const [daumAddress, setDaumAddress] = useState("");
  const [addressPost, setAddressPost] = useState("");
  const [daumData, setDaumData] = useState();

  // 팝업창 열기
  const openPostCode = () => {
    setAddressPopup(true);
  };
  // 팝업창 닫기
  const closePostCode = () => {
    setAddressPopup(false);
  };

  useEffect(() => {
    setInputs({
      ...inputs,
      ["address"]: daumAddress,
      ["sido"]: daumAddress.sido,
      ["sigungu"]: daumAddress.sigungu,
      ["sigunguCode"]: daumAddress.sigunguCode,
    });
  }, [daumAddress, addressPost]);
  //TODO

  //TODO 배송지 확인 & 수정 FORM DATA 전송
  const handleSubmit = async (e) => {
    e.preventDefault();

    let data = [];
    if (dataType == "CREATE") {
      //* 주소 신규 추가
      data = [
        ...addressData,
        {
          name: addressName,
          address: address,
          addressDetail: addressDetail,
          sido: sido,
          sigungu: sigungu,
          sigunguCode: sigunguCode,
        },
      ];
    } else {
      //* 기존 주소 변경
      data = addressData;
      data[dataType] = {
        name: addressName,
        address: address,
        addressDetail: addressDetail,
        sido: sido,
        sigungu: sigungu,
        sigunguCode: sigunguCode,
      };
    }

    const result = fetchData(data);
    result && deleter(false);
  };

  const fetchData = async (data) => {
    try {
      const response = await axios.put(
        BASE_URL + DELIVERY_URL,
        { deliveryLocations: data },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      handleToast(response.data.message);
      fetchDelivery();
      return true;
    } catch (err) {
      handleToast(err.response?.data.message);
    }
  };
  //TODO

  //* 유효성 검사
  useEffect(() => {
    if (address != "" && addressDetail != "") {
      setBtnStatus(true);
    } else {
      setBtnStatus(false);
    }
  }, [address, addressDetail]);

  //* 배송지 수정시 데이터 세팅
  useEffect(() => {
    if (dataType != "CREATE") {
      setInputs({
        ...inputs,
        ["address"]: addressData[dataType].address,
        ["addressDetail"]: addressData[dataType].addressDetail,
        ["sido"]: addressData[dataType].sido,
        ["sigungu"]: addressData[dataType].sigungu,
        ["sigunguCode"]: addressData[dataType].sigunguCode,
      });
    }
  }, []);

  /****************************************
   * TODO 모달 & 토스트 팝업 상태
   ****************************************/
  //* 모달
  const [modal, setModal] = useState({
    open: false,
    dim: true,
    title: "",
    message: "",
    notxt: "",
    oktxt: "확인",
    callback1: false,
    callback2: false,
  });

  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = (txt) => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);

  return (
    <>
      <div className={`${styles.modal} ${styles.modalWish}`}>
        <div className={`${styles.wrapper} ${styles.dim}`}>
          <form className={styles.container} action="" onSubmit={handleSubmit}>
            <button
              type="button"
              className={styles.btn_close}
              onClick={() => deleter(false)}
            ></button>
            <div className={styles.title}>
              <span>
                {dataType == "CREATE" ? "배송지 등록" : "배송지 수정"}
              </span>
            </div>
            <div className={styles.body}>
              <div className={styles.frmCont}>
                {/* 배송지 이름 */}
                <div className="input-form">
                  <p className="form-title">
                    <span>배송지 이름</span>
                  </p>
                  <div className="form-field">
                    <div className="input-element">
                      <input
                        type="text"
                        name="addressName"
                        className=""
                        placeholder="배송지 이름"
                        onChange={updateInputData}
                        value={addressName}
                      />
                    </div>
                  </div>
                </div>
                {/* 배송지 주소 */}
                <div className="input-form">
                  <p className="form-title">
                    <span>배송지 주소</span>
                  </p>
                  <div className="form-field">
                    <div className="input-element">
                      <input
                        type="text"
                        name="address"
                        className=""
                        placeholder="주소"
                        onChange={updateInputData}
                        value={address}
                        readOnly
                      />
                    </div>
                    <button
                      type="button"
                      className="btn-address"
                      onClick={openPostCode}
                    >
                      주소 검색
                    </button>
                  </div>
                </div>
                <div className="input-form">
                  <div className="form-field">
                    <div className="input-element">
                      <input
                        type="text"
                        name="addressDetail"
                        className=""
                        placeholder="상세 주소 입력"
                        onChange={updateInputData}
                        value={addressDetail}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.footer}>
              <div className="button-form">
                <button
                  type="submit"
                  className={
                    btnStatus
                      ? "btn btn-xl btn--primary"
                      : "btn btn-xl btn--disabled"
                  }
                  // onClick={() => deleter(false)}
                >
                  완료
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />

      {/* 팝업 */}
      {addressPopup && (
        <DaumPost
          onClose={closePostCode}
          setAddress={setDaumAddress}
          setAddressPost={setAddressPost}
          setDaumData={setDaumData}
        />
      )}
    </>
  );
};

export default Modal_address;
