/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import * as Common from "../actions/Common.js";

import Toast from "./Toast.js";
import styles from "./ModalInsuranceHistory.module.scss";

import CloseIcon from "../assets/icons/close-gr.svg";

import accidentThumb1 from "../assets/icons/accident-1.png";
import accidentThumb1_active from "../assets/icons/accident-1_active.png";
import accidentThumb2 from "../assets/icons/accident-2.png";
import accidentThumb2_active from "../assets/icons/accident-2_active.png";
import accidentThumb3 from "../assets/icons/accident-3.png";
import accidentThumb3_active from "../assets/icons/accident-3_active.png";
import accidentThumb4 from "../assets/icons/accident-4.png";
import accidentThumb4_active from "../assets/icons/accident-4_active.png";
import accidentThumb5 from "../assets/icons/accident-5.png";
import accidentThumb5_active from "../assets/icons/accident-5_active.png";
import accidentThumb6 from "../assets/icons/accident-6.png";
import accidentThumb6_active from "../assets/icons/accident-6_active.png";
import accidentThumb7 from "../assets/icons/accident-7.png";
import accidentThumb7_active from "../assets/icons/accident-7_active.png";
import accidentThumb8 from "../assets/icons/accident-8.png";
import accidentThumb8_active from "../assets/icons/accident-8_active.png";

import { baseUrl } from "../api";
const BASE_URL = baseUrl();
const PRODUCT_URL = "/api/user/product/v1";

const ModalInsuranceHistory = ({ dim, setModal, callback, carId }) => {
  const handleCloseModal = () => {
    document.body.style.overflow = "unset";
    setModal(false);
  };

  // 스크롤 오프셋 설정
  const reportItem1 = useRef(null);
  const reportItem2 = useRef(null);
  const reportItem3 = useRef(null);
  const reportItem4 = useRef(null);
  const reportItem5 = useRef(null);

  const onScrollTo = (idx) => {
    switch (idx) {
      case 1:
        reportItem1.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 2:
        reportItem2.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 3:
        reportItem3.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 4:
        reportItem4.current?.scrollIntoView({ behavior: "smooth" });
        break;
      case 5:
        reportItem5.current?.scrollIntoView({ behavior: "smooth" });
        break;
    }
  };

  /****************************************
   * TODO API 연동 및 세팅
   ****************************************/
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState([]);

  useEffect(() => {
    if (carId !== null) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    setLoading(true);

    try {
      //* 보험 이력 데이터 세팅
      const response = await fetchHistoryData();
      if (response !== null) {
        setItem(response.data.data);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      document.body.style.overflow = "unset";
      setModal(false);
      callback();
    }
  };
  /****************************************
   * TODO 항목별 API 목록
   ****************************************/
  // 보험 이력
  const fetchHistoryData = async () => {
    try {
      const response = await axios.get(
        BASE_URL + PRODUCT_URL + `/${carId}/car_history`
      );

      return response;
    } catch (err) {
      return false;
    }
  };

  /****************************************
   * TODO 토스트 & 모달 상태
   ****************************************/
  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = (txt) => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);

  return (
    <>
      <div className={dim ? styles.wrapper + " " + styles.dim : styles.wrapper}>
        <div className={styles.dimLayer}></div>
        {loading ? (
          <div className="loader"></div>
        ) : (
          <div className={styles.container}>
            <button className={styles.btnCloseModal} onClick={handleCloseModal}>
              <img src={CloseIcon} alt="" />
            </button>
            <div className={styles.header}></div>
            <div className={styles.body}>
              <div className={styles.carInfo}>
                <div className={styles.detail}>
                  <span className={styles.carModel}>{item.carName}</span>
                  <span className={styles.carNumber}>
                    {item.carNumber?.substring(0, 3)}****
                  </span>
                </div>
                <div className={styles.date}>
                  정보조회일자 :{" "}
                  {item.searchedAt?.replace(
                    /(\d{4})(\d{2})(\d{2})/,
                    "$1-$2-$3"
                  )}
                </div>
              </div>
              {/* 차량 정보 내비게이션 */}
              <nav className={styles.reportNav}>
                <ul>
                  <li>
                    <button
                      type="button"
                      onClick={() => {
                        onScrollTo(1);
                      }}
                    >
                      요약
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      onClick={() => {
                        onScrollTo(2);
                      }}
                    >
                      일반사양
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      onClick={() => {
                        onScrollTo(3);
                      }}
                    >
                      자동차번호/소유자 변경이력
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      onClick={() => {
                        onScrollTo(4);
                      }}
                    >
                      특수사고이력
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      onClick={() => {
                        onScrollTo(5);
                      }}
                    >
                      보험사고이력 상세
                    </button>
                  </li>
                </ul>
              </nav>
              <div className={styles.reportList}>
                <section
                  className={styles.reportItem}
                  id="reportItem1"
                  ref={reportItem1}
                >
                  <div className={styles.itemHeader}>
                    <span className={styles.tit}>
                      <h4 className="h4 fwb">차량 사고 이력 정보 (요약)</h4>
                    </span>
                  </div>
                  <div className={styles.itemBody}>
                    <div className={styles.desc}></div>
                    <div className={styles.contents}>
                      <div className={styles.summaryThumbs}>
                        <div
                          className={
                            item.normalAccidentCountTotal === 0 ||
                            item.normalAccidentCountTotal === null
                              ? `${styles.item}`
                              : `${styles.item} ${styles.active}`
                          }
                        >
                          <div className={styles.itemTit}>
                            <img
                              className={styles.imgDefault}
                              src={accidentThumb1}
                              alt=""
                            />
                            <img
                              className={styles.imgActive}
                              src={accidentThumb1_active}
                              alt=""
                            />
                          </div>
                          <div className={styles.itemDesc}>전손 보험사고</div>
                          <div className={styles.itemResult}>
                            {item.normalAccidentCountTotal === 0 ||
                            item.normalAccidentCountTotal === null
                              ? "없음"
                              : `${item.normalAccidentCountTotal}회`}
                          </div>
                        </div>
                        <div
                          className={
                            item.stolenAccidentCountTotal === 0 ||
                            item.stolenAccidentCountTotal === null
                              ? `${styles.item}`
                              : `${styles.item} ${styles.active}`
                          }
                        >
                          <div className={styles.itemTit}>
                            <img
                              className={styles.imgDefault}
                              src={accidentThumb2}
                              alt=""
                            />
                            <img
                              className={styles.imgActive}
                              src={accidentThumb2_active}
                              alt=""
                            />
                          </div>
                          <div className={styles.itemDesc}>도난 보험사고</div>
                          <div className={styles.itemResult}>
                            {item.stolenAccidentCountTotal === 0 ||
                            item.stolenAccidentCountTotal === null
                              ? "없음"
                              : `${item.stolenAccidentCountTotal}회`}
                          </div>
                        </div>
                        <div
                          className={
                            item.floodingAccidentCountTotal === 0 ||
                            item.floodingAccidentCountTotal === null
                              ? `${styles.item}`
                              : `${styles.item} ${styles.active}`
                          }
                        >
                          <div className={styles.itemTit}>
                            <img
                              className={styles.imgDefault}
                              src={accidentThumb3}
                              alt=""
                            />
                            <img
                              className={styles.imgActive}
                              src={accidentThumb3_active}
                              alt=""
                            />
                          </div>
                          <div className={styles.itemDesc}>침수 보험사고</div>
                          <div className={styles.itemResult}>
                            {item.floodingAccidentCountTotal === 0 ||
                            item.floodingAccidentCountTotal === null
                              ? "없음"
                              : `${item.floodingAccidentCountTotal}회`}
                          </div>
                        </div>
                        <div
                          className={
                            [
                              item.formalHistoryYn,
                              item.salesNormalHistoryYn,
                              item.salesRentHistoryYn,
                            ].filter((val) => val === "Y").length === 0
                              ? `${styles.item}`
                              : `${styles.item} ${styles.active}`
                          }
                        >
                          <div className={styles.itemTit}>
                            <img
                              className={styles.imgDefault}
                              src={accidentThumb4}
                              alt=""
                            />
                            <img
                              className={styles.imgActive}
                              src={accidentThumb4_active}
                              alt=""
                            />
                          </div>
                          <div className={styles.itemDesc}>특수 용도 이력</div>
                          <div className={styles.itemResult}>
                            {[
                              item.formalHistoryYn,
                              item.salesNormalHistoryYn,
                              item.salesRentHistoryYn,
                            ].filter((val) => val === "Y").length === 0
                              ? "없음"
                              : `${
                                  [
                                    item.formalHistoryYn,
                                    item.salesNormalHistoryYn,
                                    item.salesRentHistoryYn,
                                  ].filter((val) => val === "Y").length
                                }회`}
                          </div>
                        </div>
                        <div
                          className={
                            item.selfDamagedAccidentCount === 0 ||
                            item.selfDamagedAccidentCount === null
                              ? `${styles.item}`
                              : `${styles.item} ${styles.active}`
                          }
                        >
                          <div className={styles.itemTit}>
                            <img
                              className={styles.imgDefault}
                              src={accidentThumb5}
                              alt=""
                            />
                            <img
                              className={styles.imgActive}
                              src={accidentThumb5_active}
                              alt=""
                            />
                          </div>
                          <div className={styles.itemDesc}>내차 피해</div>
                          <div className={styles.itemResult}>
                            {item.selfDamagedAccidentCount === 0 ||
                            item.selfDamagedAccidentCount === null
                              ? "0회(0원)"
                              : `${
                                  item.selfDamagedAccidentCount
                                }회(${Common.commaFormat(
                                  item.selfDamagedAccidentInsuranceFeeTotal
                                )}원)`}
                          </div>
                        </div>
                        <div
                          className={
                            item.toDamagedAccidentCount === 0 ||
                            item.toDamagedAccidentCount === null
                              ? `${styles.item}`
                              : `${styles.item} ${styles.active}`
                          }
                        >
                          <div className={styles.itemTit}>
                            <img
                              className={styles.imgDefault}
                              src={accidentThumb6}
                              alt=""
                            />
                            <img
                              className={styles.imgActive}
                              src={accidentThumb6_active}
                              alt=""
                            />
                          </div>
                          <div className={styles.itemDesc}>상대차 피해</div>
                          <div className={styles.itemResult}>
                            {item.toDamagedAccidentCount === 0 ||
                            item.toDamagedAccidentCount === null
                              ? "0회(0원)"
                              : `${
                                  item.toDamagedAccidentCount
                                }회(${Common.commaFormat(
                                  item.toDamagedAccidentInsuranceFeeTotal
                                )}원)`}
                          </div>
                        </div>
                        <div
                          className={
                            item.carHistoryOwnerChanges?.length === 0
                              ? `${styles.item}`
                              : `${styles.item} ${styles.active}`
                          }
                        >
                          <div className={styles.itemTit}>
                            <img
                              className={styles.imgDefault}
                              src={accidentThumb7}
                              alt=""
                            />
                            <img
                              className={styles.imgActive}
                              src={accidentThumb7_active}
                              alt=""
                            />
                          </div>
                          <div className={styles.itemDesc}>소유자 변경</div>
                          <div className={styles.itemResult}>
                            {item.carHistoryOwnerChanges?.length === 0
                              ? "없음"
                              : `${item.carHistoryOwnerChanges?.length}회`}
                          </div>
                        </div>
                        <div
                          className={
                            item.carHistoryCarInfoChanges?.length === 0
                              ? `${styles.item}`
                              : `${styles.item} ${styles.active}`
                          }
                        >
                          <div className={styles.itemTit}>
                            <img
                              className={styles.imgDefault}
                              src={accidentThumb8}
                              alt=""
                            />
                            <img
                              className={styles.imgActive}
                              src={accidentThumb8_active}
                              alt=""
                            />
                          </div>
                          <div className={styles.itemDesc}>차량번호 변경</div>
                          <div className={styles.itemResult}>
                            {item.carHistoryCarInfoChanges?.length === 0
                              ? "없음"
                              : `${item.carHistoryCarInfoChanges?.length}회`}
                          </div>
                        </div>
                      </div>
                      <div className={styles.noteBox}>
                        <p>
                          자동차보험 사고기록이 없었다고 해서 반드시{" "}
                          <em>무사고</em>
                          라고 할 수는 없습니다.
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  className={styles.reportItem}
                  id="reportItem2"
                  ref={reportItem2}
                >
                  <div className={styles.itemHeader}>
                    <span className={styles.tit}>
                      <h4 className="h4 fwb">자동차 일반 사양 정보</h4>
                    </span>
                  </div>
                  <div className={styles.itemBody}>
                    <div className={styles.desc}>
                      자동차의 일반적인 사양 정보를 제공합니다.
                    </div>
                    <div className={styles.contents}>
                      <div className={styles.dataTable}>
                        <dl>
                          <dt>제조사</dt>
                          <dd>{item.carManufacturer}</dd>
                          <dt>연식</dt>
                          <dd>{item.carMadedYear}</dd>
                          <dt>자동차명</dt>
                          <dd>{item.carName}</dd>
                          <dt>차체형상</dt>
                          <dd>{item.carOutLine}</dd>
                          <dt>배기량</dt>
                          <dd>{Common.commaFormat(item.carDisplacement)}cc</dd>
                          <dt>용도 및 차종</dt>
                          <dd>
                            {item.carUsedBy === "1"
                              ? "관용 "
                              : item.carUsedBy === "2"
                              ? "자가용 "
                              : item.carUsedBy === "3"
                              ? "영업용 "
                              : item.carUsedBy === "4"
                              ? "개인택시 "
                              : null}

                            {item.carType === "1"
                              ? "승용"
                              : item.carType === "2"
                              ? "승합"
                              : item.carType === "3"
                              ? "화물"
                              : item.carType === "4"
                              ? "특수"
                              : null}
                          </dd>
                          <dt>사용연료</dt>
                          <dd>{item.fuelType}</dd>
                          <dt>최초 보험 가입일자</dt>
                          <dd>
                            {item.initInsuranceRegDate?.replace(
                              /(\d{4})(\d{2})(\d{2})/,
                              "$1-$2-$3"
                            )}
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  className={styles.reportItem}
                  id="reportItem3"
                  ref={reportItem3}
                >
                  <div className={styles.itemHeader}>
                    <span className={styles.tit}>
                      <h4 className="h4 fwb">
                        자동차 번호/소유자 변경 이력 정보
                      </h4>
                    </span>
                  </div>
                  <div className={styles.itemBody}>
                    <div className={styles.desc}>
                      소유자 변경이력 정보는{" "}
                      <em>
                        개인 간의 소유 변경 이외에도 매매상사 간
                        변경(상품용)까지 모두 포함된 횟수로 제공됩니다.
                      </em>{" "}
                      참고해주시기 바랍니다.
                    </div>
                    <div className={styles.contents}>
                      <table className={styles.table}>
                        <thead>
                          <tr>
                            <th>변경등록일</th>
                            <th>구분</th>
                            <th>차량번호</th>
                            <th>차량용도</th>
                          </tr>
                        </thead>
                        <tbody>
                          {item.changeInfos?.map((info, idx) => {
                            return (
                              <tr key={idx}>
                                <td>
                                  {info.changedAt?.replace(
                                    /(\d{4})(\d{2})(\d{2})/,
                                    "$1-$2-$3"
                                  )}
                                </td>
                                <td>
                                  {info.changeType === "01"
                                    ? "최초등록"
                                    : info.changeType === "02"
                                    ? "차량번호변경"
                                    : info.changeType === "04"
                                    ? "소유자변경"
                                    : "-"}
                                </td>
                                <td>
                                  {info.changeCarNumber === "" ||
                                  info.changeCarNumber === null
                                    ? "-"
                                    : info.changeCarNumber}
                                </td>
                                <td>
                                  {info.changeUsedBy === "1"
                                    ? "관용 "
                                    : info.changeUsedBy === "2"
                                    ? "자가용 "
                                    : info.changeUsedBy === "3"
                                    ? "영업용 "
                                    : info.changeUsedBy === "4"
                                    ? "개인택시 "
                                    : "-"}

                                  {info.changeCarType === "1"
                                    ? "승용"
                                    : info.changeCarType === "2"
                                    ? "승합"
                                    : info.changeCarType === "3"
                                    ? "화물"
                                    : info.changeCarType === "4"
                                    ? "특수"
                                    : null}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
                <section
                  className={styles.reportItem}
                  id="reportItem4"
                  ref={reportItem4}
                >
                  <div className={styles.itemHeader}>
                    <span className={styles.tit}>
                      <h4 className="h4 fwb">자동차 특수 사고 이력 정보</h4>
                    </span>
                  </div>
                  <div className={styles.itemBody}>
                    <div className={styles.desc}>
                      자동차보험에서 보험금이 지급된 자동차 사고기록 중
                      자동차품질에 특별히 영향을 미칠 가능성이 있는 사고
                      <em>(전손, 도난, 침수사고)</em>를 확인할 수 있습니다.
                    </div>
                    <div className={styles.contents}>
                      <div className={styles.specAccidents}>
                        <div
                          className={
                            item.carHistoryAccidents?.find(
                              (accident) =>
                                accident.accidentTypeSecond !== "38" &&
                                accident.accidentTypeSecond !== "33" &&
                                accident.accidentTypeSecond !== "" &&
                                accident.accidentTypeSecond !== null
                            )
                              ? `${styles.item} ${styles.active}`
                              : `${styles.item}`
                          }
                        >
                          <div className={styles.itemDesc}>전손 보험사고</div>
                          <div className={styles.itemResult}>
                            {item.carHistoryAccidents?.find(
                              (accident) =>
                                accident.accidentTypeSecond !== "38" &&
                                accident.accidentTypeSecond !== "33" &&
                                accident.accidentTypeSecond !== "" &&
                                accident.accidentTypeSecond !== null
                            )
                              ? "있음"
                              : "없음"}
                          </div>
                        </div>
                        <div
                          className={
                            item.carHistoryAccidents?.find(
                              (accident) =>
                                accident.accidentTypeSecond === "33" &&
                                accident.accidentTypeSecond === "" &&
                                accident.accidentTypeSecond === null
                            )
                              ? `${styles.item} ${styles.active}`
                              : `${styles.item}`
                          }
                        >
                          <div className={styles.itemDesc}>도난 보험사고</div>
                          <div className={styles.itemResult}>
                            {item.carHistoryAccidents?.find(
                              (accident) =>
                                accident.accidentTypeSecond === "33" &&
                                accident.accidentTypeSecond === "" &&
                                accident.accidentTypeSecond === null
                            )
                              ? "있음"
                              : "없음"}
                          </div>
                        </div>
                        <div
                          className={
                            item.carHistoryAccidents?.find(
                              (accident) =>
                                accident.accidentTypeSecond === "38" &&
                                accident.accidentTypeSecond === "" &&
                                accident.accidentTypeSecond === null
                            )
                              ? `${styles.item} ${styles.active}`
                              : `${styles.item}`
                          }
                        >
                          <div className={styles.itemDesc}>침수 보험사고</div>
                          <div className={styles.itemResult}>
                            {item.carHistoryAccidents?.find(
                              (accident) =>
                                accident.accidentTypeSecond === "38" &&
                                accident.accidentTypeSecond === "" &&
                                accident.accidentTypeSecond === null
                            )
                              ? "있음"
                              : "없음"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section
                  className={styles.reportItem}
                  id="reportItem5"
                  ref={reportItem5}
                >
                  <div className={styles.itemHeader}>
                    <span className={styles.tit}>
                      <h4 className="h4 fwb">보험사고 이력 상세 정보</h4>
                    </span>
                  </div>
                  <div className={styles.itemBody}>
                    <div className={styles.desc}></div>
                    <div className={styles.contents}>
                      <div className={styles.detailInfo}>
                        <div className={styles.tit}>
                          {item.carNumber?.substring(0, 3)}**** 차량이
                          자기차량손해담보에{" "}
                          <em>
                            가입하지 않은 동안에는 내 보험으로 처리한
                            사고이력정보의 제공이 불가능
                          </em>
                          합니다.
                        </div>
                        <div className={styles.noteBox}>
                          <p>미가입 기간 : {item.notInsuranceDuration}</p>
                        </div>
                        <p className={styles.mainTxt}>
                          보험금 및 수리(견적)비 출처에 따라서{" "}
                          <em>
                            '가입한 보험사에서 지금된 경우(내차 보험)'와 '다른
                            차량 보험에서 지급된 경우(상대보험)'로 나뉘어 제공
                          </em>
                          됩니다. 자동차사고로 상대 차량 또는 재물에 발생한{" "}
                          <em>
                            손해를 내 보험금에서 지급된 경우의 정보를 제공
                          </em>
                          합니다.
                        </p>
                        <p className={styles.subTxt}>
                          * 쌍방과실로 해당 자동차의 손상, 수리 기록이 내차
                          보험과 상대 보험에서 동시에 처리된 경우에는 '내차
                          보험' 에만 표시되고 '상대 보험'에서는 생략됩니다.
                        </p>

                        <div className={styles.tableWrapper}>
                          {item.carHistoryAccidents?.map((val) => {
                            return (
                              <table
                                className={`${styles.table} + ${styles.details}`}
                                key={val.id}
                              >
                                <caption>
                                  {val.accidentedAt?.replace(
                                    /(\d{4})(\d{2})(\d{2})/,
                                    "$1-$2-$3"
                                  )}
                                </caption>
                                <thead>
                                  <tr>
                                    <th colSpan="2">내 차 사고 발생 (피해)</th>
                                    <th>상대차 사고 발생 (피해)</th>
                                  </tr>
                                  <tr>
                                    <th>내 차 보험 (처리)</th>
                                    <th>상대 보험 (처리)</th>
                                    <th>내 차 보험 (처리)</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    {val.accidentType === "1" ? (
                                      <td>
                                        {val.fixFee !== 0 && (
                                          <p>
                                            <em>
                                              수리(견적)비용 :{" "}
                                              {Common.commaFormat(val.fixFee)}원
                                            </em>
                                          </p>
                                        )}
                                        {val.componentFee !== 0 && (
                                          <p>
                                            - 부품 :{" "}
                                            {Common.commaFormat(
                                              val.componentFee
                                            )}
                                            원
                                          </p>
                                        )}
                                        {val.wageFee !== 0 && (
                                          <p>
                                            - 공임 :{" "}
                                            {Common.commaFormat(val.wageFee)}원
                                          </p>
                                        )}
                                        {val.paintFee !== 0 && (
                                          <p>
                                            - 도장 :{" "}
                                            {Common.commaFormat(val.paintFee)}원
                                          </p>
                                        )}
                                        {/* {val.insuranceFee !== 0 && (
                                          <p>
                                            - 보험금 :{" "}
                                            {Common.commaFormat(
                                              val.insuranceFee
                                            )}
                                            원
                                          </p>
                                        )} */}
                                      </td>
                                    ) : (
                                      <td>-</td>
                                    )}
                                    {val.accidentType === "2" ? (
                                      <td>
                                        {val.fixFee !== 0 && (
                                          <p>
                                            <em>
                                              수리(견적)비용 :{" "}
                                              {Common.commaFormat(val.fixFee)}원
                                            </em>
                                          </p>
                                        )}
                                        {val.componentFee !== 0 && (
                                          <p>
                                            - 부품 :{" "}
                                            {Common.commaFormat(
                                              val.componentFee
                                            )}
                                            원
                                          </p>
                                        )}
                                        {val.wageFee !== 0 && (
                                          <p>
                                            - 공임 :{" "}
                                            {Common.commaFormat(val.wageFee)}원
                                          </p>
                                        )}
                                        {val.paintFee !== 0 && (
                                          <p>
                                            - 도장 :{" "}
                                            {Common.commaFormat(val.paintFee)}원
                                          </p>
                                        )}
                                        {/* {val.insuranceFee !== 0 && (
                                          <p>
                                            - 보험금 :{" "}
                                            {Common.commaFormat(
                                              val.insuranceFee
                                            )}
                                            원
                                          </p>
                                        )} */}
                                      </td>
                                    ) : (
                                      <td>-</td>
                                    )}
                                    {val.accidentType === "3" ? (
                                      <td>
                                        {val.fixFee !== 0 && (
                                          <p>
                                            <em>
                                              수리(견적)비용 :{" "}
                                              {Common.commaFormat(val.fixFee)}원
                                            </em>
                                          </p>
                                        )}
                                        {val.componentFee !== 0 && (
                                          <p>
                                            - 부품 :{" "}
                                            {Common.commaFormat(
                                              val.componentFee
                                            )}
                                            원
                                          </p>
                                        )}
                                        {val.wageFee !== 0 && (
                                          <p>
                                            - 공임 :{" "}
                                            {Common.commaFormat(val.wageFee)}원
                                          </p>
                                        )}
                                        {val.paintFee !== 0 && (
                                          <p>
                                            - 도장 :{" "}
                                            {Common.commaFormat(val.paintFee)}원
                                          </p>
                                        )}
                                        {/* {val.insuranceFee !== 0 && (
                                          <p>
                                            - 보험금 :{" "}
                                            {Common.commaFormat(
                                              val.insuranceFee
                                            )}
                                            원
                                          </p>
                                        )} */}
                                      </td>
                                    ) : (
                                      <td>-</td>
                                    )}
                                  </tr>
                                </tbody>
                              </table>
                            );
                          })}
                        </div>

                        <div className={styles.noteList}>
                          <p>
                            <em>
                              카히스토리 자료수집 방법상 일부 오류가 발생 할 수
                              있습니다.
                            </em>{" "}
                            의심되는 사항이 있으시면 전화주시기 바랍니다.
                          </p>
                          <p>
                            위 ‘수리(견적)비용’은 보험사가 지급하는 보험금
                            중에서 대차료, 휴차료 등 간접손해와 과실상계액 등을
                            제외한 수리 및 견적(부품/공임/도장) 비용으로{" "}
                            <em>실제 지급된 보험금과 차이가 있습니다.</em>
                          </p>
                          <p>
                            보험사고 이력은 <em>최근 10건의 사고만 표시</em>{" "}
                            됩니다.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className={styles.guide}>
                <div className={styles.aboutService}>
                  <div className={styles.item}>
                    <h3 className="h3 fwb">부가 이용정보</h3>
                    <div className={styles.noteList}>
                      <p>
                        본 중고차 사고이력정보는 정보조회 일자를 기준으로 작성된
                        것입니다.
                      </p>
                      <p>
                        본 정보는 자동차 일반정보로서 조회 차량을 확인하기
                        위하여 참고로 제공하는 것이며, 일부 차량의 경우, 정보의
                        누락이나 오류가 있을 수 있습니다.
                      </p>
                      <p>
                        침수사고에는 경미한 부분침수도 포함되며, 자료의 누락으로
                        ‘이력 없음’ 으로 표시되는 경우가 있습니다.
                      </p>
                      <p>
                        카히스토리 자료수집 방법상 오류가 발생할 수 있으니
                        의심되는 사항이 있으시면 전화 주시기 바랍니다.
                      </p>
                      <p>
                        수리비용은 보험사에서 지급되는 보험금 산정을 위하여
                        책정된 차량 수리 관련 항목만의 비용으로 실제 지급받은
                        보험금과 차이가 있을 수 있습니다.
                      </p>
                    </div>
                  </div>
                  <div className={styles.item}>
                    <h3 className="h3 fwb">서비스 이용 제한 안내</h3>
                    <div className={styles.noteList}>
                      <span className={styles.noteTit}>
                        중고차 사고이력정보 서비스는 자동차 보험을 취급하는 11개
                        손해보험사의 자동차 보험수리 지급기록(1996년 이후)에
                        근거하여 제공하고 있습니다. 따라서 다음과 같은 경우는
                        중고차 이력정보 서비스를 제공할 수 없습니다.
                      </span>
                      <p>
                        사고가 있었다 하더라도 보험회사에 사고신고를 하지 않고
                        자비로 처리한 경우
                        <span>
                          - 사고신고를 하였더라도 면책, 취소 등의 사유로
                          지급되지 않은 경우
                        </span>
                        <span>- 사고신고 후 자비로 처리한 경우</span>
                      </p>
                      <p>
                        자동차보험이 아닌 운수 공제(택시공제, 화물공제, 버스공제
                        등)에 가입되어 운수공제로 부터 자동차의 피해에 대한
                        손해를 보상받은 경우 등
                      </p>
                    </div>
                  </div>
                </div>
                <div className={styles.explainService}>
                  <p className={styles.tit}>
                    중고차<em>사고이력정보</em>서비스는?
                  </p>
                  <p className={styles.desc}>
                    중고차사고이력정보서비스는 중고차 거래의 활성화와 중고차
                    매매시장의 투명성을 높이기 위하여, <br />
                    보험개발원에서 보유하고 있거나 수집한 1996년 이후의 자동차
                    관련 정보를 기초로 제공되는 온라인 서비스입니다. <br />본
                    정보는 중고차품질확인을 위한 보조정보로서 자동차와 관련된
                    모든 사고의 발생 여부나 품질확인을 위한 <br />
                    결정적인 판단자료로 사용 되어서는 아니 됩니다. 따라서 본
                    정보의 확대해석이나 오·남용으로 발생하는 사항에 대해서{" "}
                    <br />
                    보험개발원은 어떤 책임도 부담하지 아니합니다.
                  </p>
                  <div className={styles.circles}>
                    <div className={styles.item}>
                      중고차 거래 <br />
                      활성화
                    </div>
                    <div className={styles.item}>
                      매매시장 <br />
                      투명성
                    </div>
                  </div>
                  <p className={styles.source}>
                    보험개발원(
                    <a href="https://www.kidi.or.kr" target="_blank">
                      www.kidi.or.kr
                    </a>
                    )은 보험입법 제176조에 의하여 설립된 보험요율산출기관이며,{" "}
                    <br />
                    중고차사고이력정보서비스(
                    <a href="https://www.carhistory.or.kr/" target="_blank">
                      www.carhistory.or.kr
                    </a>
                    )는 보험업법시행령 제86조 제1호 근거하여 제공합니다.
                  </p>
                  <div className={styles.footer}>
                    <span className={styles.date}>
                      {item.searchedAt?.replace(
                        /(\d{4})(\d{2})(\d{2})/,
                        "$1-$2-$3"
                      )}
                    </span>
                    <p className={styles.sourceName}>보험개발원</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />
    </>
  );
};

export default ModalInsuranceHistory;
