/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import styles from "./QuickMenu.module.scss";

import eyeIcon from "../assets/icons/eye-gr.svg";
import documentIcon from "../assets/icons/document-gr.svg";
import bookmarkIcon from "../assets/icons/bookmark-o-gr.svg";
import arrowIcon from "../assets/icons/depth-sm-gr.svg";

const QuickMenu = () => {
  const navigate = useNavigate();

  const [menuStatus, setMenuStatus] = useState(true);
  const handleQuickMenu = () => setMenuStatus((prev) => !prev);

  return (
    <>
      <div
        className={
          menuStatus
            ? `${styles.quickMenu} ${styles.active}`
            : `${styles.quickMenu}`
        }
      >
        <button className={styles.btnQuickToggle} onClick={handleQuickMenu}>
          <img src={arrowIcon} alt="" />
        </button>
        <ul className={styles.menuList}>
          <li className={styles.menuItem}>
            <Link to="/recentView">
              <img src={eyeIcon} alt="" />
              <span>최근 본 차량</span>
            </Link>
          </li>
          <li className={styles.menuItem}>
            <a
              onClick={() => {
                navigate("/mypage", {
                  state: {
                    tabIndex: 2,
                  },
                });
              }}
            >
              <img src={documentIcon} alt="" />
              <span>구매 요청</span>
            </a>
          </li>
          <li className={styles.menuItem}>
            <a
              onClick={() => {
                navigate("/mypage", {
                  state: {
                    tabIndex: 5,
                  },
                });
              }}
            >
              <img src={bookmarkIcon} alt="" />
              <span>관심 차량</span>
            </a>
          </li>
          <li className={styles.menuItem}>
            <div>
              <span>
                1660-6987
                <br />
                <em>문의하기</em>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default QuickMenu;
