/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ProgressBar from "../../components/ProgressBar.js";
import ModalRegion from "../../components/Modal_region.js";

import styles from "./Sales.module.scss";

import infoIcon from "../../assets/icons/interrogation-gr.svg";
import moreIcon from "../../assets/icons/depth-sm-gr.svg";
import doneIcon from "../../assets/icons/done-pr.svg";

const Sales = () => {
  // 판매 신청 탭 화면
  let navigate = useNavigate();
  const tabCount = 2;
  let [tabIndex, setTabIndex] = useState(0);
  let [btnState, setBtnState] = useState(false);
  const [completed, setCompleted] = useState(false);

  // 판매 신청 프로그레스 바
  let [progressValue, setProgressValue] = useState(100 / tabCount);

  const progressUpdateHandler = () => {
    if (btnState !== false) {
      setTabIndex((prev) => prev + 1);
      if (tabIndex + 1 === tabCount) {
        setCompleted(true);
        // navigate("/welcome");
      }
      setProgressValue((prev) => prev + 100 / tabCount);
      setBtnState(false);

      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  // 입력 데이터 세팅
  const [inputs, setInputs] = useState({
    distance: "", // 주행거리
    rental1: "",
    rental2: "",
    rental3: "",
    rental4: "",
    rental5: "",
    conditions: "",
    amountLiability: "",
    driverRange: "",
  });
  const {
    distance,
    rental1,
    rental2,
    rental3,
    rental4,
    rental5,
    conditions,
    amountLiability,
    driverRange,
  } = inputs;

  const updateInputData = (e) => {
    const { name, value } = e.target;

    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const resetInputData = () => {
    setInputs({
      distance: "",
      rental1: "",
      rental2: "",
      rental3: "",
      rental4: "",
      rental5: "",
      conditions: "",
      amountLiability: "",
      driverRange: "",
    });
  };

  // 탭 1 유효성 검사
  useEffect(() => {
    // TODO 현재 탭 인덱스 값 확인 후 return;
    if (tabIndex !== 0) {
      return;
    }
    // TODO
    if (distance !== "") {
      setBtnState(true);
    } else {
      setBtnState(false);
    }
    // TODO ID, PW input 별로 유효성 검사 처리
  }, [distance]);

  // 판매 정보 - 계약기간
  const [termChkedItems, setTermChkedItems] = useState([]);

  const termChkHandler = (e) => {
    let checked = e.currentTarget.checked;
    let value = e.target.value;

    if (checked) {
      setTermChkedItems([...termChkedItems, value]);
    } else if (!checked && termChkedItems.includes(value)) {
      setTermChkedItems(termChkedItems.filter((el) => el !== value));
    }
  };

  // 지역 선택 모달
  const [regionModalStatus, setRegionModalStatus] = useState(false);

  // 주요 계약 정보 - 계약 운전자 범위
  const [driverChkedItems, setDriverChkedItems] = useState([]);

  const driverChkHandler = (e) => {
    let checked = e.currentTarget.checked;
    let value = e.target.value;

    if (checked) {
      setDriverChkedItems([...driverChkedItems, value]);
    } else if (!checked && driverChkedItems.includes(value)) {
      setDriverChkedItems(driverChkedItems.filter((el) => el !== value));
    }
  };

  // 탭 2 유효성 검사
  useEffect(() => {
    // TODO 현재 탭 인덱스 값 확인 후 return;
    if (tabIndex !== 1) {
      return;
    }
    // TODO
    if (conditions !== "" && amountLiability !== "") {
      setBtnState(true);
    } else {
      setBtnState(false);
    }
    // TODO ID, PW input 별로 유효성 검사 처리
  }, [conditions, amountLiability]);

  return (
    <>
      {completed ? (
        <div className={`${styles.completeWork}` + " global-width"}>
          <div className={styles.logo}>
            <img src={doneIcon} alt="" />
          </div>
          <div className={styles.mainTxt}>판매 신청이 완료되었습니다.</div>
          <p className={styles.subTxt}>
            ㅇㅇㅇ서비스를 통해 판매가 진행될 예정입니다.
            <br />
            ㅇㅇㅇ 서비스에서 판매 신청내역을 확인해보세요.
          </p>

          <div className={`${styles.buttons}` + " button-form"}>
            <a href="" className="btn btn-xl btn--primary">
              구매 완료 목록으로
            </a>
          </div>
        </div>
      ) : (
        <div className={`${styles.sales}` + " form-width"}>
          <div className={styles.header}>
            <h1 className="heading-1">판매 신청</h1>
            <ProgressBar percent={progressValue} />
          </div>
          {/********** TAB 1 : 판매 정보 **********/}
          <div
            className={
              tabIndex === 0
                ? `${styles.content} ${styles.visible}`
                : `${styles.content}`
            }
          >
            <div className={styles.frmHeader}>
              <span className={`${styles.mainTxt}` + " heading-3"}>
                판매 정보
              </span>
              <button type="button" className={styles.btnNotice}>
                판매 수수료 <img src={infoIcon} alt="" />
              </button>
            </div>
            <div className={styles.frmCont}>
              {/* 담당자 연락처 */}
              <div className={styles.itemCont}>
                <div className={styles.itemHeader}>
                  <span className={styles.tit}>계약기간 (중복선택 가능)</span>
                </div>
                <div className={styles.itemBody}>
                  <div className={styles.chkList}>
                    <div className={`${styles.chkItem}` + " checkbox"}>
                      <label>
                        <input
                          type="checkbox"
                          value="term1"
                          onChange={termChkHandler}
                          defaultChecked={
                            termChkedItems.includes("term1") ? true : false
                          }
                        />
                        <div></div>
                        <span>12개월 미만</span>
                      </label>
                    </div>
                    <div className={`${styles.chkItem}` + " checkbox"}>
                      <label>
                        <input
                          type="checkbox"
                          value="term2"
                          onChange={termChkHandler}
                          defaultChecked={
                            termChkedItems.includes("term2") ? true : false
                          }
                        />
                        <div></div>
                        <span>12개월</span>
                      </label>
                    </div>
                    <div className={`${styles.chkItem}` + " checkbox"}>
                      <label>
                        <input
                          type="checkbox"
                          value="term3"
                          onChange={termChkHandler}
                          defaultChecked={
                            termChkedItems.includes("term3") ? true : false
                          }
                        />
                        <div></div>
                        <span>24개월</span>
                      </label>
                    </div>
                    <div className={`${styles.chkItem}` + " checkbox"}>
                      <label>
                        <input
                          type="checkbox"
                          value="term4"
                          onChange={termChkHandler}
                          defaultChecked={
                            termChkedItems.includes("term4") ? true : false
                          }
                        />
                        <div></div>
                        <span>36개월</span>
                      </label>
                    </div>
                    <div className={`${styles.chkItem}` + " checkbox"}>
                      <label>
                        <input
                          type="checkbox"
                          value="term5"
                          onChange={termChkHandler}
                          defaultChecked={
                            termChkedItems.includes("term5") ? true : false
                          }
                        />
                        <div></div>
                        <span>48개월</span>
                      </label>
                    </div>
                  </div>
                  <div className={styles.rentalFee}>
                    {termChkedItems.includes("term1") ? (
                      <div
                        className={`${styles.rentalFeeItem}` + " input-form"}
                      >
                        <p className="form-title">
                          <span>12개월 미만 대여료</span>
                        </p>
                        <div className="form-field">
                          <div className="input-element">
                            <input
                              type="text"
                              name="rental1"
                              className=""
                              placeholder="대여료 입력 (만원)"
                              onChange={updateInputData}
                              value={rental1}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {termChkedItems.includes("term2") ? (
                      <div
                        className={`${styles.rentalFeeItem}` + " input-form"}
                      >
                        <p className="form-title">
                          <span>12개월 대여료</span>
                        </p>
                        <div className="form-field">
                          <div className="input-element">
                            <input
                              type="text"
                              name="rental2"
                              className=""
                              placeholder="대여료 입력 (만원)"
                              onChange={updateInputData}
                              value={rental2}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {termChkedItems.includes("term3") ? (
                      <div
                        className={`${styles.rentalFeeItem}` + " input-form"}
                      >
                        <p className="form-title">
                          <span>24개월 대여료</span>
                        </p>
                        <div className="form-field">
                          <div className="input-element">
                            <input
                              type="text"
                              name="rental3"
                              className=""
                              placeholder="대여료 입력 (만원)"
                              onChange={updateInputData}
                              value={rental3}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {termChkedItems.includes("term4") ? (
                      <div
                        className={`${styles.rentalFeeItem}` + " input-form"}
                      >
                        <p className="form-title">
                          <span>36개월 대여료</span>
                        </p>
                        <div className="form-field">
                          <div className="input-element">
                            <input
                              type="text"
                              name="rental4"
                              className=""
                              placeholder="대여료 입력 (만원)"
                              onChange={updateInputData}
                              value={rental4}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {termChkedItems.includes("term5") ? (
                      <div
                        className={`${styles.rentalFeeItem}` + " input-form"}
                      >
                        <p className="form-title">
                          <span>48개월 대여료</span>
                        </p>
                        <div className="form-field">
                          <div className="input-element">
                            <input
                              type="text"
                              name="rental5"
                              className=""
                              placeholder="대여료 입력 (만원)"
                              onChange={updateInputData}
                              value={rental5}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              {/* 약정 주행거리 */}
              <div className={styles.itemCont}>
                <div className={styles.itemHeader}>
                  <span className={styles.tit}>약정 주행거리</span>
                </div>
                <div className={styles.itemBody}>
                  <div className={styles.radioList}>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input
                        type="radio"
                        id="radio1"
                        name="distanceUnit"
                        defaultChecked
                      />
                      <label htmlFor="radio1">월단위</label>
                    </div>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input type="radio" id="radio2" name="distanceUnit" />
                      <label htmlFor="radio2">연단위</label>
                    </div>
                  </div>
                  <div className="input-form">
                    <p className="form-title">
                      <span></span>
                    </p>
                    <div className="form-field">
                      <div className="input-element">
                        <input
                          type="text"
                          name="distance"
                          className=""
                          placeholder="주행거리 입력 (km)"
                          onChange={updateInputData}
                          value={distance}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* 계약 가능 지역 */}
              <div className={styles.itemCont}>
                <div className={styles.itemHeader}>
                  <span className={styles.tit}>계약 가능 지역</span>
                </div>
                <div className={styles.itemBody}>
                  <div className={styles.linkedModal}>
                    <button
                      type="button"
                      className={styles.btnCoupon}
                      onClick={() => setRegionModalStatus(true)}
                    >
                      <span>계약 가능 지역 선택</span>
                      <img src={moreIcon} alt="" />
                    </button>
                  </div>
                </div>
              </div>
              {/* 위약율 */}
              <div className={styles.itemCont}>
                <div className={styles.itemHeader}>
                  <span className={styles.tit}>위약율</span>
                </div>
                <div className={styles.itemBody}>
                  <div className={styles.radioList}>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input
                        type="radio"
                        id="radio3"
                        name="placebo"
                        defaultChecked
                      />
                      <label htmlFor="radio3">20%</label>
                    </div>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input type="radio" id="radio4" name="placebo" />
                      <label htmlFor="radio4">30%</label>
                    </div>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input type="radio" id="radio5" name="placebo" />
                      <label htmlFor="radio5">40%</label>
                    </div>
                  </div>
                </div>
              </div>
              {/* 결제 방식 */}
              <div className={styles.itemCont}>
                <div className={styles.itemHeader}>
                  <span className={styles.tit}>결제 방식</span>
                </div>
                <div className={styles.itemBody}>
                  <div className={styles.radioList}>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input
                        type="radio"
                        id="radio6"
                        name="paymentMethod"
                        defaultChecked
                      />
                      <label htmlFor="radio6">선불</label>
                    </div>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input type="radio" id="radio7" name="paymentMethod" />
                      <label htmlFor="radio7">후불</label>
                    </div>
                  </div>
                </div>
              </div>
              {/* 결제 수단 */}
              <div className={styles.itemCont}>
                <div className={styles.itemHeader}>
                  <span className={styles.tit}>결제 수단</span>
                </div>
                <div className={styles.itemBody}>
                  <div className={styles.radioList}>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input
                        type="radio"
                        id="radio8"
                        name="paymentWay"
                        defaultChecked
                      />
                      <label htmlFor="radio8">계좌이체</label>
                    </div>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input type="radio" id="radio9" name="paymentWay" />
                      <label htmlFor="radio9">신용카드</label>
                    </div>
                  </div>
                </div>
              </div>
              {/* 만기인수 */}
              <div className={styles.itemCont}>
                <div className={styles.itemHeader}>
                  <span className={styles.tit}>만기인수</span>
                </div>
                <div className={styles.itemBody}>
                  <div className={styles.radioList}>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input
                        type="radio"
                        id="radio10"
                        name="expireTakeover"
                        defaultChecked
                      />
                      <label htmlFor="radio10">가능</label>
                    </div>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input type="radio" id="radio11" name="expireTakeover" />
                      <label htmlFor="radio11">불가</label>
                    </div>
                  </div>
                </div>
              </div>
              {/* 세금계산서(현금영수중) 발행 가능 여부 */}
              <div className={styles.itemCont}>
                <div className={styles.itemHeader}>
                  <span className={styles.tit}>
                    세금계산서(현금영수중) 발행 가능 여부
                  </span>
                </div>
                <div className={styles.itemBody}>
                  <div className={styles.radioList}>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input
                        type="radio"
                        id="radio12"
                        name="taxBill"
                        defaultChecked
                      />
                      <label htmlFor="radio12">발행불가</label>
                    </div>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input type="radio" id="radio13" name="taxBill" />
                      <label htmlFor="radio13">발행가능</label>
                    </div>
                  </div>
                </div>
              </div>
              {/* 탁송비용 */}
              <div className={styles.itemCont}>
                <div className={styles.itemHeader}>
                  <span className={styles.tit}>탁송비용</span>
                </div>
                <div className={styles.itemBody}>
                  <div className={styles.radioList}>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input
                        type="radio"
                        id="radio14"
                        name="consignmentFee"
                        defaultChecked
                      />
                      <label htmlFor="radio14">전지역무료</label>
                    </div>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input type="radio" id="radio15" name="consignmentFee" />
                      <label htmlFor="radio15">일부지역무료</label>
                    </div>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input type="radio" id="radio16" name="consignmentFee" />
                      <label htmlFor="radio16">각각 50%</label>
                    </div>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input type="radio" id="radio17" name="consignmentFee" />
                      <label htmlFor="radio17">고객부담</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/********** TAB 2 : 아이디&비밀번호 입력 **********/}
          <div
            className={
              tabIndex === 1
                ? `${styles.content} ${styles.visible}`
                : `${styles.content}`
            }
          >
            <div className={styles.frmHeader}>
              <span className={`${styles.mainTxt}` + " heading-3"}>
                주요 계약 정보
              </span>
            </div>
            <div className={styles.frmCont}>
              {/* 운전자 나이 */}
              <div className={styles.itemCont}>
                <div className={styles.itemHeader}>
                  <span className={styles.tit}>운전자 나이</span>
                </div>
                <div className={styles.itemBody}>
                  <div className={styles.radioList}>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input
                        type="radio"
                        id="radio18"
                        name="driverAge"
                        defaultChecked
                      />
                      <label htmlFor="radio18">21세 이상</label>
                    </div>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input type="radio" id="radio19" name="driverAge" />
                      <label htmlFor="radio19">26세 이상</label>
                    </div>
                  </div>
                </div>
              </div>
              {/* 면허취득일 */}
              <div className={styles.itemCont}>
                <div className={styles.itemHeader}>
                  <span className={styles.tit}>면허취득일</span>
                </div>
                <div className={styles.itemBody}>
                  <div className={styles.radioList}>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input
                        type="radio"
                        id="radio20"
                        name="licenseDate"
                        defaultChecked
                      />
                      <label htmlFor="radio20">취득일무관</label>
                    </div>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input type="radio" id="radio21" name="licenseDate" />
                      <label htmlFor="radio21">1년이상가능</label>
                    </div>
                  </div>
                </div>
              </div>
              {/* 신용심사 여부 */}
              <div className={styles.itemCont}>
                <div className={styles.itemHeader}>
                  <span className={styles.tit}>신용심사 여부</span>
                </div>
                <div className={styles.itemBody}>
                  <div className={styles.radioList}>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input
                        type="radio"
                        id="radio22"
                        name="creditReview"
                        defaultChecked
                      />
                      <label htmlFor="radio22">신용무관</label>
                    </div>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input type="radio" id="radio23" name="creditReview" />
                      <label htmlFor="radio23">신용심사</label>
                    </div>
                  </div>
                </div>
              </div>
              {/* 신용 심사 시 조건 */}
              <div className={styles.itemCont}>
                <div className={styles.itemHeader}>
                  <span className={styles.tit}>신용 심사 시 조건</span>
                </div>
                <div className={styles.itemBody}>
                  <div className="input-form">
                    <p className="form-title">
                      <span></span>
                    </p>
                    <div className="form-field">
                      <div className="input-element">
                        <input
                          type="text"
                          name="conditions"
                          className=""
                          placeholder="필요 정보 입력"
                          onChange={updateInputData}
                          value={conditions}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* 책임 한도 */}
              <div className={styles.itemCont}>
                <div className={styles.itemHeader}>
                  <span className={styles.tit}>책임 한도</span>
                </div>
                <div className={styles.itemBody}>
                  <div className={styles.radioList}>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input
                        type="radio"
                        id="radio24"
                        name="liabilityLimit"
                        defaultChecked
                      />
                      <label htmlFor="radio24">대인</label>
                    </div>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input type="radio" id="radio25" name="liabilityLimit" />
                      <label htmlFor="radio25">대물</label>
                    </div>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input type="radio" id="radio26" name="liabilityLimit" />
                      <label htmlFor="radio26">자손</label>
                    </div>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input type="radio" id="radio27" name="liabilityLimit" />
                      <label htmlFor="radio27">무보험상해</label>
                    </div>
                  </div>
                  <div className="input-form">
                    <p className="form-title">
                      <span></span>
                    </p>
                    <div className="form-field">
                      <div className="input-element">
                        <input
                          type="text"
                          name="amountLiability"
                          className=""
                          placeholder="해당 책임한도 금액 입력(만원)"
                          onChange={updateInputData}
                          value={amountLiability}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* 자치보험금 추가 금액 */}
              <div className={styles.itemCont}>
                <div className={styles.itemHeader}>
                  <span className={styles.tit}>자치보험금 추가 금액</span>
                </div>
                <div className={styles.itemBody}>
                  <div className={styles.radioList}>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input
                        type="radio"
                        id="radio28"
                        name="amountSi"
                        defaultChecked
                      />
                      <label htmlFor="radio28">0원</label>
                    </div>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input type="radio" id="radio29" name="amountSi" />
                      <label htmlFor="radio29">3만원</label>
                    </div>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input type="radio" id="radio30" name="amountSi" />
                      <label htmlFor="radio30">5만원</label>
                    </div>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input type="radio" id="radio31" name="amountSi" />
                      <label htmlFor="radio31">7만원</label>
                    </div>
                  </div>
                </div>
              </div>
              {/* 정비상품 */}
              <div className={styles.itemCont}>
                <div className={styles.itemHeader}>
                  <span className={styles.tit}>정비상품</span>
                </div>
                <div className={styles.itemBody}>
                  <div className={styles.radioList}>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input
                        type="radio"
                        id="radio32"
                        name="maintenance"
                        defaultChecked
                      />
                      <label htmlFor="radio32">포함</label>
                    </div>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input type="radio" id="radio33" name="maintenance" />
                      <label htmlFor="radio33">불포함</label>
                    </div>
                    <div className={`${styles.radioItem}` + " radiobox"}>
                      <input type="radio" id="radio34" name="maintenance" />
                      <label htmlFor="radio34">케어서비스 신청</label>
                    </div>
                  </div>
                </div>
              </div>
              {/* 계약 운전자 범위 */}
              <div className={styles.itemCont}>
                <div className={styles.itemHeader}>
                  <span className={styles.tit}>계약 운전자 범위</span>
                </div>
                <div className={styles.itemBody}>
                  <div className={styles.chkList}>
                    <div className={`${styles.chkItem}` + " checkbox"}>
                      <label>
                        <input
                          type="checkbox"
                          value="term6"
                          onChange={driverChkHandler}
                          defaultChecked={
                            driverChkedItems.includes("term6") ? true : false
                          }
                        />
                        <div></div>
                        <span>개인</span>
                      </label>
                    </div>
                    <div className={`${styles.chkItem}` + " checkbox"}>
                      <label>
                        <input
                          type="checkbox"
                          value="term7"
                          onChange={driverChkHandler}
                          defaultChecked={
                            driverChkedItems.includes("term7") ? true : false
                          }
                        />
                        <div></div>
                        <span>법인</span>
                      </label>
                    </div>
                    <div className={`${styles.chkItem}` + " checkbox"}>
                      <label>
                        <input
                          type="checkbox"
                          value="term8"
                          onChange={driverChkHandler}
                          defaultChecked={
                            driverChkedItems.includes("term8") ? true : false
                          }
                        />
                        <div></div>
                        <span>제2운전자</span>
                      </label>
                    </div>
                  </div>
                  <div className={styles.driverRange}>
                    {driverChkedItems.includes("term8") ? (
                      <div
                        className={`${styles.rentalFeeItem}` + " input-form"}
                      >
                        <p className="form-title">
                          <span></span>
                        </p>
                        <div className="form-field">
                          <div className="input-element">
                            <input
                              type="text"
                              name="driverRange"
                              className=""
                              placeholder="제2운전자 추가금액 (만원)"
                              onChange={updateInputData}
                              value={driverRange}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* 탭 하단 이동 버튼 */}
          <div className={`${styles.buttons}` + " button-form"}>
            <button
              type="button"
              className={
                btnState
                  ? "btn btn-xl btn--primary"
                  : "btn btn-xl btn--disabled"
              }
              onClick={() => progressUpdateHandler()}
              disabled={!btnState}
            >
              {tabIndex + 1 === tabCount ? "완료" : "다음"}
            </button>
          </div>
        </div>
      )}

      {/* (새창) 쿠폰선택 모달 */}
      {regionModalStatus ? (
        <ModalRegion deleter={setRegionModalStatus} />
      ) : null}
    </>
  );
};

export default Sales;
