/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import * as Common from "../actions/Common.js";
import styles from "./Modal_coupon.module.scss";

// API URL
import { baseUrl } from "../api";
const BASE_URL = baseUrl();
const COUPON_URL = "/api/user/coupon/v1/me";

const Modal_Coupon = ({ deleter, selectedCoupon, setSelectedCoupon }) => {
  /****************************************
   * TODO 초기 세팅
   ****************************************/
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (selectedCoupon !== undefined) {
      setCouponChkedItems([selectedCoupon.id]);
      setSelectedItem(selectedCoupon);
    }
  }, [selectedCoupon]);

  // 보유 쿠폰 목록 Checkbox
  const [couponItems, setCouponItems] = useState([]);
  const [couponChkedItems, setCouponChkedItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState();

  const couponChkHandler = (e, coupon) => {
    let checked = e.currentTarget.checked;
    let value = e.target.value;

    if (checked) {
      setCouponChkedItems(value);
      setSelectedItem(coupon);
    } else if (!checked) {
      setCouponChkedItems([]);
      setSelectedItem();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);

    try {
      fetchCoupon();

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  /****************************************
   * TODO 항목별 API 목록
   ****************************************/
  // 쿠폰 데이터 가져오기
  const fetchCoupon = async () => {
    try {
      const response = await axios.get(BASE_URL + COUPON_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setCouponItems(response.data.data);
    } catch (err) {
      handleToast(err.response?.data.message);
      return false;
    }
  };

  return (
    <>
      <div className={`${styles.modal} ${styles.modalCoupon}`}>
        <div className={`${styles.wrapper} ${styles.dim}`}>
          <div className={styles.container}>
            <button
              className={styles.btn_close}
              onClick={() => deleter(false)}
            ></button>
            <div className={styles.title}>
              <span>쿠폰 선택</span>
            </div>
            <div className={styles.body}>
              <div className={styles.itemList}>
                {couponItems.map((coupon) => {
                  return (
                    <div className={styles.item} key={coupon.id}>
                      <div className={styles.itemChk}>
                        <div className={`${styles.chkItem}` + " checkbox"}>
                          <label>
                            <input
                              type="checkbox"
                              value={coupon.id}
                              onChange={(e) => couponChkHandler(e, coupon)}
                              checked={
                                couponChkedItems.includes(coupon.id)
                                  ? true
                                  : false
                              }
                            />
                            <div></div>
                            <span></span>
                          </label>
                        </div>
                      </div>
                      <div className={styles.itemDetail}>
                        <p className={styles.itemDesc}>
                          {Common.commaFormat(coupon.coupon.price)}원 할인
                        </p>
                        <p className={styles.itemTit}>{coupon.name}</p>
                        <p className={styles.itemDate}>
                          {coupon.coupon.startedAt
                            .split("T")[0]
                            .replaceAll("-", ".")}{" "}
                          ~
                          {coupon.coupon.endedAt
                            .split("T")[0]
                            .replaceAll("-", ".")}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={styles.footer}>
              <div className="button-form">
                <button
                  type="button"
                  className={
                    selectedItem === undefined
                      ? "btn btn-xl btn--disabled"
                      : "btn btn-xl btn--primary"
                  }
                  onClick={() => {
                    if (selectedItem !== undefined) {
                      setSelectedCoupon(selectedItem);
                      deleter(false);
                    }
                  }}
                >
                  적용하기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal_Coupon;
