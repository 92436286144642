/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import * as Common from "../../actions/Common.js";
import Toast from "../../components/Toast.js";
import ModalConfirm from "../../components/ModalConfirm.js";
import styles from "./OrderPurchasing.module.scss";

import corpLogoImage from "../../assets/images/autoc_logo.png";
import emptyIcon from "../../assets/icons/empty.svg";

import infoIcon from "../../assets/icons/info.svg";
import depthIcon from "../../assets/icons/depth-sm-gr.svg";

import { baseUrl } from "../../api";
const BASE_URL = baseUrl();
const CANCEL_URL = "/api/user/deal/v1/cancel";
const PROCESS_URL = "/api/user/deal/v1/on_process";

const OrderPurchasing = () => {
  const navigate = useNavigate();

  //* API loading state
  const [loading, setLoading] = useState(true);

  /****************************************
   * TODO 초기 세팅
   ****************************************/
  //* Init API Data
  const [items, setItems] = useState([]); // 찜한 차량

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    //* 연관 상품 세팅
    fetchMyProduct();
  };

  /****************************************
   * TODO 항목별 API 목록
   ****************************************/
  // 내 계정 연관 상품 가져오기
  const fetchMyProduct = async () => {
    setLoading(true);

    try {
      const response = await axios.get(BASE_URL + PROCESS_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setItems(response.data.data);
      setLoading(false);
    } catch (err) {
      if (err.response?.status === 401) {
        setModal({
          open: true,
          dim: true,
          title: "세션이 만료되었습니다.",
          message: "로그인 화면으로 이동합니다.",
          notxt: "",
          oktxt: "확인",
          callback2: () => {
            navigate("/logout");
          },
        });
        return;
      } else {
        handleToast(err.response?.data.message);
        setLoading(false);
      }
    }
  };

  // 찜하기 설정 / 찜하기 해제
  const fetchRemove = async (id) => {
    try {
      //? favorite 해제
      const response = await axios.delete(BASE_URL + FAVORITE_URL, {
        data: id,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      fetchFavoriteData();
    } catch (err) {
      handleToast(err.response?.data.message);
    }
  };

  // 구매 요청 취소
  const fetchCancelRequest = async (ids) => {
    try {
      const response = await axios.post(
        BASE_URL + CANCEL_URL,
        { carIds: ids },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      handleToast(response.data.message);
    } catch (err) {
      handleToast(err.response?.data.message);
    }
  };

  /****************************************
   * TODO 이벤트 핸들러
   ****************************************/
  //* 찜한차량 item remove 클릭
  const handleRemoveItem = async (id) => {
    setModal({
      open: true,
      dim: false,
      title: "선택한 상품을 삭제하시겠습니까?",
      message: "",
      notxt: "취소",
      oktxt: "확인",
      callback2: () => {
        fetchRemove([id]);
      },
    });
  };

  const handleRemoveGroup = async (id) => {
    if (checkItems.length === 0) {
      setModal({
        open: true,
        dim: false,
        title: "선택하신 제품이 없습니다.",
        message: "",
        notxt: "",
        oktxt: "확인",
        callback2: () => {
          null;
        },
      });
      return;
    }

    const ids = checkItems.join(",");
    setModal({
      open: true,
      dim: false,
      title: "선택한 상품들을 삭제하시겠습니까?",
      message: "",
      notxt: "취소",
      oktxt: "확인",
      callback2: () => {
        fetchRemove(checkItems);
      },
    });
  };

  // 부가서비스 선택
  const handleAddService = (productData) => {
    navigate("/service", { state: { data: [productData] } });
  };
  // 구매진행 선택
  const handlePurchasing = () => {
    if (checkItemDatas.length === 0) {
      // 선택된 제품이 없을 경우
      setModal({
        open: true,
        dim: false,
        title: "선택하신 제품이 없습니다.",
        message: "",
        notxt: "",
        oktxt: "확인",
        callback2: () => {
          null;
        },
      });
      return;
    }

    // 선택된 차량들 중 하나라도 부가서비스 선택이 안되어있는 경우 모달 노출
    // some() : 하나라도 특정 조건에 부합하면 true 반환
    // every() : 모든 요소가 조건에 부합하면 true 반환
    // const result = checkItemDatas.some((item) => {
    //   return item.addServices.length === 0;
    // });
    const result = checkItemDatas.map((item) => {
      return item.addServices.length === 0;
    });
    // 부가서비스 선택된 상품 개수 파악
    const selectedServiceDealCount = result.filter((value) => {
      return value === false;
    });

    if (checkItemDatas.length === 1 && selectedServiceDealCount.length === 0) {
      setModal({
        open: true,
        dim: false,
        title: "부가서비스를 선택하지 않으셨습니다.",
        message: "구매를 진행하시겠습니까?",
        notxt: "취소",
        oktxt: "확인",
        callback1: () => {
          null;
        },
        callback2: () => {
          navigate("/checkout", { state: { data: checkItemDatas } });
        },
      });
    } else if (
      checkItemDatas.length > 1 &&
      selectedServiceDealCount.length !== checkItemDatas.length
    ) {
      setModal({
        open: true,
        dim: false,
        title: `${
          checkItemDatas.length - selectedServiceDealCount.length
        }개 차량의 부가서비스를 선택하지 않으셨습니다.`,
        message: "구매를 진행하시겠습니까?",
        notxt: "취소",
        oktxt: "확인",
        callback1: () => {
          null;
        },
        callback2: () => {
          navigate("/checkout", { state: { data: checkItemDatas } });
        },
      });
    } else {
      navigate("/checkout", { state: { data: checkItemDatas } });
    }
  };

  // 구매요청 취소
  const onCancelRequest = (ids) => {
    setModal({
      open: true,
      dim: false,
      title: "관리자에게 문의해주세요.",
      message: "",
      notxt: "",
      oktxt: "확인",
      service: true,
      callback2: () => {
        null;
      },
    });
    return;

    if (checkItems.length === 0) {
      setModal({
        open: true,
        dim: false,
        title: "선택하신 제품이 없습니다.",
        message: "",
        notxt: "",
        oktxt: "확인",
        callback2: () => {
          null;
        },
      });
      return;
    }

    setModal({
      open: true,
      dim: true,
      title: "구매 요청을 취소하시겠습니까?",
      message: "",
      notxt: "취소",
      oktxt: "확인",
      callback2: async () => {
        await fetchCancelRequest(ids);
        fetchMyProduct();
      },
    });
  };

  /****************************************
   * TODO 공용 함수
   ****************************************/
  // 선택된 상품 중 "구매불가" 항목이 있는지 체크
  const isDisabledItems = () => {
    const difference = items.filter((value) => checkItems.includes(value.id));
    const result = difference.filter((value) => {
      return value.productStatus !== "EXPOSED";
    });

    // 구매불가 상품이 있는 경우 return false
    if (result.length > 0) {
      return false;
    }
    return true;
  };

  //* 선택된 체크박스 state
  const [checkItems, setCheckItems] = useState([]);
  const [checkItemDatas, setCheckItemDatas] = useState([]);

  // 개별 선택
  function checkHandler(checked, item) {
    //* 개별 Checkbox state 처리
    if (checked) {
      setCheckItems([...checkItems, item.id]);
      setCheckItemDatas([...checkItemDatas, item]);
    } else {
      setCheckItems(checkItems.filter((o) => o !== item.id));
      setCheckItemDatas(checkItemDatas.filter((o) => o.id !== item.id));
    }
  }
  // 전체 선택
  function checkAllHandler(checked) {
    if (checked) {
      const ids = [];
      items.forEach((v) => ids.push(v.id));
      setCheckItems(ids); // state 채우기
      setCheckItemDatas(items);
    } else {
      setCheckItems([]); // state 비우기
      setCheckItemDatas([]);
    }
  }

  /****************************************
   * TODO 모달 & 토스트 팝업 상태
   ****************************************/
  //* 모달
  const [modal, setModal] = useState({
    open: false,
    dim: true,
    title: "",
    message: "",
    notxt: "",
    oktxt: "확인",
    callback1: false,
    callback2: false,
  });

  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = (txt) => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalComission, setTotalComission] = useState(0);
  const [totalAddServices, setTotalAddServices] = useState(0);

  useEffect(() => {
    // 선택된 제품 차량 가격 금액 계산
    let total = 0;

    items.map((item) => {
      checkItems.filter(
        (el) => el === item.id && (total += item.car.carPrice.price)
      );
    });

    setTotalPrice(total);

    // 선택된 제품 수수료 금액 계산
    let total2 = 0;

    items.map((item) => {
      checkItems.filter(
        (el) => el === item.id && (total2 += item.car.carPrice.comission)
      );
    });

    setTotalComission(total2);

    // 선택된 제품 부가서비스 금액 계산
    const sum = checkItemDatas.map((item) => {
      return item.addServices.reduce((sum, currValue) => {
        return sum + currValue.price;
      }, 0);
    });

    const total3 = sum.reduce((sum, currValue) => {
      return sum + currValue;
    }, 0);

    setTotalAddServices(total3);
  }, [checkItems, checkItemDatas]);

  return (
    <>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <div className={`${styles.orderPurchasing}` + " global-width"}>
          <div className={styles.header}>
            <h1>구매 진행</h1>
            {/* <button type="button" className={styles.btnGuide}>
              <img className={styles.infoImg} src={infoIcon} alt="" />
              이용 및 할인 안내
              <img className={styles.moreImg} src={depthIcon} alt="" />
            </button> */}
          </div>
          <div className={styles.contents}>
            {items.length == 0 ? (
              <div className={styles.nonItems}>
                <div className={styles.icon}>
                  <img src={emptyIcon} alt="" />
                </div>
                <p>구매 진행 차량이 없습니다.</p>
              </div>
            ) : (
              <div className={styles.itemWrapper}>
                <div className={styles.contHeader}>
                  <div className={styles.item}>
                    <div className={styles.tdChk}>
                      <div className="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            onChange={(e) => checkAllHandler(e.target.checked)}
                            checked={checkItems.length === items.length}
                          />
                          <div></div>
                          <span>전체선택</span>
                        </label>
                      </div>
                    </div>
                    <div className={styles.tdContent}>
                      <span className={styles.tdTit}>차량 정보</span>
                    </div>
                    <div className={styles.tdService}>
                      <span className={styles.tdTit}>부가서비스</span>
                    </div>
                    <div className={styles.tdPrice}>
                      <span className={styles.tdTit}>구매 가격</span>
                    </div>
                    <div className={styles.tdStatus}>
                      <span className={styles.tdTit}>구매 상태</span>
                    </div>
                  </div>
                </div>
                <div className={styles.contList}>
                  {items.map((item) => {
                    return (
                      <div className={styles.itemCont} key={item.id}>
                        <div className={styles.itemList}>
                          <div
                            className={
                              item.car.productStatus === "EXPOSED"
                                ? `${styles.item}`
                                : `${styles.item}`
                            }
                            key={item.car.id}
                          >
                            <div className={styles.chk}>
                              <div className="checkbox">
                                <label>
                                  <input
                                    type="checkbox"
                                    onChange={(e) =>
                                      checkHandler(e.target.checked, item)
                                    }
                                    checked={
                                      checkItems.indexOf(item.id) >= 0
                                        ? true
                                        : false
                                    }
                                    data-id={item.car.id}
                                  />
                                  <div></div>
                                  <span></span>
                                </label>
                              </div>
                            </div>
                            <div className={styles.tdContent}>
                              <div className={styles.img}>
                                <Link
                                  to={`/product/${item.car.id}`}
                                  className={styles.img}
                                >
                                  <img src={item.car.mainImgUrl} alt="" />
                                </Link>
                              </div>
                              <div className={styles.desc}>
                                <div className={styles.tags}>
                                  {item.car.newYn === "Y" ? (
                                    <span
                                      className={`${styles.tag} ${styles.new}`}
                                    >
                                      New
                                    </span>
                                  ) : null}
                                  {item.car.productYn === "Y" ? (
                                    <span
                                      className={`${styles.tag} ${styles.commercialized}`}
                                    >
                                      상품화
                                    </span>
                                  ) : null}
                                  {item.car.productStatus === "BUY_ING" ? (
                                    <span
                                      className={`${styles.tag} ${styles.purchasing}`}
                                    >
                                      구매 진행 중
                                    </span>
                                  ) : null}
                                </div>
                                <div className={styles.title}>
                                  <span>
                                    <Link to={`/product/${item.car.id}`}>
                                      {item.car.manufacturerName +
                                        " " +
                                        item.car.modelName +
                                        " "}
                                      ({item.car.carNumber})
                                    </Link>
                                  </span>
                                </div>
                                <div className={styles.detail}>
                                  <span>
                                    {String(item.car.carMadedYear).slice(2) +
                                      "년" +
                                      String(item.car.carMadedMonth) +
                                      "월 (" +
                                      String(item.car.carMadedYear).slice(2) +
                                      "년형)"}
                                  </span>
                                  <span>
                                    {Common.commaFormat(item.car.mileage)}km
                                  </span>
                                  <span>
                                    {item.car.fuelType === "GAS"
                                      ? "가솔린"
                                      : item.car.fuelType === "DIESEL"
                                      ? "디젤"
                                      : item.car.fuelType === "LPG"
                                      ? "LPG"
                                      : item.car.fuelType === "GAS_LPG"
                                      ? "가솔린(LPG겸용)"
                                      : item.car.fuelType === "GAS_CNG"
                                      ? "가솔린(CNG겸용)"
                                      : item.car.fuelType === "GAS_HYBRID"
                                      ? "HEV(하이브리드)"
                                      : item.car.fuelType === "ELECTRIC"
                                      ? "전기"
                                      : item.car.fuelType === "HYDROGEN"
                                      ? "수소"
                                      : null}
                                  </span>
                                  <span>
                                    {item.car.accident === "NONE"
                                      ? "무사고"
                                      : item.car.accident === "SIMPLE_CHANGE"
                                      ? "단순교환"
                                      : item.car.accident === "SIMPLE_TOUCH"
                                      ? "단순사고(접촉)"
                                      : item.car.accident === "NORMAL"
                                      ? "사고"
                                      : item.car.accident === "TOTAL_LOSS"
                                      ? "전손"
                                      : item.car.accident === "FLOODING"
                                      ? "침수"
                                      : null}
                                  </span>
                                </div>
                                <div className={styles.options}>
                                  {item.car.carOptions?.map((opt, idx) => {
                                    return (
                                      idx < 5 && (
                                        <span key={idx}>{opt.nameKr}</span>
                                      )
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                            <div
                              className={`${styles.tdService}` + " button-form"}
                            >
                              {item.addServices?.length === 0 && (
                                <button
                                  type="button"
                                  className="btn btn-md btn--outline_primary"
                                  onClick={() => {
                                    handleAddService(item);
                                  }}
                                >
                                  부가서비스 선택
                                </button>
                              )}
                              {item.addServices?.length !== 0 && (
                                <>
                                  <button
                                    type="button"
                                    className="btn btn-md btn--outline_gray"
                                    onClick={() => {
                                      handleAddService(item);
                                    }}
                                  >
                                    부가서비스 수정
                                  </button>
                                  <p className={styles.selectedServices}>
                                    <span>선택 완료</span> <br />
                                    <em>
                                      (
                                      {Common.commaFormat(
                                        item.addServices.reduce(
                                          (sum, currValue) => {
                                            return sum + currValue.price;
                                          },
                                          0
                                        )
                                      ) + "원)"}
                                    </em>
                                  </p>
                                  {/* <p className={styles.servicesPrices}></p> */}
                                </>
                              )}
                              {/* {item.dealStatus === "REQUEST_CONFIRM" && (
                                <button
                                  type="button"
                                  className="btn btn-md btn--outline_primary"
                                  onClick={() => {
                                    handleAddService(item);
                                  }}
                                >
                                  부가서비스 선택
                                </button>
                              )}
                              {item.dealStatus === "ADDSERVICE_FINISH" &&  (
                                <button
                                  type="button"
                                  className="btn btn-md btn--outline_gray"
                                  onClick={() => {
                                    handleAddService(item);
                                  }}
                                >
                                  부가서비스 수정
                                </button>
                              )}
                              {item.dealStatus === "BUY_ING" && (
                                <button
                                  type="button"
                                  className="btn btn-md btn--outline_black"
                                >
                                  구매 내역
                                </button>
                              )} */}
                            </div>
                            <div className={styles.tdPrice}>
                              <div className={styles.price}>
                                <span>
                                  차량가액 <br />
                                  <em>
                                    {Common.commaFormat(
                                      item.car.carPrice.price
                                    )}
                                    원
                                  </em>
                                </span>
                              </div>
                              <p className={styles.fees}>
                                *수수료 :{" "}
                                {Common.commaFormat(
                                  item.car.carPrice.comission
                                )}
                                원
                              </p>
                            </div>
                            <div className={styles.tdStatus}>
                              <span className={styles.rqStatus}>
                                구매 진행중
                              </span>
                              <div className={styles.rqDate}>
                                <p>
                                  {item.onProcessedAt
                                    ?.replace("T", " ")
                                    ?.split(".")[0]
                                    ?.slice(0, -3)
                                    ?.replaceAll("-", ".")}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={`${styles.contOption}` + " button-form"}>
                  <button
                    type="button"
                    className="btn btn-lg btn--outline"
                    onClick={() => {
                      onCancelRequest(checkItems);
                    }}
                  >
                    선택 요청 취소
                  </button>
                </div>
                <div className={styles.contResult}>
                  <div className={styles.resItem}>
                    <span className={styles.itemTit}>선택된 제품</span>
                    <span className={styles.itemDesc}>
                      <em>{checkItems.length}</em>개
                    </span>
                  </div>
                  <div className={styles.resItem}>
                    <div className={styles.priceInfo}>
                      <span className={styles.itemTit}>차량 가격</span>
                      <span className={styles.itemDesc}>
                        <em>
                          {checkItems.length != 0
                            ? Common.commaFormat(totalPrice)
                            : 0}
                        </em>
                        원
                      </span>
                    </div>
                    <div className={styles.priceInfo}>
                      <span className={styles.itemTit}>수수료</span>
                      <span className={styles.itemDesc}>
                        <em>
                          {checkItems.length != 0
                            ? Common.commaFormat(totalComission)
                            : 0}
                        </em>
                        원
                      </span>
                    </div>
                    <div className={styles.priceInfo}>
                      <span className={styles.itemTit}>부가서비스</span>
                      <span className={styles.itemDesc}>
                        <em>
                          {checkItems.length != 0
                            ? String(totalAddServices).length === 1
                              ? String(totalAddServices)
                              : Common.commaFormat(totalAddServices)
                            : 0}
                        </em>
                        원
                      </span>
                    </div>
                  </div>
                </div>
                <div className={`${styles.contBtn}` + " button-form"}>
                  {/* <button
                    type="button"
                    className="btn btn-xl btn--outline_gray"
                    onClick={() => {
                      if (checkItems.length === 0) {
                        // 선택된 제품이 없을 경우
                        setModal({
                          open: true,
                          dim: false,
                          title: "선택하신 제품이 없습니다.",
                          message: "",
                          notxt: "",
                          oktxt: "확인",
                          callback2: () => {
                            null;
                          },
                        });
                        return;
                      }

                      setModal({
                        open: true,
                        dim: false,
                        title: "(선택) 부가서비스 선택을 진행하시겠습니까?",
                        message:
                          "진행시 기존에 선택된 부가서비스는 삭제되며 선택하신 부가서비스로 일괄적용 됩니다.",
                        notxt: "취소",
                        oktxt: "진행하기",
                        callback2: () => {
                          navigate("/service", {
                            state: { data: checkItemDatas },
                          });
                        },
                      });
                    }}
                  >
                    (선택) 부가서비스 선택
                  </button> */}
                  <button
                    type="button"
                    className="btn btn-xl btn--primary"
                    onClick={() => {
                      handlePurchasing();
                    }}
                  >
                    (선택) 구매진행
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />

      {/* 로그인 모달 */}
      {modal.open && (
        <ModalConfirm
          dim={modal.dim}
          setModal={setModal}
          title={modal.title}
          message={modal.message}
          notxt={modal.notxt}
          oktxt={modal.oktxt}
          service={modal.service}
          callback1={modal.callback1}
          callback2={modal.callback2}
        />
      )}
    </>
  );
};

export default OrderPurchasing;
