/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import * as Common from "../../actions/Common.js";
import Toast from "../../components/Toast.js";
import ModalConfirm from "../../components/ModalConfirm.js";
import styles from "./OrderWaiting.module.scss";

import emptyIcon from "../../assets/icons/empty.svg";

import { baseUrl } from "../../api";
const BASE_URL = baseUrl();
const MYPRODUCT_URL = "/api/user/product/v1/me";
const PRODUCT_STATUS = "BUY_ING";
const DEAL_URL = "/api/user/deal/v1/buy_ing";
const STANDBY_URL = "/api/user/deal/v1/before_deposit";

const OrderWaiting = () => {
  const navigate = useNavigate();

  //* API loading state
  const [loading, setLoading] = useState(true);

  /****************************************
   * TODO 초기 세팅
   ****************************************/
  //* Init API Data
  const [deals, setDeals] = useState([]); // 차량 목록

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    //* 연관 상품 세팅
    fetchDealing();
  };

  /****************************************
   * TODO 항목별 API 목록
   ****************************************/
  // 내 계정 연관 상품 가져오기
  const fetchMyProduct = async () => {
    try {
      const parameter = `?product_status_list=${PRODUCT_STATUS}`;
      const response = await axios.get(BASE_URL + MYPRODUCT_URL + parameter, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      setItems3(response.data.data);

      // 셀러 목록 세팅
      const corpList = response.data.data.filter(
        (arr, index, callback) =>
          index ===
          callback.findIndex((t) => t.seller.memberId === arr.seller.memberId)
      );
      setCorpList(corpList);
    } catch (error) {
      console.log(error);
    }
  };
  // 구매 완료 상품 목록 가져오기
  const fetchDealing = async () => {
    setLoading(true);

    try {
      const response = await axios.get(BASE_URL + STANDBY_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setDeals(response.data.data);
      setLoading(false);
      // setCorpList(corpList);
    } catch (err) {
      if (err.response?.status === 401) {
        setModal({
          open: true,
          dim: true,
          title: "세션이 만료되었습니다.",
          message: "로그인 화면으로 이동합니다.",
          notxt: "",
          oktxt: "확인",
          callback2: () => {
            navigate("/logout");
          },
        });
        return;
      } else {
        handleToast(err.response?.data.message);
        setLoading(false);
      }
    }
  };

  // 찜하기 설정 / 찜하기 해제
  const fetchRemove = async (id) => {
    try {
      //? favorite 해제
      const response = await axios.delete(BASE_URL + FAVORITE_URL, {
        data: id,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      fetchFavoriteData();
    } catch (err) {
      console.log(err);
    }
  };

  /****************************************
   * TODO 이벤트 핸들러
   ****************************************/
  //* 찜한차량 item remove 클릭
  const handleRemoveItem = async (id) => {
    setModal({
      open: true,
      dim: false,
      title: "선택한 상품을 삭제하시겠습니까?",
      message: "",
      notxt: "취소",
      oktxt: "확인",
      callback2: () => {
        fetchRemove([id]);
      },
    });
  };

  const handleRemoveGroup = async (id) => {
    if (checkItems.length === 0) {
      setModal({
        open: true,
        dim: false,
        title: "선택하신 제품이 없습니다.",
        message: "",
        notxt: "",
        oktxt: "확인",
        callback2: () => {
          null;
        },
      });
      return;
    }

    const ids = checkItems.join(",");
    setModal({
      open: true,
      dim: false,
      title: "선택한 상품들을 삭제하시겠습니까?",
      message: "",
      notxt: "취소",
      oktxt: "확인",
      callback2: () => {
        fetchRemove(checkItems);
      },
    });
  };

  // 부가서비스 선택
  const handleAddService = (ids) => {
    if (ids.length === 0) {
      // 선택된 제품이 없을 경우
      setModal({
        open: true,
        dim: false,
        title: "선택하신 제품이 없습니다.",
        message: "",
        notxt: "",
        oktxt: "확인",
        callback2: () => {
          null;
        },
      });
      return;
    }

    navigate("/service", { state: { carIds: ids } });
  };

  // 구매요청 취소
  const onCancelRequest = (ids) => {
    setModal({
      open: true,
      dim: false,
      title: "관리자에게 문의해주세요.",
      message: "",
      notxt: "",
      oktxt: "확인",
      service: true,
      callback2: () => {
        null;
      },
    });
    return;

    if (checkItems.length === 0) {
      setModal({
        open: true,
        dim: false,
        title: "선택하신 제품이 없습니다.",
        message: "",
        notxt: "",
        oktxt: "확인",
        callback2: () => {
          null;
        },
      });
      return;
    }

    setModal({
      open: true,
      dim: true,
      title: "구매 요청을 취소하시겠습니까?",
      message: "",
      notxt: "취소",
      oktxt: "확인",
      callback2: async () => {
        await fetchCancelRequest(ids);
        fetchMyProduct();
      },
    });
  };

  /****************************************
   * TODO 공용 함수
   ****************************************/
  //* 선택된 체크박스 state
  const [checkItems, setCheckItems] = useState([]);

  // 개별 선택
  function checkHandler(checked, id) {
    //* 개별 Checkbox state 처리
    if (checked) {
      setCheckItems([...checkItems, id]);
    } else {
      setCheckItems(checkItems.filter((o) => o !== id));
    }
  }
  // 전체 선택
  function checkAllHandler(checked) {
    if (checked) {
      const ids = [];
      deals.forEach((v) => ids.push(v.id));
      setCheckItems(ids); // state 채우기
    } else {
      setCheckItems([]); // state 비우기
    }
  }

  /****************************************
   * TODO 모달 & 토스트 팝업 상태
   ****************************************/
  //* 모달
  const [modal, setModal] = useState({
    open: false,
    dim: true,
    title: "",
    message: "",
    notxt: "",
    oktxt: "확인",
    callback1: false,
    callback2: false,
  });

  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = (txt) => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);

  return (
    <>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <div className={`${styles.orderWaiting}` + " global-width"}>
          <div className={styles.header}>
            <h1>입금 대기</h1>
          </div>
          <div className={styles.contents}>
            {deals.length == 0 ? (
              <div className={styles.nonItems}>
                <div className={styles.icon}>
                  <img src={emptyIcon} alt="" />
                </div>
                <p>입금 대기 차량이 없습니다.</p>
              </div>
            ) : (
              <div className={styles.itemWrapper}>
                <div className={styles.contHeader}>
                  <div className={styles.item}>
                    <div className={styles.tdChk}>
                      <div className="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            onChange={(e) => checkAllHandler(e.target.checked)}
                            checked={checkItems.length === deals.length}
                          />
                          <div></div>
                          <span>전체선택</span>
                        </label>
                      </div>
                    </div>
                    <div className={styles.tdContent}>
                      <span className={styles.tdTit}>차량 정보</span>
                    </div>
                    <div className={styles.tdService}>
                      <span className={styles.tdTit}>구매상세</span>
                    </div>
                    <div className={styles.tdPrice}>
                      <span className={styles.tdTit}>구매 가격</span>
                    </div>
                    <div className={styles.tdStatus}>
                      <span className={styles.tdTit}>구매 상태</span>
                    </div>
                  </div>
                </div>
                <div className={styles.contList}>
                  {deals.map((deal) => {
                    return (
                      <div className={styles.itemCont} key={deal.id}>
                        <div className={styles.itemHeader}>
                          <div className={styles.item}>
                            <div className={styles.tdChk}>
                              <span className={styles.dealDate}>
                                {deal.createdAt
                                  .split("T")[0]
                                  .split("-")[0]
                                  .slice(2) +
                                  "." +
                                  deal.createdAt.split("T")[0].split("-")[1] +
                                  "." +
                                  deal.createdAt.split("T")[0].split("-")[2]}
                              </span>
                              <span className={styles.dealNum}>
                                {`(${deal.identityNumber})`}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className={styles.itemList}>
                          {deal.dealItems.length !== 0 && (
                            <div
                              className={
                                deal.dealItems[0].car.productStatus ===
                                "EXPOSED"
                                  ? `${styles.item}`
                                  : `${styles.item}`
                              }
                              key={deal.dealItems[0].car.id}
                            >
                              <div className={styles.chk}>
                                <div className="checkbox">
                                  <label>
                                    <input
                                      type="checkbox"
                                      onChange={(e) =>
                                        checkHandler(e.target.checked, deal.id)
                                      }
                                      checked={
                                        checkItems.indexOf(deal.id) >= 0
                                          ? true
                                          : false
                                      }
                                      data-id={deal.id}
                                    />
                                    <div></div>
                                    <span></span>
                                  </label>
                                </div>
                              </div>
                              <div className={styles.tdContent}>
                                <div className={styles.img}>
                                  {deal.dealItems.length <= 1 ? (
                                    <Link
                                      to={`/product/${deal.dealItems[0].car.id}`}
                                      className={styles.img}
                                    >
                                      <img
                                        src={deal.dealItems[0].car.mainImgUrl}
                                        alt=""
                                      />
                                    </Link>
                                  ) : (
                                    <span className={styles.img}>
                                      <img
                                        src={deal.dealItems[0].car.mainImgUrl}
                                        alt=""
                                      />
                                    </span>
                                  )}
                                </div>
                                <div className={styles.desc}>
                                  {deal.dealItems.length <= 1 && (
                                    <div className={styles.tags}>
                                      {deal.dealItems[0].car.newYn === "Y" ? (
                                        <span
                                          className={`${styles.tag} ${styles.new}`}
                                        >
                                          New
                                        </span>
                                      ) : null}
                                      {deal.dealItems[0].car.productYn ===
                                      "Y" ? (
                                        <span
                                          className={`${styles.tag} ${styles.commercialized}`}
                                        >
                                          상품화
                                        </span>
                                      ) : null}
                                      {deal.dealItems[0].car.productStatus ===
                                      "BUY_ING" ? (
                                        <span
                                          className={`${styles.tag} ${styles.purchasing}`}
                                        >
                                          입금 대기 중
                                        </span>
                                      ) : null}
                                    </div>
                                  )}
                                  <div className={styles.title}>
                                    <span>
                                      <span>
                                        {deal.dealItems.length <= 1 ? (
                                          <Link
                                            to={`/product/${deal.dealItems[0].car.id}`}
                                          >
                                            {deal.dealItems[0].car
                                              .manufacturerName +
                                              " " +
                                              deal.dealItems[0].car.modelName +
                                              " " +
                                              "(" +
                                              deal.dealItems[0].car.carNumber +
                                              ")"}
                                          </Link>
                                        ) : (
                                          deal.dealItems[0].car
                                            .manufacturerName +
                                          " " +
                                          deal.dealItems[0].car.modelName +
                                          ` 외 ${deal.dealItems.length - 1}대`
                                        )}
                                        {}
                                      </span>
                                      {deal.dealItems.length > 1 && (
                                        <div
                                          className={styles.multipleCarNames}
                                        >
                                          {deal.dealItems.map((dealItem) => {
                                            return (
                                              <Link
                                                to={`/product/${dealItem.car.id}`}
                                                key={dealItem.car.id}
                                              >
                                                {dealItem.car.manufacturerName +
                                                  " " +
                                                  dealItem.car.modelName +
                                                  " " +
                                                  "(" +
                                                  dealItem.car.carNumber +
                                                  ")"}
                                              </Link>
                                            );
                                          })}
                                        </div>
                                      )}
                                    </span>
                                  </div>
                                  {deal.dealItems.length <= 1 && (
                                    <div className={styles.detail}>
                                      <span>
                                        {String(
                                          deal.dealItems[0].car.carMadedYear
                                        ).slice(2) +
                                          "년" +
                                          String(
                                            deal.dealItems[0].car.carMadedMonth
                                          ) +
                                          "월 (" +
                                          String(
                                            deal.dealItems[0].car.carMadedYear
                                          ).slice(2) +
                                          "년형)"}
                                      </span>
                                      <span>
                                        {Common.commaFormat(
                                          deal.dealItems[0].car.mileage
                                        )}
                                        km
                                      </span>
                                      <span>
                                        {deal.dealItems[0].car.fuelType ===
                                        "GAS"
                                          ? "가솔린"
                                          : deal.dealItems[0].car.fuelType ===
                                            "DIESEL"
                                          ? "디젤"
                                          : deal.dealItems[0].car.fuelType ===
                                            "LPG"
                                          ? "LPG"
                                          : deal.dealItems[0].car.fuelType ===
                                            "GAS_LPG"
                                          ? "가솔린(LPG겸용)"
                                          : deal.dealItems[0].car.fuelType ===
                                            "GAS_CNG"
                                          ? "가솔린(CNG겸용)"
                                          : deal.dealItems[0].car.fuelType ===
                                            "GAS_HYBRID"
                                          ? "HEV(하이브리드)"
                                          : deal.dealItems[0].car.fuelType ===
                                            "ELECTRIC"
                                          ? "전기"
                                          : deal.dealItems[0].car.fuelType ===
                                            "HYDROGEN"
                                          ? "수소"
                                          : null}
                                      </span>
                                      <span>
                                        {deal.dealItems[0].car.accident ===
                                        "NONE"
                                          ? "무사고"
                                          : deal.dealItems[0].car.accident ===
                                            "SIMPLE_CHANGE"
                                          ? "단순교환"
                                          : deal.dealItems[0].car.accident ===
                                            "SIMPLE_TOUCH"
                                          ? "단순사고(접촉)"
                                          : deal.dealItems[0].car.accident ===
                                            "NORMAL"
                                          ? "사고"
                                          : deal.dealItems[0].car.accident ===
                                            "TOTAL_LOSS"
                                          ? "전손"
                                          : deal.dealItems[0].car.accident ===
                                            "FLOODING"
                                          ? "침수"
                                          : null}
                                      </span>
                                    </div>
                                  )}
                                  {/* {deal.dealItems.length <= 1 && (
                                    <div className={styles.options}>
                                      {deal.dealItems[0].car.carOptions?.map(
                                        (opt, idx) => {
                                          return (
                                            idx < 5 && (
                                              <span key={idx}>
                                                {opt.nameKr}
                                              </span>
                                            )
                                          );
                                        }
                                      )}
                                    </div>
                                  )} */}
                                </div>
                              </div>
                              <div
                                className={
                                  `${styles.tdService}` + " button-form"
                                }
                              >
                                {deal.dealItems[0].car.productStatus ===
                                  "BUY_CONFIRM" && (
                                  <button
                                    type="button"
                                    className="btn btn-md btn--outline_primary"
                                    onClick={() => {
                                      handleAddService([
                                        deal.dealItems[0].car.id,
                                      ]);
                                    }}
                                  >
                                    부가서비스 선택
                                  </button>
                                )}
                                {deal.dealItems[0].car.productStatus ===
                                  "ADD_SERVICE_FINISH" && (
                                  <button
                                    type="button"
                                    className="btn btn-md btn--outline_gray"
                                    onClick={() => {
                                      handleAddService([
                                        deal.dealItems[0].car.id,
                                      ]);
                                    }}
                                  >
                                    부가서비스 수정
                                  </button>
                                )}
                                {/* {deal.dealItems[0].car.productStatus ===
                                  "BUY_ING" && ( */}
                                <button
                                  type="button"
                                  className="btn btn-md btn--outline_black"
                                  onClick={() => {
                                    navigate("/orderDetail", {
                                      state: {
                                        receiptId: deal.id,
                                      },
                                    });
                                  }}
                                >
                                  상세 내역
                                </button>
                                {/* )} */}
                              </div>
                              <div className={styles.tdPrice}>
                                <div className={styles.price}>
                                  <span>
                                    <em>
                                      {Common.commaFormat(
                                        deal.totalDepositPrice
                                      )}
                                    </em>
                                    원
                                  </span>
                                </div>
                                <p className={styles.fees}>
                                  *수수료 : {deal.totalComissionPrice}원
                                </p>
                              </div>
                              <div className={styles.tdStatus}>
                                <span className={styles.rqStatus}>
                                  입금 대기중
                                </span>
                                <div className={styles.rqDate}>
                                  <p>
                                    {deal.beforeDepositedAt
                                      ?.replace("T", " ")
                                      ?.split(".")[0]
                                      ?.slice(0, -3)
                                      ?.replaceAll("-", ".")}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={`${styles.contOption}` + " button-form"}>
                  <button
                    type="button"
                    className="btn btn-lg btn--outline"
                    onClick={() => {
                      onCancelRequest(checkItems);
                    }}
                  >
                    선택 요청 취소
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />

      {/* 로그인 모달 */}
      {modal.open && (
        <ModalConfirm
          dim={modal.dim}
          setModal={setModal}
          title={modal.title}
          message={modal.message}
          notxt={modal.notxt}
          oktxt={modal.oktxt}
          service={modal.service}
          callback1={modal.callback1}
          callback2={modal.callback2}
        />
      )}
    </>
  );
};

export default OrderWaiting;
