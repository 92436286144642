/* eslint-disable */
import React, { useEffect, useState } from "react";

import styles from "./Modal_normal.module.scss";

const Modal_normal = ({
  modalState,
  dim,
  title,
  content,
  btnCancel,
  btnOk,
}) => {
  return (
    <div className={dim ? styles.wrapper + " " + styles.dim : styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.title}>
          <span>{title}</span>
        </div>
        <div className={styles.body}>
          <p>{content}</p>
        </div>
        <div className={styles.footer}>
          {btnCancel != null && btnCancel != "" ? (
            <button
              className={styles.btnCancel}
              onClick={() => modalState(false)}
            >
              {btnCancel}
            </button>
          ) : null}

          {btnOk != null && btnOk != "" ? (
            <button className={styles.btnOk} onClick={() => modalState(false)}>
              {btnOk}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Modal_normal;
