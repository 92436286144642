/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import Dashboard from "./Dashboard.js";
import ModalAddress from "../../components/Modal_address.js";
import Toast from "../../components/Toast.js";
import ModalConfirm from "../../components/ModalConfirm.js";
import styles from "./AddressBook.module.scss";

import emptyIcon from "../../assets/icons/empty.svg";

import { baseUrl } from "../../api";
const BASE_URL = baseUrl();
const DELIVERY_URL = "/api/user/member/v1/delivery_location";

const AddressBook = () => {
  const navigate = useNavigate();

  // 배송지 설정 모달
  const [addressModalStatus, setAddressModalStatus] = useState(false);
  const [method, setMethod] = useState("");

  /****************************************
   * TODO 초기 세팅
   ****************************************/
  const [loading, setLoading] = useState(true);
  const [deliveryLocation, setDeliveryLocation] = useState([]);

  useEffect(() => {
    fetchSave();
  }, [deliveryLocation]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);

    try {
      fetchDelivery();

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  /****************************************
   * TODO 이벤트 핸들러
   ****************************************/
  const removeItem = (idx) => {
    setModal({
      open: true,
      dim: true,
      title: "선택한 배송지를 삭제하시겠습니까?",
      message: "",
      notxt: "취소",
      oktxt: "확인",
      callback2: () => {
        setDeliveryLocation(
          deliveryLocation.filter((value, index) => index !== idx)
        );
      },
    });
  };

  /****************************************
   * TODO 항목별 API 목록
   ****************************************/
  // 배송지 목록 가져오기
  const fetchDelivery = async () => {
    try {
      const response = await axios.get(BASE_URL + DELIVERY_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setDeliveryLocation(response.data.data);
    } catch (err) {
      handleToast(err.response?.data.message);
    }
  };
  // 배송지 저장
  const fetchSave = async (data) => {
    try {
      const response = await axios.put(
        BASE_URL + DELIVERY_URL,
        { deliveryLocations: deliveryLocation },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      // handleToast(response.data.message);
      return true;
    } catch (err) {
      handleToast(err.response?.data.message);
    }
  };

  /****************************************
   * TODO 토스트 & 모달 상태
   ****************************************/
  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = (txt) => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);
  //* 모달
  const [modal, setModal] = useState({
    open: false,
    dim: true,
    title: "",
    message: "",
    notxt: "",
    oktxt: "확인",
    callback1: false,
    callback2: false,
  });

  return (
    <>
      {/* 대시보드 */}
      <div className={`${styles.dashboard}` + " global-width"}>
        <Dashboard />
      </div>
      {/* 배송지 관리 */}
      <div className={`${styles.addressBook}` + " global-width"}>
        {/* 배송지 관리 */}
        <div className={styles.header}>
          <h1>배송지 관리</h1>
          <p className={styles.notice}>
            *배송지는 최대 3개까지 저장 가능합니다.
          </p>
        </div>
        <div className={styles.contents}>
          <div className={styles.itemWrapper}>
            <div className={styles.contHeader}>
              <div className={styles.item}>
                <div className={styles.tdTitle}>
                  <span className={styles.tdTit}>배송지 이름</span>
                </div>
                <div className={styles.tdAddress}>
                  <span className={styles.tdTit}>배송지 상세</span>
                </div>
                <div className={styles.tdOption}>
                  <span className={styles.tdTit}>알림 일자</span>
                </div>
              </div>
            </div>
            {deliveryLocation.length == 0 ? (
              <div className={styles.nonItems}>
                <div className={styles.icon}>
                  <img src={emptyIcon} alt="" />
                </div>
                <p>등록된 배송지가 없습니다.</p>
              </div>
            ) : (
              <>
                <div className={styles.itemCont}>
                  <div className={styles.itemList}>
                    {deliveryLocation.map((item, idx) => {
                      return (
                        <div className={styles.item} key={idx}>
                          <div className={styles.tdTitle}>
                            <div className={styles.mainTxt}>
                              {item.name}
                              {/* 배송지 {idx + 1} */}
                            </div>
                          </div>
                          <div className={styles.tdAddress}>
                            <div className={styles.mainTxt}>
                              {item.address} {item.addressDetail}
                            </div>
                          </div>
                          <div className={styles.tdOption}>
                            <div
                              className={
                                `${styles.buttonList}` + " button-form"
                              }
                            >
                              <button
                                type="button"
                                className="btn btn-md btn--outline_primary"
                                onClick={() => {
                                  setMethod(idx);
                                  setAddressModalStatus(true);
                                }}
                              >
                                수정하기
                              </button>
                              <button
                                type="button"
                                className="btn btn-md btn--outline_gray"
                                onClick={() => {
                                  removeItem(idx);
                                }}
                              >
                                삭제하기
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
            <div className={`${styles.contOption}` + " button-form"}>
              <button
                type="submit"
                className={"btn btn-xl btn--outline_gray"}
                onClick={() => {
                  navigate("/mypage", {
                    state: {
                      tabIndex: 10,
                    },
                  });
                }}
              >
                돌아가기
              </button>
              <button
                type="button"
                className="btn btn-xl btn--primary"
                onClick={() => {
                  setMethod("CREATE");
                  setAddressModalStatus(true);
                }}
              >
                배송지 추가하기
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />

      {/* 모달 */}
      {modal.open && (
        <ModalConfirm
          dim={modal.dim}
          setModal={setModal}
          title={modal.title}
          message={modal.message}
          notxt={modal.notxt}
          oktxt={modal.oktxt}
          callback1={modal.callback1}
          callback2={modal.callback2}
        />
      )}

      {/* (새창) 배송지 설정 모달 */}
      {addressModalStatus ? (
        <ModalAddress
          method={method}
          data={deliveryLocation}
          deleter={setAddressModalStatus}
          fetchDelivery={fetchDelivery}
        />
      ) : null}
    </>
  );
};

export default AddressBook;
