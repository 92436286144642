/* eslint-disable */
import React, { useMemo, useEffect, useState, useRef } from "react";
import Select, { components, MenuListProps } from "react-select";
import AsyncSelect from "react-select/async";
import axios from "axios";
import ApiController from "../api/ApiController";

import * as Common from "../actions/Common.js";
import Toast from "../components/Toast.js";
import SearchIcon from "../assets/icons/search-pr.svg";
import styles from "./Modal_wish.module.scss";

import { baseUrl } from "../api";
import min from "date-fns/min/index.js";
const BASE_URL = baseUrl();
// const CARMODEL_URL = `/api/user/car/v1/car_model`;
const CARMODEL_URL = `/api/user/search/v1/car_model`;
const INTEREST_URL = `/api/user/product/v1/interest`;

const Modal_wish = ({ dim, setModal, data, fetchData, mainToastMsg }) => {
  const [btnStatus, setBtnStatus] = useState(false);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);

  useEffect(async () => {
    if (data.id !== null) {
      const result = await fetchGetData(data.id);
      setSelectedCarId(result.carModelId);
      setSearchValue(result.modelName);
      setMinMadeYear(result.minCarMadedYear);
      setMaxMadeYear(result.maxCarMadedYear);
      setMinPrice(result.minPrice);
      setMaxPrice(result.maxPrice);
      setMinMileage(result.minMileage);
      setMaxMileage(result.maxMileage);
      if (result.minPrice === 0 && result.maxPrice === 1000000000) {
        setPriceStatus(false);
      }
      if (result.minMileage === 0 && result.maxMileage === 1000000) {
        setMileageStatus(false);
      }
    } else {
      setLoading(false);
    }
  }, []);

  /****************************************
   * TODO API 통신
   ****************************************/
  // 모델명 키워드 검색
  const fetchSearchData = async (keyword) => {
    try {
      const urlParams = `?car_model_name=${keyword}`;

      const response = await axios.get(BASE_URL + CARMODEL_URL + urlParams, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      // setSearchList(response.data.data);
      return response.data.data;
    } catch (err) {
      handleToast(err.response?.data.message);
    }
  };

  // 관심차량 가져오기 (상세)
  const fetchGetData = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(BASE_URL + INTEREST_URL + `/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setItems(response.data.data);
      setLoading(false);
      return response.data.data;
    } catch (err) {
      handleToast(err.response?.data.message);
      setLoading(false);
    }
  };
  // 관심차량 등록
  const fetchSubmit = async () => {
    try {
      const response = await axios.post(
        BASE_URL + INTEREST_URL,
        {
          carModelId: selectedCarId,
          minCarMadedYear: minMadeYear,
          maxCarMadedYear: maxMadeYear,
          minMileage: minMileage,
          maxMileage: maxMileage,
          minPrice: minPrice,
          maxPrice: maxPrice,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      handleToast(response.data.message);
      fetchData();
    } catch (err) {
      handleToast(err.response?.data.message);
    }
  };
  // 관심차량 수정
  const fetchUpdate = async () => {
    try {
      const response = await axios.put(
        BASE_URL + INTEREST_URL,
        {
          interestCars: [
            {
              id: data.id,
              carModelId: selectedCarId,
              minCarMadedYear: minMadeYear,
              maxCarMadedYear: maxMadeYear,
              minMileage: minMileage,
              maxMileage: maxMileage,
              minPrice: minPrice,
              maxPrice: maxPrice,
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      handleToast(response.data.message);
      fetchData();
    } catch (err) {
      handleToast(err.response?.data.message);
    }
  };

  /****************************************
   * TODO 이벤트 핸들러
   ****************************************/
  const handleSubmit = () => {
    // 연식 날짜 유효성 검사
    if (minMadeYear > maxMadeYear) {
      handleToast("연식 설정이 잘못되었습니다.");
      return;
    }
    if (mileageStatus === true) {
      if (minMileage > maxMileage) {
        handleToast("주행거리 설정이 잘못되었습니다.");
        return;
      }
    }
    if (priceStatus === true) {
      if (minPrice > maxPrice) {
        handleToast("가격 설정이 잘못되었습니다.");
        return;
      }
    }

    // 1. 모델 ID값이 적용되었는지 확인 유효성 검사 진행
    if (data.id === null) {
      fetchSubmit();
      mainToastMsg("입고알림 신청이 완료되었습니다.");
    } else {
      fetchUpdate();
      mainToastMsg("입고알림 신청이 수정되었습니다.");
    }
    setModal(false);
  };

  /****************************************
   * TODO 모델명 검색 SEARCH INPUT
   ****************************************/
  //* Init Keyword
  const [optionStatus, setOptionStatus] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [selectedCarId, setSelectedCarId] = useState(0); // 선택된 차량 ID 값

  useEffect(() => {
    initKeywords();
  }, []);

  // 키워드 세팅
  const initKeywords = () => {
    const cookieResult = Common.getCookieJson("chada_search_list");
    setSearchList(cookieResult);
  };

  // 키워드 제거
  const deleteKeywords = (keyword) => {
    let cookieList = Common.getCookieJson("chada_search_list");
    let deleteIdx = 0;

    if (cookieList && cookieList.length != 0) {
      cookieList.find((e, idx) => {
        if (e.nameKr === keyword) {
          deleteIdx = idx;
        }
      });

      cookieList.splice(deleteIdx, 1);
    }
    Common.setCookie("chada_search_list", JSON.stringify(cookieList), 7);
    // setState
    initKeywords();
  };

  // 검색 버튼 클릭 / 결과 페이지로 이동
  const showResult = () => {
    setKeywordCookie(searchValue);
    initKeywords();
    // navigate("/buy", { state: { keyword: searchValue } });
    // dispatch(allActions.userActions.loginUser());
  };
  const showResultByChoice = (value) => {
    setSearchValue(value);
    setOptionStatus(false);
    setKeywordCookie(searchValue);
    initKeywords();
    // navigate("/buy", { state: { keyword: value } });
  };

  //* 검색 결과(keyword) 쿠키 저장
  const setKeywordCookie = (keyword) => {
    if (keyword === "") {
      return;
    }
    let cookieList = [];
    const newCookie = {
      viewedAt: Common.getCurrentTime(),
      nameKr: keyword,
    };

    if (Common.getCookieJson("chada_search_list") != null) {
      cookieList = Common.getCookieJson("chada_search_list");
    }
    // 쿠키에 같은 키워드가 있는지 확인
    let checkCookieKeyword = true;
    if (cookieList && cookieList.length != 0) {
      cookieList.find((e) => {
        if (e.nameKr === keyword) {
          checkCookieKeyword = false;
        }
      });
    }

    // 중복 키워드가 없는 경우 쿠키 새로 저장
    if (checkCookieKeyword === true) {
      if (cookieList.length >= 20) {
        cookieList.shift();
      }
      cookieList.push(newCookie);
      Common.setCookie("chada_search_list", JSON.stringify(cookieList), 7);
    }
  };

  const handleSearch = async (e) => {
    setSelectedCarId(0);
    setSearchValue(e.target.value);
    if (e.target.value === "") {
      setFilteredData([]);
      return;
    }

    const result = await fetchSearchData(e.target.value); // input value로 비동기 통신 진행,

    const newArr = result.filter((data) => {
      return (
        data.keyword?.toLowerCase().search(e.target.value.toLowerCase()) != -1
      );
    });

    setFilteredData(newArr);
  };

  // Select Option 외부 클릭시 레이어 숨김
  const useOptionCloser = (ref) => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setOptionStatus(false);
      }
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  };

  const optionsRef = useRef(null);
  useOptionCloser(optionsRef);

  /****************************************
   * TODO 카테고리(연식, 주행거리, 금액) SELECTBOX
   ****************************************/
  const selectboxStyles = useMemo(
    () => ({
      option: (provided, { isDisabled, isFocused, isSelected }) => ({
        ...provided,
        color: "black",
        opacity: 0.8,
        padding: 12,
        cursor: "default",
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#eaeaea"
          : isFocused
          ? "#eaeaea"
          : undefined,
        ":active": {
          ...provided[":active"],
          backgroundColor: "#eaeaea",
        },
      }),
      control: (provided, state) => ({
        ...provided,
        width: "160px",
        height: "20px",
        background: "white",
        boxShadow: "transparent",
        cursor: "pointer",
        borderColor: state.isFocused ? "hsl(0, 0%, 80%)" : "hsl(0, 0%, 80%)",
        "&:hover": {
          borderColor: state.isFocused ? "hsl(0, 0%, 80%)" : "hsl(0, 0%, 80%)",
        },
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: "#000",
      }),
    }),
    []
  );
  const disabledSelectboxStyles = useMemo(
    () => ({
      indicatorsContainer: (styles) => ({
        ...styles,
        opacity: "0",
      }),
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          color: "#9f9f9f",
          fontSize: "14px",
        };
      },
      option: (provided, { isDisabled, isFocused, isSelected }) => ({
        ...provided,
        color: "#eaeaea",
        opacity: 0.8,
        padding: 12,
        cursor: "default",
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#eaeaea"
          : isFocused
          ? "#eaeaea"
          : undefined,
        ":active": {
          ...provided[":active"],
          backgroundColor: "#eaeaea",
        },
      }),
      control: (provided, state) => ({
        ...provided,
        width: "160px",
        height: "20px",
        background: "#eaeaea",
        boxShadow: "transparent",
        cursor: "pointer",
        borderColor: state.isFocused ? "hsl(0, 0%, 80%)" : "hsl(0, 0%, 80%)",
        "&:hover": {
          borderColor: state.isFocused ? "hsl(0, 0%, 80%)" : "hsl(0, 0%, 80%)",
        },
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: "#9f9f9f",
      }),
    }),
    []
  );

  // 필터 - 연식
  const [minMadeYear, setMinMadeYear] = useState(0);
  const [maxMadeYear, setMaxMadeYear] = useState(1000000000);
  const [yearOptions, setYearOptions] = useState();

  useEffect(() => {
    // 날짜 자동 계산 (-10년)
    const result = getDecades();
    const arr = [];
    result.map((data) => {
      arr.push({
        value: data.value,
        label: data.label,
      });
    });
    setYearOptions(arr);
  }, []);

  const getDecades = () => {
    const currentYear = new Date().getFullYear();
    const yearRange = 10;

    const years = [];
    for (let i = currentYear; i >= currentYear - 10; i--) {
      years.push({
        value: i,
        label: i + "년",
      });
    }

    return years;
  };

  // 필터 - 가격
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000000000);

  const minPriceOptions = [
    { value: "1000000", label: "100만원" },
    { value: "2000000", label: "200만원" },
    { value: "3000000", label: "300만원" },
    { value: "4000000", label: "400만원" },
    { value: "5000000", label: "500만원" },
    { value: "6000000", label: "600만원" },
    { value: "7000000", label: "700만원" },
    { value: "8000000", label: "800만원" },
    { value: "9000000", label: "900만원" },
    { value: "10000000", label: "1,000만원" },
    { value: "20000000", label: "2,000만원" },
    { value: "30000000", label: "3,000만원" },
    { value: "40000000", label: "4,000만원" },
    { value: "50000000", label: "5,000만원" },
    { value: "60000000", label: "6,000만원" },
    { value: "70000000", label: "7,000만원" },
    { value: "80000000", label: "8,000만원" },
    { value: "90000000", label: "9,000만원" },
    { value: "100000000", label: "10,000만원" },
    { value: "2000000000", label: "10,000만원 초과" },
  ];
  const maxPriceOptions = [
    { value: "1000000", label: "100만원" },
    { value: "2000000", label: "200만원" },
    { value: "3000000", label: "300만원" },
    { value: "4000000", label: "400만원" },
    { value: "5000000", label: "500만원" },
    { value: "6000000", label: "600만원" },
    { value: "7000000", label: "700만원" },
    { value: "8000000", label: "800만원" },
    { value: "9000000", label: "900만원" },
    { value: "10000000", label: "1,000만원" },
    { value: "20000000", label: "2,000만원" },
    { value: "30000000", label: "3,000만원" },
    { value: "40000000", label: "4,000만원" },
    { value: "50000000", label: "5,000만원" },
    { value: "60000000", label: "6,000만원" },
    { value: "70000000", label: "7,000만원" },
    { value: "80000000", label: "8,000만원" },
    { value: "90000000", label: "9,000만원" },
    { value: "100000000", label: "10,000만원" },
    { value: "2000000000", label: "10,000만원 초과" },
  ];

  // 필터 - 주행거리
  const [minMileage, setMinMileage] = useState(0);
  const [maxMileage, setMaxMileage] = useState(1000000);

  const minDistanceOptions = [
    { value: "10000", label: "10,000km" },
    { value: "20000", label: "20,000km" },
    { value: "30000", label: "30,000km" },
    { value: "40000", label: "40,000km" },
    { value: "50000", label: "50,000km" },
    { value: "60000", label: "60,000km" },
    { value: "70000", label: "70,000km" },
    { value: "80000", label: "80,000km" },
    { value: "90000", label: "90,000km" },
    { value: "100000", label: "100,000km" },
    { value: "110000", label: "110,000km" },
    { value: "120000", label: "120,000km" },
    { value: "130000", label: "130,000km" },
    { value: "140000", label: "140,000km" },
    { value: "150000", label: "150,000km" },
    { value: "160000", label: "160,000km" },
    { value: "170000", label: "170,000km" },
    { value: "180000", label: "180,000km" },
    { value: "190000", label: "190,000km" },
    { value: "200000", label: "200,000km" },
  ];
  const maxDistanceOptions = [
    { value: "10000", label: "10,000km" },
    { value: "20000", label: "20,000km" },
    { value: "30000", label: "30,000km" },
    { value: "40000", label: "40,000km" },
    { value: "50000", label: "50,000km" },
    { value: "60000", label: "60,000km" },
    { value: "70000", label: "70,000km" },
    { value: "80000", label: "80,000km" },
    { value: "90000", label: "90,000km" },
    { value: "100000", label: "100,000km" },
    { value: "110000", label: "110,000km" },
    { value: "120000", label: "120,000km" },
    { value: "130000", label: "130,000km" },
    { value: "140000", label: "140,000km" },
    { value: "150000", label: "150,000km" },
    { value: "160000", label: "160,000km" },
    { value: "170000", label: "170,000km" },
    { value: "180000", label: "180,000km" },
    { value: "190000", label: "190,000km" },
    { value: "200000", label: "200,000km" },
  ];

  //* 불러와서 세팅된 items State로 모델명 Input, 연식, 주행거리, 금액 Selectbox 매칭
  const [selectedMinMadeYear, setSelectedMinMadeYear] = useState(0);
  const [selectedMaxMadeYear, setSelectedMaxMadeYear] = useState(0);
  const [selectedMinPrice, setSelectedMinPrice] = useState(0);
  const [selectedMaxPrice, setSelectedMaxPrice] = useState(0);
  const [selectedMinMileage, setSelectedMinMileage] = useState(0);
  const [selectedMaxMileage, setSelectedMaxMileage] = useState(0);

  useEffect(() => {
    // setSelectedMinMadeYear(
    //   findArrIndex(minMadeYearOptions, items.minCarMadedYear)
    // );
    // setSelectedMaxMadeYear(
    //   findArrIndex(maxMadeYearOptions, items.maxCarMadedYear)
    // );
    setSelectedMinPrice(findArrIndex(minPriceOptions, items.minPrice));
    setSelectedMaxPrice(findArrIndex(maxPriceOptions, items.maxPrice));
    setSelectedMinMileage(findArrIndex(minDistanceOptions, items.minMileage));
    setSelectedMaxMileage(findArrIndex(maxDistanceOptions, items.maxMileage));
  }, [items]);

  // targetArr(배열의 value값)에 value가 몇번째에 위치했는지 확인
  const findArrIndex = (targetArr, value) => {
    const index = targetArr.findIndex((i) => i.value == value);
    return index;
  };
  /****************************************
   * TODO 화면 유효성 검사
   ****************************************/
  // 버튼 유효성 처리
  useEffect(() => {
    if (
      selectedCarId !== 0 &&
      minMadeYear !== 0 &&
      maxMadeYear !== 1000000000
    ) {
      setBtnStatus(true);
    }
  }, [
    selectedCarId,
    minMadeYear,
    maxMadeYear,
    minPrice,
    maxPrice,
    minMileage,
    maxMileage,
  ]);

  /****************************************
   * TODO 모달 & 토스트 팝업 상태
   ****************************************/
  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = (txt) => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);

  const [mileageStatus, setMileageStatus] = useState(true); // 주행거리 상태
  const [priceStatus, setPriceStatus] = useState(true); // 금액 상태

  useEffect(() => {
    if (mileageStatus === false) {
      setMinMileage(0);
      setMaxMileage(1000000);
    }
  }, [mileageStatus]);
  useEffect(() => {
    if (priceStatus === false) {
      setMinPrice(0);
      setMaxPrice(1000000000);
    }
  }, [priceStatus]);

  return (
    <>
      {!loading && (
        <div className={`${styles.modal} ${styles.modalWish}`}>
          <div className={`${styles.wrapper} ${styles.dim}`}>
            <div className={styles.container}>
              <button
                className={styles.btn_close}
                onClick={() => setModal(false)}
              ></button>
              <div className={styles.title}>
                <span>
                  {data.id === null ? "관심 차량 추가" : "관심 차량 수정"}
                </span>
              </div>
              <div className={styles.body}>
                <div className={styles.frmCont}>
                  {/* 모델 */}
                  <div className={styles.itemCont}>
                    <div className={styles.itemHeader}>
                      <span className={styles.tit}>모델</span>
                    </div>
                    <div className={styles.itemBody}>
                      <div className={styles.searchForm} ref={optionsRef}>
                        <div className={styles.searchEle}>
                          <input
                            type="text"
                            placeholder="모델명을 입력하세요 (예시: K3)"
                            value={searchValue}
                            onFocus={(e) => {
                              handleSearch(e);
                              setOptionStatus(true);
                            }}
                            onChange={(e) => {
                              handleSearch(e);
                            }}
                          />

                          <button type="button">
                            <img src={SearchIcon} alt="" />
                          </button>
                        </div>
                        {optionStatus ? (
                          <div className={styles.searchOptions}>
                            <div className={styles.optionHeader}>
                              {searchValue === "" && filteredData.length === 0
                                ? "검색 결과가 없습니다."
                                : "검색 결과"}
                            </div>
                            <div className={styles.optionList}>
                              {searchValue === "" && filteredData.length === 0
                                ? null
                                : filteredData.map((item, index) => {
                                    return (
                                      <div
                                        className={styles.optionItem}
                                        key={index}
                                      >
                                        <span
                                          className={styles.itemTit}
                                          onClick={() => {
                                            showResultByChoice(item.nameKr);
                                            setSelectedCarId(item.id);
                                          }}
                                        >
                                          {item.nameKr}
                                        </span>
                                      </div>
                                    );
                                  })}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {selectedCarId === 0 && (
                        <p className={styles.warningTxt}>
                          목록에서 모델을 선택해주세요.
                        </p>
                      )}
                    </div>
                  </div>
                  {/* 연식 */}
                  <div className={styles.itemCont}>
                    <div className={`${styles.itemHeader} ${styles.required}`}>
                      <span className={styles.tit}>연식</span>
                    </div>
                    <div className={styles.itemBody}>
                      <div className={styles.rangeItem}>
                        <Select
                          options={yearOptions}
                          value={yearOptions.filter(
                            (option) => option.value === minMadeYear
                          )}
                          isClearable={false}
                          isSearchable={false}
                          closeMenuOnSelect={true}
                          styles={selectboxStyles}
                          onChange={(e) => {
                            setMinMadeYear(e.value);
                          }}
                          placeholder="최소"
                        />
                        <span>~</span>
                        <Select
                          options={yearOptions}
                          value={yearOptions.filter(
                            (option) => option.value === maxMadeYear
                          )}
                          isClearable={false}
                          isSearchable={false}
                          closeMenuOnSelect={true}
                          styles={selectboxStyles}
                          onChange={(e) => {
                            setMaxMadeYear(e.value);
                          }}
                          placeholder="최대"
                        />
                      </div>
                    </div>
                  </div>
                  {/* 주행거리 */}
                  <div className={styles.itemCont}>
                    <div className={styles.itemHeader}>
                      <span className={styles.tit}>주행거리</span>
                      <div className={`${styles.chkItem}` + " checkbox"}>
                        <label>
                          <input
                            type="checkbox"
                            value="term2"
                            onChange={() => setMileageStatus(!mileageStatus)}
                            checked={!mileageStatus}
                          />
                          <div></div>
                          <span>(무관)</span>
                        </label>
                      </div>
                    </div>
                    <div className={styles.itemBody}>
                      <div
                        className={
                          mileageStatus === true
                            ? `${styles.rangeItem}`
                            : `${styles.rangeItem}` + ` ${styles.disabled}`
                        }
                      >
                        <Select
                          options={minDistanceOptions}
                          value={minDistanceOptions.filter(
                            (option) => option.value === String(minMileage)
                          )}
                          isClearable={false}
                          isSearchable={false}
                          closeMenuOnSelect={true}
                          styles={
                            mileageStatus === true
                              ? selectboxStyles
                              : disabledSelectboxStyles
                          }
                          onChange={(e) => {
                            setMinMileage(e.value);
                          }}
                          placeholder={mileageStatus === true ? "최소" : "무관"}
                        />
                        <span>~</span>
                        <Select
                          options={maxDistanceOptions}
                          value={maxDistanceOptions.filter(
                            (option) => option.value === String(maxMileage)
                          )}
                          isClearable={false}
                          isSearchable={false}
                          closeMenuOnSelect={true}
                          styles={
                            mileageStatus === true
                              ? selectboxStyles
                              : disabledSelectboxStyles
                          }
                          onChange={(e) => {
                            setMaxMileage(e.value);
                          }}
                          placeholder={mileageStatus === true ? "최대" : "무관"}
                        />
                      </div>
                    </div>
                  </div>
                  {/* 금액 */}
                  <div className={styles.itemCont}>
                    <div className={styles.itemHeader}>
                      <span className={styles.tit}>금액</span>
                      <div className={`${styles.chkItem}` + " checkbox"}>
                        <label>
                          <input
                            type="checkbox"
                            value="term2"
                            onChange={() => setPriceStatus(!priceStatus)}
                            checked={!priceStatus}
                          />
                          <div></div>
                          <span>(무관)</span>
                        </label>
                      </div>
                    </div>
                    <div className={styles.itemBody}>
                      <div
                        className={
                          priceStatus === true
                            ? `${styles.rangeItem}`
                            : `${styles.rangeItem}` + ` ${styles.disabled}`
                        }
                      >
                        <Select
                          options={minPriceOptions}
                          value={minPriceOptions.filter(
                            (option) => option.value === String(minPrice)
                          )}
                          isClearable={false}
                          isSearchable={false}
                          closeMenuOnSelect={true}
                          styles={
                            priceStatus === true
                              ? selectboxStyles
                              : disabledSelectboxStyles
                          }
                          onChange={(e) => {
                            setMinPrice(e.value);
                          }}
                          placeholder={priceStatus === true ? "최소" : "무관"}
                        />
                        <span>~</span>
                        <Select
                          options={maxPriceOptions}
                          value={maxPriceOptions.filter(
                            (option) => option.value === String(maxPrice)
                          )}
                          isClearable={false}
                          isSearchable={false}
                          closeMenuOnSelect={true}
                          styles={
                            priceStatus === true
                              ? selectboxStyles
                              : disabledSelectboxStyles
                          }
                          onChange={(e) => {
                            setMaxPrice(e.value);
                          }}
                          placeholder={priceStatus === true ? "최대" : "무관"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.footer}>
                <div className="button-form">
                  <button
                    type="button"
                    className={
                      btnStatus
                        ? "btn btn-xl btn--primary"
                        : "btn btn-xl btn--disabled"
                    }
                    onClick={handleSubmit}
                  >
                    {data.id === null ? "추가하기" : "수정하기"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />
    </>
  );
};

export default Modal_wish;
