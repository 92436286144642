/* eslint-disable */
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import doneIcon from "../assets/icons/done-pr.svg";

import styles from "./YourPw.module.scss";

const YourPw = () => {
  const [id, setId] = useState("Mobiment123");

  return (
    <>
      <div className={`${styles.yourPw}` + " global-width"}>
        <div className={styles.yourPw__logo}>
          <img src={doneIcon} alt="" />
        </div>
        <div className={`${styles.yourPw__mainTxt}` + " heading-2 fw-bold"}>
          비밀번호 재설정을 완료했습니다.
        </div>
        <p className={`${styles.yourPw__subTxt}` + " pl"}>
          새로운 비밀번호로 로그인을 진행해보세요.
        </p>

        <div className={`${styles.yourPw__buttons}` + " button-form"}>
          <Link to="/login" className="btn btn-xl btn--primary">
            로그인
          </Link>
        </div>
      </div>
    </>
  );
};

export default YourPw;
