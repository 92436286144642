/* eslint-disable */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

import Modal_info from "../components/Modal_info.js";
import LogoIcon from "../assets/icons/logo-chadapick_black.svg";

import styles from "./Footer.module.scss";

import { baseUrl } from "../api";
const BASE_URL = baseUrl();
const TERMS_URL = "/api/user/term/v1";

const Footer = () => {
  const [terms, setTerms] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [modalContent, setModalContent] = useState();

  const switchTermsContents = (idx) => {
    const serviceTxt = terms.find((term) => {
      return term.termType === "HOME_SERVICE"; // 메인 서비스 이용약관
    });
    const personalTxt = terms.find((term) => {
      return term.termType === "HOME_PERSONAL"; // 메인 개인정보 취급 방침
    });

    if (idx === 1) {
      setModalContent(serviceTxt.content);
    } else if (idx === 2) {
      setModalContent(personalTxt.content);
    }
    setModalState(true);
  };

  /****************************************
   * TODO 화면 렌더링
   ****************************************/
  useEffect(() => {
    fetchTerms(); // 이용약관 가져오기
  }, []);

  /****************************************
   * TODO 항목별 API 목록
   ****************************************/
  // 이용약관 내용 가져오기
  const fetchTerms = async () => {
    try {
      const response = await axios.get(BASE_URL + TERMS_URL);

      setTerms(response.data.data);
    } catch (err) {
      console.error(err.response?.data.message);
    }
  };

  return (
    <>
      <footer className={styles.footer}>
        <div className={styles.footer__content}>
          <div className={styles.footer__content__logo}>
            <img src={LogoIcon} alt="" />
          </div>
          <div className={styles.footer__content__detail}>
            <ul>
              <li>
                <span>상호명 : </span>
                <span>모비먼트(주)</span>
              </li>
              <li>
                <span>대표자 : </span>
                <span>한규민</span>
              </li>
              <li>
                <span>사업자등록번호 : </span>
                <span>172-87-01718</span>
              </li>
              {/* <li>
              <span>통신판매업번호 : </span>
              <span>123-56-78900</span>
            </li> */}
            </ul>
            <ul>
              <li>
                <span>주소 : </span>
                <span>
                  서울특별시 금천구 가산디지털1로 30, 10동 19층 1901호
                </span>
              </li>
              <li>
                <span>TEL : </span>
                <span>1644-4145</span>
              </li>
              <li>
                <span>E-MAIL : </span>
                <span>mobi@mobiment.co.kr</span>
              </li>
            </ul>
          </div>
          <div className={styles.footer__content__terms}>
            <ul>
              <li>
                <a href="http://www.mobiment.co.kr" target="_blank">
                  회사소개
                </a>
              </li>
              <li>
                <a href="http://www.chada.kr" target="_blank">
                  차다소개
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    switchTermsContents(1);
                  }}
                >
                  서비스 이용약관
                </a>
                {/* <Link to="/termsService">서비스 이용약관</Link> */}
              </li>
              <li>
                <a
                  onClick={() => {
                    switchTermsContents(2);
                  }}
                >
                  개인정보 취급 방침
                </a>
                {/* <Link to="/termsPrivacy">개인정보 취급 방침</Link> */}
              </li>
            </ul>
          </div>
        </div>
      </footer>

      {/* 모달 */}
      {modalState ? (
        <Modal_info
          modalState={setModalState}
          dim={false}
          title="이용약관"
          content={modalContent}
        />
      ) : null}
    </>
  );
};

export default Footer;
