/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import * as Common from "../../actions/Common.js";
import Toast from "../../components/Toast.js";
import ModalConfirm from "../../components/ModalConfirm.js";
import styles from "./Dashboard.module.scss";

import memberIcon from "../../assets/icons/grade-member.svg";
import silverIcon from "../../assets/icons/grade-silver.svg";
import goldIcon from "../../assets/icons/grade-gold.svg";
import platinumIcon from "../../assets/icons/grade-platinum.svg";
import vipIcon from "../../assets/icons/grade-vip.svg";
import interrogationIcon from "../../assets/icons/interrogation-gr.svg";
import depthIcon from "../../assets/icons/depth-sm.svg";
import mileageIcon from "../../assets/icons/mileage.svg";
import pointIcon from "../../assets/icons/point.svg";
import CloseIcon from "../../assets/icons/close-gr.svg";

import { baseUrl } from "../../api";
const BASE_URL = baseUrl();
const MYDATA_URL = "/api/user/member/v1/me";
const MYPRODUCT_URL = "/api/user/product/v1/me";
const SUMMARY_URL = "/api/user/deal/v1/summary";
const PRODUCT_STATUS = "BUY_ING";
const INTEREST_URL = `/api/user/product/v1/interest`;

const Dashboard = () => {
  const navigate = useNavigate();

  useEffect(() => {
    getUserId();
    getItemSummary();
    // fetchMyProduct();
    fetchInterestProduct();
  }, []);

  const [myData, setMyData] = useState([]); // 유저 정보
  const [items, setItems] = useState([]); // 구매 상품 정보
  const [summary, setSummary] = useState([]); // 구매 상품 갯수 요약
  const [interestItems, setInterestItems] = useState([]); // 구매 상품 정보
  const [gradeInfoModalStatus, setGradeInfoModalStatus] = useState(false); // 등급 정보 모달

  /****************************************
   * TODO 이벤트
   ****************************************/
  const showGradeInfo = () => {
    document.body.style.overflow = "hidden";
    setGradeInfoModalStatus(true);
  };

  const hideGradeInfo = () => {
    document.body.style.overflow = "unset";
    setGradeInfoModalStatus(false);
  };

  /****************************************
   * TODO 항목별 API 목록
   ****************************************/
  const getItemSummary = async () => {
    try {
      const response = await axios.get(BASE_URL + SUMMARY_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setSummary(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getUserId = async () => {
    try {
      const response = await axios.get(BASE_URL + MYDATA_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setMyData(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  // 내 계정 연관 상품 가져오기
  const fetchMyProduct = async () => {
    try {
      const response = await axios.get(BASE_URL + MYPRODUCT_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setItems(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  // 관심차량 목록 가져오기
  const fetchInterestProduct = async () => {
    try {
      const response = await axios.get(BASE_URL + INTEREST_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      setInterestItems(response.data.data);
    } catch (err) {
      handleToast(err.response?.data.message);
    }
  };

  /****************************************
   * TODO 모달 & 토스트 팝업 상태
   ****************************************/
  //* 모달
  const [modal, setModal] = useState({
    open: false,
    dim: true,
    title: "",
    message: "",
    notxt: "",
    oktxt: "확인",
    callback1: false,
    callback2: false,
  });

  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = (txt) => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);

  return (
    <>
      <div className={styles.dashboard}>
        <div className={styles.info}>
          <div className={styles.userGrade}>
            {myData.grade === "MEMBER" ? (
              <img src={memberIcon} alt="" />
            ) : myData.grade === "SILVER" ? (
              <img src={silverIcon} alt="" />
            ) : myData.grade === "GOLD" ? (
              <img src={goldIcon} alt="" />
            ) : myData.grade === "VVIP" ? (
              <img src={vipIcon} alt="" />
            ) : myData.grade === "PLATINUM" ? (
              <img src={platinumIcon} alt="" />
            ) : null}
          </div>
          <div className={styles.userInfo}>
            <div className={styles.name}>{myData.name}</div>
            <div className={styles.id}>{myData.loginId}</div>
            <div className={styles.grade} onClick={showGradeInfo}>
              {myData.grade === "MEMBER"
                ? "일반"
                : myData.grade === "SILVER"
                ? "실버"
                : myData.grade === "GOLD"
                ? "골드"
                : myData.grade === "VVIP"
                ? "VVIP"
                : myData.grade === "PLATINUM"
                ? "플래티넘"
                : null}{" "}
              등급 <img src={interrogationIcon} alt="" />
              {/* <span className={styles.qDesc}>등급에 대한 설명입니다.</span> */}
            </div>
          </div>
        </div>
        <div className={styles.summary}>
          <div className={styles.item}>
            <div className={styles.itemTit}>구매 요청</div>
            <div
              className={styles.itemDesc}
              onClick={() => {
                navigate("/mypage", {
                  state: {
                    tabIndex: 1,
                  },
                });
              }}
            >
              {summary.requestCount}
              {/* {
                items.filter((item) => item.productStatus === "BUY_REQUEST")
                  .length
              } */}
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTit}>구매 진행</div>
            <div
              className={styles.itemDesc}
              onClick={() => {
                navigate("/mypage", {
                  state: {
                    tabIndex: 2,
                  },
                });
              }}
            >
              {summary.onProcessCount}
              {/* {
                items.filter(
                  (item) =>
                    item.productStatus === "BUY_CONFIRM" ||
                    item.productStatus === "ADD_SERVICE_FINISH"
                ).length
              } */}
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTit}>입금 대기</div>
            <div
              className={styles.itemDesc}
              onClick={() => {
                navigate("/mypage", {
                  state: {
                    tabIndex: 3,
                  },
                });
              }}
            >
              {summary.depositWaitCount}
              {/* {items.filter((item) => item.productStatus === "BUY_ING").length} */}
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTit}>구매 완료</div>
            <div
              className={styles.itemDesc}
              onClick={() => {
                navigate("/mypage", {
                  state: {
                    tabIndex: 4,
                  },
                });
              }}
            >
              {summary.dealFinishCount}
              {/* {
                items.filter((item) => item.productStatus === "BUY_CONFIRM")
                  .length
              } */}
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemTit}>관심 차량</div>
            <div
              className={styles.itemDesc}
              onClick={() => {
                navigate("/mypage", {
                  state: {
                    tabIndex: 5,
                    subTabIndex: 2,
                  },
                });
              }}
            >
              {summary.interestCarCount}
              {/* {interestItems.length} */}
            </div>
          </div>
        </div>
        <div className={styles.mileage}>
          <div className={styles.itemTit}>
            <img src={mileageIcon} alt="" />
            마일리지
          </div>
          <div
            className={styles.itemDesc}
            onClick={() => {
              navigate("/mypage", {
                state: {
                  tabIndex: 6,
                },
              });
            }}
          >
            {Common.commaFormat(myData.mileage)}원
          </div>
        </div>
        <div className={styles.point}>
          <div className={styles.itemTit}>
            <img src={pointIcon} alt="" />
            포인트
          </div>
          <div
            className={styles.itemDesc}
            onClick={() => {
              navigate("/mypage", {
                state: {
                  tabIndex: 7,
                },
              });
            }}
          >
            {Common.commaFormat(myData.point)}P
          </div>
        </div>
      </div>

      {/* 등급 정보 */}
      {gradeInfoModalStatus && (
        <div className={styles.gradeInfoModal}>
          <div className={styles.infoCont}>
            <button className={styles.btnCloseModal} onClick={hideGradeInfo}>
              <img src={CloseIcon} alt="" />
            </button>
            <div className={styles.mainTit}>
              <span>회원등급별 혜택보기</span>
            </div>
            {/* 등급 데이터 */}
            <div className={styles.infoHeader}>
              <div className={styles.item}>
                <span className={styles.itemTit}>나의 회원 등급</span>
                <span className={styles.itemDesc}>{myData.grade}</span>
              </div>
              <div className={styles.item}>
                <span className={styles.itemTit}>3개월 누적 구매 금액</span>
                <span className={styles.itemDesc}>
                  {Common.commaFormat(myData.threeMonthTotalDealPrice)}원
                </span>
              </div>
              <div className={styles.item}>
                <span className={styles.itemTit}>3개월 누적 주문 건수</span>
                <span className={styles.itemDesc}>
                  {myData.threeMonthTotalDealCount}건
                </span>
              </div>
            </div>
            {/* 등급 가이드 */}
            <div className={styles.mainTit}>
              <span>등급별 혜택보기</span>
            </div>
            <div className={styles.gradeTable}>
              <table
                className={
                  myData.grade === "MEMBER"
                    ? `${styles.member}`
                    : myData.grade === "SILVER"
                    ? `${styles.silver}`
                    : myData.grade === "GOLD"
                    ? `${styles.gold}`
                    : myData.grade === "PLATINUM"
                    ? `${styles.platinum}`
                    : myData.grade === "VVIP"
                    ? `${styles.vvip}`
                    : ""
                }
              >
                <thead>
                  <tr>
                    <th data-grade="NONE">
                      <span>회원등급</span>
                    </th>
                    <th data-grade="MEMBER">
                      <span className={styles.gradeIcon}>
                        <img src={memberIcon} alt="" />
                      </span>
                      <span>MEMBER</span>
                    </th>
                    <th data-grade="SILVER">
                      <span className={styles.gradeIcon}>
                        <img src={silverIcon} alt="" />
                      </span>
                      <span>SILVER</span>
                    </th>
                    <th data-grade="GOLD">
                      <span className={styles.gradeIcon}>
                        <img src={goldIcon} alt="" />
                      </span>
                      <span>GOLD</span>
                    </th>
                    <th data-grade="PLATINUM">
                      <span className={styles.gradeIcon}>
                        <img src={platinumIcon} alt="" />
                      </span>
                      <span>PLATINUM</span>
                    </th>
                    <th data-grade="VVIP">
                      <span className={styles.gradeIcon}>
                        <img src={vipIcon} alt="" />
                      </span>
                      <span>VVIP</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-grade="NONE">
                      <span>
                        선정기준
                        <br />
                        (최근 3개월)
                      </span>
                    </td>
                    <td data-grade="MEMBER">
                      <span>회원가입</span>
                    </td>
                    <td data-grade="SILVER">
                      <span>
                        누적구매금액
                        <br />
                        500만원 이상
                      </span>
                    </td>
                    <td data-grade="GOLD">
                      <span>
                        누적구매금액
                        <br />
                        1,000만원 이상
                      </span>
                    </td>
                    <td data-grade="PLATINUM">
                      <span>
                        누적구매금액
                        <br />
                        1,500만원 이상
                      </span>
                    </td>
                    <td data-grade="VVIP">
                      <span>
                        누적구매금액
                        <br />
                        2,000만원 이상
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td data-grade="NONE">
                      <span>
                        최초 등급업
                        <br />
                        지급 쿠폰
                      </span>
                    </td>
                    <td data-grade="MEMBER">
                      <span>회원가입 쿠폰팩</span>
                    </td>
                    <td data-grade="SILVER">
                      <span>
                        쿠폰팩 S<br />
                        (10만원 상당)
                      </span>
                    </td>
                    <td data-grade="GOLD">
                      <span>
                        쿠폰팩 G<br />
                        (15만원 상당)
                      </span>
                    </td>
                    <td data-grade="PLATINUM">
                      <span>
                        쿠폰팩 P<br />
                        (20만원 상당)
                      </span>
                    </td>
                    <td data-grade="VVIP">
                      <span>
                        쿠폰팩 V<br />
                        (30만원 상당)
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td data-grade="NONE">
                      <span>
                        매월 지급
                        <br />
                        할인 쿠폰
                      </span>
                    </td>
                    <td data-grade="MEMBER">
                      <span>
                        수수료 할인 3만원
                        <br />
                        (1만원 × 1 / 2만원 × 1)
                      </span>
                    </td>
                    <td data-grade="SILVER">
                      <span>
                        수수료 할인 5만원
                        <br />
                        (2만원 × 1 / 3만원 × 1)
                      </span>
                    </td>
                    <td data-grade="GOLD">
                      <span>
                        수수료 할인 7만원
                        <br />
                        (2만원 × 2 / 3만원 × 1)
                      </span>
                    </td>
                    <td data-grade="PLATINUM">
                      <span>
                        수수료 할인 10만원
                        <br />
                        (2만원 × 2 / 3만원 × 2)
                      </span>
                    </td>
                    <td data-grade="VVIP">
                      <span>
                        수수료 할인 15만원
                        <br />
                        (2만원 × 3 / 3만원 × 3)
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td data-grade="NONE">
                      <span>
                        구매 포인트
                        <br />
                        적립율
                      </span>
                    </td>
                    <td data-grade="MEMBER">
                      <span>1%</span>
                    </td>
                    <td data-grade="SILVER">
                      <span>2%</span>
                    </td>
                    <td data-grade="GOLD">
                      <span>3%</span>
                    </td>
                    <td data-grade="PLATINUM">
                      <span>4%</span>
                    </td>
                    <td data-grade="VVIP">
                      <span>5%</span>
                    </td>
                  </tr>
                  <tr>
                    <td data-grade="NONE">
                      <span>
                        마일리지 추가 지급 <em>(200만원 충전부터 지급)</em>
                      </span>
                    </td>
                    <td data-grade="MEMBER">
                      <span></span>
                    </td>
                    <td data-grade="SILVER">
                      <span>추가 지급 2%</span>
                    </td>
                    <td data-grade="GOLD">
                      <span>추가 지급 3%</span>
                    </td>
                    <td data-grade="PLATINUM">
                      <span>추가 지급 4%</span>
                    </td>
                    <td data-grade="VVIP">
                      <span>추가 지급 5%</span>
                    </td>
                  </tr>
                  <tr>
                    <td data-grade="NONE">
                      <span>수수료 할인율</span>
                    </td>
                    <td data-grade="MEMBER">
                      <span></span>
                    </td>
                    <td data-grade="SILVER">
                      <span></span>
                    </td>
                    <td data-grade="GOLD">
                      <span>3%</span>
                    </td>
                    <td data-grade="PLATINUM">
                      <span>4%</span>
                    </td>
                    <td data-grade="VVIP">
                      <span>5%</span>
                    </td>
                  </tr>
                  <tr>
                    <td data-grade="NONE">
                      <span>
                        최초 등급업
                        <br />
                        특별 혜택
                      </span>
                    </td>
                    <td data-grade="MEMBER">
                      <span></span>
                    </td>
                    <td data-grade="SILVER">
                      <span>엔진오일 무상교환 1회</span>
                    </td>
                    <td data-grade="GOLD">
                      <span>엔진오일 무상교환 3회</span>
                    </td>
                    <td data-grade="PLATINUM">
                      <span>엔진오일 무상교환 4회</span>
                    </td>
                    <td data-grade="VVIP">
                      <span>엔진오일 무상교환 5회</span>
                    </td>
                  </tr>
                  {/* <tr>
                    <td data-grade="NONE">
                      <span>추가 혜택</span>
                    </td>
                    <td data-grade="MEMBER">
                      <span></span>
                    </td>
                    <td colSpan={4} data-grade="SILVER, GOLD, PLATINUM, VVIP">
                      <span>
                        법인 설립 월 기준 할인 수수료 할인 쿠폰 및 포인트 지급
                        (등급에 따라 금액 차등 지급)
                      </span>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
            <div className={styles.gradeGuide}>
              <div className={styles.guideTit}>
                <span>회원등급 안내</span>
              </div>
              <div className={styles.desc}>
                <p>
                  매월 1일에 최근 3개월 누적 실 결제금액(수수료 및
                  부가서비스)으로 등급 자동 적용됩니다.
                </p>
                <p>
                  매월 지급되는 쿠폰은 매월 1일부터 말일까지 사용 가능하며, 기간
                  내 사용되지 않은 쿠폰은 이월되지 않습니다.
                </p>
                <p>누적 구매금액은 구매가 확정된 금액을 기준으로 반영됩니다.</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />

      {/* 로그인 모달 */}
      {modal.open && (
        <ModalConfirm
          dim={modal.dim}
          setModal={setModal}
          title={modal.title}
          message={modal.message}
          notxt={modal.notxt}
          oktxt={modal.oktxt}
          callback1={modal.callback1}
          callback2={modal.callback2}
        />
      )}
    </>
  );
};

export default Dashboard;
