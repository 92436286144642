/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Header from "./Header.js";
import Footer from "./Footer.js";
import styles from "./Main.module.scss";
import BottomSheet from "../components/BottomSheet.js";

import { baseUrl } from "../api";

const Main = ({ children }) => {
  // 하단 광고 카드 상태
  const [bottomSheetStatus, setBottomSheetStatus] = useState(false);

  // 현재 화면 확인
  const location = useLocation();

  useEffect(() => {
    let curLocation = location.pathname.split("/")[1];

    if (curLocation == "buy") {
      setBottomSheetStatus(true);
    } else {
      setBottomSheetStatus(false);
    }

    window.scrollTo({ top: 0 });
  }, [location]);

  return (
    <>
      <Header />
      {baseUrl() === "http://chada-dev.kr" && (
        <div className={styles.appTest}>TEST version</div>
      )}
      <div className={styles.container}>{children}</div>
      <Footer />

      {/* 하단 광고 카드 */}
      <BottomSheet visible={bottomSheetStatus} />
    </>
  );
};

export default Main;
