/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

import * as Common from "../../actions/Common.js";
import Toast from "../../components/Toast.js";
import ModalConfirm from "../../components/ModalConfirm.js";
import Dashboard from "./Dashboard.js";
import styles from "./OrderDetail.module.scss";

import { baseUrl } from "../../api";
const BASE_URL = baseUrl();
const MYDATA_URL = "/api/user/member/v1/me";
const DEAL_URL = "/api/user/deal/v1/deal_final";

const OrderDetail = () => {
  /****************************************
   * TODO 파라미터 & 데이터
   ****************************************/
  //* Init API Data
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [receiptId, setReceiptId] = useState([]);
  const [myData, setMyData] = useState([]);
  const [receiptData, setReceiptData] = useState([]);
  const [dealItems, setDealItems] = useState([]);
  const [locations, setLocations] = useState([]);
  const [locationStatus, setLocationStatus] = useState(true); // 화면 중복 유효성 체킹용
  const [step, setStep] = useState(0);

  useEffect(async () => {
    if (location.state != null) {
      setReceiptId(location.state.receiptId);
      fetchMyData();
      fetchDealData(location.state.receiptId);
    }
  }, [location.state]);

  useEffect(() => {
    dealItems.map((item) => {
      item.addservices.map((service) => {
        if (service.serviceType === "DELIVERY") {
          setLocations((prev) => [...prev, service.deliveryAddress]);
        }
      });
    });
  }, [dealItems]);

  useEffect(() => {
    if (locations.length !== 0 && locationStatus === true) {
      setLocationStatus(false);
    }
  }, [locations]);

  /****************************************
   * TODO 항목별 API 목록
   ****************************************/
  // 토큰 상태 확인
  const fetchMyData = async () => {
    try {
      const response = await axios.get(BASE_URL + MYDATA_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setMyData(response.data.data);
    } catch (err) {
      handleToast(err.response?.data.message);
    }
  };
  // 구매내역 상세
  const fetchDealData = async (receiptId) => {
    setLoading(true);

    try {
      const response = await axios.get(BASE_URL + DEAL_URL + `/${receiptId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setReceiptData(response.data.data);
      setDealItems(response.data.data.dealItems);
      switch (response.data.data.dealFinal.dealStatus) {
        case "ON_PROCESS":
          setStep(1);
          break;
        case "DEPOSIT_FINISH":
          setStep(2);
          break;
        case "PRODUCT_MAKING":
          setStep(3);
          break;
        case "PRODUCT_FINISH":
          setStep(4);
          break;
        case "DELIVERY_WAITING":
          setStep(5);
          break;
        case "DELIVERY_ING":
          setStep(6);
          break;
        case "TRANSFERED":
          setStep(7);
          break;
        case "FINISH":
          setStep(8);
          break;
      }
      setLoading(false);
    } catch (err) {
      handleToast(err.response?.data.message);
      setLoading(false);
    }
  };

  /****************************************
   * TODO 공용 함수
   ****************************************/
  // 배열 내 같은 값 있는지 확인
  const allEqual = (arr) => {
    // 주소지 배열 내 모든 값이 같은 경우 true, 하나라도 틀리면 false
    return arr.every((v) => v === arr[0]);
  };
  /****************************************
   * TODO 토스트 & 모달 상태
   ****************************************/
  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = (txt) => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);
  //* 모달
  const [modal, setModal] = useState({
    open: false,
    dim: true,
    title: "",
    message: "",
    notxt: "",
    oktxt: "확인",
    callback1: false,
    callback2: false,
  });

  return (
    <>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <>
          {/* 대시보드 */}
          <div className={`${styles.dashboard}` + " global-width"}>
            <Dashboard />
          </div>
          {/* 주문 완료 */}
          <div className={`${styles.orderDetail}` + " global-width"}>
            {/* 화면 좌측 - 구매 내역 */}
            <div className={styles.application}>
              <div className={styles.header}>
                <h1>구매 내역</h1>
              </div>
              <div className={styles.contents}>
                <div className={styles.itemWrapper}>
                  {/* 진행 상태 */}
                  <div className={styles.itemCont}>
                    <div className={styles.itemHeader}>
                      <span className={styles.itemTit}>진행 상태</span>
                    </div>
                    <div className={styles.progressBar}>
                      <div
                        className={
                          step >= 1
                            ? `${styles.progressItem} ${styles.active}`
                            : `${styles.progressItem}`
                        }
                      >
                        <span>1</span>
                        <p>입금대기</p>
                      </div>
                      <div
                        className={
                          step >= 2
                            ? `${styles.progressItem} ${styles.active}`
                            : `${styles.progressItem}`
                        }
                      >
                        <span>2</span>
                        <p>입금완료</p>
                      </div>
                      <div
                        className={
                          step >= 3
                            ? `${styles.progressItem} ${styles.active}`
                            : `${styles.progressItem}`
                        }
                      >
                        <span>3</span>
                        <p>상품화중</p>
                      </div>
                      <div
                        className={
                          step >= 4
                            ? `${styles.progressItem} ${styles.active}`
                            : `${styles.progressItem}`
                        }
                      >
                        <span>4</span>
                        <p>상품화 완료</p>
                      </div>
                      <div
                        className={
                          step >= 5
                            ? `${styles.progressItem} ${styles.active}`
                            : `${styles.progressItem}`
                        }
                      >
                        <span>5</span>
                        <p>탁송대기</p>
                      </div>
                      <div
                        className={
                          step >= 6
                            ? `${styles.progressItem} ${styles.active}`
                            : `${styles.progressItem}`
                        }
                      >
                        <span>6</span>
                        <p>차량탁송</p>
                      </div>
                      <div
                        className={
                          step >= 7
                            ? `${styles.progressItem} ${styles.active}`
                            : `${styles.progressItem}`
                        }
                      >
                        <span>7</span>
                        <p>차량인계</p>
                      </div>
                      <div
                        className={
                          step >= 8
                            ? `${styles.progressItem} ${styles.active}`
                            : `${styles.progressItem}`
                        }
                      >
                        <span>8</span>
                        <p>구매완료</p>
                      </div>
                    </div>
                  </div>
                  {/* 입금 계좌 안내 */}
                  <div className={styles.itemCont}>
                    <div className={styles.itemHeader}>
                      <span className={styles.itemTit}>입금 계좌 안내</span>
                    </div>
                    <div className={styles.itemBody}>
                      <div className={`${styles.itemList} ${styles.bgGray}`}>
                        <div className={styles.item}>
                          <div className={styles.itemTit}>계좌 정보</div>
                          <div className={styles.itemDesc}>
                            {receiptData.bankName +
                              " " +
                              receiptData.bankAccount}
                          </div>
                        </div>
                        <div className={styles.item}>
                          <div className={styles.itemTit}>예금자명</div>
                          <div className={styles.itemDesc}>
                            {receiptData.bankOwner}
                          </div>
                        </div>
                        <div className={styles.item}>
                          <div className={styles.itemTit}>결제 금액</div>
                          <div className={styles.itemDesc}>
                            <em>
                              {Common.commaFormat(
                                receiptData.dealFinal?.totalDepositPrice
                              )}
                            </em>
                          </div>
                        </div>
                        <div className={styles.item}>
                          <div className={styles.itemTit}>입금 기간</div>
                          <div className={styles.itemDesc}>
                            {receiptData.dealFinal?.depositByDate
                              ?.split("T")[0]
                              .replaceAll("-", ".")}
                            까지
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* 구매 상품 정보 */}
                  <div className={styles.itemCont}>
                    <div className={styles.itemHeader}>
                      <span className={styles.itemTit}>구매 상품 정보</span>

                      <div className={styles.dealSummary}>
                        <div className={styles.itemSub}>
                          <div className={styles.item}>
                            <span className={styles.itemTit}>구매 일자</span>
                            <span className={styles.itemDesc}>
                              {receiptData.dealFinal?.createdAt
                                .split("T")[0]
                                .replaceAll("-", ".")}
                            </span>
                          </div>
                        </div>
                        <div className={styles.itemSub}>
                          <div className={styles.item}>
                            <span className={styles.itemTit}>구매번호</span>
                            <span className={styles.itemDesc}>
                              {receiptData.dealFinal?.identityNumber}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.detailCont}>
                      {receiptData.dealItems?.map((item, idx) => {
                        return (
                          <div className={styles.itemDetail} key={idx}>
                            <div className={styles.info}>
                              <div className={styles.img}>
                                <img src={item.car.mainImgUrl} alt="" />
                              </div>
                              <div className={styles.desc}>
                                <div className={styles.title}>
                                  <span>
                                    {`${item.car.manufacturerName} ${item.car.modelName}`}
                                  </span>
                                </div>
                                <div className={styles.detail}>
                                  <span>
                                    {String(item.car.carMadedYear).slice(2) +
                                      "년" +
                                      String(item.car.carMadedMonth) +
                                      "월 (" +
                                      String(item.car.carMadedYear).slice(2) +
                                      "년형)"}
                                  </span>
                                  <span>
                                    {Common.commaFormat(item.car.mileage)}km
                                  </span>
                                  <span>
                                    {item.car.fuelType === "GAS"
                                      ? "가솔린"
                                      : item.car.fuelType === "DIESEL"
                                      ? "디젤"
                                      : item.car.fuelType === "LPG"
                                      ? "LPG"
                                      : item.car.fuelType === "GAS_LPG"
                                      ? "가솔린(LPG겸용)"
                                      : item.car.fuelType === "GAS_CNG"
                                      ? "가솔린(CNG겸용)"
                                      : item.car.fuelType === "GAS_HYBRID"
                                      ? "HEV(하이브리드)"
                                      : item.car.fuelType === "ELECTRIC"
                                      ? "전기"
                                      : item.car.fuelType === "HYDROGEN"
                                      ? "수소"
                                      : null}
                                  </span>
                                  <span>
                                    {item.car.accident === "NONE"
                                      ? "무사고"
                                      : item.car.accident === "SIMPLE_CHANGE"
                                      ? "단순교환"
                                      : item.car.accident === "SIMPLE_TOUCH"
                                      ? "단순사고(접촉)"
                                      : item.car.accident === "NORMAL"
                                      ? "사고"
                                      : item.car.accident === "TOTAL_LOSS"
                                      ? "전손"
                                      : item.car.accident === "FLOODING"
                                      ? "침수"
                                      : null}
                                  </span>
                                </div>
                                <div className={styles.options}>
                                  {item.car.carOptions?.map((opt, idx) => {
                                    return (
                                      idx < 5 && (
                                        <span key={idx}>{opt.nameKr}</span>
                                      )
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                            <div className={styles.priceDetail}>
                              <div className={styles.itemSub}>
                                <div className={styles.item}>
                                  <span className={styles.itemTit}>
                                    차량가액
                                  </span>
                                  <span className={styles.itemDesc}>
                                    {Common.commaFormat(
                                      item.car.carPrice.price
                                    )}
                                    원
                                  </span>
                                </div>
                                <div className={styles.item}>
                                  <span className={styles.itemTit}>수수료</span>
                                  <span className={styles.itemDesc}>
                                    {Common.commaFormat(
                                      item.car.carPrice.comission
                                    )}
                                    원
                                  </span>
                                </div>
                              </div>
                              <div className={styles.addService}>
                                <div className={styles.listHeader}>
                                  <span className={styles.tit}>부가서비스</span>
                                  <span className={styles.totalPrices}>
                                    {Common.commaFormat(
                                      item?.addservices.reduce(
                                        (sum, currValue) => {
                                          return sum + currValue.price;
                                        },
                                        0
                                      )
                                    ) + "원"}
                                  </span>
                                </div>
                                <div className={styles.itemList}>
                                  {item.addservices?.map((service, index) => {
                                    return (
                                      <div
                                        className={styles.item}
                                        key={service.addServiceId}
                                      >
                                        <span className={styles.itemTit}>
                                          {service.name}
                                        </span>
                                        <span className={styles.itemDesc}>
                                          {Common.commaFormat(service.price)}원
                                        </span>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                              <div className={styles.total}>
                                <div className={styles.item}>
                                  <span className={styles.itemTit}>
                                    총 구매 금액
                                  </span>
                                  <span className={styles.itemDesc}>
                                    {Common.commaFormat(
                                      item.car.carPrice.price +
                                        item.car.carPrice.comission +
                                        item.car.carPrice.addServiceTotalPrice +
                                        item?.addservices.reduce(
                                          (sum, currValue) => {
                                            return sum + currValue.price;
                                          },
                                          0
                                        )
                                    )}
                                    원
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* 포인트 혜택 */}
                  <div className={styles.itemCont}>
                    <div className={styles.itemHeader}>
                      <span className={styles.itemTit}>포인트 혜택</span>
                    </div>
                    <div className={styles.point}>
                      <div className={styles.listHeader}>
                        <span className={styles.tit}>구매 적립</span>
                        <span className={styles.desc}>
                          총{" "}
                          {Common.commaFormat(
                            receiptData.dealFinal?.gradeSavePrice
                          )}
                          원
                        </span>
                      </div>
                      <div className={styles.itemList}>
                        {/* <div className={styles.item}>
                      <span className={styles.itemTit}>기본 적립</span>
                      <span className={styles.itemDesc}>00,000원</span>
                    </div> */}
                        <div className={styles.item}>
                          <span className={styles.itemTit}>등급별 적립</span>
                          <span className={styles.itemDesc}>
                            {Common.commaFormat(
                              receiptData.dealFinal?.gradeSavePrice
                            )}
                            원
                          </span>
                        </div>
                        {/* <div className={styles.item}>
                      <span className={styles.itemTit}>멤버십 적립</span>
                      <span className={styles.itemDesc}>00,000원</span>
                    </div> */}
                      </div>
                    </div>
                  </div>
                  {/* 배송 정보 */}
                  <div className={styles.itemCont}>
                    <div className={styles.itemHeader}>
                      <span className={styles.itemTit}>배송 정보</span>
                    </div>
                    <div className={styles.itemDesc}>
                      <p className={styles.descTxt3}>
                        {allEqual(locations) ? (
                          locations[0]
                        ) : (
                          <>
                            <p>차랑별 배송주소가 상이 합니다.</p>
                            <p>구매완료페이지 구매내역에서 확인 가능합니다.</p>
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                  {/* 환불 정보 */}
                  <div className={styles.itemCont}>
                    <div className={styles.itemHeader}>
                      <span className={styles.itemTit}>환불 정보</span>
                    </div>
                    <div className={styles.itemDesc}>
                      <p className={styles.descTxt3}>
                        {receiptData.refundBankName +
                          " " +
                          receiptData.refundBankAccount +
                          " " +
                          receiptData.refundBankOwner}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* 화면 우측 - 최종 구매 내역 */}
            <div className={styles.summary}>
              <div className={styles.header}>
                <h3>최종 구매 내역</h3>
              </div>
              <div className={styles.contents}>
                <div className={styles.itemList}>
                  <div className={styles.item}>
                    <span className={styles.itemTit}>차량가액</span>
                    <span className={styles.itemDesc}>
                      {Common.commaFormat(receiptData.dealFinal?.totalCarPrice)}
                      원
                    </span>
                  </div>
                  <span className={styles.line}></span>
                  <div className={styles.item}>
                    <span className={styles.itemTit}>수수료</span>
                    <span className={styles.itemDesc}>
                      {Common.commaFormat(
                        receiptData.dealFinal?.totalComissionPrice
                      )}
                      원
                    </span>
                  </div>
                  <div className={styles.item}>
                    <span className={styles.itemTit}>부가서비스</span>
                    <span className={styles.itemDesc}>
                      {Common.commaFormat(
                        receiptData.dealFinal?.totalAddServicePrice
                      )}
                      원
                    </span>
                  </div>
                  <div className={styles.item}>
                    <span className={styles.itemTit}>수수료 할인</span>
                    <span className={styles.itemDesc}>
                      <em>({receiptData.dealFinal?.gradeDiscountRate}%)</em> -
                      {Common.commaFormat(
                        receiptData.dealFinal?.gradeDiscountPrice
                      )}
                      원
                    </span>
                  </div>
                  {receiptData.dealFinal?.couponId !== null && (
                    <div className={styles.item}>
                      <span className={styles.itemTit}>
                        {receiptData.dealFinal?.couponName}
                      </span>
                      <span className={styles.itemDesc}>
                        -
                        {Common.commaFormat(
                          receiptData.dealFinal?.couponDiscountPrice
                        )}
                        원
                      </span>
                    </div>
                  )}

                  <span className={styles.line}></span>
                  <div className={styles.item}>
                    <span className={styles.itemTit}>결제금액</span>
                    <span className={styles.itemDesc}>
                      {Common.commaFormat(
                        receiptData.dealFinal?.totalComissionPrice +
                          receiptData.dealFinal?.totalAddServicePrice -
                          receiptData.dealFinal?.gradeDiscountPrice -
                          receiptData.dealFinal?.couponDiscountPrice
                      )}
                      원
                    </span>
                  </div>
                  <span className={styles.line}></span>
                  <div className={styles.item}>
                    <span className={styles.itemTit}>포인트</span>
                    <span className={styles.itemDesc}>
                      -
                      {Common.commaFormat(
                        receiptData.dealFinal?.totalUsedPoint
                      )}
                      P
                    </span>
                  </div>
                  <div className={styles.item}>
                    <span className={styles.itemTit}>마일리지</span>
                    <span className={styles.itemDesc}>
                      -
                      {Common.commaFormat(
                        receiptData.dealFinal?.totalUsedMileage
                      )}
                      원
                    </span>
                  </div>
                </div>
                <div className={styles.total}>
                  <div className={styles.item}>
                    <span className={styles.itemTit}>
                      최종 결제금액 (VAT 포함)
                    </span>
                    <span className={styles.itemDesc}>
                      {Common.commaFormat(
                        receiptData.dealFinal?.totalDepositPrice
                      )}
                      원
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className={styles.buttons}>
            <div className="button-form">
              <button type="button" className="btn btn-xl btn--disabled">
                판매 신청
              </button>
            </div>
          </div> */}
            </div>
          </div>
        </>
      )}

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />

      {/* 모달 */}
      {modal.open && (
        <ModalConfirm
          dim={modal.dim}
          setModal={setModal}
          title={modal.title}
          message={modal.message}
          notxt={modal.notxt}
          oktxt={modal.oktxt}
          callback1={modal.callback1}
          callback2={modal.callback2}
        />
      )}
    </>
  );
};

export default OrderDetail;
