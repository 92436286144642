/* eslint-disable */
import React, { useEffect, useState } from "react";
import * as Common from "../actions/Common.js";
import axios from "axios";

import Toast from "../components/Toast.js";
import styles from "./Modal_mileage.module.scss";

import doneIcon from "../assets/icons/done-pr.svg";

// API URL
import { baseUrl } from "../api";
const BASE_URL = baseUrl();
const CHARGE_MILEAGE_URL = "/api/user/mileage/v1/charge_request";

const Modal_mileage = ({ deleter, fetchData, mileage }) => {
  // 구매 상태
  const [chargingStatus, setChargingStatus] = useState(false);
  const [chargingReceipt, setChargingReceipt] = useState();

  // 모달 버튼 상태
  const [btnStatus, setBtnStatus] = useState(false);

  const [selectedMileage, setSelectedMileage] = useState(1000000);

  // 결제 진행 동의 여부
  const [agreeChkedItems, setAgreeChkedItems] = useState([]);

  const agreeChkHandler = (e) => {
    let checked = e.currentTarget.checked;
    let value = e.target.value;

    if (checked) {
      setAgreeChkedItems([...agreeChkedItems, value]);
      setBtnStatus(true);
    } else if (!checked && agreeChkedItems.includes(value)) {
      setAgreeChkedItems(agreeChkedItems.filter((el) => el !== value));
      setBtnStatus(false);
    }
  };

  useEffect(() => {
    console.log(mileage);
  }, []);

  /****************************************
   * TODO 함수 목록
   ****************************************/
  const handleChargeMileage = async () => {
    const result = await chargeMileage();

    if (result === true) {
      setChargingStatus(true);
      fetchData();
    }
  };

  /****************************************
   * TODO 항목별 API 목록
   ****************************************/
  // 인증번호 발송
  const chargeMileage = async () => {
    try {
      const response = await axios.post(
        BASE_URL + CHARGE_MILEAGE_URL,
        { requestMileage: selectedMileage },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      setChargingReceipt(response.data.data);
      return true;
    } catch (err) {
      handleToast(err.response?.data.message);
      return false;
    }
  };

  /****************************************
   * TODO 모달 & 토스트 팝업 상태
   ****************************************/

  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = (txt) => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);
  return (
    <>
      <div className={`${styles.modal} ${styles.modalMileage}`}>
        <div className={`${styles.wrapper} ${styles.dim}`}>
          <div className={styles.container}>
            <button
              className={styles.btn_close}
              onClick={() => deleter(false)}
            ></button>
            <div className={styles.title}>
              <span>{!chargingStatus ? "충전하기" : "구매 완료"}</span>
            </div>
            <div className={styles.body}>
              {!chargingStatus ? (
                <div className={styles.frmCont}>
                  {/* 충전 단위 선택 */}
                  <div className={styles.itemCont}>
                    <div className={styles.itemHeader}>
                      <span>충전 단위 선택</span>
                      <div className={styles.itemBody}>
                        <div className={styles.radioList}>
                          <div className={`${styles.radioItem}` + " radiobox"}>
                            <input
                              type="radio"
                              id="radio1"
                              name="amount"
                              onClick={() => setSelectedMileage(1000000)}
                              defaultChecked
                            />
                            <label htmlFor="radio1">1,000,000원</label>
                          </div>
                          <div className={`${styles.radioItem}` + " radiobox"}>
                            <input
                              type="radio"
                              id="radio2"
                              name="amount"
                              onClick={() => setSelectedMileage(2000000)}
                            />
                            <label htmlFor="radio2">2,000,000원</label>
                          </div>
                          <div className={`${styles.radioItem}` + " radiobox"}>
                            <input
                              type="radio"
                              id="radio3"
                              name="amount"
                              onClick={() => setSelectedMileage(2500000)}
                            />
                            <label htmlFor="radio3">2,500,000원</label>
                          </div>
                          <div className={`${styles.radioItem}` + " radiobox"}>
                            <input
                              type="radio"
                              id="radio4"
                              name="amount"
                              onClick={() => setSelectedMileage(3000000)}
                            />
                            <label htmlFor="radio4">3,000,000원</label>
                          </div>
                        </div>
                        <div className={styles.item}>
                          <div className={styles.itemTit}>
                            현재 보유 마일리지
                          </div>
                          <div className={styles.itemDesc}>
                            {Common.commaFormat(mileage)}원
                          </div>
                        </div>
                        <div className={styles.item}>
                          <div className={styles.itemTit}>
                            충전 후 보유 마일리지
                          </div>
                          <div className={styles.itemDesc}>
                            {Common.commaFormat(mileage + selectedMileage)}원
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* 결제 금액 */}
                  <div className={styles.itemRes}>
                    <div className={styles.item}>
                      <div className={styles.itemTit}>결제 금액 (VAT 포함)</div>
                      <div className={styles.itemDesc}>
                        {Common.commaFormat(selectedMileage)}원
                      </div>
                    </div>
                  </div>
                  {/* 진행 동의 */}
                  <div className={styles.itemAgree}>
                    <div className={styles.chkList}>
                      <div className={`${styles.chkItem}` + " checkbox"}>
                        <label>
                          <input
                            type="checkbox"
                            value="term8"
                            onChange={agreeChkHandler}
                            defaultChecked={
                              agreeChkedItems.includes("term8") ? true : false
                            }
                          />
                          <div></div>
                          <span>아래 이용안내 및 결제 진행에 동의합니다.</span>
                        </label>
                      </div>
                    </div>
                    <div className={styles.desc}>
                      <p>마일리지 충전 요청 시 3일 이내 충전이 완료 됩니다.</p>
                      <p>
                        등급별 추가 제공 마일리지는 7일 이내 별도로 적립 됩니다.
                      </p>
                      <p>
                        모든 소요일은 Working Day 기준이며, 입금 이후부터
                        적용됩니다.
                      </p>
                      <p>
                        세금계산서는 회원가입 시 첨부하신 사업자로 발행 됩니다.
                      </p>
                      <p>
                        모든 마일리지 충전 가격은 부가가치세(VAT) 포함 가격
                        입니다.
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles.setCharge}>
                  <div className={styles.guide}>
                    <div className={styles.icon}>
                      <img src={doneIcon} alt="" />
                    </div>
                    <p className={styles.mainTxt}>
                      마일리지 충전을 요청 하셨습니다.
                    </p>
                    <p className={styles.subTxt}>
                      충전이 완료 되면 차량 구매 시 사용하실 수 있습니다.
                    </p>
                  </div>
                  <div className={styles.details}>
                    <div className={styles.header}>
                      <span>구매 상세 정보</span>
                    </div>
                    <div className={styles.itemList}>
                      <div className={styles.item}>
                        <div className={styles.itemTit}>구매 날짜</div>
                        <div className={styles.itemDesc}>
                          {chargingReceipt.createdAt
                            .split("T")[0]
                            .replaceAll("-", ".")}
                        </div>
                      </div>
                      <div className={styles.item}>
                        <div className={styles.itemTit}>구매 번호</div>
                        <div className={styles.itemDesc}>
                          {chargingReceipt.id}
                        </div>
                      </div>
                      <div className={styles.item}>
                        <div className={styles.itemTit}>충전 금액</div>
                        <div className={styles.itemDesc}>
                          {Common.commaFormat(selectedMileage)}원
                        </div>
                      </div>
                      {/* <div className={styles.item}>
                        <div className={styles.itemTit}>부가세(10%)</div>
                        <div className={styles.itemDesc}>
                          {Common.commaFormat(selectedMileage * 0.1)}원
                        </div>
                      </div> */}
                    </div>
                    <div className={styles.total}>
                      <div className={styles.item}>
                        <div className={styles.itemTit}>
                          총 입금 금액 (VAT 포함)
                        </div>
                        <div className={styles.itemDesc}>
                          {Common.commaFormat(selectedMileage)}원
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.info}>
                    <div className={styles.infoTit}>
                      <span>입금 계좌 번호</span>
                    </div>
                    <div className={styles.item}>
                      <span>국민은행</span>
                      <span>081101-04-204808</span>
                      <span>모비먼트주식회사</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.footer}>
              <div className="button-form">
                {!chargingStatus ? (
                  <button
                    type="button"
                    className={
                      btnStatus
                        ? "btn btn-xl btn--primary"
                        : "btn btn-xl btn--disabled"
                    }
                    onClick={() => {
                      if (btnStatus === true) {
                        handleChargeMileage();
                      }
                    }}
                  >
                    {Common.commaFormat(selectedMileage)}원 충전하기
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-xl btn--primary"
                    onClick={() => {
                      deleter(false);
                    }}
                  >
                    닫기
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />
    </>
  );
};

export default Modal_mileage;
