/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import * as Common from "../../actions/Common.js";
import ModalConfirm from "../../components/ModalConfirm.js";
import Toast from "../../components/Toast.js";
import styles from "./Notification.module.scss";

import emptyIcon from "../../assets/icons/empty.svg";
import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";

// API URL
import { baseUrl } from "../../api";
const BASE_URL = baseUrl();
const NOTIFICATION_URL = "/api/user/notification/v1";

const Notification = () => {
  const navigate = useNavigate();

  /****************************************
   * TODO Pagination Init
   ****************************************/
  const [pagination, setPagination] = useState([]);
  const [page, setPage] = useState(0); // 현재 페이지 번호
  const rangeSize = 10;

  /****************************************
   * TODO 초기 세팅
   ****************************************/
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [deliveryLocation, setDeliveryLocation] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, [page]);

  const fetchData = async () => {
    fetchNotification();
  };

  /****************************************
   * TODO 항목별 API 목록
   ****************************************/
  // 알림 내역 데이터 가져오기
  const fetchNotification = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        BASE_URL + NOTIFICATION_URL + `?page=${page}&size=${rangeSize}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      setPagination(response.data.pagination);
      setItems(response.data.data);
      setLoading(false);
    } catch (err) {
      if (err.response?.status === 401) {
        setModal({
          open: true,
          dim: true,
          title: "세션이 만료되었습니다.",
          message: "로그인 화면으로 이동합니다.",
          notxt: "",
          oktxt: "확인",
          callback2: () => {
            navigate("/logout");
          },
        });
        return;
      } else {
        handleToast(err.response?.data.message);
        setLoading(false);
        return false;
      }
    }
  };

  /****************************************
   * TODO 모달 & 토스트 팝업 상태
   ****************************************/
  //* 모달
  const [modal, setModal] = useState({
    open: false,
    dim: true,
    title: "",
    message: "",
    notxt: "",
    oktxt: "확인",
    callback1: false,
    callback2: false,
  });

  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = (txt) => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);

  return (
    <>
      <div className={`${styles.notification}` + " global-width"}>
        <div className={styles.header}>
          <h1>알림 내역</h1>
        </div>
        <div className={styles.contents}>
          <div className={styles.itemWrapper}>
            <div className={styles.contHeader}>
              <div className={styles.item}>
                <div className={styles.tdContent}>
                  <span className={styles.tdTit}>알림 내용</span>
                </div>
                <div className={styles.tdDate}>
                  <span className={styles.tdTit}>알림 일자</span>
                </div>
              </div>
            </div>
            {items.length == 0 ? (
              <div className={styles.nonItems}>
                <div className={styles.icon}>
                  <img src={emptyIcon} alt="" />
                </div>
                <p>알림내역이 없습니다.</p>
              </div>
            ) : (
              <div className={styles.itemCont}>
                <div className={styles.itemList}>
                  {items.map((item, index) => {
                    return (
                      <div
                        className={styles.item}
                        key={item.id}
                        onClick={(e) =>
                          e.currentTarget.classList.toggle(`${styles.open}`)
                        }
                      >
                        <div className={styles.tdContent}>
                          {/* <div className={styles.img}>
                            <img src={item.imgUrl} alt="" />
                          </div> */}
                          <div className={styles.mainTxt}>{item.content}</div>
                        </div>
                        <div className={styles.tdDate}>
                          <div className={styles.descTxt}>
                            {item.createdAt.split("T")[0]}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                {/* pagination */}
                {pagination.totalPages > 1 && (
                  <div className={styles.pagination}>
                    <div className={styles.numList}>
                      <div
                        className={styles.btnPrev}
                        onClick={() => setPage(pagination.prevPage)}
                        aria-current={pagination.isFirst ? false : true}
                      >
                        <AiOutlineLeft />
                      </div>
                      <div
                        className={styles.btnNext}
                        onClick={() => setPage(pagination.nextPage)}
                        aria-current={pagination.isLast ? false : true}
                      >
                        <AiOutlineRight />
                      </div>
                      <ul className={styles.currentPage}>
                        {Array(pagination.endPage)
                          .fill()
                          .map(
                            (_, i) =>
                              i >= pagination.startPage - 1 && (
                                <li
                                  key={i}
                                  onClick={() => setPage(i)}
                                  className={
                                    pagination.page === i
                                      ? `${styles.active}`
                                      : null
                                  }
                                >
                                  {i + 1}
                                </li>
                              )
                          )}
                      </ul>
                      {pagination.endPage !== pagination.totalPages && (
                        <div className={styles.lastPage}>
                          <li
                            onClick={() => setPage(pagination.totalPages - 1)}
                          >
                            {pagination.totalPages}
                          </li>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />

      {/* 로그인 모달 */}
      {modal.open && (
        <ModalConfirm
          dim={modal.dim}
          setModal={setModal}
          title={modal.title}
          message={modal.message}
          notxt={modal.notxt}
          oktxt={modal.oktxt}
          callback1={modal.callback1}
          callback2={modal.callback2}
        />
      )}
    </>
  );
};

export default Notification;
