/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import styles from "./NotFound.module.scss";
import WarnIcon from "../assets/icons/warning.png";

const NotFound = () => {
    const navigate = useNavigate();
    
  return (
    
    <div className={styles.notFound}>
        <img src={WarnIcon} alt="" />
        <h1>요청하신 페이지를 찾을 수 없습니다.</h1>
        <button type="button" onClick={() => {
            navigate("/home");
        }}>
            <span>메인화면 돌아가기</span>
        </button>
    </div>
  )
}

export default NotFound