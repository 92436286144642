/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./MobileView.module.scss";

import logoIcon from "../assets/icons/chada-mobile-icon.svg";
import googlePlayIcon from "../assets/icons/button-google_play.svg";
import appStoreIcon from "../assets/icons/button-app_store.svg";

const MobileView = () => {
  return (
    <div className={styles.mobileView}>
      <div className={styles.logo}>
        <img src={logoIcon} alt="" />
      </div>
      <div className={styles.guide}>
        <div className={styles.desc}>
          차다 모바일은 <br />
          어플리케이션을 이용해주세요.
        </div>
        <div className={styles.buttons}>
          <a className={styles.btn_download} href="https://chada.page.link/1Crm">
            <img src={googlePlayIcon} alt="" />
          </a>
          <a className={styles.btn_download} href="https://chada.page.link/1Crm">
            <img src={appStoreIcon} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default MobileView;
