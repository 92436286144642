/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import ApiController from "../../api/ApiController";
import Toast from "../../components/Toast.js";
import Dashboard from "./Dashboard.js";
import styles from "./OrderModify.module.scss";

// API URL
import { baseUrl } from "../../api";
const BASE_URL = baseUrl();
const MYDATA_URL = "/api/user/member/v1/me";
const TAXEMAIL_URL = "/api/user/member/v1/tax_email";

const OrderModify = () => {
  const navigate = useNavigate();

  /****************************************
   * TODO 초기 세팅
   ****************************************/
  //* 로그인 상태
  const [loginStatus, setLoginStatus] = useState(false);

  // 버튼 상태
  let [btnState, setBtnState] = useState(false);

  useEffect(() => {
    fetchData();
  }, [loginStatus]);

  const fetchData = async () => {
    try {
      //* 토큰 상태 확인
      const result = await fetchMyData();

      if (!result) {
        setLoginStatus(false);

        setModal({
          open: true,
          dim: true,
          title: "로그인이 필요합니다.",
          message: "지금 로그인 하시겠습니까?",
          notxt: "취소",
          oktxt: "로그인 하기",
          callback2: () => {
            navigate("/login");
          },
        });
      } else {
        setLoginStatus(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /****************************************
   * TODO 항목별 API 목록
   ****************************************/
  // 토큰 상태 확인
  const fetchMyData = async () => {
    try {
      const response = await axios.get(BASE_URL + MYDATA_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setInputs({ taxinEmail: response.data.data.rentCarCorperation.taxEmail });
      return response;
    } catch (err) {
      handleToast(err.response?.data.message);
      return false;
    }
  };

  /****************************************
   * TODO 이벤트 핸들러
   ****************************************/
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(
        BASE_URL + TAXEMAIL_URL,
        {
          taxEmail: taxinEmail,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      console.log(response);
      handleToast(response);
    } catch (err) {
      handleToast(err.response?.data.message);
    }
  };

  /****************************************
   * TODO 모달 & 토스트 팝업 상태
   ****************************************/
  //* 모달
  const [modal, setModal] = useState({
    open: false,
    dim: true,
    title: "",
    message: "",
    notxt: "",
    oktxt: "확인",
    callback1: false,
    callback2: false,
  });

  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = (txt) => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);

  /****************************************
   * TODO 유효성 검사
   ****************************************/

  // 입력 데이터 세팅
  const [inputs, setInputs] = useState({
    taxinEmail: "", // 세금 계산서 발행 이메일
  });
  const { taxinEmail } = inputs;

  const updateInputData = (e) => {
    const { name, value } = e.target;

    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const resetInputData = () => {
    setInputs({
      taxinEmail: "",
    });
  };

  // 폼 유효성 검사
  useEffect(() => {
    if (taxinEmail != "") {
      setBtnState(true);
    } else {
      setBtnState(false);
    }
    // input 별로 유효성 검사 처리

    ///////////////////////////////////
  }, [taxinEmail]);

  return (
    <>
      {/* 대시보드 */}
      <div className={`${styles.dashboard}` + " global-width"}>
        <Dashboard />
      </div>
      {/* 구매 정보 변경 */}
      <form
        className={`${styles.orderModify}` + " form-width"}
        action=""
        onSubmit={handleSubmit}
      >
        <div className={styles.content}>
          <div className={`${styles.mainTxt}` + " heading-1"}>
            구매 정보 변경
          </div>
          <div className={styles.formContainer}>
            {/* 세금 계산서 발행 이메일 */}
            <div className="input-form required">
              <p className="form-title">
                <span>세금 계산서 발행 이메일</span>
              </p>
              <div className="form-field">
                <div className="input-element">
                  <input
                    type="text"
                    name="taxinEmail"
                    className=""
                    placeholder="예시) Mobiment@gmail.com"
                    onChange={updateInputData}
                    value={taxinEmail}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.buttons}` + " button-form"}>
          <button
            type="submit"
            className={
              btnState ? "btn btn-xl btn--primary" : "btn btn-xl btn--disabled"
            }
            disabled={!btnState}
          >
            완료
          </button>
        </div>
      </form>

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />
    </>
  );
};

export default OrderModify;
