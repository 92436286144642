/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";

import * as Common from "../../actions/Common.js";
import Toast from "../../components/Toast.js";
import Share from "../../components/Share.js";
import ModalConfirm from "../../components/ModalConfirm.js";
import ModalWish from "../../components/Modal_wish.js";
import styles from "./Wish.module.scss";

import emptyIcon from "../../assets/icons/empty.svg";
import shareWhIcon from "../../assets/icons/share-wh.svg";
import heartWhIcon from "../../assets/icons/heart-wh.svg";
import heartPrIcon from "../../assets/icons/pr-full-heart-pr.svg";

import { baseUrl } from "../../api";
const BASE_URL = baseUrl();
// const PRODUCT_URL = "/api/user/product/v1";
const INTEREST_URL = `/api/user/product/v1/interest`;
const CART_URL = `/api/user/product/v1/cart`;
const FAVORITE_URL = `/api/user/product/v1/favorite`;

const Wish = ({ subTabIndex, setSubTabIndex }) => {
  const navigate = useNavigate();

  //* Swiper 초기화
  SwiperCore.use([Navigation, Pagination]);

  //* API loading state
  const [loading, setLoading] = useState(true);

  // 탭 인덱스
  const [tabIndex, setTabIndex] = useState(1);
  // 관심 차량 추가 모달
  const [wishModalStatus, setWishModalStatus] = useState(false);

  // 관심 차량 아이템
  const [items, setItems] = useState([]);
  // 관심 차량 입고 내역
  const [incomes, setIncomes] = useState([]);
  const [favoriteItems, setFavoriteItems] = useState([]); // 찜한 차량
  const [cartItems, setCartItems] = useState([]); // 장바구니 차량

  useEffect(() => {
    fetchData();
    fetchIncomeData();
    setTabIndex(subTabIndex);
  }, []);

  /****************************************
   * TODO API 통신
   ****************************************/
  // 관심차량 목록 가져오기
  const fetchData = async () => {
    try {
      const response = await axios.get(BASE_URL + INTEREST_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      setItems(response.data.data);
    } catch (err) {
      if (err.response?.status === 401) {
        setModal({
          open: true,
          dim: true,
          title: "세션이 만료되었습니다.",
          message: "로그인 화면으로 이동합니다.",
          notxt: "",
          oktxt: "확인",
          callback2: () => {
            navigate("/logout");
          },
        });
        return;
      } else {
        handleToast(err.response?.data.message);
        setLoading(false);
      }
    }
  };
  // 관심차량 상품 삭제
  const fetchRemove = async (id) => {
    setLoading(true);

    try {
      const response = await axios.delete(BASE_URL + INTEREST_URL, {
        data: id,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      fetchData();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  // 관심차량 입고내역 목록 가져오기
  const fetchIncomeData = async () => {
    setLoading(true);

    try {
      // const response = await axios.get(BASE_URL + PRODUCT_URL, {
      const response = await axios.get(BASE_URL + INTEREST_URL + `/income`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setIncomes(response.data.data);
      setLoading(false);
    } catch (err) {
      if (err.response?.status === 401) {
        setModal({
          open: true,
          dim: true,
          title: "세션이 만료되었습니다.",
          message: "로그인 화면으로 이동합니다.",
          notxt: "",
          oktxt: "확인",
          callback2: () => {
            navigate("/logout");
          },
        });
        return;
      } else {
        handleToast(err.response?.data.message);
        setLoading(false);
      }
    }
  };
  // 장바구니 담기
  const fetchCartAdd = async (target, id) => {
    try {
      if (target === "off") {
        //? cart 추가
        const response = await axios.post(BASE_URL + CART_URL, [id], {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });

        handleToast("장바구니에 등록 되었습니다.");
      } else if (target === "on") {
        //? cart 해제
        const response = await axios.delete(BASE_URL + CART_URL, {
          data: [id],
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });

        handleToast("장바구니에서 등록 해제 되었습니다.");
      }

      fetchCartData();
    } catch (err) {
      handleToast(err.response?.data.message);
    }
  };
  // 장바구니 차량
  const fetchCartData = async () => {
    try {
      const response = await axios.get(BASE_URL + CART_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      setCartItems(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  // 찜한 차량
  const fetchFavoriteData = async () => {
    try {
      const response = await axios.get(BASE_URL + FAVORITE_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      setFavoriteItems(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  // 찜하기 설정 / 찜하기 해제
  const fetchFavoriteSet = async (target, id) => {
    try {
      if (target === "off") {
        //? favorite 추가
        const response = await axios.post(BASE_URL + FAVORITE_URL, [id], {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });

        handleToast("찜한차량으로 등록 되었습니다.");
      } else if (target === "on") {
        //? favorite 해제
        const response = await axios.delete(BASE_URL + FAVORITE_URL, {
          data: [id],
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });

        handleToast("찜한차량이 등록 해제 되었습니다.");
      }

      fetchFavoriteData();
    } catch (err) {
      console.log(err);
    }
  };

  /****************************************
   * TODO 이벤트 핸들러
   ****************************************/
  // 공유하기 버튼 클릭
  const handleShare = async (carId, carData) => {
    setShareModal({
      open: true,
      dim: true,
      carId: carId,
      carData: carData,
    })
  }

  //* 찜하기 버튼 클릭
  const handleFavorite = async (e, id) => {
    e.preventDefault();

    // if (!loginStatus) {
    //   setModal({
    //     open: true,
    //     dim: true,
    //     title: "로그인이 필요합니다.",
    //     message: "지금 로그인 하시겠습니까?",
    //     notxt: "취소",
    //     oktxt: "로그인 하기",
    //     callback2: () => {
    //       navigate("/login");
    //     },
    //   });
    //   return;
    // }

    const isFavorite = e.currentTarget.dataset.favorite;
    fetchFavoriteSet(isFavorite, id);
  };
  //* 장바구니 버튼 클릭
  const handleCart = async (e, id) => {
    e.preventDefault();

    // if (!loginStatus) {
    //   setModal({
    //     open: true,
    //     dim: true,
    //     title: "로그인이 필요합니다.",
    //     message: "지금 로그인 하시겠습니까?",
    //     notxt: "취소",
    //     oktxt: "로그인 하기",
    //     callback2: () => {
    //       navigate("/login");
    //     },
    //   });
    //   return;
    // }

    const isCart = e.currentTarget.dataset.cart;
    fetchCartAdd(isCart, id);
  };
  /****************************************
   * TODO 이벤트핸들러
   ****************************************/
  const removeItem = (id) => {
    setModal({
      open: true,
      dim: false,
      title: "선택한 상품을 삭제하시겠습니까?",
      message: "",
      notxt: "취소",
      oktxt: "확인",
      callback2: () => {
        fetchRemove([id]);
      },
    });
  };

  /****************************************
   * TODO 모달 & 토스트 팝업 상태
   ****************************************/
  const [modalWish, setModalWish] = useState({
    open: false,
    dim: false,
    data: { id: null },
  });

  //* 공유 모달
  const [shareModal, setShareModal] = useState({
    open: false,
    dim: true,
    carId: null,
    carData: null,
  });

  useEffect(() => {
    fetchData();
  }, [modalWish]);

  //* 모달
  const [modal, setModal] = useState({
    open: false,
    dim: true,
    title: "",
    message: "",
    notxt: "",
    oktxt: "확인",
    callback1: false,
    callback2: false,
  });

  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = (txt) => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);
  return (
    <>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <div className={`${styles.wish}` + " global-width"}>
          <div className={styles.header}>
            <h1>관심 차량</h1>
            <p className={styles.notice}>
              *관심차량 설정은 최대 5개까지만 등록가능합니다.
            </p>
          </div>
          <div className={styles.contents}>
            <div className={styles.tabWrapper}>
              <div className={styles.tabHeader}>
                <button
                  className={
                    tabIndex === 1
                      ? `${styles.btnRadio} ${styles.active}`
                      : `${styles.btnRadio}`
                  }
                  onClick={() => {
                    setTabIndex(1);
                    setSubTabIndex(1);
                  }}
                >
                  관심 차량 설정
                </button>
                <button
                  className={
                    tabIndex === 2
                      ? `${styles.btnRadio} ${styles.active}`
                      : `${styles.btnRadio}`
                  }
                  onClick={() => {
                    setTabIndex(2);
                    setSubTabIndex(2);
                  }}
                >
                  관심 차량 입고 내역
                </button>
              </div>
              <div className={styles.tabContainer}>
                {/* 탭 1 : 관심 차량 설정 */}
                {tabIndex === 1 ? (
                  items.length === 0 ? (
                    <div className={styles.nonItems}>
                      <div className={styles.icon}>
                        <img src={emptyIcon} alt="" />
                      </div>
                      <p>관심 차량이 없습니다.</p>

                      <div className={`${styles.contOption}` + " button-form"}>
                        <button
                          type="button"
                          className="btn btn-xl btn--primary"
                          // onClick={() => setWishModalStatus(true)}
                          onClick={() =>
                            setModalWish({
                              open: true,
                              dim: false,
                              data: { id: null },
                            })
                          }
                        >
                          관심차량 설정
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className={styles.wishItemWrapper}>
                      <div className={styles.contHeader}>
                        <div className={styles.item}>
                          <div className={styles.tdModel}>
                            <span className={styles.tdTit}>브랜드 및 모델</span>
                          </div>
                          <div className={styles.tdYear}>
                            <span className={styles.tdTit}>연식</span>
                          </div>
                          <div className={styles.tdDistance}>
                            <span className={styles.tdTit}>주행거리</span>
                          </div>
                          <div className={styles.tdPrice}>
                            <span className={styles.tdTit}>금액</span>
                          </div>
                          <div className={styles.tdOption}>
                            <span className={styles.tdTit}>설정</span>
                          </div>
                        </div>
                      </div>
                      <div className={styles.contList}>
                        <div className={styles.itemCont}>
                          <div className={styles.itemList}>
                            {items.map((item, index) => {
                              return (
                                <div className={styles.item} key={item.id}>
                                  <div className={styles.tdModel}>
                                    <div className={styles.mainTxt}>
                                      {`${item.manufacturerName} ${item.modelName}`}
                                    </div>
                                  </div>
                                  <div className={styles.tdYear}>
                                    <div className={styles.descTxt}>
                                      {item.minCarMadedYear}년 <br />~
                                      {item.maxCarMadedYear}년
                                    </div>
                                  </div>
                                  <div className={styles.tdDistance}>
                                    <div className={styles.descTxt}>
                                      {item.minMileage === 0 &&
                                        item.maxMileage === 1000000 ? (
                                        "무관"
                                      ) : (
                                        <span>
                                          {Common.commaFormat(item.minMileage)}
                                          km
                                          <br />~
                                          {Common.commaFormat(item.maxMileage)}
                                          km
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div className={styles.tdPrice}>
                                    <div className={styles.descTxt}>
                                      {item.minPrice === 0 &&
                                        item.maxPrice === 1000000000 ? (
                                        "무관"
                                      ) : (
                                        <span>
                                          {Common.commaFormat(
                                            String(item.minPrice).slice(0, -4)
                                          )}
                                          만원 <br />~
                                          {Common.commaFormat(
                                            String(item.maxPrice).slice(0, -4)
                                          )}
                                          만원
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    className={
                                      `${styles.tdOption}` + " button-form"
                                    }
                                  >
                                    <button
                                      type="button"
                                      className="btn btn-md btn--outline_gray"
                                      onClick={() => {
                                        setModalWish({
                                          open: true,
                                          dim: false,
                                          data: { id: item.id },
                                        });
                                      }}
                                    >
                                      수정하기
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-md btn--outline_black"
                                      onClick={() => {
                                        removeItem(item.id);
                                      }}
                                    >
                                      삭제하기
                                    </button>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className={`${styles.contOption}` + " button-form"}>
                        <button
                          type="button"
                          className="btn btn-xl btn--primary"
                          onClick={() =>
                            setModalWish({
                              open: true,
                              dim: false,
                              data: { id: null },
                            })
                          }
                        >
                          관심차량 설정
                        </button>
                      </div>
                    </div>
                  )
                ) : null}
                {/* 탭 2 : 관심 차량 입고 내역 */}
                {tabIndex === 2 ? (
                  incomes.length === 0 ? (
                    <div className={styles.nonItems}>
                      <div className={styles.icon}>
                        <img src={emptyIcon} alt="" />
                      </div>
                      <p>입고된 관심 차량이 없습니다.</p>
                    </div>
                  ) : (
                    <div className={styles.historyItemWrapper}>
                      <Swiper
                        className={styles.itemList}
                        spaceBetween={24}
                        slidesPerView={3}
                        loop={false}
                        navigation
                        pagination={{
                          clickable: true,
                        }}
                      >
                        {incomes.map((item, idx) => {
                          return (
                            <SwiperSlide key={item.id}>
                              <div className={styles.item}>
                                <Link
                                  to={`/product/${item.id}`}
                                  className={styles.img}
                                >
                                  <img src={item.mainImgUrl} alt="" />
                                  <div className={styles.category}>
                                    {item.newYn === "Y" && (
                                      <span className={styles.new}>New</span>
                                    )}
                                    {item.productYn === "Y" && (
                                      <span className={styles.commerce}>
                                        상품화
                                      </span>
                                    )}
                                    {item.productStatus === "BUY_ING" && (
                                      <span className={styles.purchasing}>
                                        구매 진행 중
                                      </span>
                                    )}
                                  </div>
                                  <div className={styles.service}>
                                    <button
                                      type="button"
                                      onClick={(e) => {
                                        e.preventDefault();

                                        handleShare(item.id, item);
                                      }}
                                      className={styles.share}
                                    >
                                      <img src={shareWhIcon} alt="" />
                                    </button>
                                    {favoriteItems.some(
                                      (el) => el.id === item.id
                                    ) ? (
                                      <button
                                        type="button"
                                        className={styles.favorite}
                                        onClick={(e) => {
                                          handleFavorite(e, item.id);
                                        }}
                                        data-favorite="on"
                                      >
                                        <img src={heartPrIcon} alt="" />
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className={styles.favorite}
                                        onClick={(e) => {
                                          handleFavorite(e, item.id);
                                        }}
                                        data-favorite="off"
                                      >
                                        <img src={heartWhIcon} alt="" />
                                      </button>
                                    )}
                                  </div>
                                </Link>
                                <div className={styles.txt}>
                                  <div className={styles.tit}>
                                    <Link to={`/product/${item.id}`}>
                                      {item.manufacturerName +
                                        " " +
                                        item.modelName +
                                        " (" +
                                        item.carNumber +
                                        ")"}
                                    </Link>
                                  </div>
                                  <div className={styles.sub}>
                                    {item.productedAt && (
                                      <span>
                                        {String(item.carMadedYear).slice(2) +
                                          "." +
                                          String(item.carMadedMonth) +
                                          " (" +
                                          String(item.carMadedYear).slice(2) +
                                          "년형)"}
                                      </span>
                                    )}
                                    {item.mileage && (
                                      <span>
                                        {Common.commaFormat(item.mileage)}
                                        km
                                      </span>
                                    )}
                                    {item.fuelType && (
                                      <span>
                                        {item.fuelType == "GAS"
                                          ? "가솔린"
                                          : item.fuelType == "DIESEL"
                                            ? "디젤"
                                            : item.fuelType == "LPG"
                                              ? "LPG"
                                              : item.fuelType == "GAS_LPG"
                                                ? "가솔린(LPG겸용)"
                                                : item.fuelType == "GAS_CNG"
                                                  ? "가솔린(CNG겸용)"
                                                  : item.fuelType == "GAS_HYBRID"
                                                    ? "HEV(하이브리드)"
                                                    : item.fuelType == "ELECTRIC"
                                                      ? "전기"
                                                      : item.fuelType == "HYDROGEN"
                                                        ? "수소"
                                                        : null}
                                      </span>
                                    )}
                                  </div>
                                  <div className={styles.desc}>
                                    {item.majorOptions &&
                                      item.majorOptions.map((opt, idx) => {
                                        return (
                                          <span key={opt.id}>{opt.nameKr}</span>
                                        );
                                      })}
                                  </div>
                                  <div className={styles.summary}>
                                    <span className={styles.price}>
                                      {Common.commaFormat(
                                        String(item.carPrice.price).slice(0, -4)
                                      )}
                                      만원
                                    </span>
                                    {cartItems.some(
                                      (el) => el.id === item.id
                                    ) ? (
                                      <button
                                        type="button"
                                        className={`${styles.optionItem} ${styles.cart}`}
                                        onClick={(e) => {
                                          handleCart(e, item.id);
                                        }}
                                        data-cart="on"
                                      ></button>
                                    ) : (
                                      <button
                                        type="button"
                                        className={`${styles.optionItem} ${styles.cart}`}
                                        onClick={(e) => {
                                          handleCart(e, item.id);
                                        }}
                                        data-cart="off"
                                      ></button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </SwiperSlide>
                          );
                        })}
                      </Swiper>
                    </div>
                  )
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* (새창) 관심차량 설정 모달 */}
      {modalWish.open && (
        <ModalWish
          dim={modalWish.dim}
          setModal={setModalWish}
          data={modalWish.data}
          fetchData={fetchData}
          mainToastMsg={handleToast}
        />
      )}

      {/* 공유하기 */}
      {shareModal.open &&
        <Share dim={shareModal.dim} setModal={setShareModal} carId={shareModal.carId} carData={shareModal.carData} />
      }

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />

      {/* 로그인 모달 */}
      {modal.open && (
        <ModalConfirm
          dim={modal.dim}
          setModal={setModal}
          title={modal.title}
          message={modal.message}
          notxt={modal.notxt}
          oktxt={modal.oktxt}
          callback1={modal.callback1}
          callback2={modal.callback2}
        />
      )}
    </>
  );
};

export default Wish;
