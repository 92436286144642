/* eslint-disable */
import React, { useMemo, useEffect, useState } from "react";
import Select, { components, MenuListProps } from "react-select";

const Components = () => {
  const options = useMemo(
    () => [
      { value: "카카오뱅크", label: "카카오뱅크" },
      { value: "농협", label: "농협" },
      { value: "신한", label: "신한" },
      { value: "IBK기업", label: "IBK기업" },
      { value: "하나", label: "하나" },
      { value: "우리", label: "우리" },
      { value: "국민", label: "국민" },
      { value: "농협", label: "농협" },
      { value: "새마을", label: "새마을" },
      { value: "부산", label: "부산" },
      { value: "대구", label: "대구" },
      { value: "SC제일", label: "SC제일" },
      { value: "광주", label: "광주" },
      { value: "수협", label: "수협" },
      { value: "토스", label: "토스" },
      { value: "씨티", label: "씨티" },
    ],
    []
  );
  const options2 = useMemo(
    () => [
      { value: "K3", label: "K3", className: "option_remove" },
      { value: "K5", label: "K5", className: "option_remove" },
      { value: "K7", label: "K7", className: "option_remove" },
      { value: "K9", label: "K9", className: "option_remove" },
      { value: "QM3", label: "QM3" },
      { value: "QM5", label: "QM5" },
      { value: "스포티지", label: "스포티지" },
      { value: "투싼", label: "투싼" },
      { value: "쏘렌토", label: "쏘렌토" },
      { value: "싼타페", label: "싼타페" },
      { value: "모닝", label: "모닝" },
      { value: "레이", label: "레이" },
      { value: "그랜저", label: "그랜저" },
      { value: "G70", label: "G70" },
      { value: "G80", label: "G80" },
      { value: "G90", label: "G90" },
    ],
    []
  );
  const customStyles = useMemo(
    () => ({
      option: (provided, { isDisabled, isFocused, isSelected }) => ({
        ...provided,
        color: "black",
        opacity: 0.8,
        padding: 12,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#eaeaea"
          : isFocused
          ? "#eaeaea"
          : undefined,
        ":active": {
          ...provided[":active"],
          backgroundColor: "eaeaea",
        },
      }),
      control: (provided, state) => ({
        ...provided,
        width: "360px",
        height: "48px",
        background: "white",
        boxShadow: "transparent",
        borderColor: state.isFocused ? "#eaeaea" : "#eaeaea",
        "&:hover": {
          borderColor: state.isFocused ? "#eaeaea" : "#eaeaea",
        },
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: "#000",
      }),
    }),
    []
  );
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={require("../assets/icons/search-pr.svg").default} alt="" />
      </components.DropdownIndicator>
    );
  };

  const menuHeaderStyle = {
    padding: "8px 12px 16px 12px",
    background: "white",
    color: "eaeaea",
    borderBottom: "1px solid #eaeaea",
  };

  const MenuList = (props) => {
    return (
      <components.MenuList {...props}>
        <div style={menuHeaderStyle}>최근 검색</div>
        {props.children}
      </components.MenuList>
    );
  };

  const [price, setPrice] = useState("");
  const [priceText, setPriceText] = useState("");
  const priceInputHandler = (event) => {
    setPrice(event.target.value);
  };
  const [km, setKm] = useState("");
  const [kmText, setKmText] = useState("");
  const kmInputHandler = (event) => {
    setKm(event.target.value);
  };

  useEffect(() => {
    if (price != "") {
      setPriceText("(만원)");
    } else {
      setPriceText("");
    }

    if (km != "") {
      setKmText("(km)");
    } else {
      setKmText("");
    }
  }, [price, km]);

  const [num, setNum] = useState("");
  const inputPriceFormat = (str) => {
    const comma = (str) => {
      str = String(str);
      return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
    };
    const uncomma = (str) => {
      str = String(str);
      return str.replace(/[^\d]+/g, "");
    };
    return comma(uncomma(str));
  };

  const style2 = {
    display: "flex",
    flexDirection: "column",
  };
  const style3 = {
    fontSize: "24px",
    marginBottom: "12px",
  };
  const style4 = {
    marginBottom: "8px",
  };

  return (
    <>
      {/* buttons */}
      <div className="component--item">
        <div className="component--item__title" style={style3}>
          Button
        </div>
        {/* button-default */}
        <div className="button-form" style={style2}>
          <button type="button" className="btn btn-xl btn--primary">
            텍스트
          </button>
          <button type="button" className="btn btn-xl btn--outline_gray">
            텍스트
          </button>
          <button type="button" className="btn btn-xl btn--disabled">
            텍스트
          </button>
          <button type="button" className="btn btn-lg btn--primary">
            텍스트
          </button>
          <button type="button" className="btn btn-lg btn--outline_black">
            텍스트
          </button>
          <button type="button" className="btn btn-md btn--primary">
            텍스트
          </button>
          <button type="button" className="btn btn-md btn--outline_black">
            텍스트
          </button>
          <button type="button" className="btn btn-md btn--outline_gray">
            텍스트
          </button>
        </div>
        {/* button-cart */}
        <div className="button-form">
          <button type="button" className="btn btn-cart">
            <img src={require("../assets/icons/cart-gr.svg").default} alt="" />
          </button>
          <button type="button" className="btn btn-cart active">
            <img
              src={require("../assets/icons/cart-active.svg").default}
              alt=""
            />
          </button>
        </div>

        {/* button-sns */}
        <div className="button-form">
          <button type="button" className="btn btn-channel"></button>
          <button type="button" className="btn btn-sns btn--kakao"></button>
          <button type="button" className="btn btn-sns btn--facebook"></button>
          <button type="button" className="btn btn-sns btn--naver"></button>
          <button type="button" className="btn btn-sns btn--link"></button>
        </div>
      </div>

      {/* inputs */}
      <div className="component--item">
        <div className="component--item__title" style={style3}>
          Input
        </div>
        {/* default */}
        <div className="input-form" style={style2}>
          <div className="form-field">
            <div className="input-element">
              <input type="text" className="" placeholder="텍스트 입력" />
            </div>
          </div>
          <p className="alert-msg error">에러 메시지입니다.</p>
          <p className="alert-msg success">인증을 완료했습니다 :)</p>
        </div>
        {/* disabled */}
        <div className="input-form disabled" style={style2}>
          <div className="form-field">
            <div className="input-element">
              <input type="text" className="" placeholder="텍스트 입력" />
            </div>
          </div>
          <p className="alert-msg error">에러 메시지입니다.</p>
          <p className="alert-msg success">인증을 완료했습니다 :)</p>
        </div>
        {/* error */}
        <div className="input-form error" style={style2}>
          <div className="form-field">
            <div className="input-element">
              <input type="text" className="" placeholder="텍스트 입력" />
            </div>
          </div>
          <p className="alert-msg error">에러 메시지입니다.</p>
          <p className="alert-msg success">인증을 완료했습니다 :)</p>
        </div>
        {/* success */}
        <div className="input-form success" style={style2}>
          <div className="form-field">
            <div className="input-element">
              <input type="text" className="" placeholder="텍스트 입력" />
            </div>
          </div>
          <p className="alert-msg error">에러 메시지입니다.</p>
          <p className="alert-msg success">인증을 완료했습니다 :)</p>
        </div>
        {/* timer */}
        <div className="input-form timer" style={style2}>
          <div className="form-field">
            <div className="input-element">
              <input type="text" className="" placeholder="텍스트 입력" />
              <button type="button" className="btn-certification">
                인증하기
              </button>
            </div>
          </div>
          <p className="alert-msg timer">3분 00초</p>
          <p className="alert-msg error">에러 메시지입니다.</p>
          <p className="alert-msg success">인증을 완료했습니다 :)</p>
        </div>
        {/* completed */}
        <div className="input-form timer" style={style2}>
          <div className="form-field">
            <div className="input-element">
              <input type="text" className="" placeholder="텍스트 입력" />
              <button type="button" className="btn-certification completed">
                인증하기
              </button>
            </div>
          </div>
          <p className="alert-msg timer">3분 00초</p>
          <p className="alert-msg error">에러 메시지입니다.</p>
          <p className="alert-msg success">인증을 완료했습니다 :)</p>
        </div>
        {/* certified */}
        <div className="input-form success" style={style2}>
          <div className="form-field">
            <div className="input-element">
              <input type="text" className="" placeholder="텍스트 입력" />
              <button type="button" className="btn-certification certified">
                인증완료
              </button>
            </div>
          </div>
          <p className="alert-msg timer">3분 00초</p>
          <p className="alert-msg error">에러 메시지입니다.</p>
          <p className="alert-msg success">인증을 완료했습니다 :)</p>
        </div>
        {/* price */}
        <div className="input-form" style={style2}>
          <div className="form-field">
            <div className="input-element text_notice">
              <input
                type="text"
                className=""
                placeholder="금액 입력(만원)"
                value={price}
                onChange={(e) => setPrice(inputPriceFormat(e.target.value))}
                onKeyUp={priceInputHandler}
              />
              <span className="price-text">{priceText}</span>
            </div>
          </div>
        </div>
        {/* km */}
        <div className="input-form" style={style2}>
          <div className="form-field">
            <div className="input-element text_notice">
              <input
                type="text"
                className=""
                placeholder="주행거리 입력(km)"
                value={km}
                onChange={(e) => setKm(inputPriceFormat(e.target.value))}
                onKeyUp={kmInputHandler}
              />
              <span className="km-text">{kmText}</span>
            </div>
          </div>
        </div>
      </div>

      {/* selects */}
      <div className="component--item">
        <div className="component--item__title" style={style3}>
          Select
        </div>
        {/* dropdown */}
        <div className="select-form" style={style2}>
          <div className="select-element">
            <Select
              options={options}
              // defaultValue={options[0]}
              isClearable={false}
              isSearchable={false}
              closeMenuOnSelect={true}
              styles={customStyles}
              placeholder="은행 선택"
            />
          </div>
        </div>
        {/* search */}
        <div className="select-form" style={style2}>
          <div className="select-element">
            <Select
              options={options2}
              // defaultValue={options[0]}
              isClearable={false}
              isSearchable={true}
              closeMenuOnSelect={false}
              components={{ DropdownIndicator, MenuList }}
              styles={customStyles}
              placeholder="모델명을 입력하세요 (예시 : K3)"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Components;
