/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import QuickSNS from "../components/QuickSNS.js";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import SwiperCore, { Navigation, Pagination } from "swiper";
import axios from "axios";

import Toast from "../components/Toast.js";
import Share from "../components/Share.js";
import * as Common from "../actions/Common.js";
import styles from "./Home.module.scss";

import emptyIcon from "../assets/icons/empty.svg";
import depthIcon from "../assets/icons/depth-sm-gr.svg";
import cartIcon from "../assets/icons/cart-gr.svg";
import shareWhIcon from "../assets/icons/share-wh.svg";
import heartWhIcon from "../assets/icons/heart-wh.svg";
import heartPrIcon from "../assets/icons/pr-full-heart-pr.svg";
import ModalConfirm from "../components/ModalConfirm.js";

import { baseUrl } from "../api";
const BASE_URL = baseUrl();
const MYDATA_URL = "/api/user/member/v1/me";
const BANNER_URL = "/api/user/event/v1/banner";
const PRODUCT_URL = "/api/user/product/v1";
const RECENTVIEW_URL = "/api/user/product/v1/recent_view";
const NOTICE_URL = "/api/user/notice/v1";
const CART_URL = `/api/user/product/v1/cart`;
const FAVORITE_URL = `/api/user/product/v1/favorite`;
const page = 0;
const cSize = 20;
const nSize = 5;

const Home = () => {
  const navigate = useNavigate();

  //* 로그인 상태
  const [loginStatus, setLoginStatus] = useState(false);

  //* API loading state
  const [loading, setLoading] = useState(false);

  /****************************************
   * TODO 초기 세팅
   ****************************************/
  //* Swiper 초기화
  SwiperCore.use([Navigation, Pagination]);

  //* Init API Data
  const [banners, setBanners] = useState([]); // 배너
  const [newItems, setNewItems] = useState([]); // 최근 등록 차량
  const [popularItems, setPopularItems] = useState([]); // 인기 차량
  const [recentviewItems, setRecentviewItems] = useState([]); // 최근 본 차량
  const [noticeItems, setNoticeItems] = useState([]); // 공지사항
  const [favoriteItems, setFavoriteItems] = useState([]); // 찜한 차량
  const [cartItems, setCartItems] = useState([]); // 장바구니 차량

  useEffect(() => {
    fetchData();
  }, [loginStatus]);

  const fetchData = async () => {
    setLoading(false);

    try {
      //* 토큰 상태 확인
      const result = await fetchMyData();

      if (!result) {
        setLoginStatus(false);

        // 최근 본 차량 세팅 (로그아웃)
        fetchRecentDataLogout();
      } else {
        setLoginStatus(true);

        // 장바구니 차량 세팅
        fetchCartData();

        //* 찜한 차량 세팅
        fetchFavoriteData();

        // 최근 본 차량 세팅 (로그인)
        fetchRecentDataLogin();
      }

      //* 배너 세팅
      fetchBannerData();

      //* 최근 등록 차량 세팅
      fetchNewData();

      //* 인기 차량 세팅
      fetchPopularData();

      //* 최근 본 차량 세팅
      // fetchRecentData();

      //* 공지사항 세팅
      fetchNoticeData();

      setLoading(true);
    } catch (error) {
      setLoading(true);
      console.log(error);
    }
  };

  /****************************************
   * TODO 항목별 API 목록
   ****************************************/
  // 토큰 상태 확인
  const fetchMyData = async () => {
    try {
      const response = await axios.get(BASE_URL + MYDATA_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      return response;
    } catch (err) {
      console.error(err.response?.data.message);
      return false;
    }
  };
  // 배너
  const fetchBannerData = async () => {
    try {
      const response = await axios.get(
        BASE_URL + BANNER_URL + `?banner_type=PC`
      );
      setBanners(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  // 최근 등록 차량
  const fetchNewData = async () => {
    try {
      const response = await axios.get(
        BASE_URL +
        PRODUCT_URL +
        `?page=${page}&size=${cSize}&sort=exposedAt,desc&sort=productStatus,asc`
      );
      setNewItems(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  // 인기 차량
  const fetchPopularData = async () => {
    try {
      const response = await axios.get(
        BASE_URL +
        PRODUCT_URL +
        `?page=${page}&size=${cSize}&sort=viewCnt,desc&sort=productStatus,asc`
      );
      setPopularItems(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  // 최근 본 차량(로그인)
  const fetchRecentDataLogin = async () => {
    try {
      const response = await axios.get(
        BASE_URL + RECENTVIEW_URL + `?page=${page}&size=${cSize}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      setRecentviewItems(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  // 최근 본 차량(로그아웃)
  const fetchRecentDataLogout = async () => {
    try {
      const cookies = Common.getCookieJson("chada_recent_view");

      if (cookies != null) {
        const ids = cookies.map((item) => {
          return item.carId;
        });

        const response = await axios.get(
          BASE_URL + RECENTVIEW_URL + `/not_login?&ids=${ids}`
        );

        setRecentviewItems(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // 장바구니 담기
  const fetchCartAdd = async (target, id) => {
    try {
      if (target === "off") {
        //? cart 추가
        const response = await axios.post(BASE_URL + CART_URL, [id], {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });

        handleToast("장바구니에 등록 되었습니다.");
      } else if (target === "on") {
        //? cart 해제
        const response = await axios.delete(BASE_URL + CART_URL, {
          data: [id],
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });

        handleToast("장바구니에서 등록 해제 되었습니다.");
      }

      fetchCartData();
    } catch (err) {
      handleToast(err.response?.data.message);
    }
  };
  // 장바구니 차량
  const fetchCartData = async () => {
    try {
      const response = await axios.get(BASE_URL + CART_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      setCartItems(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  // 찜한 차량
  const fetchFavoriteData = async () => {
    try {
      const response = await axios.get(BASE_URL + FAVORITE_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      setFavoriteItems(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  // 찜하기 설정 / 찜하기 해제
  const fetchFavoriteSet = async (target, id) => {
    try {
      if (target === "off") {
        //? favorite 추가
        const response = await axios.post(BASE_URL + FAVORITE_URL, [id], {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });

        handleToast("찜한차량으로 등록 되었습니다.");
      } else if (target === "on") {
        //? favorite 해제
        const response = await axios.delete(BASE_URL + FAVORITE_URL, {
          data: [id],
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });

        handleToast("찜한차량이 등록 해제 되었습니다.");
      }

      fetchFavoriteData();
    } catch (err) {
      console.log(err);
    }
  };
  // 공지사항
  const fetchNoticeData = async () => {
    try {
      const response = await axios.get(
        BASE_URL + NOTICE_URL + `?page=${page}&size=${nSize}`
      );
      setNoticeItems(response.data.data); // 공지사항
    } catch (error) {
      console.log(error);
    }
  };

  /****************************************
   * TODO 이벤트 핸들러
   ****************************************/
  // 공유하기 버튼 클릭
  const handleShare = async (carId, carData) => {
    setShareModal({
      open: true,
      dim: true,
      carId: carId,
      carData: carData,
    })
  }

  //* 찜하기 버튼 클릭
  const handleFavorite = async (e, id) => {
    e.preventDefault();

    if (!loginStatus) {
      setModal({
        open: true,
        dim: true,
        title: "로그인이 필요합니다.",
        message: "지금 로그인 하시겠습니까?",
        notxt: "취소",
        oktxt: "로그인 하기",
        callback2: () => {
          navigate("/login");
        },
      });
      return;
    }

    const isFavorite = e.currentTarget.dataset.favorite;
    fetchFavoriteSet(isFavorite, id);
  };
  //* 장바구니 버튼 클릭
  const handleCart = async (e, id) => {
    e.preventDefault();

    if (!loginStatus) {
      setModal({
        open: true,
        dim: true,
        title: "로그인이 필요합니다.",
        message: "지금 로그인 하시겠습니까?",
        notxt: "취소",
        oktxt: "로그인 하기",
        callback2: () => {
          navigate("/login");
        },
      });
      return;
    }

    const isCart = e.currentTarget.dataset.cart;
    fetchCartAdd(isCart, id);
  };
  /****************************************
   * TODO 모달 & 토스트 팝업 상태
   ****************************************/
  //* 모달
  const [modal, setModal] = useState({
    open: false,
    dim: true,
    title: "",
    message: "",
    notxt: "",
    oktxt: "확인",
    callback1: false,
    callback2: false,
  });

  //* 공유 모달
  const [shareModal, setShareModal] = useState({
    open: false,
    dim: true,
    carId: null,
    carData: null,
  });

  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = (txt) => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);

  return (
    <>
      {!loading ? (
        <div className="loader"></div>
      ) : (
        <div className={styles.home}>
          {/* 메인 배너 */}
          <section className={`${styles.section} ${styles.sc01}`}>
            <Swiper
              className={styles.mainBanner}
              spaceBetween={0}
              slidesPerView={1}
              loop={true}
              // navigation
              pagination={{
                clickable: true,
                renderBullet: (index, className) => {
                  return (
                    '<span class="bullet-primary ' +
                    className +
                    '">' +
                    "</span>"
                  );
                },
              }}
            >
              {banners.map((banner) => {
                return (
                  <SwiperSlide
                    key={banner.id}
                    onClick={() => {
                      window.open(`${banner.linkUrl}`, "_blank");
                    }}
                  >
                    <img src={banner.imgUrl} alt="" />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </section>
          {/* 최근 등록 차량 */}
          <section
            className={`${styles.section} ${styles.sc02}` + " global-width"}
          >
            <div className={styles.inner}>
              <div className={styles.title}>
                <h3>최근 등록 차량</h3>
              </div>
              <div className={styles.content}>
                <Swiper
                  className={styles.itemList}
                  spaceBetween={24}
                  slidesPerView={3}
                  loop={false}
                  navigation
                  pagination={{
                    clickable: true,
                  }}
                >
                  {newItems.map((item, idx) => {
                    return (
                      <SwiperSlide key={item.id}>
                        <div className={styles.item}>
                          <Link
                            to={`/product/${item.id}`}
                            className={styles.img}
                          >
                            <img src={item.mainImgUrl} alt="" />
                            <div className={styles.category}>
                              {item.newYn === "Y" && (
                                <span className={styles.new}>New</span>
                              )}
                              {item.productYn === "Y" && (
                                <span className={styles.commerce}>상품화</span>
                              )}
                              {item.productStatus === "BUY_ING" && (
                                <span className={styles.purchasing}>
                                  구매 진행 중
                                </span>
                              )}
                            </div>
                            <div className={styles.service}>
                              <button type="button" className={styles.share} onClick={(e) => {
                                e.preventDefault();

                                handleShare(item.id, item);
                              }}>
                                <img src={shareWhIcon} alt="" />
                              </button>
                              {favoriteItems.some((el) => el.id === item.id) ? (
                                <button
                                  type="button"
                                  className={styles.favorite}
                                  onClick={(e) => {
                                    handleFavorite(e, item.id);
                                  }}
                                  data-favorite="on"
                                >
                                  <img src={heartPrIcon} alt="" />
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className={styles.favorite}
                                  onClick={(e) => {
                                    handleFavorite(e, item.id);
                                  }}
                                  data-favorite="off"
                                >
                                  <img src={heartWhIcon} alt="" />
                                </button>
                              )}
                            </div>
                          </Link>
                          <div className={styles.txt}>
                            <div className={styles.tit}>
                              <Link to={`/product/${item.id}`}>
                                {item.manufacturerName +
                                  " " +
                                  item.modelName +
                                  " (" +
                                  item.carNumber +
                                  ")"}
                              </Link>
                            </div>
                            <div className={styles.sub}>
                              {item.productedAt && (
                                <span>
                                  {String(item.carMadedYear).slice(2) +
                                    "." +
                                    String(item.carMadedMonth) +
                                    " (" +
                                    String(item.carMadedYear).slice(2) +
                                    "년형)"}
                                </span>
                              )}
                              {item.mileage && (
                                <span>
                                  {Common.commaFormat(item.mileage)}
                                  km
                                </span>
                              )}
                              {item.fuelType && (
                                <span>
                                  {item.fuelType == "GAS"
                                    ? "가솔린"
                                    : item.fuelType == "DIESEL"
                                      ? "디젤"
                                      : item.fuelType == "LPG"
                                        ? "LPG"
                                        : item.fuelType == "GAS_LPG"
                                          ? "가솔린(LPG겸용)"
                                          : item.fuelType == "GAS_CNG"
                                            ? "가솔린(CNG겸용)"
                                            : item.fuelType == "GAS_HYBRID"
                                              ? "HEV(하이브리드)"
                                              : item.fuelType == "ELECTRIC"
                                                ? "전기"
                                                : item.fuelType == "HYDROGEN"
                                                  ? "수소"
                                                  : null}
                                </span>
                              )}
                            </div>
                            <div className={styles.desc}>
                              {item.majorOptions &&
                                item.majorOptions.map((opt, idx) => {
                                  return <span key={opt.id}>{opt.nameKr}</span>;
                                })}
                            </div>
                            <div className={styles.summary}>
                              {loginStatus === true ? (
                                <span className={styles.price}>
                                  {Common.commaFormat(
                                    String(item.carPrice.price).slice(0, -4)
                                  )}
                                  만원
                                </span>
                              ) : (
                                <div className="hidePrice2">
                                  <p className="hp1">금액 비공개</p>
                                  <p className="hp2">
                                    로그인 후 금액이 노출됩니다.
                                  </p>
                                </div>
                              )}
                              {cartItems.some((el) => el.id === item.id) ? (
                                <button
                                  type="button"
                                  className={`${styles.optionItem} ${styles.cart}`}
                                  onClick={(e) => {
                                    handleCart(e, item.id);
                                  }}
                                  data-cart="on"
                                ></button>
                              ) : (
                                <button
                                  type="button"
                                  className={`${styles.optionItem} ${styles.cart}`}
                                  onClick={(e) => {
                                    handleCart(e, item.id);
                                  }}
                                  data-cart="off"
                                ></button>
                              )}
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </section>
          {/* 인기 차량 */}
          <section
            className={`${styles.section} ${styles.sc03}` + " global-width"}
          >
            <div className={styles.inner}>
              <div className={styles.title}>
                <h3>인기 차량</h3>
              </div>
              <div className={styles.content}>
                <Swiper
                  className={styles.itemList}
                  spaceBetween={24}
                  slidesPerView={3}
                  loop={false}
                  navigation
                  pagination={{
                    clickable: true,
                  }}
                >
                  {popularItems.map((item, idx) => {
                    return (
                      <SwiperSlide key={item.id}>
                        <div className={styles.item}>
                          <Link
                            to={`/product/${item.id}`}
                            className={styles.img}
                          >
                            <img src={item.mainImgUrl} alt="" />
                            <div className={styles.category}>
                              {item.newYn === "Y" && (
                                <span className={styles.new}>New</span>
                              )}
                              {item.productYn === "Y" && (
                                <span className={styles.commerce}>상품화</span>
                              )}
                              {item.productStatus === "BUY_ING" && (
                                <span className={styles.purchasing}>
                                  구매 진행 중
                                </span>
                              )}
                            </div>
                            <div className={styles.service}>
                              <button className={styles.share} onClick={(e) => {
                                e.preventDefault();

                                handleShare(item.id, item);
                              }}>
                                <img src={shareWhIcon} alt="" />
                              </button>
                              {favoriteItems.some((el) => el.id === item.id) ? (
                                <button
                                  type="button"
                                  className={styles.favorite}
                                  onClick={(e) => {
                                    handleFavorite(e, item.id);
                                  }}
                                  data-favorite="on"
                                >
                                  <img src={heartPrIcon} alt="" />
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className={styles.favorite}
                                  onClick={(e) => {
                                    handleFavorite(e, item.id);
                                  }}
                                  data-favorite="off"
                                >
                                  <img src={heartWhIcon} alt="" />
                                </button>
                              )}
                            </div>
                          </Link>
                          <div className={styles.txt}>
                            <div className={styles.tit}>
                              <Link to={`/product/${item.id}`}>
                                {item.manufacturerName +
                                  " " +
                                  item.modelName +
                                  " (" +
                                  item.carNumber +
                                  ")"}
                              </Link>
                            </div>
                            <div className={styles.sub}>
                              {item.productedAt && (
                                <span>
                                  {String(item.carMadedYear).slice(2) +
                                    "." +
                                    String(item.carMadedMonth) +
                                    " (" +
                                    String(item.carMadedYear).slice(2) +
                                    "년형)"}
                                </span>
                              )}
                              {item.mileage && (
                                <span>
                                  {Common.commaFormat(item.mileage)}km
                                </span>
                              )}
                              {item.fuelType && (
                                <span>
                                  {item.fuelType == "GAS"
                                    ? "가솔린"
                                    : item.fuelType == "DIESEL"
                                      ? "디젤"
                                      : item.fuelType == "LPG"
                                        ? "LPG"
                                        : item.fuelType == "GAS_LPG"
                                          ? "가솔린(LPG겸용)"
                                          : item.fuelType == "GAS_CNG"
                                            ? "가솔린(CNG겸용)"
                                            : item.fuelType == "GAS_HYBRID"
                                              ? "HEV(하이브리드)"
                                              : item.fuelType == "ELECTRIC"
                                                ? "전기"
                                                : item.fuelType == "HYDROGEN"
                                                  ? "수소"
                                                  : null}
                                </span>
                              )}
                            </div>
                            <div className={styles.desc}>
                              {item.majorOptions &&
                                item.majorOptions.map((opt, idx) => {
                                  return <span key={opt.id}>{opt.nameKr}</span>;
                                })}
                            </div>
                            <div className={styles.summary}>
                              {loginStatus === true ? (
                                <span className={styles.price}>
                                  {Common.commaFormat(
                                    String(item.carPrice.price).slice(0, -4)
                                  )}
                                  만원
                                </span>
                              ) : (
                                <div className="hidePrice2">
                                  <p className="hp1">금액 비공개</p>
                                  <p className="hp2">
                                    로그인 후 금액이 노출됩니다.
                                  </p>
                                </div>
                              )}
                              {cartItems.some((el) => el.id === item.id) ? (
                                <button
                                  type="button"
                                  className={`${styles.optionItem} ${styles.cart}`}
                                  onClick={(e) => {
                                    handleCart(e, item.id);
                                  }}
                                  data-cart="on"
                                ></button>
                              ) : (
                                <button
                                  type="button"
                                  className={`${styles.optionItem} ${styles.cart}`}
                                  onClick={(e) => {
                                    handleCart(e, item.id);
                                  }}
                                  data-cart="off"
                                ></button>
                              )}
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </section>
          {/* 최근 본 차량 */}
          {recentviewItems.length != 0 ? (
            <section
              className={`${styles.section} ${styles.sc04}` + " global-width"}
            >
              <div className={styles.inner}>
                <div className={styles.title}>
                  <h3>최근 본 차량</h3>
                </div>
                <div className={styles.content}>
                  <Swiper
                    className={styles.itemList}
                    spaceBetween={24}
                    slidesPerView={3}
                    loop={false}
                    navigation
                    pagination={{
                      clickable: true,
                    }}
                  >
                    {recentviewItems.map((item, idx) => {
                      return (
                        <SwiperSlide key={item.id}>
                          <div className={styles.item}>
                            <Link
                              to={`/product/${item.id}`}
                              className={styles.img}
                            >
                              <img src={item.mainImgUrl} alt="" />
                              <div className={styles.category}>
                                {item.newYn === "Y" && (
                                  <span className={styles.new}>New</span>
                                )}
                                {item.productYn === "Y" && (
                                  <span className={styles.commerce}>
                                    상품화
                                  </span>
                                )}
                                {item.productStatus === "BUY_ING" && (
                                  <span className={styles.purchasing}>
                                    구매 진행 중
                                  </span>
                                )}
                              </div>
                              <div className={styles.service}>
                                <button className={styles.share} onClick={(e) => {
                                  e.preventDefault();

                                  handleShare(item.id, item);
                                }}>
                                  <img src={shareWhIcon} alt="" />
                                </button>
                                {favoriteItems.some(
                                  (el) => el.id === item.id
                                ) ? (
                                  <button
                                    type="button"
                                    className={styles.favorite}
                                    onClick={(e) => {
                                      handleFavorite(e, item.id);
                                    }}
                                    data-favorite="on"
                                  >
                                    <img src={heartPrIcon} alt="" />
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className={styles.favorite}
                                    onClick={(e) => {
                                      handleFavorite(e, item.id);
                                    }}
                                    data-favorite="off"
                                  >
                                    <img src={heartWhIcon} alt="" />
                                  </button>
                                )}
                              </div>
                            </Link>
                            <div className={styles.txt}>
                              <div className={styles.tit}>
                                <Link to={`/product/${item.id}`}>
                                  {item.manufacturerName +
                                    " " +
                                    item.modelName +
                                    " (" +
                                    item.carNumber +
                                    ")"}
                                </Link>
                              </div>
                              <div className={styles.sub}>
                                {item.productedAt && (
                                  <span>
                                    {String(item.carMadedYear).slice(2) +
                                      "." +
                                      String(item.carMadedMonth) +
                                      " (" +
                                      String(item.carMadedYear).slice(2) +
                                      "년형)"}
                                  </span>
                                )}
                                {item.mileage && (
                                  <span>
                                    {Common.commaFormat(item.mileage)}km
                                  </span>
                                )}
                                {item.fuelType && (
                                  <span>
                                    {item.fuelType == "GAS"
                                      ? "가솔린"
                                      : item.fuelType == "DIESEL"
                                        ? "디젤"
                                        : item.fuelType == "LPG"
                                          ? "LPG"
                                          : item.fuelType == "GAS_LPG"
                                            ? "가솔린(LPG겸용)"
                                            : item.fuelType == "GAS_CNG"
                                              ? "가솔린(CNG겸용)"
                                              : item.fuelType == "GAS_HYBRID"
                                                ? "HEV(하이브리드)"
                                                : item.fuelType == "ELECTRIC"
                                                  ? "전기"
                                                  : item.fuelType == "HYDROGEN"
                                                    ? "수소"
                                                    : null}
                                  </span>
                                )}
                              </div>
                              <div className={styles.desc}>
                                {item.majorOptions &&
                                  item.majorOptions.map((opt, idx) => {
                                    return (
                                      <span key={opt.id}>{opt.nameKr}</span>
                                    );
                                  })}
                              </div>
                              <div className={styles.summary}>
                                {loginStatus === true ? (
                                  <span className={styles.price}>
                                    {Common.commaFormat(
                                      String(item.carPrice.price).slice(0, -4)
                                    )}
                                    만원
                                  </span>
                                ) : (
                                  <div className="hidePrice2">
                                    <p className="hp1">금액 비공개</p>
                                    <p className="hp2">
                                      로그인 후 금액이 노출됩니다.
                                    </p>
                                  </div>
                                )}
                                {cartItems.some((el) => el.id === item.id) ? (
                                  <button
                                    type="button"
                                    className={`${styles.optionItem} ${styles.cart}`}
                                    onClick={(e) => {
                                      handleCart(e, item.id);
                                    }}
                                    data-cart="on"
                                  ></button>
                                ) : (
                                  <button
                                    type="button"
                                    className={`${styles.optionItem} ${styles.cart}`}
                                    onClick={(e) => {
                                      handleCart(e, item.id);
                                    }}
                                    data-cart="off"
                                  ></button>
                                )}
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
            </section>
          ) : null}
          {/* 공지사항 */}
          <section
            className={`${styles.section} ${styles.sc05}` + " global-width"}
          >
            <div className={styles.inner}>
              <div className={styles.title}>
                <h3>공지사항</h3>
                <Link to="/contact">
                  더보기 <img src={depthIcon} alt="" />
                </Link>
              </div>
              <div className={styles.itemWrapper}>
                <div className={styles.contHeader}>
                  <div className={styles.item}>
                    <div className={styles.tdContent}>
                      <span className={styles.tdTit}>제목</span>
                    </div>
                    <div className={styles.tdDate}>
                      <span className={styles.tdTit}>등록</span>
                    </div>
                  </div>
                </div>
                {noticeItems.length === 0 ? (
                  <div className={styles.nonItems}>
                    <div className={styles.icon}>
                      <img src={emptyIcon} alt="" />
                    </div>
                    <p>게시글이 없습니다.</p>
                  </div>
                ) : (
                  <div className={styles.itemCont}>
                    <div className={styles.itemList}>
                      {noticeItems.map((item, idx) => {
                        return (
                          <div className={styles.item} key={item.id}>
                            <div className={styles.tdContent}>
                              <Link
                                to={`/postDetail/${item.id}`}
                                className={styles.mainTxt}
                              >
                                {item.title}
                              </Link>
                            </div>
                            <div className={styles.tdDate}>
                              <div className={styles.descTxt}>
                                {item.createdAt
                                  .split("T")[0]
                                  .replaceAll("-", ".")}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      )}

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />

      {/* 공유하기 */}
      {shareModal.open &&
        <Share dim={shareModal.dim} setModal={setShareModal} carId={shareModal.carId} carData={shareModal.carData} />
      }

      {/* SNS - 카카오 */}
      <QuickSNS />

      {/* 로그인 모달 */}
      {modal.open && (
        <ModalConfirm
          dim={modal.dim}
          setModal={setModal}
          title={modal.title}
          message={modal.message}
          notxt={modal.notxt}
          oktxt={modal.oktxt}
          callback1={modal.callback1}
          callback2={modal.callback2}
        />
      )}
    </>
  );
};

export default Home;
