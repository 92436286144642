/* eslint-disable */
import React, { useState, useEffect } from "react";

import styles from "./QuickSNS.module.scss";

import snsIcon from "../assets/icons/button-channel.svg";

const QuickSNS = () => {
  return (
    <>
      <div className={styles.quickSNS}>
        <a href="http://pf.kakao.com/_DBpeb" target="_blank">
          <img src={snsIcon} alt="" />
        </a>
      </div>
    </>
  );
};

export default QuickSNS;
