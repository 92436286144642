/* eslint-disable */
import React from "react";
import DaumPostcode from "react-daum-postcode";
import { translate } from "react-range/lib/utils";

import styles from "./DaumPost.module.scss";

const DaumPost = ({ onClose, setAddress, setAddressPost, setDaumData }) => {
  // 우편번호 검색 후 주소 클릭 시 실행될 함수, data callback 용
  const handlePostCode = (data) => {
    let fullAddress = data.address;
    let extraAddress = "";

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    // console.log(data);
    // console.log(fullAddress);
    // console.log(data.zonecode);

    onClose();
    setAddress(fullAddress);
    setAddressPost(data.zonecode);
    setDaumData(data);
  };

  const postCodeStyle = {
    display: "block",
    position: "absolute",
    bottom: "0",
    left: "50%",
    transform: "translateX(-50%)",
    width: "100%",
    height: "600px",
  };

  return (
    <>
      <div className={`${styles.modal} ${styles.modalDaumPost}`}>
        <div className={`${styles.wrapper} ${styles.dim}`}>
          <div className={styles.container}>
            <button
              type="button"
              onClick={() => {
                onClose();
              }}
              className={styles.btn_close}
            ></button>
            <DaumPostcode style={postCodeStyle} onComplete={handlePostCode} />
          </div>
        </div>
      </div>
    </>
  );
};

export default DaumPost;
