/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import * as Common from "../actions/Common.js";

import Toast from "../components/Toast.js";
import ModalConfirm from "../components/ModalConfirm.js";
import styles from "./Favorite.module.scss";

import corpLogoImage from "../assets/images/autoc_logo.png";
import deleteIcon from "../assets/icons/close-2.svg";
import emptyIcon from "../assets/icons/empty.svg";

import { baseUrl } from "../api";
const BASE_URL = baseUrl();
const MYDATA_URL = "/api/user/member/v1/me";
const CART_URL = "/api/user/product/v1/cart";
const FAVORITE_URL = `/api/user/product/v1/favorite`;
const BUYREQUEST_URL = "/api/user/deal/v1/buy_request";

const Favorite = () => {
  const navigate = useNavigate();

  //* 로그인 상태
  const [loginStatus, setLoginStatus] = useState(false);

  //* API loading state
  const [loading, setLoading] = useState(true);

  /****************************************
   * TODO 초기 세팅
   ****************************************/
  //* Init API Data
  const [favoriteItems, setFavoriteItems] = useState([]); // 찜한 차량
  const [corpList, setCorpList] = useState([]); // 셀러 목록

  useEffect(() => {
    if (loginStatus === false) {
      fetchData();
    }
  }, [loginStatus]);

  const fetchData = async () => {
    try {
      //* 토큰 상태 확인
      const result = await fetchMyData();

      if (!result) {
        setLoginStatus(false);

        setModal({
          open: true,
          dim: true,
          title: "로그인이 필요합니다.",
          message: "지금 로그인 하시겠습니까?",
          notxt: "취소",
          oktxt: "로그인 하기",
          callback1: () => {
            navigate(-1);
          },
          callback2: () => {
            navigate("/login");
          },
        });
      } else {
        setLoginStatus(true);

        //* 찜한 차량 세팅
        fetchFavoriteData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  /****************************************
   * TODO 항목별 API 목록
   ****************************************/
  // 토큰 상태 확인
  const fetchMyData = async () => {
    try {
      const response = await axios.get(BASE_URL + MYDATA_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      return response;
    } catch (err) {
      console.error(err.response?.data.message);
      return false;
    }
  };
  // 찜한 차량
  const fetchFavoriteData = async () => {
    setLoading(true);

    try {
      const response = await axios.get(BASE_URL + FAVORITE_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      setFavoriteItems(response.data.data);

      // 셀러 목록 세팅
      const corpList = response.data.data.filter(
        (arr, index, callback) =>
          index ===
          callback.findIndex((t) => t.seller.memberId === arr.seller.memberId)
      );
      setCorpList(corpList);
      setLoading(false);
    } catch (err) {
      if (err.response?.status === 401) {
        setModal({
          open: true,
          dim: true,
          title: "세션이 만료되었습니다.",
          message: "로그인 화면으로 이동합니다.",
          notxt: "",
          oktxt: "확인",
          callback2: () => {
            navigate("/logout");
          },
        });
        return;
      } else {
        handleToast(err.response?.data.message);
        setLoading(false);
      }
    }
  };
  // 찜하기 해제
  const fetchRemove = async (id) => {
    try {
      const response = await axios.delete(BASE_URL + FAVORITE_URL, {
        data: id,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      fetchFavoriteData();
    } catch (err) {
      console.log(err);
    }
  };
  // 장바구니 담기
  const fetchCartAdd = async (ids) => {
    try {
      const response = await axios.post(BASE_URL + CART_URL, ids, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      handleToast(response.data.message);
    } catch (err) {
      handleToast(err.response?.data.message);
    }
  };
  // 구매 요청
  const fetchBuyRequest = async (ids) => {
    try {
      const response = await axios.post(
        BASE_URL + BUYREQUEST_URL,
        { carIds: ids },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      return true;
    } catch (err) {
      handleToast(err.response?.data.message);
      return false;
    }
  };

  /****************************************
   * TODO 이벤트 핸들러
   ****************************************/
  //* 찜한차량 item remove 클릭
  const handleRemoveItem = async (id) => {
    setModal({
      open: true,
      dim: false,
      title: "선택한 상품을 삭제하시겠습니까?",
      message: "",
      notxt: "취소",
      oktxt: "확인",
      callback2: () => {
        fetchRemove([id]);
      },
    });
  };

  const handleRemoveGroup = async (id) => {
    if (checkItems.length === 0) {
      setModal({
        open: true,
        dim: false,
        title: "선택하신 제품이 없습니다.",
        message: "",
        notxt: "",
        oktxt: "확인",
        callback2: () => {
          null;
        },
      });
      return;
    }

    const ids = checkItems.join(",");
    setModal({
      open: true,
      dim: false,
      title: "선택한 상품들을 삭제하시겠습니까?",
      message: "",
      notxt: "취소",
      oktxt: "확인",
      callback2: () => {
        fetchRemove(checkItems);
      },
    });
  };

  const handleCart = async () => {
    if (checkItems.length === 0) {
      setModal({
        open: true,
        dim: false,
        title: "선택하신 제품이 없습니다.",
        message: "",
        notxt: "",
        oktxt: "확인",
        callback2: () => {
          null;
        },
      });
      return;
    }

    const ids = checkItems.join(",");
    setModal({
      open: true,
      dim: false,
      title: "선택한 상품들을 장바구니에 담으시겠습니까?",
      message: "",
      notxt: "취소",
      oktxt: "확인",
      callback2: () => {
        fetchCartAdd(checkItems);
      },
    });
  };

  const handleBuyRequest = async () => {
    if (checkItems.length === 0) {
      setModal({
        open: true,
        dim: false,
        title: "선택하신 제품이 없습니다.",
        message: "",
        notxt: "",
        oktxt: "확인",
        callback2: () => {
          null;
        },
      });
      return;
    }

    // 구매불가 상품 판별
    const result = isDisabledItems();
    if (result === false) {
      setModal({
        open: true,
        dim: false,
        title: "선택한 상품 중 구매 불가 상품이 있습니다.",
        message: "",
        notxt: "",
        oktxt: "확인",
        callback2: () => {
          null;
        },
      });
      return;
    }

    const ids = checkItems.join(",");
    setModal({
      open: true,
      dim: false,
      title: "선택한 상품들을 구매요청 하시겠습니까?",
      message: "",
      notxt: "취소",
      oktxt: "확인",
      callback2: async () => {
        const result = await fetchBuyRequest(checkItems);

        if (result === null || result === undefined) {
          return;
        }
        if (result) {
          fetchRemove(checkItems);
          navigate("/mypage", {
            state: {
              tabIndex: 1,
            },
          });
        }
      },
    });
  };

  /****************************************
   * TODO 공용 함수
   ****************************************/
  // 선택된 상품 중 "구매불가" 항목이 있는지 체크
  const isDisabledItems = () => {
    const difference = favoriteItems.filter((value) =>
      checkItems.includes(value.id)
    );
    const result = difference.filter((value) => {
      return value.productStatus !== "EXPOSED";
    });

    // 구매불가 상품이 있는 경우 return false
    if (result.length > 0) {
      return false;
    }
    return true;
  };

  //* 선택된 체크박스 state
  const [checkItems, setCheckItems] = useState([]);

  // 개별 선택
  function checkHandler(checked, sellerId, id) {
    //* 개별 Checkbox 선택, sellerId 그룹의 모든 체크박스가 true인 경우 그룹 체크박스 true/false
    // 선택된 아이템이 속한 셀러 id값의 모든 체크박스
    const selectedEls = document.querySelectorAll(
      `input[data-seller="${sellerId}"]`
    );
    // 해당 sellerId의 모든 상품 checked가 true인/false 경우 셀러 전체 checked 변환
    const arr = Array.prototype.slice.call(selectedEls); // NodeList를 Array로 변환
    const result = arr.every((v) => v.checked === true); // checked가 true인 것만 반환 : true/false
    const selectedSeller = document.querySelectorAll(
      `input[data-seller-all="${sellerId}"]`
    );
    if (result === true) {
      selectedSeller[0].checked = true;
    } else if (result === false) {
      selectedSeller[0].checked = false;
    }

    //* 개별 Checkbox state 처리
    if (checked) {
      setCheckItems([...checkItems, id]);
    } else {
      setCheckItems(checkItems.filter((o) => o !== id));
    }
  }
  // 그룹별 선택
  function checkGroupHandler(checked, sellerId) {
    //* 그룹 Checkbox 선택, sellerId 그룹 내 모든 체크박스를 true/false
    const selectedEls = document.querySelectorAll(
      `input[data-seller="${sellerId}"]`
    );
    const arr = Array.prototype.slice.call(selectedEls);

    // true/false 여부에 따라 배열 setState
    if (checked) {
      const ids = arr.map((item) => {
        item.checked = true;
        return Number(item.dataset.id);
      });

      // 업데이트할 배열 중복 제거 처리
      const newArr = [...checkItems, ...ids];
      const set = new Set(newArr);
      const uniqueArr = [...set];

      setCheckItems(uniqueArr);
    } else {
      const ids = arr.map((item) => {
        item.checked = false;
        return Number(item.dataset.id);
      });
      const difference = checkItems.filter((id) => !ids.includes(id)); // checkItems 내에서 ids를 걸러냄

      setCheckItems(difference);
    }
  }
  // 전체 선택
  function checkAllHandler(checked) {
    if (checked) {
      const ids = [];
      favoriteItems.forEach((v) => {
        // if (v.productStatus === "EXPOSED") {
        ids.push(v.id);
        // }
      });
      setCheckItems(ids); // state 채우기

      // 모든 체크박스 + 그룹 체크박스 true
      corpList.map((item) => {
        const selectedSeller = document.querySelectorAll(
          `input[data-seller-all="${item.seller.memberId}"]`
        );
        selectedSeller[0].checked = true;
      });
    } else {
      setCheckItems([]); // state 비우기

      // 모든 체크박스 + 그룹 체크박스 false
      corpList.map((item) => {
        const selectedSeller = document.querySelectorAll(
          `input[data-seller-all="${item.seller.memberId}"]`
        );
        selectedSeller[0].checked = false;
      });
    }
  }

  //* 선택된 제품 합산 금액 계산
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    let total = 0;

    favoriteItems.map((item) => {
      checkItems.filter(
        (el) => el === item.id && (total += item.carPrice.price)
      );
    });

    setTotalPrice(total);
  }, [checkItems]);

  /****************************************
   * TODO 토스트 & 모달 상태
   ****************************************/
  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = (txt) => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);
  //* 모달
  const [modal, setModal] = useState({
    open: false,
    dim: true,
    title: "",
    message: "",
    notxt: "취소",
    oktxt: "확인",
    callback1: false,
    callback2: false,
  });

  return (
    <>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <div className={`${styles.favorite}` + " global-width"}>
          <div className={styles.header}>
            <h1>찜한 차량</h1>
          </div>
          <div className={styles.contents}>
            {favoriteItems.length === 0 ? (
              <div className={styles.nonItems}>
                <div className={styles.icon}>
                  <img src={emptyIcon} alt="" />
                </div>
                <p>찜한 차량이 없습니다.</p>
              </div>
            ) : (
              <div className={styles.itemWrapper}>
                <div className={styles.contHeader}>
                  <div className={styles.item}>
                    <div className={styles.chk}>
                      <div className="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            onChange={(e) => checkAllHandler(e.target.checked)}
                          />
                          <div></div>
                          <span>전체선택</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.contList}>
                  {corpList.map((corp) => {
                    return (
                      <div className={styles.itemCont} key={corp.seller.id}>
                        <div className={styles.itemHeader}>
                          <div className={styles.item}>
                            <div className={styles.chk}>
                              <div className="checkbox">
                                <label>
                                  <input
                                    type="checkbox"
                                    onChange={(e) => {
                                      checkGroupHandler(
                                        e.target.checked,
                                        corp.seller.memberId
                                      );
                                    }}
                                    data-seller-all={corp.seller.memberId}
                                  />
                                  <div></div>
                                  <span>
                                    <span className={styles.corpTit}>
                                      {corp.seller.corpName}
                                    </span>
                                    {/* <span className={styles.corpLogo}>
                                      <img src={corpLogoImage} alt="" />
                                    </span> */}
                                  </span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.itemList}>
                          {favoriteItems.map((item) => {
                            return item.seller.memberId ===
                              corp.seller.memberId ? (
                              <div
                                className={
                                  item.productStatus === "EXPOSED"
                                    ? `${styles.item}`
                                    : `${styles.item} ${styles.disabled}`
                                }
                                key={item.id}
                              >
                                <div className={styles.chk}>
                                  <div className="checkbox">
                                    <label>
                                      <input
                                        type="checkbox"
                                        onChange={(e) =>
                                          checkHandler(
                                            e.target.checked,
                                            corp.seller.memberId,
                                            item.id
                                          )
                                        }
                                        checked={
                                          checkItems.indexOf(item.id) >= 0
                                            ? true
                                            : false
                                        }
                                        data-seller={item.seller.memberId}
                                        data-id={item.id}
                                      />
                                      <div></div>
                                      <span></span>
                                    </label>
                                  </div>
                                </div>
                                <div className={styles.content}>
                                  <button
                                    type="button"
                                    className={styles.btnDeleteItem}
                                    onClick={() => {
                                      handleRemoveItem(item.id);
                                    }}
                                  >
                                    <img src={deleteIcon} alt="" />
                                  </button>
                                  <div className={styles.img}>
                                    <Link
                                      to={`/product/${item.id}`}
                                      className={styles.img}
                                    >
                                      <img src={item.mainImgUrl} alt="" />
                                    </Link>
                                  </div>
                                  <div className={styles.desc}>
                                    <div className={styles.tags}>
                                      {item.newYn === "Y" ? (
                                        <span
                                          className={`${styles.tag} ${styles.new}`}
                                        >
                                          New
                                        </span>
                                      ) : null}
                                      {item.productYn === "Y" ? (
                                        <span
                                          className={`${styles.tag} ${styles.commercialized}`}
                                        >
                                          상품화
                                        </span>
                                      ) : null}
                                      {item.productStatus === "BUY_ING" ? (
                                        <span
                                          className={`${styles.tag} ${styles.purchasing}`}
                                        >
                                          구매 진행 중
                                        </span>
                                      ) : null}
                                    </div>
                                    <div className={styles.title}>
                                      <span>
                                        <Link to={`/product/${item.id}`}>
                                          {item.manufacturerName +
                                            " " +
                                            item.modelName}
                                        </Link>
                                      </span>
                                    </div>
                                    <div className={styles.detail}>
                                      <span>
                                        {String(item.carMadedYear).slice(2) +
                                          "년" +
                                          String(item.carMadedMonth) +
                                          "월 (" +
                                          String(item.carMadedYear).slice(2) +
                                          "년형)"}
                                      </span>
                                      <span>
                                        {Common.commaFormat(item.mileage)}km
                                      </span>
                                      <span>
                                        {item.fuelType === "GAS"
                                          ? "가솔린"
                                          : item.fuelType === "DIESEL"
                                          ? "디젤"
                                          : item.fuelType === "LPG"
                                          ? "LPG"
                                          : item.fuelType === "GAS_LPG"
                                          ? "가솔린(LPG겸용)"
                                          : item.fuelType === "GAS_CNG"
                                          ? "가솔린(CNG겸용)"
                                          : item.fuelType === "GAS_HYBRID"
                                          ? "HEV(하이브리드)"
                                          : item.fuelType === "ELECTRIC"
                                          ? "전기"
                                          : item.fuelType === "HYDROGEN"
                                          ? "수소"
                                          : null}
                                      </span>
                                      <span>
                                        {item.accident === "NONE"
                                          ? "무사고"
                                          : item.accident === "SIMPLE_CHANGE"
                                          ? "단순교환"
                                          : item.accident === "SIMPLE_TOUCH"
                                          ? "단순사고(접촉)"
                                          : item.accident === "NORMAL"
                                          ? "사고"
                                          : item.accident === "TOTAL_LOSS"
                                          ? "전손"
                                          : item.accident === "FLOODING"
                                          ? "침수"
                                          : null}
                                      </span>
                                    </div>
                                    <div className={styles.options}>
                                      {item.carOptions.map((opt, idx) => {
                                        return (
                                          idx < 5 && (
                                            <span key={idx}>{opt.nameKr}</span>
                                          )
                                        );
                                      })}
                                    </div>
                                    <div className={styles.price}>
                                      <span>
                                        <em>
                                          {Common.commaFormat(
                                            String(item.carPrice.price).slice(
                                              0,
                                              -4
                                            )
                                          )}
                                        </em>
                                        만원
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null;
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={`${styles.contOption}` + " button-form"}>
                  <button
                    type="button"
                    className="btn btn-lg btn--outline"
                    onClick={() => {
                      handleRemoveGroup();
                    }}
                  >
                    선택 제품 삭제
                  </button>
                </div>

                <div className={`${styles.contBtn}` + " button-form"}>
                  <button
                    type="button"
                    className="btn btn-xl btn--outline_gray"
                    onClick={handleCart}
                  >
                    장바구니 담기
                  </button>
                  <button
                    type="button"
                    className="btn btn-xl btn--primary"
                    onClick={handleBuyRequest}
                  >
                    구매 요청하기
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />

      {/* 로그인 모달 */}
      {modal.open && (
        <ModalConfirm
          dim={modal.dim}
          setModal={setModal}
          title={modal.title}
          message={modal.message}
          notxt={modal.notxt}
          oktxt={modal.oktxt}
          callback1={modal.callback1}
          callback2={modal.callback2}
        />
      )}
    </>
  );
};

export default Favorite;
