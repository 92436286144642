/* eslint-disable */
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { isMobile } from "react-device-detect";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import allActions from "./actions";
import axios from "axios";

import Main from "./layout/Main";
import Sitemap from "./pages/_Sitemap";
import Home from "./pages/Home";
import Components from "./pages/_Components";
import PageModal from "./pages/_PageModal";
import Buy from "./pages/Buy";
import Product from "./pages/Product";
import RecentView from "./pages/RecentView";
import Cart from "./pages/Cart";
import Favorite from "./pages/Favorite";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Join from "./pages/Join";
import Welcome from "./pages/Welcome";
import FindId from "./pages/FindId";
import YourId from "./pages/YourId";
import FindPw from "./pages/FindPw";
import YourPw from "./pages/YourPw";
import Service from "./pages/Service";
import Checkout from "./pages/Checkout";
import Receipt from "./pages/Receipt";
import Mypage from "./pages/Mypage";
import OrderDetail from "./pages/mypages/OrderDetail";
import Sales from "./pages/mypages/Sales";
import AddressBook from "./pages/mypages/AddressBook";
import CorpModify from "./pages/mypages/CorpModify";
import ManagerModify from "./pages/mypages/ManagerModify";
import ManagerPhone from "./pages/mypages/ManagerPhone";
import OrderModify from "./pages/mypages/OrderModify";
import ResetPw from "./pages/mypages/ResetPw";
import PostDetail from "./pages/PostDetail";
import NotFound from "./pages/NotFound";
import MobileView from "./pages/MobileView";

import * as Common from "./actions/Common.js";
import Test from "./Test";

import "./styles/reset.scss";
import "./styles/custom.scss";

import { baseUrl } from "./api";
const BASE_URL = baseUrl();
const MYDATA_URL = "/api/user/member/v1/me";

function App() {
  const dispatch = useDispatch();

  //* 브라우저 시작시 로그인 상태 처리
  const [loginStatus, setLoginStatus] = useState(false);

  useEffect(() => {
    console.log(isMobile);
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      //* 토큰 상태 확인
      const result = await fetchMyData();

      if (!result) {
        setLoginStatus(false);

        Common.delCookie("chada_login_status");
      } else {
        setLoginStatus(true);

        dispatch(allActions.userActions.loginUser());
      }
    } catch (error) {
      console.log(error);
    }
  };

  /****************************************
   * TODO 항목별 API 목록
   ****************************************/
  // 토큰 상태 확인
  const fetchMyData = async () => {
    try {
      const response = await axios.get(BASE_URL + MYDATA_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      return response;
    } catch (err) {
      return false;
    }
  };

  return (
    <div className="App">
      {isMobile ? (
        <Router>
          <Routes>
            {/* All */}
            <Route path="*" element={<MobileView />} />
          </Routes>
        </Router>
      ) : (
        <Router>
          <Main>
            <Routes>
              {/* 루트 접속 */}
              <Route path="/" exact element={<Home />} />

              {/* 컴포넌트 */}
              <Route path="/components" exact element={<Components />} />
              <Route path="/page-modal" element={<PageModal />} />

              {/* 온보딩 */}
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/join" element={<Join />} />
              <Route path="/welcome" element={<Welcome />} />
              <Route path="/findId" element={<FindId />} />
              <Route path="/yourId" element={<YourId />} />
              <Route path="/findPw" element={<FindPw />} />
              <Route path="/yourPw" element={<YourPw />} />

              {/* 홈 */}
              <Route path="/home" exact element={<Home />} />

              {/* 서브페이지 */}
              <Route path="/buy" element={<Buy />} />
              <Route path="/product/:id" element={<Product />} />
              <Route path="/recentView" element={<RecentView />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/favorite" element={<Favorite />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/receipt" element={<Receipt />} />
              <Route path="/mypage" element={<Mypage />} />
              <Route path="/orderDetail" element={<OrderDetail />} />
              <Route path="/sales" element={<Sales />} />
              <Route path="/addressBook" element={<AddressBook />} />
              <Route path="/corpModify" element={<CorpModify />} />
              <Route path="/managerModify" element={<ManagerModify />} />
              <Route path="/managerPhone" element={<ManagerPhone />} />
              <Route path="/orderModify" element={<OrderModify />} />
              <Route path="/resetPw" element={<ResetPw />} />
              <Route path="/postDetail/:id" element={<PostDetail />} />

              <Route path="/service" element={<Service />} />

              {/* 404 */}
              <Route path="*" element={<NotFound />} />

              {/* <Route path="/test" element={<Test />} /> */}
            </Routes>
          </Main>
        </Router>
      )}
    </div>
  );
}

export default App;
