/* eslint-disable */
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import Toast from "../components/Toast.js";
import ModalConfirm from "../components/ModalConfirm.js";

import styles from "./PostDetail.module.scss";

import { baseUrl } from "../api";
const BASE_URL = baseUrl();
const NOTICE_URL = "/api/user/notice/v1";

const PostDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  //TODO API loading state
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);

  //TODO Loading & Init API Datas
  const [postData, setPostData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await axios.get(BASE_URL + NOTICE_URL + `/${id}`);

      setPostData(response.data.data);
      if (response?.status === 200) {
        setLoading(false);
      }
    } catch (error) {
      if (error.response?.data.result === 500) {
        setLoading(true);
        setModal({
          open: true,
          dim: true,
          title: error.response?.data.message,
          message: "",
          notxt: "",
          oktxt: "확인",
          callback2: () => {
            navigate(-1);
          },
        });
      } else {
        handleToast(error.response?.data.message);
        setLoading(false);
      }
    }
  };

  /****************************************
   * TODO 토스트 & 모달 상태
   ****************************************/
  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = (txt) => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);
  //* 모달
  const [modal, setModal] = useState({
    open: false,
    dim: true,
    title: "",
    message: "",
    notxt: "",
    oktxt: "확인",
    callback1: false,
    callback2: false,
  });
  return (
    <>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <div className={`${styles.postDetail}` + " global-width"}>
          <div className={styles.header}>
            <h1>공지사항</h1>
          </div>
          <div className={styles.contents}>
            <div className={styles.postHeader}>
              <span className={styles.title}>{postData.title}</span>
              {loaded && (
                <span className={styles.date}>
                  {postData.createdAt.split("T")[0].replaceAll("-", ".")}
                </span>
              )}
            </div>
            <div className={styles.postContent}>
              <div id="textContainer" className={styles.textarea} readOnly>
                <div
                  dangerouslySetInnerHTML={{ __html: postData.content }}
                ></div>
                {/* {postData.content.split("\n").map((line) => {
                return (
                  <span>
                    {line}
                    <br />
                  </span>
                );
              })} */}
              </div>
            </div>
            <div className={`${styles.buttons}` + " button-form"}>
              <button
                type="button"
                className="btn btn-xl btn--primary"
                onClick={() => {
                  navigate("/contact");
                }}
              >
                목록으로
              </button>
            </div>
          </div>
        </div>
      )}

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />

      {/* 로그인 모달 */}
      {modal.open && (
        <ModalConfirm
          dim={modal.dim}
          setModal={setModal}
          title={modal.title}
          message={modal.message}
          notxt={modal.notxt}
          oktxt={modal.oktxt}
          callback1={modal.callback1}
          callback2={modal.callback2}
        />
      )}
    </>
  );
};

export default PostDetail;
