/* eslint-disable */
import { useEffect, useRef } from "react";

const useThemeObserver = (setState, stateNumber) => {
  const isRef = useRef(0);
  const option = {
    threshold: 0.3,
    rootMargin: "-143px 0px -70% 0px",
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entry) => {
      if (entry[0].isIntersecting) {
        setState(stateNumber);
      }
    }, option);

    if (isRef.current) {
      observer.observe(isRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return [isRef];
};

export default useThemeObserver;
