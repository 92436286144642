/* eslint-disable */
import React, { useRef, useState, useEffect, useContext } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import allActions from "../actions";

import ApiGetToken from "../api/ApiGetToken.js";
import Toast from "../components/Toast.js";
import axios from "axios";
import * as Common from "../actions/Common.js";

import styles from "./Login.module.scss";
import LogoIcon from "../assets/icons/logo-chadapick.svg";

// API URL
import { baseUrl } from "../api";
const BASE_URL = baseUrl();
const LOGIN_URL = "/token/exchange";
const RECENTVIEW_URL = "/api/user/product/v1/recent_view";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //* 로그인 상태 확인
  const userStatus = useSelector((state) => state.currentUser);
  const loginStatus = userStatus.login;

  useEffect(() => {
    // 로그인 상태인 경우 home 화면으로 이동
    // if (loginStatus) {
    //   navigate("/home");
    // }
  }, []);

  const userRef = useRef();
  const errRef = useRef();

  const [userId, setUserId] = useState("");
  const [userPw, setUserPw] = useState("");
  const [success, setSuccess] = useState(false);

  //* 로그인 함수
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // 로그인 시도 및 토큰 발급
      const response = await ApiGetToken.post(BASE_URL + LOGIN_URL, {
        loginId: userId,
        password: userPw,
      });

      // 쿠키 데이터(최근 본 차량) 서버에 전달
      const viewList = Common.getCookieJson("chada_recent_view");

      if (viewList != null) {
        await axios.post(
          BASE_URL + RECENTVIEW_URL,
          {
            viewList: viewList,
          },
          {
            headers: {
              Authorization: `Bearer ${response.data.access_token}`,
            },
          }
        );
      }

      localStorage.setItem("access_token", response.data.access_token); // localStrorage 토큰 저장
      Common.setCookie("chada_login_status", "login", 1); // cookie 로그인 상태 저장
      dispatch(allActions.userActions.loginUser()); // redux 로그인 상태 저장

      navigate("/home");
    } catch (err) {
      if (err.response?.status === 401) {
        handleToast(err.response?.data.message);
      } else {
        handleToast(err.response?.data.message);
      }
    }
  };

  /****************************************
   * TODO 토스트 & 모달 상태
   ****************************************/
  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = (txt) => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);

  return (
    <>
      {success ? (
        <div className={`${styles.login}` + " global-width"}>
          <div className={styles.login__suc}>You are logged in</div>
        </div>
      ) : (
        <div className={`${styles.login}` + " global-width"}>
          <div className={styles.login__slogan}>
            <em>차</em>를 구매하<em>다</em>
          </div>
          <div className={styles.login__logo}>
            <img src={LogoIcon} alt="" />
          </div>

          <form
            className={styles.login__form}
            action=""
            onSubmit={handleSubmit}
          >
            {/* default */}
            <div className="input-form">
              <div className="form-field">
                <div className="input-element">
                  {/* <label htmlFor="userid">userid</label> */}
                  <input
                    type="text"
                    id="userid"
                    className=""
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setUserId(e.target.value)}
                    placeholder="아이디 (이메일)"
                    value={userId}
                    required
                  />
                </div>
              </div>
              {/* <p
                ref={errRef}
                className={errMsg ? "alert-msg error" : null}
                aria-live="assertive"
              >
                {errMsg}
              </p> */}
              {/* <p className="alert-msg error">에러 메시지입니다.</p> */}
              <p className="alert-msg success">인증을 완료했습니다 :)</p>
            </div>
            {/* default */}
            <div className="input-form mt-15">
              <div className="form-field">
                {/* <div className="input-element">
                <input type="password" className="" placeholder="비밀번호" />
              </div> */}

                <div className="input-element">
                  {/* <label htmlFor="userPw">userPw</label> */}
                  <input
                    type="password"
                    id="userpw"
                    className=""
                    onChange={(e) => setUserPw(e.target.value)}
                    placeholder="비밀번호"
                    value={userPw}
                    required
                  />
                </div>
              </div>
              <p className="alert-msg error">에러 메시지입니다.</p>
              <p className="alert-msg success">인증을 완료했습니다 :)</p>
            </div>
            {/* button-default */}
            <div className="button-form mt-25">
              <button type="submit" className="btn btn-xl btn--primary">
                로그인
              </button>
            </div>
          </form>

          <ul className={styles.login__mnb}>
            <li>
              <Link to="/join">회원가입</Link>
            </li>
            <li>
              <Link to="/findId">아이디 찾기</Link>
            </li>
            <li>
              <Link to="/findPw">비밀번호 찾기</Link>
            </li>
          </ul>
        </div>
      )}

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />
    </>
  );
};

export default Login;
